import React, { Fragment, Component } from "react";
import {
  FcTemplate,
  FcOrganization,
  FcWorkflow,
  FcAutomatic,
} from "react-icons/fc";
import "./SkillMainTab.css";
import { SubSkillBackEndNavigation } from "./SubSkillNavigation/SubSkillBackEndNavigation";
import { SubSkillDevOPSNavigation } from "./SubSkillNavigation/SubSkillDevOPSNavigation";
import { SubSkillFrontEndNavigation } from "./SubSkillNavigation/SubSkillFrontEndNavigation";
import { SubSkillOthersNavigation } from "./SubSkillNavigation/SubSkillOthersNavigation";

interface ITabMenuData {
  name: string;
  componentName: string;
  link: string;
  shown: Boolean;
}

type Props = {};
type State = {
  frontEndData: ITabMenuData[];
  backEndData: ITabMenuData[];
  devOpsData: ITabMenuData[];
  othersData: ITabMenuData[];
};

class SkillMainTab extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      frontEndData: [
        {
          name: "AngularJS",
          componentName: "<AngularContent />",
          link: "angularjs",
          shown: false,
        },
        {
          name: "ReactJS",
          componentName: "<ReactContent />",
          link: "reactjs",
          shown: false,
        },
        {
          name: "VueJS",
          componentName: "<VueContent />",
          link: "vuejs",
          shown: false,
        },
        {
          name: "Svelte",
          componentName: "<SvelteContent />",
          link: "svelte",
          shown: false,
        },
      ],
      backEndData: [
        {
          name: "PHP",
          componentName: "<PHPContent />",
          link: "php",
          shown: false,
        },
        {
          name: "Python",
          componentName: "<PythonContent />",
          link: "python",
          shown: false,
        },
        {
          name: "Ruby",
          componentName: "<RubyContent />",
          link: "ruby",
          shown: false,
        },
        {
          name: "C#",
          componentName: "<CSharpContent />",
          link: "csharp",
          shown: false,
        },
      ],
      devOpsData: [
        {
          name: "AWS",
          componentName: "<AWSContent />",
          link: "aws",
          shown: true,
        },
        {
          name: "Google",
          componentName: "<GoogleContent />",
          link: "google",
          shown: false,
        },
        {
          name: "Digital Ocean",
          componentName: "<DigitalOceanContent />",
          link: "do",
          shown: false,
        },
        {
          name: "Heroku",
          componentName: "<HerokuContent />",
          link: "heroku",
          shown: false,
        },
        {
          name: "Docker",
          componentName: "<CSharpContent />",
          link: "docker",
          shown: false,
        },
        {
          name: "Git",
          componentName: "<CSharpContent />",
          link: "git",
          shown: false,
        },
      ],
      othersData: [
        {
          name: "Design",
          componentName: "<DesignContent />",
          link: "design",
          shown: false,
        },
        {
          name: "Planning",
          componentName: "<PlanningContent />",
          link: "planning",
          shown: false,
        },
      ],
    };
  }

  render() {
    return (
      <Fragment>
        <div id="skilltabmain">
          <ul
            className="nav nav-tabs flex  flex-row flex-wrap text-xs  list-none border-b-0 pl-0 mb-4 float-right bg-blue-200"
            id="tabs-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a
                href="#tabs-frontend"
                className="
      nav-link
      block
      font-medium
      text-xs
      xs:text-xxs
      leading-tight
      uppercase
      border-x-0 border-t-0 border-b-2 border-transparent
      xs:px-2
      px-6
      py-3
      my-2
      hover:border-transparent hover:bg-gray-100
      focus:bg-gray-100
      active:bg-gray-100
      focus:border-transparent
      active
    "
                id="tabs-backend-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-backend"
                role="tab"
                aria-controls="tabs-backend"
                aria-selected="true"
              >
                <FcOrganization className="inline-block" size={22} /> Back-end
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                href="#tabs-profile"
                className="
      nav-link
      block
      font-medium
      text-xs
      xs:text-xxs
      leading-tight
      uppercase
      border-x-0 border-t-0 border-b-2 border-transparent
      xs:px-2
      px-6
      py-3
      my-2
      hover:border-transparent hover:bg-gray-100
      focus:bg-gray-100
      active:bg-gray-100
      focus:border-transparent
    "
                id="tabs-frontend-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-frontend"
                role="tab"
                aria-controls="tabs-frontend"
                aria-selected="false"
              >
                <FcTemplate className="inline-block" size={22} /> Front-end
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                href="#tabs-messages"
                className="
      nav-link
      block
      font-medium
      text-xs
      xs:text-xxs
      leading-tight
      uppercase
      border-x-0 border-t-0 border-b-2 border-transparent
      xs:px-2
      px-6
      py-3
      my-2
      hover:border-transparent hover:bg-gray-100
      focus:bg-gray-100
      active:bg-gray-100
      focus:border-transparent
    "
                id="tabs-devops-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-devops"
                role="tab"
                aria-controls="tabs-devops"
                aria-selected="false"
              >
                <FcWorkflow className="inline-block" size={22} /> DevOps
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                href="#tabs-other"
                className="
                nav-link
                block
                font-medium
                text-xs
                xs:text-xxs
                leading-tight
                uppercase
                border-x-0 border-t-0 border-b-2 border-transparent
                xs:px-2
                px-6
                py-3
                my-2
                hover:border-transparent hover:bg-gray-100
                focus:bg-gray-100
                focus:border-transparent
    "
                id="tabs-other-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-other"
                role="tab"
                aria-controls="tabs-other"
                aria-selected="false"
              >
                <FcAutomatic className="inline-block" size={22} /> Others
              </a>
            </li>
          </ul>
          <div className="tab-content clear-both" id="tabs-tabContent">
            <div
              className="tab-pane fade"
              id="tabs-frontend"
              role="tabpanel"
              aria-labelledby="tabs-frontend-tab"
            >
              <SubSkillFrontEndNavigation
                menuObject={this.state.frontEndData}
              />
            </div>
            <div
              className="tab-pane fade show active"
              id="tabs-backend"
              role="tabpanel"
              aria-labelledby="tabs-backed-tab"
            >
              <SubSkillBackEndNavigation menuObject={this.state.backEndData} />
            </div>
            <div
              className="tab-pane fade"
              id="tabs-devops"
              role="tabpanel"
              aria-labelledby="tabs-devops-tab"
            >
              <SubSkillDevOPSNavigation menuObject={this.state.devOpsData} />
            </div>
            <div
              className="tab-pane fade"
              id="tabs-other"
              role="tabpanel"
              aria-labelledby="tabs-other-tab"
            >
              <SubSkillOthersNavigation menuObject={this.state.othersData} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SkillMainTab;
