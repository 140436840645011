import React, { Fragment, Component } from "react";
import "./AboutButton.css";

type Props = {
  aboutProfile: () => void;
  aboutExperience: () => void;
  aboutBuild: () => void;
  aboutTinker: () => void;
};

class AboutButton extends Component<Props> {
  childAboutProfile = () => {
    this.props.aboutProfile();
  };
  childAboutExperience = () => {
    this.props.aboutExperience();
  };
  childAboutBuild = () => {
    this.props.aboutBuild();
  };
  childAboutTinker = () => {
    this.props.aboutTinker();
  };
  render() {
    return (
      <Fragment>
        <div id="aboutbuttoncircles">
          <div className="about1">
            <span
              className="rounded-full text-gray-200 bg-mostblue font-semibold text-sm flex align-center cursor-pointer active:bg-mostbluehover hover:bg-mostbluehover transition duration-300 ease w-max h-20 about-button-wrapper-1"
              onClick={this.childAboutBuild}
            >
              <img
                className="rounded-full w-20 h-20 max-w-none"
                alt="React Tailwind Animejs"
                src="./weblogo.png"
              />
              <span className="flex items-center px-3 py-2">Web Build</span>
              <button className="bg-transparent hover focus:outline-none">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="times"
                  className="w-3 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512"
                  onClick={this.childAboutBuild}
                ></svg>
              </button>
            </span>
          </div>
          <div className="about2">
            <span
              className="rounded-full text-gray-200 bg-mostblue font-semibold text-sm flex align-center cursor-pointer active:bg-mostbluehover hover:bg-mostbluehover transition duration-300 ease w-max about-button-wrapper-2"
              onClick={this.childAboutProfile}
            >
              <img
                className="rounded-full max-w-none about-button-2"
                alt="Aaron Villanueva Profile"
                src="./profile-1l.png"
              />
              <span className="flex items-center px-3 py-2">Profile</span>
              <button
                className="bg-transparent hover focus:outline-none"
                onClick={this.childAboutProfile}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="times"
                  className="w-3 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512"
                ></svg>
              </button>
            </span>
          </div>
          <div className="about3">
            <span
              className="rounded-full text-gray-200 bg-mostblue font-semibold text-sm flex align-center cursor-pointer active:bg-mostbluehover hover:bg-mostbluehover transition duration-300 ease w-max about-button-wrapper-3"
              onClick={this.childAboutExperience}
            >
              <img
                className="rounded-full max-w-none about-button-3"
                alt="Aaron Villanueva Experience"
                src="./profile-2.jpg"
              />
              <span className="flex items-center px-3 py-2">Experience</span>
              <button
                className="bg-transparent hover focus:outline-none"
                onClick={this.childAboutExperience}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="times"
                  className="w-3 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512"
                ></svg>
              </button>
            </span>
          </div>
          <div className="about4">
            <span
              className="rounded-full text-gray-200 bg-mostblue font-semibold text-sm flex align-center cursor-pointer active:bg-mostbluehover hover:bg-mostbluehover transition duration-300 ease w-max about-button-wrapper-4"
              onClick={this.childAboutTinker}
            >
              <img
                className="rounded-full max-w-none about-button-4"
                alt="Aaron Villanueva Tinker"
                src="./profile-3.jpg"
              />
              <span className="flex items-center px-3 py-2">Tinker</span>
              <button
                className="bg-transparent hover focus:outline-none"
                onClick={this.childAboutTinker}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="times"
                  className="w-3 mr-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512"
                ></svg>
              </button>
            </span>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AboutButton;
