import React, { Fragment, FunctionComponent } from "react";
import { SkillHeader } from "../SkillHeader";
import "./VueContent.css";

export const VueContent: FunctionComponent = () => {
  return (
    <Fragment>
      <div className="block p-6 rounded-lg shadow-lg bg-white  relative">
        <SkillHeader />
        <h5 className="text-gray-900 absolute left-40 text-2xl leading-tight font-bold mb-2">
          Vue JS
        </h5>

        <div
          aria-label="card react"
          className="focus:outline-none flex sm:w-full md:w-full pb-20 mt-20"
        >
          <div className="w-20  h-20 relative xs:absolute xs:top-0 xs:right-0 mr-5 ">
            <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1"></div>
            <div className="absolute text-white bottom-0 left-0 bg-theme rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
              <img
                src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/vue/vue.png"
                alt="Vue JS"
              />
            </div>
          </div>
          <div className="w-full">
            <h2 className="focus:outline-none text-lg font-bold leading-tight text-gray-800">
              Mixins with Laravel, This JS Framweork is almost with laravel
              projects I work on. Woek with people that use within laravel
              projects.
            </h2>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-2">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Used frontend UI with
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800  text-white rounded">
                vuetify
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                Bootstrap
              </span>
            </div>

            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-1">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Understand usage of
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800  text-white rounded">
                Composition API
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800  text-white rounded">
                Mixins
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800  text-white rounded">
                Props
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800  text-white rounded">
                Lifecycle hooks
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800  text-white rounded">
                Vuex
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800  text-white rounded">
                Typescript
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
