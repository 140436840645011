import React, { Fragment, Component } from "react";
import "./Footer.css";
import FlockBirds from "./Footer/FlockBirds";
import MainBackgroundSquares from "./Footer/MainBackgroundSquares";
import MainBuilding from "./Footer/MainBuilding";
import OtherBuildings from "./Footer/OtherBuildings";

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <div id="footerajov">
          <MainBackgroundSquares />
          <FlockBirds />
          <MainBuilding />
          <OtherBuildings />
        </div>
      </Fragment>
    );
  }
}

export default Footer;
