import React, { Fragment, FunctionComponent } from "react";
import "./CakePHPContent.css";

export const CakePHPContent: FunctionComponent = () => {
  return (
    <Fragment>
      <div className="flex flex-col  justify-between lg:flex-row-reverse rounded overflow-hidden h-auto border shadow shadow-lg">
        <img
          className="block h-auto w-full lg:w-48 flex-none bg-cover"
          src="./cakephp.png"
          alt="CakePHP"
        />
        <div className="bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col leading-normal">
          <div className="text-black font-bold text-xl mb-2 leading-tight">
            <h2 className="focus:outline-none text-lg font-bold leading-tight text-gray-800">
              The first PHP framework I worked on and was the best IMHO back
              late 2000 era
            </h2>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-2">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Experience with development
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                Bake commands
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                Scaffolding
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                Controller
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                Model
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                View
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                Helpers
              </span>
            </div>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-1">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                First Project
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                2009
              </span>
            </div>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-1">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Last Project
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                2018
              </span>
            </div>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-1">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Last version used
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                3
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
