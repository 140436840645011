import { Component, Fragment, ReactNode } from "react";
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "./InfiniteScroll.css";

SwiperCore.use([Autoplay]);

class InfiniteScroll extends Component {
  render(): ReactNode {
    return (
      <Fragment>
        <div className="skewMain">
          <div className="bg-text-container">
            <div className="animate-text-deux">
              <span className="dualScroll">Continuous education&nbsp;</span>
              <span className="dualScroll">Web Updates&nbsp;</span>
              <span className="dualScroll">
                Unlimited amount of energy&nbsp;
              </span>
            </div>
            <div className="animate-text-deux left">
              <span className="dualScroll">Continuous education&nbsp;</span>
              <span className="dualScroll">Web Updates&nbsp;</span>
              <span className="dualScroll">
                Unlimited amount of energy&nbsp;
              </span>
            </div>
          </div>

          <div className="container">
            <Swiper
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              effect={"fade"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={1}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              loop={true}
              pagination={true}
              modules={[EffectCoverflow, Pagination]}
              className="mySwiper trioblog"
            >
              {/*
              <SwiperSlide>
                <div className="col">
                  <h1>Exploring Redwood</h1>
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-cyan-500 text-white rounded">
                    What I am doing now?
                  </span>
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                    Aug 2022
                  </span>
                  <p>
                    Storybook is one of my favorite tool with ReactJS, once I
                    heard about Redwood and has the weave of all great web tools
                    out there like Prisma, Jest.. etc and as a practicing
                    Typescript developer. I instantly dove to its documentation
                    and tutorials, and applying it on test project, so far its
                    really great for organizing project with testing.
                  </p>
                </div>
              </SwiperSlide>
            */}
              <SwiperSlide>
                <div className="col">
                  <h1>MinIO</h1>
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-cyan-500 text-white rounded">
                    What I am doing now?
                  </span>
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                    Oct 2022
                  </span>
                  <p>
                    Project with serverless, minIO in my opinion is great to
                    test project locally before deplying to production
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col">
                  <h1>GraphQL WP CMS headless</h1>
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-cyan-500 text-white rounded">
                    What I am doing now?
                  </span>
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                    Nov 2023
                  </span>
                  <p>
                    Currently, I am working on a project that utilizes WordPress
                    with support for various GraphQL plugins, integrating
                    seamlessly with NextJS
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col">
                  <h1>.NET Maui </h1>
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-cyan-500 text-white rounded">
                    What I am doing now?
                  </span>
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                    Dec 2023
                  </span>
                  <p>Just creating simple app with C# .Net Maui</p>
                </div>
              </SwiperSlide>
              {/*
              <SwiperSlide>
                <div className="col">
                  <h1>ChatGPT Prompt </h1>
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-cyan-500 text-white rounded">
                    What I am enjoying right now?
                  </span>
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                    May 2023
                  </span>
                  <p>
                    If you've heard about the buzz surrounding ChatGPT then it's
                    not a lie, it's one of the best things that has happened
                    since the dark era of the internet, largely due to social
                    media. I'm really having fun discovering how to make sense
                    of using its prompts.
                  </p>
                </div>
              </SwiperSlide>
               */}
            </Swiper>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default InfiniteScroll;
