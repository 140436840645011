import React, { Fragment, Component } from "react";
import "./MainBuilding.css";
import anime from "animejs";

class MainBuilding extends Component {
  componentDidMount() {
    this.buildingRectAnimation();
  }
  buildingRectAnimation = () => {
    anime({
      targets: "#building-rects .building-rect",
      //translateX: 270,
      opacity: function () {
        return anime.random(0, 1);
      },
      easing: "easeInOutQuad",
      duration: 2000,
      complete: this.buildingRectAnimation,
    });
  };

  render() {
    return (
      <Fragment>
        <div id="mainbuilding">
          <svg
            id="building-rects"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 267.14 122.36"
          >
            <g id="right-border">
              <g>
                <rect
                  className="footer-mainbuilding-1"
                  x="184.46"
                  y="20.7"
                  width="29.23"
                  height="99.18"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="24.28"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="24.28"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="24.28"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="24.28"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="24.28"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="24.28"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="30.34"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="30.34"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="30.34"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="30.34"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="30.34"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="30.34"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="36.39"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="36.39"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="36.39"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="36.39"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="36.39"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="36.39"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="42.44"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="42.44"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="42.44"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="42.44"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="42.44"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="42.44"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="48.49"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="48.49"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="48.49"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="48.49"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="48.49"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="48.49"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="54.55"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="54.55"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="54.55"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="54.55"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="54.55"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="54.55"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="60.6"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="60.6"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="60.6"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="60.6"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="60.6"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="60.6"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="66.65"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="66.65"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="66.65"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="66.65"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="66.65"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="66.65"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="72.7"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="72.7"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="72.7"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="72.7"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="72.7"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="72.7"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="78.76"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="78.76"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="78.76"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="78.76"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="78.76"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="78.76"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="84.81"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="84.81"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="84.81"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="84.81"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="84.81"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="84.81"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="90.86"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="90.86"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="90.86"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="90.86"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="90.86"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="90.86"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="96.91"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="96.91"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="96.91"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="96.91"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="96.91"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="96.91"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="187.03"
                  y="102.97"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="191.22"
                  y="102.97"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="195.4"
                  y="102.97"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="199.58"
                  y="102.97"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="203.76"
                  y="102.97"
                  width="3.08"
                  height="3.3"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="207.95"
                  y="102.97"
                  width="3.08"
                  height="3.3"
                />
              </g>
              <g>
                <rect
                  className="footer-mainbuilding-1"
                  x="223.36"
                  y="50.64"
                  width="20.6"
                  height="69.88"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="53.17"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="53.17"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="53.17"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="53.17"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="53.17"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="53.17"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="57.44"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="57.44"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="57.44"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="57.44"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="57.44"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="57.44"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="61.7"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="61.7"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="61.7"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="61.7"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="61.7"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="61.7"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="65.96"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="65.96"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="65.96"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="65.96"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="65.96"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="65.96"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="70.23"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="70.23"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="70.23"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="70.23"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="70.23"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="70.23"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="74.49"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="74.49"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="74.49"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="74.49"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="74.49"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="74.49"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="78.76"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="78.76"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="78.76"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="78.76"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="78.76"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="78.76"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="83.02"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="83.02"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="83.02"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="83.02"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="83.02"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="83.02"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="87.29"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="87.29"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="87.29"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="87.29"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="87.29"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="87.29"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="91.55"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="91.55"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="91.55"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="91.55"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="91.55"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="91.55"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="95.82"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="95.82"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="95.82"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="95.82"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="95.82"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="95.82"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="100.08"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="100.08"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="100.08"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="100.08"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="100.08"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="100.08"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="104.35"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="104.35"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="104.35"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="104.35"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="104.35"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="104.35"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="225.17"
                  y="108.61"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="228.12"
                  y="108.61"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="231.07"
                  y="108.61"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="234.01"
                  y="108.61"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="236.96"
                  y="108.61"
                  width="2.17"
                  height="2.33"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="239.91"
                  y="108.61"
                  width="2.17"
                  height="2.33"
                />
              </g>
              <rect
                className="footer-mainbuilding-1"
                x="155.1"
                y="53.52"
                width="15.37"
                height="65.38"
              />
              <rect
                className="footer-mainbuilding-1"
                x="254.98"
                y="93.18"
                width="7.11"
                height="27.46"
              />
              <rect
                className="footer-mainbuilding-1"
                x="261.32"
                y="102.12"
                width="5.8"
                height="18.74"
              />
              <g>
                <polygon
                  className="footer-mainbuilding-1"
                  points="220.45 15.98 221.14 15.98 219.84 11.72 220.53 11.72 217.16 0 214.21 11.72 214.91 11.72 213.6 15.98 214.37 15.98 206.85 28.26 209.07 28.26 209.07 120.5 225.92 120.5 225.92 28.26 227.97 28.26 220.45 15.98"
                />
                <path
                  className="building-rect footer-mainbuilding-2"
                  d="M1231.9,921.46v-7.2c0-1.68-.83-3-1.86-3h-1.7c-1,0-1.86,1.36-1.86,3v7.2Z"
                  transform="translate(-1011.88 -821.3)"
                />
              </g>
              <g>
                <rect
                  className="footer-mainbuilding-1"
                  x="91.98"
                  y="94.66"
                  width="6.99"
                  height="23.71"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="95.52"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="95.52"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="95.52"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="95.52"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="95.52"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="95.52"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="96.97"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="96.97"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="96.97"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="96.97"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="96.97"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="96.97"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="98.41"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="98.41"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="98.41"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="98.41"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="98.41"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="98.41"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="99.86"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="99.86"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="99.86"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="99.86"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="99.86"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="99.86"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="101.31"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="101.31"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="101.31"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="101.31"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="101.31"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="101.31"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="102.75"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="102.75"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="102.75"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="102.75"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="102.75"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="102.75"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="104.2"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="104.2"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="104.2"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="104.2"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="104.2"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="104.2"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="105.65"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="105.65"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="105.65"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="105.65"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="105.65"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="105.65"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="107.09"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="107.09"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="107.09"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="107.09"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="107.09"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="107.09"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="108.54"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="108.54"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="108.54"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="108.54"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="108.54"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="108.54"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="109.99"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="109.99"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="109.99"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="109.99"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="109.99"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="109.99"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="111.43"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="111.43"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="111.43"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="111.43"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="111.43"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="111.43"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="112.88"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="112.88"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="112.88"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="112.88"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="112.88"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="112.88"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="92.59"
                  y="114.33"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="93.59"
                  y="114.33"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="94.59"
                  y="114.33"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="95.59"
                  y="114.33"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="96.59"
                  y="114.33"
                  width="0.74"
                  height="0.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="97.59"
                  y="114.33"
                  width="0.74"
                  height="0.79"
                />
              </g>
              <g>
                <rect
                  className="footer-mainbuilding-1"
                  x="98.47"
                  y="78.11"
                  width="11.96"
                  height="40.58"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="79.57"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="79.57"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="79.57"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="79.57"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="79.57"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="79.57"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="82.05"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="82.05"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="82.05"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="82.05"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="82.05"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="82.05"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="84.53"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="84.53"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="84.53"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="84.53"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="84.53"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="84.53"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="87"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="87"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="87"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="87"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="87"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="87"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="89.48"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="89.48"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="89.48"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="89.48"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="89.48"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="89.48"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="91.96"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="91.96"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="91.96"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="91.96"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="91.96"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="91.96"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="94.44"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="94.44"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="94.44"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="94.44"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="94.44"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="94.44"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="96.91"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="96.91"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="96.91"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="96.91"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="96.91"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="96.91"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="99.39"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="99.39"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="99.39"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="99.39"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="99.39"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="99.39"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="101.86"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="101.86"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="101.86"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="101.86"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="101.86"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="101.86"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="104.34"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="104.34"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="104.34"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="104.34"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="104.34"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="104.34"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="106.82"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="106.82"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="106.82"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="106.82"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="106.82"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="106.82"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="109.3"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="109.3"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="109.3"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="109.3"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="109.3"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="109.3"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="99.52"
                  y="111.77"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="101.23"
                  y="111.77"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="102.94"
                  y="111.77"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="104.65"
                  y="111.77"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="106.36"
                  y="111.77"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="108.08"
                  y="111.77"
                  width="1.26"
                  height="1.35"
                />
              </g>
              <g>
                <rect
                  className="footer-mainbuilding-1"
                  x="243.65"
                  y="80.06"
                  width="11.96"
                  height="40.58"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="81.53"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="81.53"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="81.53"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="81.53"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="81.53"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="81.53"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="84.01"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="84.01"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="84.01"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="84.01"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="84.01"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="84.01"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="86.48"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="86.48"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="86.48"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="86.48"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="86.48"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="86.48"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="88.96"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="88.96"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="88.96"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="88.96"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="88.96"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="88.96"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="91.44"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="91.44"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="91.44"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="91.44"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="91.44"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="91.44"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="93.91"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="93.91"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="93.91"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="93.91"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="93.91"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="93.91"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="96.39"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="96.39"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="96.39"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="96.39"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="96.39"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="96.39"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="98.87"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="98.87"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="98.87"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="98.87"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="98.87"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="98.87"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="101.34"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="101.34"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="101.34"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="101.34"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="101.34"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="101.34"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="103.82"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="103.82"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="103.82"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="103.82"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="103.82"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="103.82"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="106.3"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="106.3"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="106.3"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="106.3"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="106.3"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="106.3"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="108.77"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="108.77"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="108.77"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="108.77"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="108.77"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="108.77"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="111.25"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="111.25"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="111.25"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="111.25"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="111.25"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="111.25"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="244.7"
                  y="113.73"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="246.42"
                  y="113.73"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="248.13"
                  y="113.73"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="249.84"
                  y="113.73"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="251.55"
                  y="113.73"
                  width="1.26"
                  height="1.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="253.26"
                  y="113.73"
                  width="1.26"
                  height="1.35"
                />
              </g>
              <g>
                <rect
                  className="footer-mainbuilding-1"
                  x="130.87"
                  y="34.83"
                  width="24.75"
                  height="84"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="37.87"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="37.87"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="37.87"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="37.87"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="37.87"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="37.87"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="42.99"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="42.99"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="42.99"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="42.99"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="42.99"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="42.99"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="48.12"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="48.12"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="48.12"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="48.12"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="48.12"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="48.12"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="53.24"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="53.24"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="53.24"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="53.24"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="53.24"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="53.24"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="58.37"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="58.37"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="58.37"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="58.37"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="58.37"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="58.37"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="63.5"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="63.5"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="63.5"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="63.5"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="63.5"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="63.5"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="68.62"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="68.62"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="68.62"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="68.62"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="68.62"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="68.62"
                  width="2.61"
                  height="2.79"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="73.75"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="73.75"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="73.75"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="73.75"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="73.75"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="73.75"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="78.88"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="78.88"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="78.88"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="78.88"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="78.88"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="78.88"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="84"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="84"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="84"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="84"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="84"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="84"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="89.13"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="89.13"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="89.13"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="89.13"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="89.13"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="89.13"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="94.25"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="94.25"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="94.25"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="94.25"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="94.25"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="94.25"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="99.38"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="99.38"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="99.38"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="99.38"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="99.38"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="99.38"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="133.05"
                  y="104.51"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="136.59"
                  y="104.51"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="140.14"
                  y="104.51"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="143.68"
                  y="104.51"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="147.22"
                  y="104.51"
                  width="2.61"
                  height="2.8"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="150.76"
                  y="104.51"
                  width="2.61"
                  height="2.8"
                />
              </g>
              <g>
                <path
                  className="footer-mainbuilding-1"
                  d="M1196.5,903s0,0,0-.06a6.64,6.64,0,0,0-13.27,0s0,0,0,.06H1182v37.79h15.59V903Z"
                  transform="translate(-1011.88 -821.3)"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="176.05"
                  y="113.35"
                  width="3.72"
                  height="5.21"
                />
              </g>
              <rect
                className="building-rect footer-mainbuilding-1"
                x="87.4"
                y="103.64"
                width="4.5"
                height="14.2"
              />
              <g>
                <rect
                  className="footer-mainbuilding-1"
                  x="110.26"
                  y="48.26"
                  width="20.77"
                  height="70.48"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="50.81"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="50.81"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="50.81"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="50.81"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="50.81"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="50.81"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="55.11"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="55.11"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="55.11"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="55.11"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="55.11"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="55.11"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="59.42"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="59.42"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="59.42"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="59.42"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="59.42"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="59.42"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="63.72"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="63.72"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="63.72"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="63.72"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="63.72"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="63.72"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="68.02"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="68.02"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="68.02"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="68.02"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="68.02"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="68.02"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="72.32"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="72.32"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="72.32"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="72.32"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="72.32"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="72.32"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="76.62"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="76.62"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="76.62"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="76.62"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="76.62"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="76.62"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="80.92"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="80.92"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="80.92"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="80.92"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="80.92"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="80.92"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="85.22"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="85.22"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="85.22"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="85.22"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="85.22"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="85.22"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="89.52"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="89.52"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="89.52"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="89.52"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="89.52"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="89.52"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="93.83"
                  width="2.19"
                  height="2.34"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="93.83"
                  width="2.19"
                  height="2.34"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="93.83"
                  width="2.19"
                  height="2.34"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="93.83"
                  width="2.19"
                  height="2.34"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="93.83"
                  width="2.19"
                  height="2.34"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="93.83"
                  width="2.19"
                  height="2.34"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="98.13"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="98.13"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="98.13"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="98.13"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="98.13"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="98.13"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="102.43"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="102.43"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="102.43"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="102.43"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="102.43"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="102.43"
                  width="2.19"
                  height="2.35"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="112.09"
                  y="106.73"
                  width="2.19"
                  height="2.34"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="115.06"
                  y="106.73"
                  width="2.19"
                  height="2.34"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="118.04"
                  y="106.73"
                  width="2.19"
                  height="2.34"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="121.01"
                  y="106.73"
                  width="2.19"
                  height="2.34"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="123.98"
                  y="106.73"
                  width="2.19"
                  height="2.34"
                />
                <rect
                  className="building-rect footer-mainbuilding-2"
                  x="126.95"
                  y="106.73"
                  width="2.19"
                  height="2.34"
                />
              </g>
              <line
                className="footer-mainbuilding-3"
                x1="0.01"
                y1="118.56"
                x2="267.13"
                y2="120.86"
              />
            </g>
          </svg>
        </div>
      </Fragment>
    );
  }
}

export default MainBuilding;
