import React, { Fragment, FunctionComponent } from "react";
import "./DrupalContent.css";

export const DrupalContent: FunctionComponent = () => {
  return (
    <Fragment>
      <div className="flex flex-col  justify-between lg:flex-row-reverse rounded overflow-hidden h-auto border shadow shadow-lg">
        <img
          className="block h-auto w-full lg:w-48 flex-none bg-cover"
          src="./drupal.png"
          alt="Drupal"
        />
        <div className="bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col leading-normal">
          <div className="text-black font-bold text-xl mb-2 leading-tight">
            <h2 className="focus:outline-none text-lg font-bold leading-tight text-gray-800">
              When I used this CMS back then it was the most secure compare to
              other Content management system, but not much people use it, cause
              its much complex and hard to maintain in my opinion.
            </h2>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-2">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Experience with development
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                Module
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                Custom Theme
              </span>
            </div>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-1">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                First Project
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                2010
              </span>
            </div>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-1">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Last Project
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                2012
              </span>
            </div>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-1">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Last version used
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                7.15
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
