import React, { FunctionComponent } from "react";

import Popover from "@paprika/popover";

interface PopOverProps {
  content: string;
  children: React.ReactNode;
}

export const Soc: FunctionComponent<PopOverProps> = ({ content, children }) => {
  const [isOpen, setOpen] = React.useState(false);

  const changeButton = () => {
    setOpen(true);
  };

  return (
    <Popover
      isOpen={isOpen}
      zIndex={2000}
      onClose={() => {
        setOpen(false);
      }}
      isEager={true}
      isDark={true}
    >
      <button onClick={changeButton}>{children}</button>
      <Popover.Content>
        <Popover.Card className="bg-blue-700">{content}</Popover.Card>
      </Popover.Content>
      <Popover.Tip zIndex={2000} />
    </Popover>
  );
};
