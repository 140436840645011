import React, { Fragment, FunctionComponent } from "react";
import "./OtherCMSContent.css";

export const OtherCMSContent: FunctionComponent = () => {
  return (
    <Fragment>
      <div className="flex flex-col  justify-between lg:flex-row-reverse rounded overflow-hidden h-auto border shadow shadow-lg">
        <div className="block h-auto w-full lg:w-48 flex-none bg-cover">
          {" "}
          &nbsp;{" "}
        </div>
        <div className="bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col leading-normal">
          <div className="text-black font-bold text-xl mb-2 leading-tight">
            <h2 className="focus:outline-none text-lg font-bold leading-tight text-gray-800">
              CMS tools I used once for a project, but not really explored too
              much, just maintaining front-end or adjusting bug on backend.
            </h2>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-2">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Concrete5
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Typo 3
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                MediaWiki
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                CMS Made Simple (CMSMS)
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
