import React, { Fragment, Component } from "react";
import { CraftCMSContent } from "./CMS/CraftCMSContent";
import { DrupalContent } from "./CMS/DrupalContent";
import { JoomlaContent } from "./CMS/JoomlaContent";
import { WordpressContent } from "./CMS/WordpressContent";
import { OtherCMSContent } from "./CMS/OtherCMSContent";
import "./PHPSubSkillContent.css";

interface ITabMenuData {
  name: string;
  componentName: string;
  link: string;
  shown: Boolean;
}

type Props = {
  name: string;
  show: boolean;
};
type State = {
  phpData: ITabMenuData[];
};

class PHPSubSkillContent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phpData: [
        {
          name: "Wordpress",
          componentName: "<WordpressContent />",
          link: "wp",
          shown: true,
        },
        {
          name: "CreftCMS",
          componentName: "<CraftCMSContent />",
          link: "craftcms",
          shown: false,
        },
        {
          name: "Joomla",
          componentName: "<JoomlaContent />",
          link: "joomla",
          shown: false,
        },
        {
          name: "Drupal",
          componentName: "<DrupalContent />",
          link: "drupal",
          shown: false,
        },
        {
          name: "Others",
          componentName: "<OtherCMSContent />",
          link: "others",
          shown: false,
        },
      ],
    };
  }

  render() {
    const { phpData } = this.state;
    const { show } = this.props;
    return (
      <Fragment>
        <ul
          className={`nav nav-pills nav-justified flex flex-col md:flex-row flex-wrap list-none pl-0 mb-4 ${
            show ? "" : "hidden"
          }`}
          id="pills-tabJustify"
          role="tablist"
        >
          {phpData.map((anObjectMapped, index) => {
            return (
              <li
                className="nav-item flex-grow text-center my-2 md:mr-2"
                key={index}
                role="presentation"
              >
                <a
                  href={`#pills-${anObjectMapped.link}-tabJustify`}
                  className="
      nav-link
      w-full
      block
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      px-6
      py-3
      focus:outline-none focus:ring-0
    "
                  id={`#pills-${anObjectMapped.link}-tabJustify`}
                  data-bs-toggle="pill"
                  data-bs-target={`#pills-${anObjectMapped.link}Justify`}
                  role="tab"
                  aria-controls={`pills-${anObjectMapped.link}Justify`}
                  aria-selected="true"
                >
                  {anObjectMapped.name}
                </a>
              </li>
            );
          })}
        </ul>
        <div
          className={`tab-content ${show ? "" : "hidden"}`}
          id="pills-tabContentJustify"
        >
          {/* {`tab-pane fade ${show ? "" : "show active"}`} */}
          <div
            className="tab-pane fade show active"
            id="pills-wpJustify"
            role="tabpanel"
            aria-labelledby="pills-wp-tabJustify"
          >
            <WordpressContent />
          </div>
          <div
            className="tab-pane fade"
            id="pills-craftcmsJustify"
            role="tabpanel"
            aria-labelledby="pills-craftcms-tabJustify"
          >
            <CraftCMSContent />
          </div>
          <div
            className="tab-pane fade"
            id="pills-joomlaJustify"
            role="tabpanel"
            aria-labelledby="pills-joomla-tabJustify"
          >
            <JoomlaContent />
          </div>
          <div
            className="tab-pane fade"
            id="pills-drupalJustify"
            role="tabpanel"
            aria-labelledby="pills-drupal-tabJustify"
          >
            <DrupalContent />
          </div>
          <div
            className="tab-pane fade"
            id="pills-othersJustify"
            role="tabpanel"
            aria-labelledby="pills-others-tabJustify"
          >
            <OtherCMSContent />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PHPSubSkillContent;
