import React, { Fragment, Component } from "react";
import AjovLogo from "./AjovLogo";
import "./Background.css";
import anime from "animejs";

class Background extends Component {
  componentDidMount() {
    var bgAnimation = (function () {
      var bgTimeline = anime({
        targets: "#Layer_2 .bgajovanim",
        translateX: 270,
        rotate: "1turn",
        delay: anime.stagger(200, { start: 100, direction: "reverse" }),
        easing: "easeInOutQuad",
        direction: "reverse",
      });

      function init() {
        bgTimeline.play();
      }

      return {
        init: init,
      };
    })();
    bgAnimation.init();
    //this.rotateMainBG();
  }

  rotateMainBG = () => {
    anime({
      targets: "#Layer_2 .bgajovanim",
      //translateX: 0,
      /*opacity: function () {
        return anime.random(0, 1);
      },*/
      translateX: function () {
        return anime.random(0, 270);
      },
      /*rotate: function () {
        return anime.random(0, 360);
      },*/
      easing: "easeInOutQuad",
      delay: 10000,
      duration: 2000,
      complete: this.rotateMainBG,
    });
  };

  render() {
    return (
      <Fragment>
        <div id="main-background">
          <svg
            id="Layer_2"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16.95 18.15"
          >
            <polygon
              className="bgmainajovanim bg-ajov-1"
              points="1.67 9.67 8.39 2.69 15.37 9.42 8.64 16.39 1.67 9.67 1.67 9.67"
            />
            <polygon
              id="greymain"
              className="bgmainajovanim bg-ajov-2"
              points="2.56 9.65 8.41 3.58 14.48 9.43 8.63 15.5 2.56 9.65 2.56 9.65"
            />
            <polygon
              className="bgajovanim bg-ajov-3"
              points="3.67 13.76 4.38 13.03 5.11 13.73 4.4 14.47 3.67 13.76 3.67 13.76"
            />
            <polygon
              className="bgajovanim bg-ajov-4"
              points="4.05 13.75 4.38 13.41 4.73 13.74 4.39 14.09 4.05 13.75 4.05 13.75"
            />
            <polygon
              className="bgajovanim bg-ajov-4"
              points="3.27 13 3.6 12.65 3.95 12.99 3.62 13.34 3.27 13 3.27 13"
            />
            <polygon
              className="bgajovanim bg-ajov-4"
              points="7.16 16.76 7.5 16.41 7.84 16.75 7.51 17.09 7.16 16.76 7.16 16.76"
            />
            <polygon
              className="bgajovanim bg-ajov-3"
              points="12 13.54 12.74 12.77 13.5 13.51 12.77 14.27 12 13.54 12 13.54"
            />
            <polygon
              className="bgajovanim bg-ajov-4"
              points="12.32 13.53 12.74 13.09 13.18 13.51 12.76 13.95 12.32 13.53 12.32 13.53"
            />
            <polygon
              className="bgajovanim bg-ajov-4"
              points="11.49 14.39 11.91 13.96 12.35 14.38 11.93 14.82 11.49 14.39 11.49 14.39"
            />
            <polygon
              className="bgajovanim bg-ajov-4"
              points="15.32 10.41 15.75 9.98 16.19 10.4 15.76 10.84 15.32 10.41 15.32 10.41"
            />
            <polygon
              className="bgajovanim bg-ajov-3"
              points="12.96 12.52 14.92 10.49 16.95 12.45 14.99 14.48 12.96 12.52 12.96 12.52"
            />
            <polygon
              className="bgajovanim bg-ajov-1"
              points="13.46 12.51 14.93 10.99 16.44 12.46 14.98 13.98 13.46 12.51 13.46 12.51"
            />
            <polygon
              className="bgajovanim bg-ajov-3"
              points="14.75 12.49 15.39 11.83 16.05 12.46 15.41 13.13 14.75 12.49 14.75 12.49"
            />
            <polygon
              className="bgajovanim bg-ajov-1"
              points="3.27 16.07 5.36 13.91 7.51 15.99 5.43 18.15 3.27 16.07 3.27 16.07"
            />
            <polygon
              className="bgajovanim bg-ajov-4"
              points="3.77 16.06 5.37 14.41 7.01 16 5.42 17.66 3.77 16.06 3.77 16.06"
            />
            <polygon
              className="bgajovanim bg-ajov-3"
              points="4.74 16.63 5.39 15.96 6.07 16.61 5.42 17.28 4.74 16.63 4.74 16.63"
            />
            <polygon
              className="bgajovanim bg-ajov-1"
              points="9.58 3.03 12.5 0 15.53 2.92 12.61 5.96 9.58 3.03 9.58 3.03"
            />
            <polygon
              className="bgajovanim bg-ajov-3"
              points="12.93 6.26 13.79 5.37 14.68 6.23 13.82 7.13 12.93 6.26 12.93 6.26"
            />
            <polygon
              className="bgajovanim bg-ajov-4"
              points="13.28 6.26 13.8 5.72 14.33 6.24 13.82 6.77 13.28 6.26 13.28 6.26"
            />
            <polygon
              className="bgajovanim bg-ajov-4"
              points="14.24 7.18 14.76 6.65 15.29 7.16 14.78 7.7 14.24 7.18 14.24 7.18"
            />
            <polygon
              className="bgajovanim bg-ajov-4"
              points="8.92 2.05 9.44 1.52 9.97 2.03 9.45 2.57 8.92 2.05 8.92 2.05"
            />
            <polygon
              className="bgajovanim bg-ajov-4"
              points="10.21 3.02 12.51 0.63 14.9 2.94 12.6 5.33 10.21 3.02 10.21 3.02"
            />
            <polygon
              className="bgajovanim bg-ajov-3"
              points="11.67 1.97 12.52 1.09 13.41 1.94 12.55 2.83 11.67 1.97 11.67 1.97"
            />
            <polygon
              className="bgajovanim bg-ajov-1"
              points="0 6.54 1.99 4.48 4.06 6.47 2.07 8.54 0 6.54 0 6.54"
            />
            <polygon
              className="bgajovanim bg-ajov-4"
              points="3.34 5.13 4.35 4.08 5.39 5.09 4.38 6.14 3.34 5.13 3.34 5.13"
            />
            <polygon
              className="bgajovanim bg-ajov-3"
              points="3.72 5.12 4.35 4.47 5.01 5.1 4.38 5.75 3.72 5.12 3.72 5.12"
            />
            <polygon
              className="bgajovanim bg-ajov-3"
              points="4.81 4 5.44 3.34 6.09 3.97 5.46 4.63 4.81 4 4.81 4"
            />
            <polygon
              className="bgajovanim bg-ajov-4"
              points="0.64 6.53 2 5.12 3.42 6.48 2.06 7.9 0.64 6.53 0.64 6.53"
            />
            <polygon
              className="bgajovanim bg-ajov-3"
              points="1.02 6.53 1.51 6.02 2.02 6.51 1.53 7.02 1.02 6.53 1.02 6.53"
            />
          </svg>

          <AjovLogo />
        </div>
      </Fragment>
    );
  }
}

export default Background;
