import React, { Fragment, Component } from "react";
import SkillMainTab from "../UI/SkillContents/SkillMainTab";
import "./SkillPage.css";

class SkillPage extends Component {
  render() {
    return (
      <Fragment>
        <div id="skillpagemain">
          <div
            className="offcanvas offcanvas-bottom fixed bottom-0 flex flex-col max-w-full  invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-1/3 2k:h-4/5 max-h-full bg-transparent"
            id="offcanvasBottom"
            aria-labelledby="offcanvasBottomLabel"
          >
            <div className="offcanvas-header bg-white flex items-center justify-between p-4">
              <h5
                className="offcanvas-title mb-0 leading-normal font-semibold"
                id="offcanvasBottomLabel"
              >
                AJOV Skills
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body flex-grow p-4 overflow-y-auto small bg-sky-500/50">
              <SkillMainTab />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SkillPage;
