import React, { Fragment, Component } from "react";
import "./Header.css";
import BlueLines from "./Header/BlueLines";
import FlockBirdsHeader from "./Header/FlockBirdsHeader";
import SquareHeader from "./Header/SqaureHeader";

class Header extends Component {
  render() {
    return (
      <Fragment>
        <div id="headerajov">
          <SquareHeader />
          <FlockBirdsHeader />
          <BlueLines />
        </div>
      </Fragment>
    );
  }
}

export default Header;
