import React, { Fragment, FunctionComponent, useState } from "react";
import { SkillHeader } from "../SkillHeader";
import "./GoogleContent.css";
import GoogleMain from "./GoogleContent/GoogleMain";

export const GoogleContent: FunctionComponent = () => {
  const [Google, setGoogle] = useState(true);

  const googleClick = (theSite: any) => {
    switch (theSite) {
      case "googlemain":
        setGoogle(true);
        break;
    }
  };

  return (
    <Fragment>
      <div className="block p-6  w-full rounded-lg shadow-lg bg-white relative">
        <SkillHeader />

        <h5 className="text-gray-900 absolute left-40 text-2xl leading-tight font-bold mb-2">
          Google
        </h5>

        <nav className="relative w-5/6 flex flex-wrap items-center justify-between py-3 bg-transparent text-gray-900 shadow-lg navbar navbar-expand-lg navbar-light float-right">
          <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
            <button
              className="navbar-toggler text-gray-200 border-0 hover:shadow-none hover:no-underline py-2 px-2.5 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent1"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="bars"
                className="w-6"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                ></path>
              </svg>
            </button>
            <div
              className="collapse navbar-collapse flex-grow items-center"
              id="navbarSupportedContent1"
            >
              <ul className="navbar-nav flex flex-col pl-0 list-style-none mr-auto">
                <li className="nav-item p-2">
                  <button
                    className="nav-link menublue bg-sky-300 hover:bg-sky-500 font-bold py-2 px-4 border border-blue-300 rounded"
                    onClick={() => googleClick("googlemain")}
                  >
                    Google
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div
          aria-label="card google"
          className="focus:outline-none flex  sm:w-full md:w-full pb-20 mt-20"
        >
          <div className="w-20  h-20 relative xs:absolute xs:top-0 xs:right-0 mr-5 ">
            <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1"></div>
            <div className="absolute text-white bottom-0 left-0 bg-theme rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
              <img src="./devops.png" alt="DevOps" />
            </div>
          </div>
          <div className="w-full">
            <GoogleMain name="frameeworks" show={Google} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
