import React, { Fragment, Component } from "react";
import "./FlockBirdsHeader.css";
import anime from "animejs";

class FlockBirdsHeader extends Component {
  componentDidMount() {
    /* var topBirdAnimation = (function () {
      var topBirdTimeline = anime({
        targets: "#header-birds .flock-birds-top-animate",
        scale: [
          { value: 0.1, easing: "easeOutSine", duration: 500 },
          { value: 1, easing: "easeInOutQuad", duration: 1200 },
        ],
        delay: anime.stagger(200, { grid: [14, 5], from: "first" }),
        direction: "reverse",
      });

      function init() {
        topBirdTimeline.play();
      }

      return {
        init: init,
      };
    })();
    topBirdAnimation.init(); */
    this.rotateFlockHeader();
  }

  rotateFlockHeader = () => {
    anime({
      targets: "#header-birds .flock-birds-top-animate",
      //translateX: 270,
      opacity: function () {
        return anime.random(0.8, 1);
      },
      rotate: function () {
        return anime.random(-360, 360);
      },
      easing: "easeInOutQuad",
      delay: 2000,
      duration: 1000,
      complete: this.rotateFlockHeader,
    });
  };

  render() {
    return (
      <Fragment>
        <div id="headerflockbirdsajov">
          <svg
            id="header-birds"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 182.87 225.93"
          >
            <g>
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M56.64,144.6A13.37,13.37,0,0,0,55.29,142a8.93,8.93,0,0,0-1.84-1.39c-.31-.23-.55-.54-.88-.76a6.06,6.06,0,0,1-.93-.61c-.3-.29-.61-.57-.92-.86s-.37-.63-.67-.78c-.16.35,0,.52.16.81s.16.4.26.63,1.07,1,.92,1.16a1.39,1.39,0,0,0-.54-.1c.18.53.95.8,1.32,1.2a3.67,3.67,0,0,1,.76,1.05,4.07,4.07,0,0,0,.86,1c.24.26,1.07,1.12,1,1.44s-.33.3-.41.49,0,.44-.07.62c-.24.48-.89-.09-.94.5,0,.42.87,1,1.15,1.31s.52.76.85,1a2,2,0,0,1-.2-1.32c.48.44.74.41,1.17.06s.42-.16.88.3c.63.63,1.51.51,2.14,1.16,1.26,1.3,2,3.33,3.81,4a5.92,5.92,0,0,0-1.29-1.61,9,9,0,0,1-1.17-1.83A6.44,6.44,0,0,0,59,147c-.33-.27-.62-.65-1-.9s-.62-.18-.78-.49.13-.9-.07-1.32a.55.55,0,0,0-.92-.16.64.64,0,0,1-.26-.59"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M59.95,171.27a.65.65,0,0,1-.66-.43c-.11-.22-.17-.47-.28-.68a3.55,3.55,0,0,0-.76-1,26.43,26.43,0,0,0-2.32-1.51c-.39-.27-.79-.5-1.2-.75s-.88-.64-1.35-.87a9.41,9.41,0,0,1-1.4-.93c-.32-.23-.66-1-1.1-.94.06.46.57.77.9,1s.41.37.62.56.4.25.47.47c-.14,0-.23-.08-.37-.1,1,1,1.92,2,2.89,2.94a6,6,0,0,1,1.78,2.34c.44,1.78-2.76,1-3.53.81,0,.17.15.19.29.25a6.22,6.22,0,0,1,.64.37l.93.61a7.41,7.41,0,0,1,1.18,1c.35.35.67.72,1,1.07.17.17.3.33.46.51a3.33,3.33,0,0,0,.52.51c0-.18.13-.29.08-.48s-.32-.53-.42-.82a1.55,1.55,0,0,0,1.46-.23c.22-.19.41-.65.73-.41a2.3,2.3,0,0,1,.42.6,7,7,0,0,0,.54.77,6.52,6.52,0,0,0,1.18,1c1.93,1.44,2.52,4.39,5,5.09-.34-.49-1-.65-1.32-1.13a2.58,2.58,0,0,1-.34-.62,1.41,1.41,0,0,1-.18-.47,1.19,1.19,0,0,0,.41.26,14,14,0,0,1-.88-1.6L64.89,177a5.78,5.78,0,0,0-2-3,6.19,6.19,0,0,0-1.34-.74.7.7,0,0,1-.4-.87c0-.26.18-.68.05-.93s-.32-.2-.52-.23a1.36,1.36,0,0,0-.49,0c-.39,0-.67-.31-.92-.56"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M57.05,178.3a.79.79,0,0,1-.61-.44c-.15-.21-.31-.44-.47-.63s-.32-.49-.57-.62c-.75-.38-1.59-.5-2.36-.84s-1.43-.73-2.17-1c-.36-.14-.72-.31-1.09-.42a4.88,4.88,0,0,1-1.06-.37c.17.14.31.32.48.47s.52.38.77.58.52.34.77.53.35.48.62.5c-.21,0-.39-.1-.59-.11.78.54,1.56,1.07,2.36,1.57a14.61,14.61,0,0,1,1.76,1.18c.22.19.67.59.33.86s-1.3.14-1.76.11c-.14.46.56.56.84.68a3.81,3.81,0,0,1,1.12,1c.15.17.38.75.63.77s.13-.81.11-1a1.92,1.92,0,0,0,.71,0,3.33,3.33,0,0,0,.46-.24c.34-.15.48.16.62.44s.26.67.42,1,.35.63.51,1a14.83,14.83,0,0,0,1.06,1.91,10.87,10.87,0,0,1,1.14,2A7.74,7.74,0,0,0,60.3,185a3.56,3.56,0,0,1-.25-1.71,4.85,4.85,0,0,0-.18-1.18,4.23,4.23,0,0,0-.25-.92,5.32,5.32,0,0,0-1.18-1.43,1.73,1.73,0,0,1-.54-.78c-.06-.26,0-.54,0-.8a3.2,3.2,0,0,0-.44-.12c-.17,0-.31.08-.48.1"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M26.21,195.62a2.59,2.59,0,0,1-1,.36c-.27,0-.58-.05-.86,0a6.79,6.79,0,0,0-1.37,0c.06.25.32.37.52.5a3.17,3.17,0,0,1,.61.52c.19.2.33.44.53.63a5.11,5.11,0,0,1,.52.48c.22.29.38.63.59.94a3.71,3.71,0,0,1,.47.86c.3-.08.18-.79.18-1s.08-.54-.07-.7c.45-.14.85.29,1.3.09.23-.11.29-.17.56,0a4.53,4.53,0,0,1,.72.47c.78.58,1.41,1.33,2.23,1.83.52.32.9.79,1.42,1.11a4.4,4.4,0,0,1,1.29,1.25,15.46,15.46,0,0,0,1.27,1.42c.23.24.63.66,1,.63-.08-.27-.4-.41-.57-.62a1.82,1.82,0,0,1-.27-.43,2.17,2.17,0,0,1,.33.23c-.67-.89-1.45-1.65-2.06-2.59a20.81,20.81,0,0,1-1.09-2.06,7.17,7.17,0,0,0-.8-1.16,5.32,5.32,0,0,0-.79-.93c-.29-.23-.61-.21-.94-.35a1.43,1.43,0,0,1-.72-.68c.11-.14.39.08.53.11a5.32,5.32,0,0,0,.7,0c-.15-.13-.34-.2-.48-.33s-.24-.41-.45-.56a1.38,1.38,0,0,0-.65-.16,5.3,5.3,0,0,1-.76-.22c-.2-.06-.3-.08-.4-.28s-.2-.56-.37-.83-.24-.58-.4-.83a1.46,1.46,0,0,0-.66-.47c-.57-.28-1-.73-1.64-1a14.31,14.31,0,0,1-1.8-.88A8,8,0,0,1,21,189.46a9,9,0,0,1-.73-.8,1.22,1.22,0,0,0-.55-.45c0,.22.2.34.33.5a2.7,2.7,0,0,1,.38.68c-.11,0-.33-.32-.48-.37a2.15,2.15,0,0,0-.49-.14c.19.16.41.27.6.43a1.54,1.54,0,0,1,.4.59,3.7,3.7,0,0,0,.73.76c.3.28.65.53,1,.8.16.14.32.2.48.32s.31.37.55.5a2.93,2.93,0,0,1,1,.85c.13.18.32.3.45.48a4.29,4.29,0,0,1,.27.63c.09.19.23.33.34.51a5,5,0,0,0,.41.75,3.66,3.66,0,0,0,.7.5l-.07,0"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M49.16,209.47a5.82,5.82,0,0,0-.94-1.56c-.3-.39-.5-.78-1-.87a21.24,21.24,0,0,1-2.89-.25c0,.5.67.64,1,.74,0,.15-.33.15-.46.21.17.11.35.06.54.08s.22.08.39.15a6.44,6.44,0,0,1,1.23.45c-.16,0-.33,0-.5,0,.34.18.75.18,1.06.43s.6.45.63.78c-.31.08-.46-.07-.53.32-.11-.06-.14-.18-.26-.28a.94.94,0,0,0-.39-.18c-.19-.06-.38-.17-.57-.22,0,.25.47.3.62.43s.19.37.27.56a3.19,3.19,0,0,0,.65,1c.3.29.77.45,1,.78a3.69,3.69,0,0,1,.28.62,1.82,1.82,0,0,1,.26.52c.24-.1,0-.69-.07-.83a4.23,4.23,0,0,0-.41-.58,1.2,1.2,0,0,0,.74,0c.26-.1.19-.28.25-.49a1.52,1.52,0,0,1,.69.26c.15.14.28.29.45.42a.75.75,0,0,1,.31.4,1.68,1.68,0,0,1,.08.53c0-.06.1-.09.14-.14.15.13.06.37.07.54s.08.16.31.35.12.29.24.4a1.56,1.56,0,0,0,.37.22,1.22,1.22,0,0,0,0-1c-.1-.36-.18-.72-.31-1.07a2.82,2.82,0,0,0-1.12-1.62,11,11,0,0,1-1.47-.64c-.21-.17-.22-.38-.35-.59a1.16,1.16,0,0,0-.76-.43"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M72.52,218.26c-.33.19-.72.28-.93.64a1.8,1.8,0,0,0-.22.91,6.94,6.94,0,0,1-.94.11c-.18,0-.47.08-.55-.12a1.75,1.75,0,0,0,.82.42,2.5,2.5,0,0,1,.65.06c.18.08.28.27.41.41a1.19,1.19,0,0,0,.52.29,1.61,1.61,0,0,0,1,.07,1.53,1.53,0,0,0,.78-.91c.24.14.46.34.7.49a7.06,7.06,0,0,1,.71.43,5.06,5.06,0,0,1,.65.68,6.17,6.17,0,0,1,.75.93,6.32,6.32,0,0,0,.55,1c.27.3.58.57.85.87s.33.32.45.48.1.22.18.32.22.2.31.3.24.37.48.3a17.58,17.58,0,0,1-1.82-2.79,14.84,14.84,0,0,0-1.18-2.43c-.54-.71-1.42-1-2-1.66a4.94,4.94,0,0,0-.44-.54,2.8,2.8,0,0,0-.76-.39c-.62-.27-.8-.8-1.25-1.25s-1.14-.54-1.67-.88a16.06,16.06,0,0,0-2.76-1.4,4.87,4.87,0,0,1-2-1.13A9.33,9.33,0,0,0,67.2,215l1,.85a7.31,7.31,0,0,0,1.71.84,2.91,2.91,0,0,1,1.4,1.07,1.21,1.21,0,0,0,1.08.75"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M84.41,224.36a5.57,5.57,0,0,0-1.47-.32,7.39,7.39,0,0,1-1.1,0,4.07,4.07,0,0,0-1.14-.15,3.09,3.09,0,0,0-1,.24c-.74.35-1.48.78-2.23,1.07a7.2,7.2,0,0,1-1.45.4c-.47.07-.95.06-1.42.14.24.27.55.25.86.32a.21.21,0,0,1-.22.08c.78.23,1.43-.2,2.16-.39.57-.15,1.1-.39,1.67-.53.3-.07.56-.18.86-.23s.47-.17.73-.2.85-.22,1.09.11c-.12,0-.35,0-.39.16s.18.33.25.44a1.37,1.37,0,0,0,.68.53,1.59,1.59,0,0,0,.94.29,1.71,1.71,0,0,0,.85-.37,2.14,2.14,0,0,1,.44.63c.1.23.19.47.3.69s.21.27.27.42.08.42.15.61.06.53.18.57a.59.59,0,0,0,.19-.14c.48.39.26,1.33.67,1.8.29-.33.31-.37.6,0a2.71,2.71,0,0,1,.28.35c.09.17.05.37.24.47a3,3,0,0,0-.31-2.16,17.45,17.45,0,0,0-.76-1.62,4.58,4.58,0,0,0-.53-.7c-.33-.4-.91-.9-.89-1.45a1,1,0,0,0-.17-.67c-.17-.23-.5-.25-.65-.5"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M101.16,227.15a2.71,2.71,0,0,0-.65-.12,3,3,0,0,0-.37.21,3.89,3.89,0,0,1-.93.28,4.17,4.17,0,0,0-1.61.7,17.58,17.58,0,0,1-4.28,2.56c.43.35.86-.28,1.28-.23a1.79,1.79,0,0,1-1.1.57c-.24,0-.48,0-.72,0a2.46,2.46,0,0,1-.6.1c1.78-.25,3.54-.47,4.91-1.74a14.41,14.41,0,0,1,1.56-1.39c.31-.22.88-.6,1.28-.39s.3.58.39.87a.76.76,0,0,0,.71.59,4.69,4.69,0,0,1,1.3.15,1.16,1.16,0,0,1,.56,1,1.11,1.11,0,0,0-.37.35c.14-.08.28-.29.46-.22a3.24,3.24,0,0,1-.08,1.29,6.46,6.46,0,0,0-.31,1.28,2.54,2.54,0,0,0,.92-2.33c-.09-.63-.53-1-.72-1.62a2.46,2.46,0,0,0-.72-1.42,2.92,2.92,0,0,0-1.65-.63"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M78.21,201.3c-.4,0-.49,0-.71.33s-.41.31-.79.2l-.91-.23a2.34,2.34,0,0,1-.49-.11c-.16-.08-.27-.25-.41-.36a5,5,0,0,0,.75,1c.17.22.23.55.41.75s.33,0,.61,0c.43.08,0,.31,0,.47s.42.45.58.57.62.45.91.69.52.54.79.79a3.17,3.17,0,0,0,.8.46c0-.18-.41-.65-.51-.83a2.91,2.91,0,0,1-.37-.79c.29-.06.51,0,.74-.21a1.39,1.39,0,0,0,.37-.83,17.38,17.38,0,0,1,2.27,1.49,13,13,0,0,0,2.21,1.6c.43.2.9.21,1.34.39l1.41.6c.28.12.59.18.88.28s.58.26.87.36a1.19,1.19,0,0,0,1.31-.16,11.77,11.77,0,0,1-4.65-1.88,10.75,10.75,0,0,0-2.34-1.58,8.87,8.87,0,0,1-2.41-1.2,5.13,5.13,0,0,1-.77-1.38,9.65,9.65,0,0,0-1.2-1.28,5.88,5.88,0,0,1-1-2,5.94,5.94,0,0,0-1-1.75,10.47,10.47,0,0,1-1.54-2.46,10.58,10.58,0,0,1-.7-2.83,5.56,5.56,0,0,0-.12,1.42,5.34,5.34,0,0,0,.4,1.45c.23.7.36,1.44.62,2.13.38,1,.72,2,1.19,3a4.61,4.61,0,0,0,.88,1.24c.21.2.46.37.44.7a.62.62,0,0,1-.3,0"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M116.25,221a2,2,0,0,1-1.41.39,2.41,2.41,0,0,1-1-.34c-.23-.18-.33-.47-.56-.65a2.32,2.32,0,0,0-.65-.31c-.75-.28-1.53-.48-2.29-.74a7,7,0,0,1-1.46-.54,4,4,0,0,1-1-.93c.21.07.37.28.56.32-.13-.19-.22-.37-.34-.55s-.25-.27-.26-.55a5.07,5.07,0,0,0,1.89,1.37,16,16,0,0,0,1.55.54c.55.16,1,.47,1.6.6a13.81,13.81,0,0,1,1.55.54,4.5,4.5,0,0,0,1.65.24,5,5,0,0,1,1.73.11,12.83,12.83,0,0,0,1.61.4,2.56,2.56,0,0,0,1.82,0,2.26,2.26,0,0,1-1.35.51c-.46,0-.9.1-1.37.09a10.08,10.08,0,0,0-1.44,0,2.06,2.06,0,0,1-1.37-.58"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M105.81,186.21a1.63,1.63,0,0,0-.62,1.35c0,.44,0,1,.46,1.29.19.11.29,0,.23.26s-.18.25-.3.39a2.15,2.15,0,0,0-.4,1.44,9,9,0,0,1,.47-1.12,1.35,1.35,0,0,1,.84-.8,4.72,4.72,0,0,0,.88-.16,1.54,1.54,0,0,0,.27-.17c.07-.1,0-.25.13-.32a20.72,20.72,0,0,1,4.71,4.11l.84,1c.09.11.34.52.49.55s0-.07,0-.16a5.68,5.68,0,0,0-.54-.87,33.35,33.35,0,0,0-4.34-5.35c-1.17-1.16-2.36-2.27-3.46-3.51a44.22,44.22,0,0,0-5.17-4.94,5.82,5.82,0,0,0,.73,1.1c.27.28.5.6.76.88s.75.74,1.1,1.14a9.52,9.52,0,0,0,1,1c.25.21.48.44.72.67a3,3,0,0,1,.52,1,2.84,2.84,0,0,0,.38.76c.1.13.41.38.39.58,0,0,0,0,0-.07"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M90.12,156a12.26,12.26,0,0,1-1.2-1.41c-.09-.12-.24-.17-.34-.28s-.09-.21-.23-.28h-.16a.58.58,0,0,1-.23-.14,2,2,0,0,0-.55-.23,8.59,8.59,0,0,1-1.11-.44,12.63,12.63,0,0,1-3.94-2.56c-.06.42.67.92.93,1.19a6.77,6.77,0,0,0,1.16.84c.44.3.85.63,1.3.92a1.94,1.94,0,0,1,.61.47,5.63,5.63,0,0,0,1.32,1.72c.53.34,1.48.39,1.77,1-.13,0-.26,0-.39,0s-.15.08-.25.11-.82-.2-.82,0c.4.19.82.24.88.75a1.37,1.37,0,0,0,.46,1.09,3.36,3.36,0,0,1,.7.51c.24.31.13.77.47,1,0-.39-.2-1.25.26-1.48s.89.22,1.12.48a28.75,28.75,0,0,1,1.4,2.57,19.57,19.57,0,0,1,1.54,2.35c.46.88.75,2.27,2,2.29a3.72,3.72,0,0,1-1-1.46,8.61,8.61,0,0,0-.51-1.46c-.3-.55-.71-1.07-1-1.64a13.18,13.18,0,0,1-.53-1.56,3.23,3.23,0,0,0-.71-1.34c-.17-.17-.34-.36-.53-.52a7,7,0,0,1-.81-.63c-.37-.44-.42-1.21-.9-1.55-.23-.16-.52-.25-.76-.4l-1.31-.8"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M76.06,123.11c-.2.06-.6.11-.74.29s-.05.23-.06.37a2,2,0,0,0,0,.83c.16.45.63,1.15,1.14,1.2a5.44,5.44,0,0,0,.19,1.11c.19.6.23-.18.24-.4s0-.48.12-.59.26,0,.37,0c.31,0,.49-.28.67-.5.35.23.44.67.81.9a2.33,2.33,0,0,1,.86.76c.2.32.48.57.69.9a9.15,9.15,0,0,0,.87,1.06,16.76,16.76,0,0,1,1.7,2.44,2.48,2.48,0,0,0,.72.85,3.72,3.72,0,0,0,.55.23,2.07,2.07,0,0,0,1.1.28A7.19,7.19,0,0,1,83,130.94a10.6,10.6,0,0,1-1.23-2.36c-.4-.95-.74-1.95-1.23-2.86a2.4,2.4,0,0,0-.73-.88c-.3-.21-.65-.31-1-.49-.59-.33-.75-.86-1.16-1.35s-1-.65-1.49-1.06-.63-.86-1.09-1.16a18.51,18.51,0,0,0-1.62-.91,18.28,18.28,0,0,1-2.18-1c-.75-.53-1.37-1.24-2.07-1.83-.2.73.58,1.31,1.05,1.75s.81.46,1.18.77a7.64,7.64,0,0,0,1.06.63,9.92,9.92,0,0,1,2.28,1.59,5.27,5.27,0,0,1,.57.74,2.21,2.21,0,0,1,.39.95,5.73,5.73,0,0,1,.16-1.09"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M89.5,141a13.8,13.8,0,0,0-1.68-1.75,1.19,1.19,0,0,0-1.09-.41,3,3,0,0,0,.52.48,1.16,1.16,0,0,1,.43.57c.07.18.21.33.26.5s0,.38.06.58c-.26.09-.44,0-.69.12a1.18,1.18,0,0,1-.32.18c-.2.06-.41,0-.6,0,.1.26.52.17.73.22a1.73,1.73,0,0,0,.3.07,2.68,2.68,0,0,1,.31,0c.25,0,.45.24.7.3a1.85,1.85,0,0,0,.68,0c.18,0,.33-.19.53-.21a2.79,2.79,0,0,1,.73,0c.11,0,.21.13.31.16s.21,0,.31,0,.31.24.47.37c0-.25-.35-.58-.51-.78a2.58,2.58,0,0,0-.59-.54c-.44-.26-.82-.1-1.27-.2a3.12,3.12,0,0,0-.33-.92"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M79.58,102.53a2.73,2.73,0,0,1-1,.41,2.59,2.59,0,0,1-1.1-.27c-.32-.1-.65-.21-1-.34s-.41-.42-.62-.41c-.09.41,0,1,.49,1,0,.09-.14.26-.11.35s.17.13.23.2a1.75,1.75,0,0,1,.31.68.29.29,0,0,0,.17.23c.14.1.23,0,.36.07s.21.46.48.44c0,.19.14.44.36.48s.35-.18.56.13,0,.12.19.23a.88.88,0,0,0,.34.07,1.08,1.08,0,0,1,.64.25c.38.24.67.58,1.07.79.2.1.44,0,.63.14s.37.54.56.33c0-.27-.48-.6-.64-.81a2.45,2.45,0,0,0-.4-.43c-.1-.09-.31-.2-.29-.36a2.24,2.24,0,0,0,1.13-.6A19.45,19.45,0,0,1,84,106.88c.28.24.62.42.92.64s.19.25.32.39.33.19.47.33a9.91,9.91,0,0,0,1,.93c.28.22.52.49.8.71s.32.24.48.38c.36.32.77.62,1.11,1a6.33,6.33,0,0,0,.52.63c.12.1.27.16.39.26-.32-.52-.67-1-1-1.49a9.94,9.94,0,0,1-.74-1.4c-.19-.39-.46-.74-.63-1.15a7.26,7.26,0,0,0-.63-1.2,5.15,5.15,0,0,0-2-1.85c-.34-.15-.66-.34-1-.5s-1-.46-1.12-.83c.23-.06.42.2.65.18s.26-.28.45-.13a2.54,2.54,0,0,1-.65-.83c-.1-.2-.15-.61-.39-.68a2.74,2.74,0,0,0-.74,0,1.27,1.27,0,0,1-.77-.25c-.23-.17-.28-.42-.42-.66s-.27-.38-.38-.59a4.54,4.54,0,0,0-.81-1.23,17.13,17.13,0,0,0-1.58-1c-.6-.46-1.23-.89-1.8-1.39s-1.11-.82-1.63-1.26-.53-1.12-1.13-1.37c.12.4.49.6.75.89.08.08.2.22.14.34-.23,0-.69-.4-.91-.29,0,.46.81.69,1,1.09-.19,0-.38-.27-.56-.27,0,.21.18.33.3.49s.26.47.41.7a7.71,7.71,0,0,0,.65.75,6.31,6.31,0,0,0,1,.89c.28.23.52.53.79.78s.54.51.83.73a2.13,2.13,0,0,1,.73.71,2.92,2.92,0,0,0,.39.71,3.74,3.74,0,0,0,.23.3c.09.09.22.09.29.21a2,2,0,0,1-.72,0"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M81.5,53.3a1.76,1.76,0,0,1-.53-.62,1.36,1.36,0,0,0-.33-.42,2.08,2.08,0,0,0-.9-.41,2.55,2.55,0,0,1-.91-.23c-.12-.07-.6-.26-.6-.36.29.09.37.36.63.49l.57.28a.61.61,0,0,1,.35.42c.06.14.21.27.24.42-.25-.25-.39.08-.59.19s-1,.44-1.06.12a3.55,3.55,0,0,0,.77.12h.56c.21,0,.38.09.59.11a1.83,1.83,0,0,1,.3,0c.09,0,.1.08.19.11.46.11.67-.34,1.08-.42a2.42,2.42,0,0,1,.68,0c.16,0,.32.16.49.08-.18-.12-.26-.3-.47-.36a1.84,1.84,0,0,0-.58,0,1.94,1.94,0,0,0-.55.18,2.9,2.9,0,0,1-.84,0"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M100.72,57.54c-.3,0-.68,0-.91.23a5.75,5.75,0,0,0-.44.61c-.15.2-.35.3-.51.49a2.17,2.17,0,0,1-.62.62,2.11,2.11,0,0,1-.63.22c-.12,0-.32,0-.38.11.45,0,.78-.29,1.2-.39a1.91,1.91,0,0,1,.88.1c.63.13.91.7,1.49.95a2.52,2.52,0,0,0,.9.16,3.23,3.23,0,0,0,1,0,1.16,1.16,0,0,0,.64-1,9.6,9.6,0,0,0,.81.65,1.47,1.47,0,0,0,.79.16,1.53,1.53,0,0,1,.86.12,3,3,0,0,1,.53.61c.16.22.2.48.43.64a8.18,8.18,0,0,1,.81.6c.11.1.2.23.31.32s.29.13.42.22.26.37.36.31.07-.24,0-.31c0,.1.13.15.2.22a3.41,3.41,0,0,1,.32.39c.22.29.56.34.82.58a2,2,0,0,0,.86.43.88.88,0,0,0,.58.08c-.23-.47-.9-.73-1.31-1a12.36,12.36,0,0,1-1.28-1.17,10.52,10.52,0,0,1-.8-.87c-.28-.32-.64-.56-1-.86a5,5,0,0,1-.81-.9,2.64,2.64,0,0,0-.69-.78,6.34,6.34,0,0,0-1.89-.63,1.41,1.41,0,0,0-.84-1c-.44-.14-1.17,0-1.44-.49-.09-.16-.13-.33-.23-.49s-.27-.36-.4-.54a2.89,2.89,0,0,0-1.38-.81,6.88,6.88,0,0,0-.93-.36c-.32-.08-.63-.1-.95-.2-.52-.18-1-.36-1.56-.52a4.48,4.48,0,0,1-1.68-1c-.25-.24-.55-.35-.81-.56a2.14,2.14,0,0,0-.85-.58c0,.34,1,.78.93,1-.14,0-.26-.08-.4-.09a10.1,10.1,0,0,0,1.3.92,9.39,9.39,0,0,0,.85.57c.15.09.32.12.47.21s.2.19.31.27c.27.2.56.39.84.59s.51.31.77.47.55.47.83.69A3.83,3.83,0,0,1,99.72,58c.13,0,.15-.17.07-.35"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M98.76,31.8c-.06.19-.15.56-.36.67s-.29,0-.47-.12-.58-.25-.79.07c.13.18.33.25.5.39a.58.58,0,0,1,.21.37c.07.21.13.45.21.66s.2.27.37.41.26.34.43.46a3.39,3.39,0,0,0,.45.17,1,1,0,0,0,.9,0c.07.14.34.72.5.68,0-.46-.26-1.15.33-1.3s1,.46,1.37.82a4.38,4.38,0,0,1,1,1.12c.21.39.4.8.64,1.18.09-.07.12-.17.24-.15s.31.34.38.45c.25.43.5.84.77,1.26s.62.69.87,1.09a1.65,1.65,0,0,0,1,.68c-1.06-1.17-1.79-2.58-2.75-3.83-.47-.61-.9-1.24-1.4-1.83a20.27,20.27,0,0,0-1.61-1.53c-.43-.41-.7-.94-1.15-1.33A9.86,9.86,0,0,1,99,31a7.51,7.51,0,0,0-1.44-1.62,8.52,8.52,0,0,0-1.64-.86c-.81-.34-1.66-.61-2.46-1a4.77,4.77,0,0,1-1.8-1.38c0,.18.41.48.49.57s.36.41.53.62c-.13,0-.24-.1-.38-.07.43.63,1.17.84,1.74,1.31a17.25,17.25,0,0,0,1.67,1,8.35,8.35,0,0,1,.76.51c.25.2.45.47.69.67a5.94,5.94,0,0,1,.6.62,2.32,2.32,0,0,1,.29.31c.14.22.18.47.43.62v-.2"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M109.72,22.18c-.17.17-.39.3-.43.54s0,.3,0,.46c-.19.06-.94-.29-1,.11a2,2,0,0,1,.68.35c.1.13,0,.29,0,.45a1.8,1.8,0,0,0,.33.46c.07.08.17.1.24.16s.1.16.17.22a1.12,1.12,0,0,0,.45.24c.21,0,.28-.1.44-.14s.28.06.43.24.16.38.3.43a.75.75,0,0,0,.12-.52c0-.14-.2-.25-.19-.4.32-.07.47-.52.81-.39a4,4,0,0,1,.82.61,8.73,8.73,0,0,0,1.08.73,3.33,3.33,0,0,1,.84.75c.31.33.71.62,1,.93a8.2,8.2,0,0,0,1,.77,13.69,13.69,0,0,0,2.56,1.42c-.1-.25-.54-.29-.75-.43s-.54-.42-.82-.62c-.44-.33-.87-.64-1.27-1a11.75,11.75,0,0,1-1.3-1.62,6.76,6.76,0,0,0-.83-.87,6.08,6.08,0,0,0-.81-.64,4.66,4.66,0,0,0-1-.25,2.56,2.56,0,0,1-.87-.55c-.47-.4-.93-.79-1.36-1.23-.2-.2-.47-.27-.69-.47a4.86,4.86,0,0,0-.79-.71,7.82,7.82,0,0,1-.76-.46c-.27-.2-.48-.42-.73-.65a8.55,8.55,0,0,0-.87-.69c-.41-.28-.91-.44-1.33-.7a5.69,5.69,0,0,1-1.84-2c.25.37.5.74.78,1.09a4.78,4.78,0,0,1,.64.86,2.71,2.71,0,0,0,.18.38,2.84,2.84,0,0,0,.74.69,5.19,5.19,0,0,0,.72.49c.11.05.23,0,.34.11s.24.29.39.4.58.4.86.61a5.35,5.35,0,0,1,.9.71,2.56,2.56,0,0,1,.52.73"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M124.29,36.65a6.36,6.36,0,0,1-.83,0,1.85,1.85,0,0,1-.4-.19c-.28-.14-.32-.28-.46-.55s-.24-.55-.36-.83a2.55,2.55,0,0,0-.24-.62,1.75,1.75,0,0,0-.55-.32c-.29-.15-.5-.37-.8-.52-.49-.24-.88-.62-1.37-.85-.1,0-.22,0-.32-.09s-.12-.12-.19-.16-.29-.08-.42-.16-.19-.19-.31-.25a1.23,1.23,0,0,1-.31-.16,2.39,2.39,0,0,0-.25-.21,6.46,6.46,0,0,0-.68-.46,6.56,6.56,0,0,1-1.55-.88c-.48-.43-1.1-.73-1.61-1.14.39.37.83.67,1.21,1s.66.49,1,.77.49.41.72.62a6.87,6.87,0,0,0,.48.52,1,1,0,0,0-.36-.1c.13.18.43.24.6.4l.52.52a8.58,8.58,0,0,0,1,.8.21.21,0,0,0-.14.06,2.32,2.32,0,0,0,.69,1c.06.06.19.12.24.2a1.31,1.31,0,0,1,.12.34,3.12,3.12,0,0,0,.93,1.35c-.59.1-1.14.43-1.73.27s-1-.35-1.46-.46c-.25-.05-.64-.12-.47.24.3.59,1.07.83,1.4,1.44s.63,1.31.91,2a1.62,1.62,0,0,0,.6-1.08,3.46,3.46,0,0,0,1.53.71c.29,0,1.31,0,1.26-.48.3.35.37.87.7,1.21.06.06.16.08.21.14s.06.17.13.24.4.26.54.52a3.16,3.16,0,0,1,.47.87,2.65,2.65,0,0,0,.28.6c.17.37.23.8.41,1.16a2.72,2.72,0,0,0,.24.49,4.26,4.26,0,0,1,.4.56c.12.21.31.37.45.57s.26.5.42.74a9.67,9.67,0,0,1,.82,1.33c.16.36.36,1.17.78,1.32.31-.23,0-.63,0-.89.17,0,.19.19.28.28-.18-.73-.33-1.46-.52-2.19s-.52-1.44-.73-2.17a13,13,0,0,1-.22-1.9,3.94,3.94,0,0,0-.63-1.71,8.92,8.92,0,0,0-1.09-1.27,1.82,1.82,0,0,1-.72-1.2c0-.4.18-.76-.15-1.09a3.8,3.8,0,0,0-1.82-.65"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M133.52,101.23a3.71,3.71,0,0,0-.51.78c-.16.28-.26.19-.51.25-.08,0-.1.08-.17.1a1.41,1.41,0,0,1-.37,0h-.57a3.91,3.91,0,0,0,1.39.43c.43.08.75.43,1.16.57.22.08.45.12.66.22s.39.28.61.41c.45.26.41-.42.63-.66s1,0,1.33.17a2.62,2.62,0,0,1,1.26.84,6.25,6.25,0,0,0,1.07.92c.53.38,1,.86,1.48,1.28a9.8,9.8,0,0,1,1.53,1.77A1.33,1.33,0,0,0,144,109a23.1,23.1,0,0,1-2-2,18.64,18.64,0,0,1-2.1-2.39,15.49,15.49,0,0,0-1.55-2.15,1.71,1.71,0,0,0-.76-.48,3.18,3.18,0,0,1-.88-.24c-.22-.15-.26-.42-.33-.66a1.26,1.26,0,0,0-.56-.79,6.13,6.13,0,0,0-.71-.2.8.8,0,0,1-.42-.51c-.23-.53-.41-1.19-1-1.45a3.84,3.84,0,0,0-1-.2,5.06,5.06,0,0,1-1.29-.4,18.49,18.49,0,0,1-5.06-3.33c-.13.24.43.71.59.89s.59.6.89.89.88.64,1.29,1,.65.7,1,1a11.6,11.6,0,0,0,1,.88c.19.13.33.31.53.42s.45.22.66.34.76.38.9.78a2.37,2.37,0,0,1,0,1.3"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M150.88,77.66a3.24,3.24,0,0,0-.57-.68c-.17-.15-.38-.23-.56-.37-.52-.4-1-.85-1.53-1.25a5.9,5.9,0,0,1-.52-.36,3.68,3.68,0,0,0-.25-.28c-.1-.08-.21-.1-.3-.19s-.15-.26-.28-.36-1.09-.34-1.18-.64a9,9,0,0,1,4.13,2.6,12.11,12.11,0,0,0,1.47,1.26,9.52,9.52,0,0,1,1.34,1c.51.5,1,1,1.57,1.48a5.67,5.67,0,0,1,.69.8,8.34,8.34,0,0,1,.79,1,29,29,0,0,0,2.52,3.47,6.09,6.09,0,0,1-.69-.7,11.48,11.48,0,0,1-.84-.91c-.14-.16-.23-.36-.36-.53a5.4,5.4,0,0,1-.35-.42,1.51,1.51,0,0,0-.32-.36,1.92,1.92,0,0,1-.36-.47,6.42,6.42,0,0,0-.76-.7c-.27-.28-.62-.51-.87-.8-.1-.12-.18-.26-.28-.38s-.31-.31-.45-.48c-.74.33-1.62,1.12-2.4.44a.89.89,0,0,1-.22-1.19,8.74,8.74,0,0,0,.79-1.23"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M137.13,113a4.55,4.55,0,0,0-1.24.08l-.43.08c-.16,0-.18,0-.36,0a3.37,3.37,0,0,0-1.87.43,10.42,10.42,0,0,1-1,.32,7.85,7.85,0,0,0-1.28.56,2.34,2.34,0,0,1-1.84-.2c1.26-.34,2.51-.72,3.75-1.12a15.05,15.05,0,0,1,4.22-1,2.65,2.65,0,0,1,2.72,1.61,9.32,9.32,0,0,1,.16,4.46c-.23,1.84-.56,3.68-.84,5.51a4.57,4.57,0,0,1-.58-1,2.19,2.19,0,0,1-.23-1.21,5.63,5.63,0,0,1,.1-1.21c.09-.41.22-.81.32-1.22s.07-.87.19-1.29.13-.58.19-.87c.1-.49.39-1.49-.09-1.86-.31.2-.43.52-.83.31s-.43-.36-.79-.15c-.05-.28,0-.47-.23-.61a2,2,0,0,1-.61-.43c-.23-.24-.25-.29-.09-.57a.75.75,0,0,0,.17-.64l-.09,0"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M134.53,77.38a1.34,1.34,0,0,1-.55.1.76.76,0,0,1-.16-.21c-.17-.16-.38-.29-.56-.45a7.7,7.7,0,0,0-1-.7,21,21,0,0,0-2-1.25c-.43-.22-.88-.4-1.32-.6a2.28,2.28,0,0,0-.58-.12,4.29,4.29,0,0,1-.58-.21,9.52,9.52,0,0,1-1.29-.52c-.3-.15-.5-.39-.77-.56,0,.42.53.65.78.9a1.34,1.34,0,0,0,.41.31,1.42,1.42,0,0,1,.45.29l-.17,0c0,.19.2.18.31.29s.07.13.19.2.5.17.73.29a8.61,8.61,0,0,0,.83.44c.12,0,.24.12.36.16s.29,0,.43.06a3.2,3.2,0,0,1,.83.42,7.19,7.19,0,0,1,.68.67c.09.08.19.08.28.15s.18.23.29.32a3.27,3.27,0,0,0,.88.35c-.09.23-.23.42-.31.65s0,.58-.35.63c-.15,0-.29,0-.43,0a2.61,2.61,0,0,0-.38.1l-.69.15a6.2,6.2,0,0,0,1.35.68,4.2,4.2,0,0,0,.47.31,1.39,1.39,0,0,1,.36.15c.3.23.61.44.92.67s.6.36.71.62c.05-.16-.22-.47-.29-.62s-.26-.28-.26-.4c.29-.07.56,0,.83-.15a.81.81,0,0,0,.31-.66c.35,0,.5.36.75.53s.34.16.49.28a5.12,5.12,0,0,0,.45.39,3.29,3.29,0,0,1,.95.68,3.85,3.85,0,0,1,.31.54c.12.19.29.34.4.54s.16.51.27.75a2,2,0,0,0,.42.55,10.75,10.75,0,0,0,1.09,1,6,6,0,0,0,.52.32,2.21,2.21,0,0,0,.56.35,4.05,4.05,0,0,0,1.13,0,1.28,1.28,0,0,0-.64-.39,3,3,0,0,1-.86-.59,5,5,0,0,1-1.27-1.33c-.16-.3-.28-.62-.46-.91s-.38-.6-.54-.92a4.12,4.12,0,0,0-.87-1.5,6,6,0,0,0-.53-.47c-.22-.15-.46-.23-.68-.39-.41-.3-1-.42-1.09-1-.06-.3-.26-.95-.58-1.06a2.12,2.12,0,0,0-.64,0c-.11,0-.14.05-.26,0s-.43-.38-.57-.53"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M109.95,154.47c-.29-.29-.58-.59-.89-.86a4.79,4.79,0,0,1-.62-.8,4.57,4.57,0,0,0-.91-.88,10.61,10.61,0,0,0-1.08-.58c-.32-.19-.64-.4-1-.6s-.37-.16-.54-.26-.38-.32-.58-.46c-.41-.29-.77-.63-1.16-.95a8.44,8.44,0,0,1-1.06-.75,2.53,2.53,0,0,1-.28-.47,2.1,2.1,0,0,0-.41-.38,7.41,7.41,0,0,0,1.83,2.33,10.58,10.58,0,0,0,2.36,2,10.23,10.23,0,0,1,1.57.82,6.33,6.33,0,0,1,1.5,1.47.82.82,0,0,1-.28,1.29,1.49,1.49,0,0,1-1.51-.14c.09.21.38.32.54.49a3.51,3.51,0,0,1,.56.56c.1.17.11.38.22.54a1.76,1.76,0,0,0,.54.47,2,2,0,0,1,.77.7,1.74,1.74,0,0,0,.45.51c.13.12.19.52.33.58a2.32,2.32,0,0,0-.05-1c0-.25.19-.25.43-.36s.5-.16.64-.36,0-.73.23-1c.14,0,.17.14.26.23s.14.06.21.11l.38.26a2,2,0,0,0,.6.35,1.51,1.51,0,0,1,.54.23c.62.42,1.4.6,2,1,.34.24.6.58.94.83s.91.6,1.31.95c.18.16.32.36.5.51s.32.18.47.3.82.73,1.17.6c-.05-.27-.42-.39-.62-.53a8,8,0,0,1-.65-.59c-.16-.15-.31-.31-.48-.44s-.52-.38-.73-.6-.1-.16-.19-.23-.29-.14-.43-.25a3.8,3.8,0,0,1-.49-.43,5.42,5.42,0,0,0-1.07-1c-.52-.35-.92-.85-1.44-1.2a9.54,9.54,0,0,0-1.37-.63c-.27-.13-.64-.1-.89-.26a2.45,2.45,0,0,1-.49-.64.94.94,0,0,0-.66-.49c-.23-.06-.55-.07-.62-.35"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M118.32,158.09c-.43.39-1.09.91-1.06,1.56a1.56,1.56,0,0,0,.85,1.13,1.41,1.41,0,0,0,1.38.14c.33-.21.25-.57.47-.85s.74-.16,1-.1a2.68,2.68,0,0,1,1.09.45,6.06,6.06,0,0,0,2,1.17,11.62,11.62,0,0,0-.84-.92,4,4,0,0,0-1.24-1.06c-.37-.16-.77-.2-1.15-.35a8.34,8.34,0,0,0-1.09-.33c-.75-.18-.65-.92-.9-1.52a4.72,4.72,0,0,0-.55-1.07,1.88,1.88,0,0,1-.45-.93c-.24.06-.32.53-.27.73a4.35,4.35,0,0,0,.66,1.15c.19.29.24.36,0,.62s-.26.17-.39.29-.26.48-.51.62"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M119.15,188.59a4.12,4.12,0,0,1-.92-1c-.24-.3-.6-.31-.94-.46s-.74-.28-1.11-.45a6.4,6.4,0,0,0-.87-.37l-2.67-.81c-.21-.06-.43-.09-.63-.15a3.3,3.3,0,0,1-.55-.32c-.19-.1-.35-.23-.54-.34s-.32-.06-.43-.16c-.23.35.61.7.82.82.39.23.76.47,1.13.72.61.41,1.23.84,1.88,1.19a2.23,2.23,0,0,1,.5.27,2.31,2.31,0,0,1,.27.5,1.9,1.9,0,0,0,.56.59,5.5,5.5,0,0,0,1,.56c.19.08.46.1.6.28a.84.84,0,0,0-.74.43,2.12,2.12,0,0,1-.24.42c-.15.14-.28.1-.48.1s-.33,0-.49,0-.3-.1-.45-.12c-.49-.06-.86.27-1.3.34.19.31.83.37,1.16.49.12,0,.27.05.38.11a3.88,3.88,0,0,1,.48.46l.88.9a7,7,0,0,0,.57.54c.06,0,.08,0,.06,0a.68.68,0,0,0,0-.39c0-.21-.42-.74.05-.71.17,0,.28.2.43.24a2.2,2.2,0,0,0,.45,0,.89.89,0,0,0,.36,0,3.19,3.19,0,0,0,.45-.09c.12,0,.17-.15.28-.22l.19-.09a.28.28,0,0,0,.09-.14c.22-.08.46.25.65.39a8.68,8.68,0,0,0,1.67.94c.25.1.29.35.49.51s.64.43.95.65c.6.44.79,1.21,1.37,1.69a2.3,2.3,0,0,0,1.83.71,12.38,12.38,0,0,1-2.41-3.54,14.92,14.92,0,0,0-1-1.65c-.29-.43-.48-.8-1-1-.26-.09-1.2-.17-.72-.57.15-.12.48-.2.59-.36s0-.13-.12-.2a9.47,9.47,0,0,0-1.31-.57,4.64,4.64,0,0,0-1.57,0c0-.14,0-.42-.22-.38"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M125.14,181.35a3.47,3.47,0,0,1-.87.62c-.1,0-.23,0-.35,0a.86.86,0,0,0-.39.46c-.22.51-.07,1.05-.32,1.56a1.81,1.81,0,0,1,2.43.86,5.74,5.74,0,0,0,.55,1.29c.28.31.58,0,.74-.3a2.26,2.26,0,0,0,.13-1.76,14,14,0,0,0-.81-1.5c-.17-.39,0-.64.32-.87.09-.06.67-.36.59-.49s-1.25.12-1.44.16q-.71.15-1.42.35"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M139.31,197.17a.9.9,0,0,1-.66-.21c-.17-.11-.21-.06-.38-.1s-.45-.15-.67-.2a3,3,0,0,1-.68-.19,7.07,7.07,0,0,1-.8-.32,7.1,7.1,0,0,0-1.69-.81,5,5,0,0,0-1.13,0,1.81,1.81,0,0,1-.91-.28c0,.4.7.64,1,.77a7.74,7.74,0,0,0,1.15.26.18.18,0,0,1-.19.1c0,.26.43.21.59.26a3.41,3.41,0,0,0,.53.13c.19,0,.35.16.53.21s.69.08.71.29c-.24,0-1,.12-.58.47a2.63,2.63,0,0,0,1.18.28c.33,0,.76-.13,1,.13a3.61,3.61,0,0,1,.56.88.52.52,0,0,1-.28,0c.95.2,1.58,1,2.52,1.23.26.05.72.08.5-.29a2.55,2.55,0,0,0-.94-.74c.33-.48-.93-1-1.16-1.06s-.27-.23-.48-.37c.19-.07.74-.05.79-.32s-.63-.21-.84-.3"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M151.81,204.91a15.55,15.55,0,0,1-.63-1.51c-.29-.69-.59-1.37-.85-2.07a9.47,9.47,0,0,0-.86-2c-.34-.52-.8-.51-1.34-.69a1.46,1.46,0,0,1-1.06-1.21c0-.24,0-.52-.13-.72a.58.58,0,0,0-.59-.15,2.23,2.23,0,0,0-.61-.4,9.25,9.25,0,0,0-1-.49c-.1-.15-.23-.28-.33-.43a.17.17,0,0,0,0-.18l-.26-.16a1.88,1.88,0,0,0-.53-.2,9.12,9.12,0,0,1-1.52-.47,8.28,8.28,0,0,0-1.2-.4c-.36-.09-.72-.11-1.08-.23-.2-.07-.41-.08-.61-.16a1.24,1.24,0,0,0-.54-.14.5.5,0,0,1-.34-.06l-.26-.17c-.17,0-.25.07-.39,0a.56.56,0,0,0-.13-.2,1,1,0,0,0-.29-.13.73.73,0,0,1-.37-.35c-.1.44.51.88.8,1.12a7.63,7.63,0,0,0,1.32.78,9.51,9.51,0,0,1,1.53.83c.41.32.8.62,1.24.9l1.12.73c.31.21.57.48.87.71s.62.49.54,1a8,8,0,0,0-.05,1.06,3,3,0,0,0-.74.19c-.25.13-.24.19-.35.43.11.13.24.11.4.12a3.25,3.25,0,0,1,.56.08c.31.06.6,0,.91.12a1.63,1.63,0,0,1,.71.39,1.36,1.36,0,0,1,.25.23c.07.09.07.2.21.21.06-.16.1-.84,0-.92a1.64,1.64,0,0,0,.51-.26c.08-.07.11-.17.2-.22a3.46,3.46,0,0,0,.93.66c.17.07.25.18.41.26s.19,0,.28.08.16.21.24.31c.23.32.63.52.9.83s.42.8.68,1.17a4.27,4.27,0,0,1,.36.53c.09.17.2.34.3.49.21.33.52.6.74.95s.35.69.53,1c.06.12.1.32.19.41s.33,0,.51,0C152.47,206.09,152.13,205.51,151.81,204.91Z"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M145.71,208.44c-.83.08-1.48.66-2.32.25a3.88,3.88,0,0,0-.92-.37c-.06,0,0-.08-.12-.08a.69.69,0,0,0-.25.11c-.14.07-.28.14-.42.23-.06-.36.2-.54.39-.79s0-.39-.23-.61a1.27,1.27,0,0,1-.37-.91,1.49,1.49,0,0,1,.32-1.22,15.65,15.65,0,0,1,1.16,1.51c.14.2.24.49.48.6a1.33,1.33,0,0,0,.89-.07,6.24,6.24,0,0,1,1.36-.42,6.3,6.3,0,0,0,1.79-.44,1.26,1.26,0,0,1-.9.94,9.43,9.43,0,0,0-1.43.25c-.18.08-.57.28-.45.53s.71.26.92.3c0,.21-.24.33-.4.29"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M143.54,215.26c-.16-.22-.37-.42-.51-.65a2.66,2.66,0,0,1-.19-.61,3.29,3.29,0,0,1-.22-.56c0-.18,0-.35,0-.54-.07-.37-.2-.74-.26-1.11s-.06-.63-.11-.94c0-.15-.09-.31-.11-.46a2.3,2.3,0,0,0,0-.36,3.87,3.87,0,0,0-.25-.51,7.73,7.73,0,0,1-.26-.85,2.59,2.59,0,0,0-.39-.62,3.49,3.49,0,0,0,0,1,3.56,3.56,0,0,1,0,.91s-.13-.05-.17-.08c-.21.46.11,1,.09,1.5a.07.07,0,0,0-.08-.06,5.5,5.5,0,0,0,.1,1.8c.14.52.47,1,.59,1.49.19.75-1,.85-.54,1.67a2.16,2.16,0,0,0,2.33,1,8.73,8.73,0,0,1,1.18-.25,2.53,2.53,0,0,1,1.15.32,1.17,1.17,0,0,0-.8-.64c-.21-.08-.16-.1-.2-.27a.77.77,0,0,0-.18-.41c.42.14.84.1,1.25.24.22.07.42.17.65.23a2.79,2.79,0,0,0,.64,0c.23,0,.41.09.63.11a2,2,0,0,0,.64-.08,2.09,2.09,0,0,0,.32-.06c.09,0,.13-.12.21-.16s.37-.07.46-.31a1.24,1.24,0,0,0-.73.08,2.8,2.8,0,0,1-.57.1c-.24,0-.45.17-.71.11a5.11,5.11,0,0,1-.62-.22,7.28,7.28,0,0,0-1-.3c-.2,0-.4-.13-.61-.18s-.46,0-.56-.12a1.52,1.52,0,0,1-.86.09c-.27,0-.41-.12-.36-.42"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M147.37,213.71a1.59,1.59,0,0,1-1.26.51c-.32,0-.64,0-1,0a2,2,0,0,1-.94-.29.81.81,0,0,0-.84,0c0-.29.18-.38.44-.49s.43-.16.33-.45-.69-.72-1-1a4.17,4.17,0,0,0-.51-.51c-.16-.11-.25-.12-.35-.3s-.14-.47-.27-.69a1.69,1.69,0,0,1-.23-.88,7.17,7.17,0,0,1,2.28,1.64,3.8,3.8,0,0,1,.81.9,6.92,6.92,0,0,0,.37.8c.41.56,1.13-.19,1.56-.37s.62-.15.93-.25a2.11,2.11,0,0,0,.8-.54c.27.26-.05.68-.31.8a6,6,0,0,0-1.08.43c-.15.1-.7.47-.58.69s.8-.37.7.35"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M135.69,221.77c-.25.34-.81.93-.61,1.43a2.74,2.74,0,0,0,.46.59,4.87,4.87,0,0,0,.38.47c.33.31.63.07,1,.19s.37.56.72.67c0-.32-.29-.51-.37-.8s.27-.46.4-.78c.38.08.62.43,1,.52a4.61,4.61,0,0,1,1.26.59,3.14,3.14,0,0,1,1,.71,1.63,1.63,0,0,0,.88.72c.8.18,1.55.5,2.35.69l1.37.32c.36.08.46-.06.61-.34a35.92,35.92,0,0,1-4.08-1.4c-.53-.25-1-.56-1.53-.84s-1.22-.62-1.82-1a7.1,7.1,0,0,1-1.69-1.24c-.43-.44-.78-.94-1.18-1.41a22.17,22.17,0,0,1-2.38-3.06,20.41,20.41,0,0,1-2-5c-.83.72-.28,2.08.15,2.88a18.63,18.63,0,0,0,1.15,2.23c.48.66,1,1.3,1.55,1.92a6.58,6.58,0,0,1,1.51,1.83l-.2.13"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M137.86,229.73a6,6,0,0,0-1.65-2.21,2.4,2.4,0,0,0-1.66-.53c-.4.09-.69.45-1.06.61a2.1,2.1,0,0,1-1.4.06,3.47,3.47,0,0,0,.59.29c.12,0,.19,0,.3,0s.26.12.39.12.27-.13.44-.13a1.29,1.29,0,0,0,.33,0,1.54,1.54,0,0,1,.4-.1,2.64,2.64,0,0,1,.84.3,2.14,2.14,0,0,1,.71.63c.19.27.15.64.52.72a2.18,2.18,0,0,1,.29,0c.12,0,.18.17.34.19a4.32,4.32,0,0,0,.72,0"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M157.09,220.32c-.26.15-.52.26-.79.39-.1,0-.21.16-.31.12s-.19-.38-.28-.48a2.21,2.21,0,0,0-.32-.23c-.41-.28-.41,0-.74.21a5.53,5.53,0,0,1-.94.31,1.32,1.32,0,0,1,1-.07,2,2,0,0,1,.56.49,1.47,1.47,0,0,0,1.49.6,1,1,0,0,0,.31-.16c.14-.1.12-.21.21-.32A.66.66,0,0,1,158,221c.3.1.51.39.81.44s.65.21.91,0c-.29-.12-.59-.07-.83-.29s-.32-.46-.51-.67a1.11,1.11,0,0,0-.8-.3c-.35,0-.61-.18-.93.1"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M168.46,207.2a6.78,6.78,0,0,0-.82.09,6.39,6.39,0,0,1-.68,0c-.34,0-.51.19-.85,0s-.56,0-.85.37a1.67,1.67,0,0,1-.46.4c-.11.07-.22.08-.26.23a5.28,5.28,0,0,1,2.38.1c.58.32.86,1.07,1.56,1.19.26,0,1.39.1,1.17-.34-.13-.26-.51-.45-.71-.67a.86.86,0,0,1-.25-.63,1.32,1.32,0,0,0,.76,0c.17-.07.43-.29.25-.46s-.54-.05-.72-.13a1.52,1.52,0,0,0-.62-.2"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M180.55,214.74a2.66,2.66,0,0,0-1.39,1.3c-.1.17-.24.3-.35.46s-.08.25-.19.3c.2-.31.51-.57.74-.86s.41-.27.62-.47.16-.08.22-.13.11-.14.13-.15.26.12.4,0c0,.63.73.54,1.15.45.16,0,.14-.1.31,0a.87.87,0,0,1,.28.29c.09.12.15.27.24.39s.22.15.3.25.16.45.29.65a1.76,1.76,0,0,0,.45.52,2.51,2.51,0,0,0,1.45.33c0-.26-.24-.4-.38-.6a1.82,1.82,0,0,0-.32-.44c.19-.34-.11-.44-.3-.72-.08-.11-.16-.23-.23-.34s-.31-.36-.31-.44.19,0,.29,0a1.46,1.46,0,0,0-.81-.7,3.9,3.9,0,0,0-1-.39,2.13,2.13,0,0,0-2,.49"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M184.79,213.5c-.44-.11-.64-.11-.93.21-.12.13-.06,0-.1.19a.93.93,0,0,0,0,.41c.16,0,.16-.32.38-.29.05.24,0,.58.28.7.12,0,.27-.05.36.06s0,.29,0,.38c.25-.15.47-.09.73-.15a3.22,3.22,0,0,1,.75-.07,1.52,1.52,0,0,0,.83-.13c.1-.07.14-.17.22-.25s.26-.13.36-.25c-.23,0-.79.11-.67-.27a1.23,1.23,0,0,1,1-.63,3.75,3.75,0,0,0,1.39-.19c.35-.16.78-.35.89-.75a4,4,0,0,1-1.16.53,9.3,9.3,0,0,1-1,.07,3.19,3.19,0,0,0-1.13.37c-.26.13-.64.42-1,.37s-.4-.38-.5-.62a3.21,3.21,0,0,0-.61-1c-.27-.28-.61-.49-.88-.77a3.44,3.44,0,0,1-.63-1,.51.51,0,0,1-.18.37c0-.24-.11-.46-.15-.71-.12,0-.15.16-.19.28a1.88,1.88,0,0,0,0-.6.8.8,0,0,0-.31,1,6.71,6.71,0,0,0,.4,1.32c.35.67,1,.84,1.59,1.28"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M146.64,182.38c-.37.06-.52-.12-.83-.21s-.22,0-.36-.15a2.08,2.08,0,0,0-.34-.34.62.62,0,0,0-.51-.11c-.14,0-.46.17-.51.32.32.16.86.23,1.07.57s0,.41,0,.61a.77.77,0,0,0,.37.38,1.32,1.32,0,0,0,1.32-.16c.15-.13.24-.41.45-.46s.39.11.56.17a1,1,0,0,0-.46-.71c-.14-.08-.14-.11-.31-.09s-.3.16-.47,0c-.15.06-.22.29-.41.22"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M150.29,183.28a20.25,20.25,0,0,0-2.11-3.06,18.41,18.41,0,0,0-3.75-3.41l-.06-.11v.07l-.36-.24c-2.28-1.41-4.88-2.25-7.28-3.44-.09.18.15.3.25.42a2.58,2.58,0,0,0,.47.42c.14.11.29.17.43.26a3.65,3.65,0,0,0,.36.27c.12.06.26.09.38.14s.22.13.34.18.31.07.46.13.27.18.43.23c.71.26,1.48.6,2.18.94a4.39,4.39,0,0,1,1,.55,3.24,3.24,0,0,0,.41.23c.17.11.21.29.36.4l.12.28a.86.86,0,0,0-.3,1,1.39,1.39,0,0,0,1,1,1,1,0,0,0,.65-.15c.59.36,1.31.47,1.91.82a8.54,8.54,0,0,1,2.57,2.72c.34.51.67,1,1,1.57,0,.07.37.72.46.7S150.36,183.41,150.29,183.28Z"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M133.41,166.26c-.09.2-.14.43-.21.63s0,.22-.2.2l-.22-.12a.37.37,0,0,0-.2,0,1.68,1.68,0,0,1-.42,0,4.13,4.13,0,0,0-1.71-.16,14.37,14.37,0,0,1,1.38,1.49.73.73,0,0,0,.52.25c.35.06.37.27.22.61.11,0,.28,0,.38,0s.14.15.22.21.25.08.31.28a.63.63,0,0,0,.2.38c.09.09.19.16.28.24s.17.25.29.36a2.87,2.87,0,0,1,.32.32.38.38,0,0,0,.44.14,2.46,2.46,0,0,0-.1-1.18c0-.22-.28-.63-.18-.84a5.57,5.57,0,0,1,3,2.19c.48.65.93,1.33,1.47,1.94a13.56,13.56,0,0,0,2.11,1.91c.13.1,1.06.84,1.17.57.05-.13-.23-.48-.29-.58-.23-.37-.47-.73-.69-1.11a14.74,14.74,0,0,1-1.1-2.4,5.52,5.52,0,0,0-1.08-1.91c-.59-.63-1.34-1.09-1.92-1.73-.34-.37-.29-.6.16-.79-.32-.29-.63-.59-.94-.89s-.93-.19-1.29-.54a4.26,4.26,0,0,1-.89-1.53,3,3,0,0,0-.84-1.46,11.64,11.64,0,0,0-1.7-1,7.92,7.92,0,0,1-1.54-1.3c-.55-.51-1.05-1-1.57-1.57a4.34,4.34,0,0,0-1.87-1.35,3.59,3.59,0,0,0,1,1.5c.16.14.12.16.17.35s.39.46.61.68a1.05,1.05,0,0,1,.29.61c.07.3.22.29.44.46s.35.59.65.8c.07.05.24.1.29.18s0,.31,0,.39c0,.37.29.39.47.66.07.11.08.24.15.34s.16.14.22.23c.18.25.16.47.46.64a2.46,2.46,0,0,1,.56.47c.27.26,1.47,1.47,1,1.85"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M157.19,173a1.59,1.59,0,0,0-.53-.8,2.3,2.3,0,0,0-.71-.49,2.74,2.74,0,0,0-.54-.14,4.23,4.23,0,0,0-1,0,6.26,6.26,0,0,1-.89,0,3.29,3.29,0,0,1-.94-.17c-.13-.07-.15-.14-.3-.15s-.28,0-.46,0-.24-.12-.37-.16-.2,0-.28-.13a3.8,3.8,0,0,0,.66.58,3.64,3.64,0,0,0,.68.56c.18.09.39.16.58.24s.4.05.59.12.62.24.93.38a2.16,2.16,0,0,1,.44.2.83.83,0,0,0,.43.23c-.4.66.5.88,1,.77.21,0,.47-.3.67-.24s.21.21.32.26.3.08.44.14a4.81,4.81,0,0,0,2,.4,2.09,2.09,0,0,1,.83.11,7.3,7.3,0,0,0,1,.44c.07-.35-.23-.5-.37-.77a1.62,1.62,0,0,0-.63-.72c-.26-.16-.5-.07-.79-.1a5.7,5.7,0,0,0-1-.11,1.85,1.85,0,0,1-.65-.1c-.17,0-.27-.13-.42-.19s-.36.07-.48-.2-.08-.29-.41-.36l0-.08"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M162.15,148.79a5.11,5.11,0,0,0-1.26-.66A3,3,0,0,0,160,148c-.39,0-.76-.12-1.15-.11s-.82,0-1.23,0a6.58,6.58,0,0,1-1.11-.07c-.19,0-.4,0-.58-.06s-.35-.16-.53-.22c-.52-.17-1.23-.41-1.46-1a12.62,12.62,0,0,0,3.05.87c1.14.13,2.29,0,3.43.09a7,7,0,0,1,3.8,1.21,7.82,7.82,0,0,1,2.22,3.41,39.82,39.82,0,0,0,2.54,5c-.35.25-.66,0-.88-.29a7.54,7.54,0,0,1-.84-.91c-.19-.32-.34-.67-.53-1a11.16,11.16,0,0,1-.57-1.22c-.16-.35-.3-.7-.49-1s-.56-.63-.76-1-.47-1.35-1.15-1.37c-.1.48-.59.5-1,.49a1.2,1.2,0,0,1-1-.43,1.72,1.72,0,0,1-.16-1.95.28.28,0,0,1,.19,0"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M176.42,176.91c-.44.29-.71.39-.83.9,0,.17,0,.45-.19.54s-.57-.17-.77-.19-.53,0-.8,0c.17-.09.65.14.83.23a2.19,2.19,0,0,1,.58.32c.16.15.13.29.25.44a3,3,0,0,0,.51.56c.12.09.3.16.43.23a.54.54,0,0,0,.25.11c.15,0,.41-.08.54,0s.16.21.26.29l.41.3a2.71,2.71,0,0,0,.59.39c-.25-.37-.74-.95-.63-1.43s.62-.43,1-.35c1,.22,1.38,1.15,2.08,1.75a10.4,10.4,0,0,0,1,.67,3.7,3.7,0,0,1,.89.88,7.83,7.83,0,0,0,1,1.2,6.63,6.63,0,0,0,1.48.94c-.19-.27-.52-.4-.74-.64s-.14-.27-.27-.36-.14,0-.2-.09a2.51,2.51,0,0,1-.26-.33,4.66,4.66,0,0,1-.68-.77c-.19-.33-.5-.49-.65-.85a2,2,0,0,0-.31-.43c-.07-.1-.09-.24-.18-.33a7.19,7.19,0,0,1-.81-.88,2.79,2.79,0,0,0-.55-.67s-.13,0-.18-.05-.12-.12-.2-.15-.31-.11-.45-.16a2.2,2.2,0,0,1-.46-.16c-.16-.1-.37-.16-.53-.27a3.41,3.41,0,0,1-.67-.78c-.08-.13-.11-.29-.19-.42s-.23-.2-.35-.32a1.87,1.87,0,0,1-.55-.8,1.9,1.9,0,0,0-.33-.73c-.12-.16-.26-.35-.4-.5a1.42,1.42,0,0,0-.3-.22c-.11-.08-.15-.23-.24-.3a1.37,1.37,0,0,0-.33-.11,2.34,2.34,0,0,1-.53-.27c-.32-.19-.6-.45-.92-.6s-.26-.25-.41-.42-.32-.31-.47-.46a5,5,0,0,1-.32-.43,4,4,0,0,1-.42-.46,2.45,2.45,0,0,0-.24-.4c-.12-.14-.3-.22-.4-.37a1.8,1.8,0,0,0-.29-.4c-.11-.09-.25-.15-.31-.3,0,.25.15.39.3.57s.19.36.32.51.35.21.47.38a2.63,2.63,0,0,1,.19.37,7.58,7.58,0,0,0,1.32,1.54,9.57,9.57,0,0,0,.71.83,8.45,8.45,0,0,1,.78,1,3.68,3.68,0,0,1,.64,1.06c.09.24.17.77.44.86"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M195.85,167.5a4.58,4.58,0,0,0-.63.46.68.68,0,0,0-.19.26c-.09.1-.21.15-.31.24s0,.13-.24.15-.48-.17-.68-.23-.46,0-.68-.11-.37-.18-.58-.22a2.23,2.23,0,0,0-.83,0c0,.19.26.42.4.54a10,10,0,0,1,.83.68c.22.22.37.14.61.29a2.52,2.52,0,0,1,.49.49c.16.22.19.49.34.72s.35.43.49.68.25.83.64,1.09c.07-.85.15-2.56,1.45-2.21a1.77,1.77,0,0,1,.95.82c.36.53.66,1.1,1,1.64a15.3,15.3,0,0,1,1.06,1.84c.28.6.46,1.23.7,1.85a22.74,22.74,0,0,0,1.46,2.51,3.34,3.34,0,0,0-.36-2.55,5.5,5.5,0,0,0-.21-2.95,9.34,9.34,0,0,0-.3-1.21,5.62,5.62,0,0,0-.53-1,12.86,12.86,0,0,1-.56-1.33c-.14-.32-.4-.57-.54-.88-.34-.77.68-.51,1.08-.48-.14-.42-.47-.46-.7-.79a1.9,1.9,0,0,0-1.16-.81c-.57-.13-1,0-1.26-.67-.18-.49-.25-1-.76-1.29a8.19,8.19,0,0,0-1.06-.34,4.09,4.09,0,0,1-.91-.54,18,18,0,0,0-1.86-.85c-.73-.4-1.32-1-2-1.42a20.16,20.16,0,0,0-1.77-1.17,2.55,2.55,0,0,0,1,1.33,3.22,3.22,0,0,1-.34-.06,19.91,19.91,0,0,0,1.85,1.87,3.83,3.83,0,0,0,.66.67c.17.12.37.18.54.28a10.5,10.5,0,0,0,.94.65.78.78,0,0,1,.44.51c.1.26.25.48.35.74a2.63,2.63,0,0,0,.37.61c.16.21.5.27.61.52"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M158.74,191.69c-.79-.23-1.23-.91-1.87-1.34a5.3,5.3,0,0,0-1.66-.75c-.17,0-.36-.05-.53-.11s-.27-.18-.41-.24-.21,0-.31-.07-.13-.14-.22-.2a6.13,6.13,0,0,0-.57-.26c-.36-.21-.67-.45-1-.65a8.5,8.5,0,0,0-.87-.41,11.46,11.46,0,0,0,2,1.59,5.8,5.8,0,0,0,2,1,4,4,0,0,1,1.88,1.28c.17.2.7.53.7.81s-.38.53-.42.85c-.25,0-.44.2-.68.25.09.15.34,0,.47.18s.07.2.13.28.25.17.35.29a1.62,1.62,0,0,0,.24.29c.23.16.56.12.76.27a3.19,3.19,0,0,1,.29.38c.11.13.27.22.32.39,0-.15,0-.3-.08-.46a1.49,1.49,0,0,0-.13-.34c0-.15.11-.15.25-.19s.28,0,.44-.08.23-.28.38-.39a5.55,5.55,0,0,1,.87.66,3,3,0,0,1,.74.67c.19.29.4.55.58.85a3.33,3.33,0,0,0,.55.66,8.31,8.31,0,0,1,.56.85c.11.14.24.27.33.41a5,5,0,0,0,.27.53,2.83,2.83,0,0,0,1.61.8c-.06-.12-.1,0-.18-.11s-.13-.21-.24-.3a7.87,7.87,0,0,1-.62-.51,5.65,5.65,0,0,1-.81-1.15c-.27-.43-.5-.88-.76-1.32s-.74-.77-1-1.23a3.22,3.22,0,0,0-1-1,2.21,2.21,0,0,0-.6-.32.82.82,0,0,1-.5-.39,3.31,3.31,0,0,0-.46-.7c-.17-.17-.38-.32-.55-.49s-.4-.24-.57-.42a2.76,2.76,0,0,1-.27-.31c-.07-.11,0-.25-.14-.36"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M189.44,201.82a8.1,8.1,0,0,0-.5-1.15,8.3,8.3,0,0,0-.53-1c-.43-.53-.94-1-1.32-1.55s-.91-1.39-1.36-2.1a2.17,2.17,0,0,1-.33-1.32,2.93,2.93,0,0,1,.08-1.06,3.1,3.1,0,0,0,.31,1.14c.05.08.16.14.21.21s0,.61.24.75a.9.9,0,0,1,0-.35c1,1.67,2,3.39,3.18,5a5.88,5.88,0,0,0,3.46,2.35c1.86.49,3.78.66,5.67,1,.11,0,.95.15.85.26s-.44,0-.52,0c-.37,0-.75,0-1.12,0-2.09-.1-4.18-.27-6.26-.43a2.07,2.07,0,0,0,.11.48c.07.1.26,0,.28.21a1.77,1.77,0,0,0-1.26,0,1.48,1.48,0,0,1-1.49-.35,1.2,1.2,0,0,1-.32-1.34,3.09,3.09,0,0,0,.29-1.37"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M173.83,186.83c-.12.13-.3.38-.48.43s-.18,0-.24,0c-.27,0-.4.21-.68.22s-.94,0-1.06.23c.37.15.79.23,1.15.42a5.51,5.51,0,0,0,.54.31,1,1,0,0,1,.48.33,6.89,6.89,0,0,0,.7.82c.09.08.3.32.44.25s.05-.36,0-.48c.23,0,.31.17.48.25a.93.93,0,0,0,.57,0c.34-.08.42-.39.68-.58a13.9,13.9,0,0,1,2.76,3c.43.71.79,1.47,1.19,2.2a5.81,5.81,0,0,0,1.21,1.72c.41.33.33-.15.25-.44-.22-.75-.56-1.46-.8-2.2-.48-1.44-.56-2.9-1.62-4.07-.3-.33-.62-.63-.94-.93a2.47,2.47,0,0,1-.63-.69c-.19-.48.72-.34,1-.33a2.48,2.48,0,0,0-.81-.71c-.33-.25-.53-.68-1-.69s-1,.4-1.25.07-.13-.76-.62-1-.87-.21-1.29-.41a14.8,14.8,0,0,0-1.71-.59c-.69-.23-1.32-.59-2-.83a18.69,18.69,0,0,1-1.83-.56c-.16-.08-1.31-.86-1.41-.62s.37.49.46.56a4.89,4.89,0,0,0,1,.55,1.26,1.26,0,0,0-.46,0c.08.24.2.22.39.31s.24.2.41.22.23,0,.36,0a3.68,3.68,0,0,1,.58.28,14.27,14.27,0,0,1,2,.71c.2.13.24.33.33.54a2,2,0,0,0,.63.84c.28.21.61.35.9.55s.42.46.62.27"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M186,188.8c-.53.39-1.11.8-.93,1.53a1.83,1.83,0,0,0,.91,1.1,1.8,1.8,0,0,0,1.45.44c.45-.15.51-.56.45-1a16,16,0,0,1,2.94,2.55,10.71,10.71,0,0,0,2.94,2.59c.15.07.5.26.51,0s-.61-.73-.78-.88c-.94-.85-1.9-1.67-2.81-2.56a11.73,11.73,0,0,0-2.9-2.07,2.62,2.62,0,0,0-1.17-1.63c-.18-.09-.43-.09-.59-.23s-.15-.35-.27-.5-.25-.21-.35-.34a5.25,5.25,0,0,0-.67-.88c-.73-.63-1.68-1-2.36-1.68-.42-.42-.82-.87-1.2-1.32-.52-.6-.9-1.29-1.37-1.92-.1.24.07.44.16.65-.21,0-.32-.4-.47-.49,0,.62.29.92.6,1.43.13.21.24.46.39.66a4.09,4.09,0,0,0,.4.35,5.89,5.89,0,0,0,.81.77c.34.24.67.49,1,.7a13.14,13.14,0,0,1,1.83,1.48,6.35,6.35,0,0,1,1.24,1.5V189"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M197.51,177.32c-.4-.3-.92-.54-1.29,0-.13.17-.2.46-.36.59s-.32.09-.39.3c.1.2.75,0,.91-.07a1.28,1.28,0,0,1,.59-.15c-.16,0-.38.13-.25.29s.38,0,.5,0,.4.18.63.21a2.06,2.06,0,0,0,.54-.05c.25,0,.33,0,.47.21a3.35,3.35,0,0,0,.51.6c.36.38.41.93.81,1.26.21-.3.61-.26.93-.36a8.21,8.21,0,0,1-.76-1,6.18,6.18,0,0,1-.24-.65c-.11-.26-.11-.29.17-.3s.42,0,.22-.21-.58-.21-.81-.26a5.47,5.47,0,0,1-1-.34,5.59,5.59,0,0,0-.93-.45,3.29,3.29,0,0,0-.82,0"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M157.19,32.22a7.37,7.37,0,0,1-2.26-.71,11.28,11.28,0,0,1-.9-.75,22.53,22.53,0,0,0-2.42-1.43c-.79-.43-1.4-1-2.21-1.42-.58-.29-1.72-1.12-2.36-.92.33.64,1.1.74,1.69,1s.93.57,1.41.83a9.87,9.87,0,0,1,2,1.66c.31.27.75.35,1,.68.41.49.13.84.25,1.38a2.68,2.68,0,0,0,2.31,1.5c.46,0,.79-.34,1.26-.32a2.23,2.23,0,0,1,1.12.49c.6.45,1.09,1,1.67,1.52a7.77,7.77,0,0,1,1.25,1,10.55,10.55,0,0,0,1.25,1.51,9.44,9.44,0,0,0,2,1.7c.29-.78-.27-1.23-.64-1.81-.21-.33-.29-.82-.53-1.12s-.61-.45-.89-.76a5.75,5.75,0,0,0-.74-.71,3.45,3.45,0,0,0-.34-.46,1.39,1.39,0,0,0-.45-.14c-.4-.29-1.27-.94-1.3-1.43.73,0,1.83-2,2.63-.65-.18-.16-.34-.46-.54-.58s-.54,0-.8-.16-.25-.4-.74-.55-.93.07-1.34,0c-.26,0-.38-.29-.76-.25a6.76,6.76,0,0,0-1,.3c-.23.09-.39.27-.61.32-.49.11-1-.11-1.55,0"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M161.33,124.51a1.41,1.41,0,0,0-.89-.94,5.31,5.31,0,0,1-2.43,2.19,6.7,6.7,0,0,0,.84,0c.44-.1.53-.43.93-.59.58-.23,1.23.09,1.82-.1.08-.54-.09-.89.5-.87.4,0,.92.53,1.21.79a3.87,3.87,0,0,0,2.88,1.07,8.72,8.72,0,0,1-.7-.61c-.37-.36-.72-.49-1.07-.8s-2.06-2.62-2.54-.81c-.39-.08-.74-.37-1.17-.27"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M105.5,124.17h-.4A2.34,2.34,0,0,0,105.5,124.17Zm9,2.15a5.33,5.33,0,0,0,.66.29c.28-.49-.24-.62-.87-.62-.41-.28-1.3-.37-1.51.17l-.06,0c-1.13.3-1.15-.09-1.93-.8a7.62,7.62,0,0,0-2.79-1.45c-.94-.25-1.69.2-2.53.24a6.14,6.14,0,0,1,2,.38.21.21,0,0,1-.16.24c.53-.07,1.24.39,1.72.6a2.82,2.82,0,0,1,1.88,1.87l-.31-.62c-.48-.14-.53.23-.92.33s-.81-.08-1.22,0c.08.74,1,.57,1.45.48.29-.05.3-.17.62-.16a.49.49,0,0,0,.39.25,2.56,2.56,0,0,0,.46,0,8.84,8.84,0,0,0,2,.32,1.45,1.45,0,0,1,1.59.84c0-1.36-1.17-1-1.86-1.76C114.13,126.79,114.51,126.54,114.53,126.31Zm-1.67.33,0,0,.15.3A1.52,1.52,0,0,1,112.87,126.64Z"
                transform="translate(-19.42 -7.11)"
              />
              <path
                className="flock-birds-top-animate flock-birds-header-cls-1"
                d="M132.93,8.24l-.52-.1,0,.05ZM137.3,9.9c-.38.37-1.1.24-1.59.12s-.85-.27-1.26-.38a1,1,0,0,0,.16-1.08c-.4-.91-1.35-.43-2,0a.76.76,0,0,0-.18-.41,3.12,3.12,0,0,1-.74-.19l.7.14a1,1,0,0,0-.93-.23l-.13-.05c-.28-.12-.42-.26-.76-.23s-.49.25-.75.31a4.65,4.65,0,0,1-1.17,0c-.56-.08-1.16-.28-1.72-.41a6.59,6.59,0,0,1-2-.42,7,7,0,0,0,2.57,1.42,12.61,12.61,0,0,0,2.34,1l.39,0a2.64,2.64,0,0,1-2.19.85,3.27,3.27,0,0,0,.92.17,1.24,1.24,0,0,1-.45.14c.54.13.62.8,1.24,1.16.13-.8.25-1.06.75-1.42.83-.1,1.64.1,2.45,0a2.12,2.12,0,0,0,.36-.08c.57.12,1.16,0,1.74.12a10.82,10.82,0,0,0,1.12.39A1,1,0,0,0,137.3,9.9Zm-4.68-1.1v0l.92-.23A4.72,4.72,0,0,1,132.62,8.8ZM128,10.72a2.39,2.39,0,0,0,.49,0A1.09,1.09,0,0,0,128,10.72Z"
                transform="translate(-19.42 -7.11)"
              />
            </g>
          </svg>
        </div>
      </Fragment>
    );
  }
}

export default FlockBirdsHeader;
