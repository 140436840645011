import React, { Fragment, Component } from "react";
import anime from "animejs";
import AboutButton from "../UI/AboutGraphics/AboutButton";
import AboutCircles from "../UI/AboutGraphics/AboutCircles";
import AboutGraphics from "../UI/AboutGraphics/AboutGraphics";
import "./AboutAjov.css";

import { MdFacebook } from "react-icons/md";
import { TiSocialTwitter } from "react-icons/ti";
import { BsLinkedin } from "react-icons/bs";
import { Soc } from "../SocialMediaCrap/Soc";

type Props = {};
type State = {
  about1: boolean;
  about2: boolean;
  about3: boolean;
  about4: boolean;
};

class AboutAjov extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      about1: false,
      about2: false,
      about3: false,
      about4: false,
    };
  }

  activateCardProfile = () => {
    var cardAnimation = (function () {
      var cardTimeline = anime.timeline({
        autoplay: false,
        direction: "normal",
        loop: false,
      });

      cardTimeline.add(
        {
          targets: ".profile-about-card",
          translateY: 2260,
          rotate: [270, 0],
        },
        0
      );

      function init() {
        cardTimeline.play();
      }

      return {
        init: init,
      };
    })();
    cardAnimation.init();

    //Deactivate other
    this.deActivateCardExperience();
    this.deActivateCardBuild();
    this.deActivateCardTinker();
  };

  deActivateCardProfile = () => {
    var cardAnimation = (function () {
      var cardTimeline = anime.timeline({
        autoplay: false,
        direction: "normal",
        loop: false,
      });

      cardTimeline.add(
        {
          targets: ".profile-about-card",
          translateY: -2300,
          rotate: [0, 270],
          duration: 1000,
        },
        0
      );

      function init() {
        cardTimeline.play();
      }

      return {
        init: init,
      };
    })();
    cardAnimation.init();
  };

  activateCardExperience = () => {
    var cardAnimation = (function () {
      var cardTimeline = anime.timeline({
        autoplay: false,
        direction: "normal",
        loop: false,
      });

      cardTimeline.add(
        {
          targets: ".experience-about-card",
          translateX: 2200,
          rotate: [270, 0],
        },
        0
      );

      function init() {
        cardTimeline.play();
      }

      return {
        init: init,
      };
    })();
    cardAnimation.init();

    this.deActivateCardProfile();
    this.deActivateCardBuild();
    this.deActivateCardTinker();
  };

  deActivateCardExperience = () => {
    var cardAnimation = (function () {
      var cardTimeline = anime.timeline({
        autoplay: false,
        direction: "normal",
        loop: false,
      });

      cardTimeline.add(
        {
          targets: ".experience-about-card",
          translateX: -2300,
          rotate: [0, 270],
          duration: 1000,
        },
        0
      );

      function init() {
        cardTimeline.play();
      }

      return {
        init: init,
      };
    })();
    cardAnimation.init();
  };

  activateCardBuild = () => {
    var cardAnimation = (function () {
      var cardTimeline = anime.timeline({
        autoplay: false,
        direction: "normal",
        loop: false,
      });

      cardTimeline.add(
        {
          targets: ".build-about-card",
          translateX: -5300,
          rotate: [270, 0],
        },
        0
      );

      function init() {
        cardTimeline.play();
      }

      return {
        init: init,
      };
    })();
    cardAnimation.init();

    this.deActivateCardProfile();
    this.deActivateCardExperience();
    this.deActivateCardTinker();
  };

  deActivateCardBuild = () => {
    var cardAnimation = (function () {
      var cardTimeline = anime.timeline({
        autoplay: false,
        direction: "normal",
        loop: false,
      });

      cardTimeline.add(
        {
          targets: ".build-about-card",
          translateX: 5300,
          rotate: [0, 270],
          duration: 1000,
        },
        0
      );

      function init() {
        cardTimeline.play();
      }

      return {
        init: init,
      };
    })();
    cardAnimation.init();
  };

  activateCardTinker = () => {
    var cardAnimation = (function () {
      var cardTimeline = anime.timeline({
        autoplay: false,
        direction: "normal",
        loop: false,
      });

      cardTimeline.add(
        {
          targets: ".tinker-about-card",
          translateY: -5200,
          rotate: [270, 0],
        },
        0
      );

      function init() {
        cardTimeline.play();
      }

      return {
        init: init,
      };
    })();
    cardAnimation.init();

    this.deActivateCardProfile();
    this.deActivateCardBuild();
    this.deActivateCardExperience();
  };

  deActivateCardTinker = () => {
    var cardAnimation = (function () {
      var cardTimeline = anime.timeline({
        autoplay: false,
        direction: "normal",
        loop: false,
      });

      cardTimeline.add(
        {
          targets: ".tinker-about-card",
          translateY: 5300,
          rotate: [0, 270],
          duration: 1000,
        },
        0
      );

      function init() {
        cardTimeline.play();
      }

      return {
        init: init,
      };
    })();
    cardAnimation.init();
  };

  render() {
    return (
      <Fragment>
        <div className="flex space-x-2">
          <div>
            <div
              className="offcanvas offcanvas-start fixed bottom-0 flex flex-col bg-blue-500 bg-opacity-50 invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 left-0 border-none overflow-none w-6/12  xs:w-full "
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div className="offcanvas-header bg-white flex items-center justify-between p-4 z-20">
                <h5
                  className="offcanvas-title mb-0 leading-normal font-semibold"
                  id="offcanvasExampleLabel"
                >
                  About AJOV
                </h5>
                <button
                  type="button"
                  className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body flex-grow p-4 xs:p-0 overflow-y-auto">
                <div className="flex ">
                  <div className="profile-about-card w-10/12 2k:w-11/12 xs:w-full">
                    <div className="ajov-profile rounded-lg shadow-lg bg-white w-full z-20 2k:overflow-y-auto xs:overflow-y-auto 2k:h-[40rem] xs:h-[40rem]">
                      <button
                        type="button"
                        className="absolute bg-red-600 right-1 top-1 rounded-md p-2 inline-flex items-center  text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={this.deActivateCardProfile}
                      >
                        <span className="sr-only">Close menu</span>

                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>

                      <img
                        className="rounded-t-lg w-full"
                        src="./familypic-medium.jpg"
                        alt=""
                      />
                      <div className="ml-80"> </div>

                      <div className="p-6">
                        <img
                          src="./ajovxyz-profile-stroke-small.png"
                          className="rounded-full w-32 shadow-lg mx-auto ajov-profile-pic"
                          alt="Avatar"
                        />
                        <h5 className="text-gray-900 text-xl font-medium mb-2">
                          <span className="inline-block py-1 px-1.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-700 text-white rounded ml-2">
                            A
                          </span>
                          aron{" "}
                          <span className="inline-block py-1 px-1.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-700 text-white rounded ml-2">
                            J
                          </span>{" "}
                          <span className="inline-block py-1 px-1.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-700 text-white rounded ml-2">
                            O
                          </span>{" "}
                          <span className="inline-block py-1 px-1.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-700 text-white rounded ml-2">
                            V
                          </span>
                          illanueva
                        </h5>
                        <div className="w-1/6 grid grid-cols-3 justify-items-center mx-auto mb-5">
                          <Soc content="if its free then you are the product.">
                            <MdFacebook
                              className="text-center"
                              size={30}
                              color="#008BD2"
                            />
                          </Soc>

                          <Soc content="not really sure why I need this.">
                            <TiSocialTwitter
                              className="text-center"
                              size={30}
                              color="#008BD2"
                            />
                          </Soc>
                          <Soc content="just explore my site :)">
                            <BsLinkedin
                              className="text-center"
                              size={30}
                              color="#008BD2"
                            />
                          </Soc>
                        </div>

                        <p className="text-gray-700 text-base text-left mb-4">
                          Hello, my name is Aaron, I am the father of three
                          kids—two boys and a girl—and a fantastic wife.
                          Hardworker, loves to code, loves free speech, I've
                          been programming for 20 years mostly with web
                          development.I believe in ongoing learning and if you
                          stop learning you stop living.
                        </p>
                        <h3 className="font-medium leading-tight text-3xl">
                          Heroes / Idols
                        </h3>
                        <hr className="mt-3 mb-5" />
                        <div className="grid grid-cols-3 2k:grid-cols-1 xs:grid-cols-1 gap-4">
                          <div>
                            {" "}
                            <div
                              className="bg-blue-600 shadow-lg mx-auto w-96 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block mb-3"
                              id="static-example"
                              role="alert"
                              aria-live="assertive"
                              aria-atomic="true"
                              data-mdb-autohide="false"
                            >
                              <div className="bg-blue-600 flex justify-between items-center py-2 px-3 bg-clip-padding border-b border-blue-500 rounded-t-lg">
                                <p className="font-bold text-white flex items-center">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="info-circle"
                                    className="w-4 h-4 mr-2 fill-current"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                                    ></path>
                                  </svg>
                                  Aaron Swartz
                                </p>
                                <div className="flex items-center">
                                  <p className="text-white opacity-90 text-xs">
                                    Internet Activist
                                  </p>
                                </div>
                              </div>
                              <div className="p-3 bg-blue-600 rounded-b-lg break-words text-white flex">
                                <img
                                  src="./aaron-swartz.jpg"
                                  className="rounded-full w-32 shadow-lg mr-5"
                                  alt="Savant Swartz"
                                />
                                <p className="text-left text-sm">
                                  Gifted and a savant, one of the founder of
                                  Rededit.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            {" "}
                            <div
                              className="bg-blue-600 shadow-lg mx-auto w-96 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block mb-3"
                              id="static-example"
                              role="alert"
                              aria-live="assertive"
                              aria-atomic="true"
                              data-mdb-autohide="false"
                            >
                              <div className="bg-blue-600 flex justify-between items-center py-2 px-3 bg-clip-padding border-b border-blue-500 rounded-t-lg">
                                <p className="font-bold text-white flex items-center">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="info-circle"
                                    className="w-4 h-4 mr-2 fill-current"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                                    ></path>
                                  </svg>
                                  Julian Assange
                                </p>
                                <div className="flex items-center">
                                  <p className="text-white opacity-90 text-xs">
                                    Hacker
                                  </p>
                                </div>
                              </div>
                              <div className="p-3 bg-blue-600 rounded-b-lg break-words text-white flex">
                                <img
                                  src="./julian-assange.jpg"
                                  className="rounded-full w-32 shadow-lg mr-5"
                                  alt="Wikileaks"
                                />
                                <p className="text-left text-sm">
                                  From hacking to wikileaks, leak to the world
                                  the information that US considered illegal.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            {" "}
                            <div
                              className="bg-blue-600 shadow-lg mx-auto w-96 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block mb-3"
                              id="static-example"
                              role="alert"
                              aria-live="assertive"
                              aria-atomic="true"
                              data-mdb-autohide="false"
                            >
                              <div className="bg-blue-600 flex justify-between items-center py-2 px-3 bg-clip-padding border-b border-blue-500 rounded-t-lg">
                                <p className="font-bold text-white flex items-center">
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="info-circle"
                                    className="w-4 h-4 mr-2 fill-current"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                                    ></path>
                                  </svg>
                                  Ukrainian Defenders
                                </p>
                                <div className="flex items-center">
                                  <p className="text-white opacity-90 text-xs">
                                    Great Defenders
                                  </p>
                                </div>
                              </div>
                              <div className="p-3 bg-blue-600 rounded-b-lg break-words text-white flex">
                                <img
                                  src="./olexander_matsievsky.jpg"
                                  className="rounded-full w-32 shadow-lg mr-5 max-h-32"
                                  alt="Olexander Matsievsky.jpg"
                                />
                                <p className="text-left text-sm">
                                  No Doubt, UA generation of soldiers are the
                                  best right now defending versus the aggressor,
                                  supposed to be rank 2nd powerful army in the
                                  world and still holding.
                                  <strong>Slava Ukraine!</strong>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="mt-3 mb-5" />
                        <button
                          type="button"
                          className=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                          onClick={this.deActivateCardProfile}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="experience-about-card w-10/12 2k:w-full">
                    <div className="ajov-experience rounded-lg shadow-lg bg-white w-12/12 z-20">
                      <button
                        type="button"
                        className="absolute bg-red-600 right-1 top-1 rounded-md p-2 inline-flex items-center  text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={this.deActivateCardExperience}
                      >
                        <span className="sr-only">Close menu</span>

                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>

                      <img
                        className="rounded-t-lg w-full"
                        src="./header-about-2.jpg"
                        alt=""
                      />

                      <div className="p-6">
                        <img
                          src="./profile-2.jpg"
                          className="rounded-full w-32 shadow-lg mx-auto ajov-experience-pic"
                          alt="Avatar"
                        />
                        <h5 className="text-gray-900 text-xl font-medium mb-2">
                          AJOV Experience
                        </h5>
                        <p className="text-gray-700 text-base mb-4">
                          Worked with various clients from Australia(Xenex
                          Media, Private Media), Japan(Supersonic Designs),
                          USA(Coralyn(dot)com, BrightSkyWebDesign, BNBFinancials
                          ), Canada(WebAholics), Norway(Abovestratus) etc...
                          Adept with Frontend, Backend, DevOps for Web, know
                          desktop app development for python and C#, Mobile app
                          development with Xamarin, Android Java.
                        </p>
                        <button
                          type="button"
                          className=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                          onClick={this.deActivateCardExperience}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="build-about-card w-10/12 xs:w-full">
                    <div className="ajov-build rounded-lg shadow-lg bg-white w-12/12 z-20">
                      <img
                        className="rounded-t-lg w-full"
                        src="./header-about-1.jpg"
                        alt=""
                      />

                      <div className="p-6">
                        <button
                          type="button"
                          className="absolute bg-red-600 right-1 top-1 rounded-md p-2 inline-flex items-center  text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                          onClick={this.deActivateCardBuild}
                        >
                          <span className="sr-only">Close menu</span>

                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                        <img
                          src="./weblogo.png"
                          className="rounded-full w-32 shadow-lg mx-auto ajov-build-pic"
                          alt="Avatar"
                        />
                        <h5 className="text-gray-900 text-xl font-medium mb-2">
                          AJOV Web Build
                        </h5>
                        <p className="text-gray-700 text-base mb-4">
                          This website is built with ReactTS and Tailwind,
                          AnimeJS, Knightlab Timeline JS and couple of SVG
                          vectors.
                        </p>
                        <button
                          type="button"
                          className=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                          onClick={this.deActivateCardBuild}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="tinker-about-card w-10/12 xs:w-full">
                    <div className="ajov-tinker rounded-lg shadow-lg bg-white w-12/12 z-20">
                      <button
                        type="button"
                        className="absolute bg-red-600 right-1 top-1 rounded-md p-2 inline-flex items-center  text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={this.deActivateCardTinker}
                      >
                        <span className="sr-only">Close menu</span>

                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                      <img
                        className="rounded-t-lg w-full"
                        src="./header-about-3.jpg"
                        alt=""
                      />

                      <div className="p-6">
                        <img
                          src="./profile-3.jpg"
                          className="rounded-full w-32 shadow-lg mx-auto ajov-tinker-pic"
                          alt="Avatar"
                        />
                        <h5 className="text-gray-900 text-xl font-medium mb-2">
                          Try Hard Coding Updates
                        </h5>
                        <p className="text-gray-700 text-base mb-4">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>

                        <button
                          type="button"
                          className=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                          onClick={this.deActivateCardTinker}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                  <AboutButton
                    aboutProfile={this.activateCardProfile}
                    aboutExperience={this.activateCardExperience}
                    aboutBuild={this.activateCardBuild}
                    aboutTinker={this.activateCardTinker}
                  />
                  <AboutGraphics />
                  <AboutCircles />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default AboutAjov;
