import React, { Fragment, Component } from "react";
import "./DesignMain.css";
import { DesignMainContent } from "./DesignMain/DesignMainContent";

interface ITabMenuData {
  name: string;
  componentName: string;
  link: string;
  shown: Boolean;
}

type Props = {
  name: string;
  show: boolean;
};
type State = {
  designData: ITabMenuData[];
};

class DesignMain extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      designData: [
        {
          name: "Design",
          componentName: "<WinFormsContent />",
          link: "design",
          shown: false,
        },
      ],
    };
  }

  render() {
    const { designData } = this.state;
    const { show } = this.props;
    return (
      <Fragment>
        <ul
          className={`nav nav-pills nav-justified flex flex-col md:flex-row flex-wrap list-none pl-0 mb-4 ${
            show ? "" : "hidden"
          }`}
          id="pills-tabJustify"
          role="tablist"
        >
          {designData.map((anObjectMapped, index) => {
            return (
              <li
                className="nav-item flex-grow text-center my-2 md:mr-2"
                key={anObjectMapped.link}
                role="presentation"
              >
                <a
                  href={`#pills-${anObjectMapped.link}-tabJustify`}
                  className="
      nav-link
      w-full
      block
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      px-6
      py-3
      focus:outline-none focus:ring-0
      active
    "
                  id={`#pills-${anObjectMapped.link}-tabJustify`}
                  data-bs-toggle="pill"
                  data-bs-target={`#pills-${anObjectMapped.link}Justify`}
                  role="tab"
                  aria-controls={`pills-${anObjectMapped.link}Justify`}
                  aria-selected="true"
                >
                  {anObjectMapped.name}
                </a>
              </li>
            );
          })}
        </ul>
        <div
          className={`tab-content ${show ? "" : "hidden"}`}
          id="pills-tabContentJustify"
        >
          <div
            className="tab-pane fade active show"
            id="pills-designJustify"
            role="tabpanel"
            aria-labelledby="pills-design-tabJustify"
          >
            <DesignMainContent />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default DesignMain;
