import React, { FunctionComponent } from "react";
import { AngularContent } from "../FrontEnd/AngularContent";
import { ReactContent } from "../FrontEnd/ReactContent";
import { SvelteContent } from "../FrontEnd/SvelteContent";
import { VueContent } from "../FrontEnd/VueContent";

import "./SubSkillFrontEndNavigation.css";

interface IMenuObject {
  name: string;
  componentName: string;
  link: string;
  shown: Boolean;
}

type MenuProps = {
  menuObject: IMenuObject[];
};

export const SubSkillFrontEndNavigation: FunctionComponent<MenuProps> = ({
  menuObject,
}) => {
  const aaron = (compname: String) => {
    //alert(compname);
  };
  return (
    <div className="flex items-start xs:flex-col">
      <ul
        className="nav nav-pills flex flex-col flex-wrap xs:flex-row xs:justify-between xs:w-full  list-none pl-0 mr-4 font-russosans"
        id="pills-tabVertical"
        role="tablist"
      >
        {menuObject.map((anObjectMapped, index) => {
          return (
            <li
              key={`${anObjectMapped.componentName}`}
              className="nav-item mb-2"
              role="presentation"
            >
              <a
                href={`#pills-${anObjectMapped.link}Vertical`}
                className="
          block
          font-medium
          text-xs
          xs:text-xxs
          leading-tight
          uppercase
          rounded
          px-6
          py-3
          focus:outline-none focus:ring-0
          bg-menublue
          text-white
        "
                id={`#ppills-${anObjectMapped.link}-tabVertical`}
                data-bs-toggle="pill"
                data-bs-target={`#pills-${anObjectMapped.link}Vertical`}
                role="tab"
                aria-controls={`pills-${anObjectMapped.link}Vertical`}
                aria-selected="true"
                onClick={() => aaron(`${anObjectMapped.componentName}`)}
              >
                {anObjectMapped.name}
              </a>
            </li>
          );
        })}
      </ul>
      <div className="tab-content grow" id="pills-tabContentVertical">
        <div
          className="tab-pane fade show active"
          id="pills-angularjsVertical"
          role="tabpanel"
          aria-labelledby="pills-angularjs-tabVertical"
        >
          <div className="flex text-left font-opensans">
            <AngularContent />
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-reactjsVertical"
          role="tabpanel"
          aria-labelledby="pills-reactjs-tabVertical"
        >
          <div className="flex text-left font-opensans">
            <ReactContent />
          </div>
        </div>

        <div
          className="tab-pane fade"
          id="pills-vuejsVertical"
          role="tabpanel"
          aria-labelledby="pills-vuejs-tabVertical"
        >
          <div className="flex text-left font-opensans">
            <VueContent />
          </div>
        </div>

        <div
          className="tab-pane fade"
          id="pills-svelteVertical"
          role="tabpanel"
          aria-labelledby="pills-svelte-tabVertical"
        >
          <div className="flex text-left font-opensans">
            <SvelteContent />
          </div>
        </div>
      </div>
    </div>
  );
};
