import React, { Fragment, FunctionComponent } from "react";
import "./PlanningMainContent.css";

export const PlanningMainContent: FunctionComponent = () => {
  return (
    <Fragment>
      <div className="flex flex-col  justify-between rounded overflow-hidden h-auto border shadow shadow-lg">
        <div className="bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col leading-normal">
          <div className="text-black font-bold text-xl mb-2 leading-tight">
            <h2 className="focus:outline-none text-lg font-bold leading-tight text-gray-800">
              Tools I have experience using that helps a lot with projects I am
              working
            </h2>
            <div className="bg-white w-1/2 xs:w-full xs:text-xs rounded px-6">
              <hr className="-mx-6" />
              <div className="flex items-center justify-between my-4">
                <div className="w-16">
                  <img
                    className="w-12 h-12 rounded-full"
                    src="./drawio.jpg"
                    alt="DrawIO"
                  />
                </div>
                <div className="flex-1 pl-2">
                  <div className="text-gray-700 font-semibold">DrawIO</div>
                  <div className="text-gray-600 font-thin">
                    From planning flow of an APP to setting up VPC, this tool is
                    free and easy to use.
                  </div>
                </div>
                <div className="text-red-400"></div>
              </div>
              <hr className="boder-b-0 my-4" />
              <div className="flex items-center my-4">
                <div className="w-16">
                  <img
                    className="w-12 h-12 rounded-full"
                    src="./postman.jpg"
                    alt="Postman"
                  />
                </div>
                <div className="flex-1 pl-2">
                  <div className="text-gray-700 font-semibold">Postman</div>
                  <div className="text-gray-600 font-thin">
                    API preview and testing, really helps me collaborate with
                    fellow deveopers
                  </div>
                </div>
                <div className="text-red-400"></div>
              </div>
              <hr className="boder-b-0 my-4" />
              <div className="flex items-center my-4">
                <div className="w-16">
                  <img
                    className="w-12 h-12 rounded-full"
                    src="./litmus.jpg"
                    alt="Litmus"
                  />
                </div>
                <div className="flex-1 pl-2">
                  <div className="text-gray-700 font-semibold">Litmus</div>
                  <div className="text-gray-600 font-thin">
                    Email marketing design need this tool, email clients have
                    different output. This tool helps me check every content.
                  </div>
                </div>
                <div className="text-red-400"></div>
              </div>{" "}
              {/* .itemcenter */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
