import React from "react";
import "tw-elements";
import "./App.css";
import MainPage from "./components/MainPatge";

function App() {
  return <MainPage />;
}

export default App;
