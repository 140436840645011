import React, { Fragment, Component } from "react";
import "./AboutCircles.css";

class AboutCircles extends Component {
  render() {
    return (
      <Fragment>
        <div id="aboutcircles">
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 306.06 475.17"
          >
            <path
              className="about-circles-1"
              d="M667.52,793.65a13.41,13.41,0,0,0-5.45,1.16L636.22,769a42,42,0,0,0,1.21-51.88l10.1-10a13.46,13.46,0,1,0-5-5.73l-10.18,10.08A42,42,0,0,0,577,709.79l-8-8a13.48,13.48,0,1,0-6.39,5.84l8.32,8.32a42,42,0,0,0-.06,52.53l-13.06,13.06a18.44,18.44,0,1,0,8.61,8l13-13A41.79,41.79,0,0,0,599.43,784V894h13.8V783.17A41.85,41.85,0,0,0,630.05,775l25.65,25.65a13.45,13.45,0,1,0,11.82-7Z"
              transform="translate(-376.92 -420.83)"
            />
            <path
              className="about-circles-1"
              d="M629.89,629.39a24.81,24.81,0,0,0-10.07,2.14L572,583.76a77.67,77.67,0,0,0,2.23-95.85l18.66-18.49a24.87,24.87,0,1,0-9.28-10.6l-18.82,18.63a77.64,77.64,0,0,0-102.13-3L448,459.66a24.89,24.89,0,1,0-11.81,10.79l15.37,15.37a77.59,77.59,0,0,0-.11,97.06L427.27,607a34.09,34.09,0,1,0,15.9,14.85l24-24a77.28,77.28,0,0,0,36.86,13.82V863.88h25.5V610.08A77.42,77.42,0,0,0,560.67,595l47.39,47.39a24.85,24.85,0,1,0,21.83-13Z"
              transform="translate(-376.92 -420.83)"
            />
          </svg>
        </div>
      </Fragment>
    );
  }
}

export default AboutCircles;
