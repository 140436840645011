import React, { Fragment, FunctionComponent } from "react";
import "./SkillHeader.css";

export const SkillHeader: FunctionComponent = () => {
  return (
    <Fragment>
      <svg
        id="Skill_Header"
        data-name="Layer_Skill_Header"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 294.63 139.77"
        className="headerSkill"
      >
        <g>
          <polygon
            className="tech3 tech3animate1"
            points="132.38 83.53 83.53 83.53 0 0 3.91 0 24.91 21.01 24.91 23.45 84.02 82.56 99.17 82.56 99.17 59.84 114.07 59.84 132.38 83.53"
          />
          <rect
            className="tech3 tech3animate2"
            x="88.6"
            y="86.01"
            width="19.36"
            height="2.96"
          />
          <polygon
            className="tech3 tech3animate3"
            points="107.96 88.97 57.16 139.77 57.16 125.47 57.65 125.47 57.65 133.98 62.21 133.98 107.4 88.78 107.96 88.97"
          />
          <g>
            <rect
              className="tech3 tech3animate4"
              x="34.13"
              y="136.78"
              width="2.93"
              height="2.93"
            />
            <rect
              className="tech3 tech3animate1"
              x="39.91"
              y="136.78"
              width="2.93"
              height="2.93"
            />
            <rect
              className="tech3 tech3animate5"
              x="45.69"
              y="136.78"
              width="2.93"
              height="2.93"
            />
            <rect
              className="tech3 tech3animate6"
              x="51.48"
              y="136.78"
              width="2.93"
              height="2.93"
            />
          </g>
          <g>
            <rect
              className="tech3 tech3animate7"
              x="199.06"
              y="70.83"
              width="2.93"
              height="11.72"
            />
            <rect
              className="tech3 tech3animate8"
              x="204.85"
              y="70.83"
              width="2.93"
              height="11.72"
            />
            <rect
              className="tech3 tech3animate9"
              x="210.62"
              y="70.83"
              width="2.93"
              height="11.72"
            />
            <rect
              className="tech3 tech3animate10"
              x="216.41"
              y="70.83"
              width="2.93"
              height="11.72"
            />
          </g>
          <polygon
            className="tech3"
            points="111.28 87.48 205.8 87.48 205.8 88.7 143.03 88.7 128.01 103.73 97.07 103.73 111.28 89.52 111.28 87.48"
          />
          <rect
            className="tech3 tech3animate11"
            x="111.19"
            y="85.98"
            width="183.43"
            height="0.49"
          />
          <g>
            <path
              className="tech3-stroke"
              d="M321.62,392.23a.83.83,0,1,1,0-1.17A.83.83,0,0,1,321.62,392.23Zm.4-1.58a1.4,1.4,0,1,0,0,2A1.4,1.4,0,0,0,322,390.65Z"
              transform="translate(-251.31 -376.51)"
            />
            <rect
              className="tech3 tech3animate12"
              x="288.65"
              y="405.19"
              width="37.15"
              height="0.52"
              transform="translate(-448.06 -40.16) rotate(-45.04)"
            />
          </g>
          <g>
            <path
              className="tech3-stroke"
              d="M324,394.55a.83.83,0,1,1,0-1.17A.83.83,0,0,1,324,394.55Zm.4-1.58a1.4,1.4,0,1,0,0,2A1.41,1.41,0,0,0,324.43,393Z"
              transform="translate(-251.31 -376.51)"
            />
            <rect
              className="tech3 tech3animate13"
              x="291.06"
              y="407.51"
              width="37.15"
              height="0.52"
              transform="translate(-448.95 -38.13) rotate(-45)"
            />
          </g>
          <g>
            <path
              className="tech3-stroke"
              d="M326.6,396.88a.83.83,0,1,1,0-1.17A.83.83,0,0,1,326.6,396.88Zm.41-1.58a1.4,1.4,0,1,0,0,2A1.41,1.41,0,0,0,327,395.29Z"
              transform="translate(-251.31 -376.51)"
            />
            <rect
              className="tech3 tech3animate14"
              x="293.64"
              y="409.83"
              width="37.15"
              height="0.52"
              transform="translate(-449.89 -35.27) rotate(-45.04)"
            />
          </g>
        </g>
      </svg>
    </Fragment>
  );
};
