import React, { Fragment, FunctionComponent } from "react";
import { SkillHeader } from "../SkillHeader";
import "./SvelteContent.css";

export const SvelteContent: FunctionComponent = () => {
  return (
    <Fragment>
      <div className="block p-6 rounded-lg shadow-lg bg-white  relative">
        <SkillHeader />
        <h5 className="text-gray-900 absolute left-40 text-2xl leading-tight font-bold mb-2">
          Svelte JS
        </h5>

        <div
          aria-label="card sveltet"
          className="focus:outline-none flex sm:w-full md:w-full pb-20 mt-20"
        >
          <div className="w-20  h-20 relative xs:absolute xs:top-0 xs:right-0 mr-5 ">
            <div className="absolute top-0 right-0 bg-indigo-100 rounded w-16 h-16 mt-2 mr-1"></div>
            <div className="absolute text-white bottom-0 left-0 bg-theme rounded w-16 h-16 flex items-center justify-center mt-2 mr-3">
              <img src="./svelte.pnggit status" alt="Svelte JS" />
            </div>
          </div>
          <div className="w-full">
            <h2 className="focus:outline-none text-lg font-bold leading-tight text-gray-800">
              I like the framework cause of its lightweight, and its true open
              source that is not manage of any big company. I use only
              personally.
            </h2>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-2">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Used frontend UI with
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                Bootstrap
              </span>
            </div>

            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-1">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Understand usage of
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800  text-white rounded">
                Component Composition
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800  text-white rounded">
                Stores
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800  text-white rounded">
                Props
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800  text-white rounded">
                Lifecycle
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
