import React, { Fragment, FunctionComponent } from "react";
import "./DesignMainContent.css";

export const DesignMainContent: FunctionComponent = () => {
  return (
    <Fragment>
      <div className="flex flex-col  justify-between rounded overflow-hidden h-auto border shadow shadow-lg">
        <div className="bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col leading-normal">
          <div className="text-black font-bold text-xl mb-2 leading-tight">
            <h2 className="focus:outline-none text-lg font-bold leading-tight text-gray-800">
              Design is the first cloud computing server I learned.
            </h2>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-2">
              <div className="bg-white w-1/2 xs:w-full xs:text-xs rounded px-6">
                <hr className="-mx-6" />
                <div className="flex items-center justify-between my-4">
                  <div className="w-16">
                    <img
                      className="w-12 h-12 rounded-full"
                      src="./adobe_photoshop.jpg"
                      alt="Photoshop"
                    />
                  </div>
                  <div className="flex-1 pl-2">
                    <div className="text-gray-700 font-semibold">
                      Adobe Photoshop
                    </div>
                    <div className="text-gray-600 font-thin">
                      Back in the the days we still slice HTML, PS still the
                      editor creating creative media design.
                    </div>
                  </div>
                  <div className="text-red-400"></div>
                </div>
                <hr className="boder-b-0 my-4" />
                <div className="flex items-center my-4">
                  <div className="w-16">
                    <img
                      className="w-12 h-12 rounded-full"
                      src="./adobe_animate.jpg"
                      alt="Animate"
                    />
                  </div>
                  <div className="flex-1 pl-2">
                    <div className="text-gray-700 font-semibold">
                      Adobe Animate
                    </div>
                    <div className="text-gray-600 font-thin">
                      Started with Macromedia flash, Adobe Animate is
                      Canvas/Javascript version you can easily integration to
                      your web application
                    </div>
                  </div>
                  <div className="text-red-400"></div>
                </div>
                <hr className="boder-b-0 my-4" />
                <div className="flex items-center my-4">
                  <div className="w-16">
                    <img
                      className="w-12 h-12 rounded-full"
                      src="./adobe_iafter_effects.jpg"
                      alt="After Effects"
                    />
                  </div>
                  <div className="flex-1 pl-2">
                    <div className="text-gray-700 font-semibold">
                      After Effects
                    </div>
                    <div className="text-gray-600 font-thin">
                      Know basic animation,transition for video
                    </div>
                  </div>
                  <div className="text-red-400"></div>
                </div>{" "}
                {/* .itemcenter */}
                <hr className="boder-b-0 my-4" />
                <div className="flex items-center my-4">
                  <div className="w-16">
                    <img
                      className="w-12 h-12 rounded-full"
                      src="./adobe_illustrator.jpg"
                      alt="Illustrator"
                    />
                  </div>
                  <div className="flex-1 pl-2">
                    <div className="text-gray-700 font-semibold">
                      Illustrator
                    </div>
                    <div className="text-gray-600 font-thin">
                      Application I use to create vector media/assets
                    </div>
                  </div>
                  <div className="text-red-400"></div>
                </div>{" "}
                {/* .itemcenter */}
                <hr className="boder-b-0 my-4" />
                <div className="flex items-center my-4">
                  <div className="w-16">
                    <img
                      className="w-12 h-12 rounded-full"
                      src="./adobe_indesign.jpg"
                      alt="Adobe InDesign"
                    />
                  </div>
                  <div className="flex-1 pl-2">
                    <div className="text-gray-700 font-semibold">InDesign</div>
                    <div className="text-gray-600 font-thin">
                      Creating My CV for pdf print is never been easier.
                    </div>
                  </div>
                  <div className="text-red-400"></div>
                </div>{" "}
                {/* .itemcenter */}
                <hr className="boder-b-0 my-4" />
                <div className="flex items-center my-4">
                  <div className="w-16">
                    <img
                      className="w-12 h-12 rounded-full"
                      src="./adobe_indesign.jpg"
                      alt="Adobe InDesign"
                    />
                  </div>
                  <div className="flex-1 pl-2">
                    <div className="text-gray-700 font-semibold">InDesign</div>
                    <div className="text-gray-600 font-thin">
                      Creating My CV for pdf print is never been easier.
                    </div>
                  </div>
                  <div className="text-red-400"></div>
                </div>{" "}
                {/* .itemcenter */}
                <hr className="boder-b-0 my-4" />
                <div className="flex items-center my-4">
                  <div className="w-16">
                    <img
                      className="w-12 h-12 rounded-full"
                      src="./adobe_premiere_pro.jpg"
                      alt="PremierePro"
                    />
                  </div>
                  <div className="flex-1 pl-2">
                    <div className="text-gray-700 font-semibold">
                      Premiere Pro
                    </div>
                    <div className="text-gray-600 font-thin">
                      I used to upload video a lot with my gaming, Premiere Pro
                      is the tool I used often editing videos if I want to
                      scale, speed, blur out things, screen fps support, etc...
                    </div>
                  </div>
                  <div className="text-red-400"></div>
                </div>{" "}
                {/* .itemcenter */}
                <hr className="boder-b-0 my-4" />
                <div className="flex items-center my-4">
                  <div className="w-16">
                    <img
                      className="w-12 h-12 rounded-full"
                      src="./adobe_xd.jpg"
                      alt="XD"
                    />
                  </div>
                  <div className="flex-1 pl-2">
                    <div className="text-gray-700 font-semibold">XD</div>
                    <div className="text-gray-600 font-thin">
                      XD is commonly used now if not for Figma or Sketch,
                      Designer share their idea to convert to mobile App or web
                      screens.
                    </div>
                  </div>
                  <div className="text-red-400"></div>
                </div>{" "}
                {/* .itemcenter */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
