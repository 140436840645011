import React, { Fragment, Component } from "react";
import "./BlueLines.css";

class BlueLines extends Component {
  render() {
    return (
      <Fragment>
        <div id="headerbluelinesajov">
          <svg
            id="header-lines"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 239.93 76.33"
          >
            <g>
              <polygon
                className="header-bluelines-"
                points="143.71 0 163.6 0 239.93 76.33 239.2 76.33 178.95 16.09 158.19 16.09 143.71 0"
              />
              <polygon
                className="header-bluelines-"
                points="223.4 62.44 196.06 35.09 97.64 35.09 97.64 35.83 164.18 35.83 190.72 62.37 223.4 62.44"
              />
              <polygon
                className="header-bluelines-"
                points="170.09 43.64 163.29 36.84 18.94 36.84 18.94 37.5 92.43 37.5 92.43 43.64 170.09 43.64"
              />
              <g>
                <g>
                  <rect
                    className="header-bluelines-"
                    x="127.46"
                    y="13.4"
                    width="1.1"
                    height="20.18"
                  />
                  <rect
                    className="header-bluelines-"
                    x="130.42"
                    y="13.4"
                    width="1.1"
                    height="20.18"
                  />
                  <rect
                    className="header-bluelines-"
                    x="133.38"
                    y="13.4"
                    width="1.1"
                    height="20.18"
                  />
                </g>
                <g>
                  <rect
                    className="header-bluelines-"
                    x="118.68"
                    y="13.4"
                    width="1.1"
                    height="20.18"
                  />
                  <rect
                    className="header-bluelines-"
                    x="121.64"
                    y="13.4"
                    width="1.1"
                    height="20.18"
                  />
                  <rect
                    className="header-bluelines-"
                    x="124.6"
                    y="13.4"
                    width="1.1"
                    height="20.18"
                  />
                </g>
              </g>
              <rect
                className="header-bluelines-"
                x="14.26"
                y="35.82"
                width="2.41"
                height="2.41"
              />
              <rect
                className="header-bluelines-"
                x="9.51"
                y="35.82"
                width="2.41"
                height="2.41"
              />
              <rect
                className="header-bluelines-"
                x="4.75"
                y="35.82"
                width="2.41"
                height="2.41"
              />
              <rect
                className="header-bluelines-"
                y="35.82"
                width="2.41"
                height="2.41"
              />
            </g>
          </svg>
        </div>
      </Fragment>
    );
  }
}
export default BlueLines;
