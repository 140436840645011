import React, { Fragment, Component } from "react";
import "./FlockBirds.css";
import anime from "animejs";
class FlockBirds extends Component {
  componentDidMount() {
    var topBirdTimeline = anime({
      targets: "#flockfooterbirds",
      translateX: 770,
      endDelay: 5000, //anime.stagger(5500, { start: 3500, direction: "reverse" }),
      easing: "easeInOutQuad",
      direction: "reverse",
    });
  }
  render() {
    return (
      <Fragment>
        <div id="footerflockofbirds">
          <svg
            id="flockfooterbirds"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 491.52 227.49"
          >
            <g>
              <path
                className="footflockanim2 footer-flock-of-birds-cls-1"
                d="M799.79,809.21a16.32,16.32,0,0,1-4-2.28,4.71,4.71,0,0,0-2.73-1.4c-1.28-.09-2.58.2-3.87.19a24.9,24.9,0,0,0-4.15,0c-.62.1-1.94.33-1.61,1.2.14.36.81.66,1.1.87a7.52,7.52,0,0,1,1.56,1.45c1,1.32,1.16,2.86,1.76,4.35.44,1.08.91.51,1.46-.23.43.76,2.38,6.32,3.19,6s.48-2.51.46-3.06a17.11,17.11,0,0,0-.46-3.68c-.4-1.55-1-3.09,1.27-3.41a6.16,6.16,0,0,1,2.85.73,5.64,5.64,0,0,0,3.62-.29c-1.39-.45-2.94-.87-3.85-2.12"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim2  footer-flock-of-birds-cls-1"
                d="M829,839.79c-1-.44-2.84-1.37-3.85-.27-.46.5-.15.86.1,1.41a7.33,7.33,0,0,1,.69,2.81c-.54,0-.8-.57-1.39-.64a5.84,5.84,0,0,0-1.59,0c-.54.19-.83.55-1.36.7a.4.4,0,0,0-.6.16,1.11,1.11,0,0,1-.54.52,3.76,3.76,0,0,0,1.4.6c.72,0,.91-.42,1.6-.09.48.22.79.66,1.34.79a17.73,17.73,0,0,0,1.77.35,12.22,12.22,0,0,0,3.45-.33,13.57,13.57,0,0,1,2.93,0c-.83-.23-1.6-.73-2.41-1s-1.18-.38-1.43-1.1a4.32,4.32,0,0,1,0-2.32c.27-1,0-1.16-.78-2"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M795.09,872.72c-.24-1.31-.93-3.2-.5-4.53.22-.68,1.62-1.43,1.63-2.06s-1.53.3-1.86.5c-.67.4-1.5.73-2.12,1.15-1.8,1.22.93,3.53,0,4.8-.49.64-2.1.28-2.8.46-1,.26-1.36,1-1.6,2a18.86,18.86,0,0,0,3.06,0,4.83,4.83,0,0,1,1.3.09,9.78,9.78,0,0,0,1.72.61,10.47,10.47,0,0,0,2.38,0c.55-.11,1.84-.38,2.07-.93-.56-.83-1.43-.69-2.25-1-1.07-.47-.16-1.24.12-2.11a2.89,2.89,0,0,1,1.22-2,12,12,0,0,1,2.27-1.41,2.16,2.16,0,0,0-2.86,0,3.39,3.39,0,0,0-.8.79c-.35.39-1.18.71-.94,1.34"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M833.06,886.5c-.56,0-1.87-.31-2.3.18s.1,1.59.35,2c.68,1.21,1.9,2.16,2.38,3.49.74-.19.18-3.36-.08-3.91a2.66,2.66,0,0,0,1.37.13c.46-.09.89-.45,1.34-.54A2.25,2.25,0,0,1,838.3,889a6.59,6.59,0,0,0,.62.88,14.21,14.21,0,0,1,1.19.84c.4.43.64,1.34,1.28,1.51.31-1.12-.32-1.74-.84-2.66s-.56-1.3.55-1.42c0-.63-.57-.49-1-.73s-.62-.6-1-.85a4.66,4.66,0,0,0-2.41-.75,12.55,12.55,0,0,0-2.19.5c-1.09.16-2.06-.53-3.15.21"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M832.39,894.23c-1.06,0-2-.44-2.87,0-.38.19-1.4.49-1.47.91,1.26.64,2,1.14.68,2.1s-1.34,1.82-1.29,3.42c0,.7,0,4.24,1.38,3,.93-.85,1.1-2.76,1.29-3.94,1,1.37,2.09,1.67,2.3-.35.18-1.74-.19-2.8,1.82-3.1.37-.06,1.35,0,1.46-.56s-.77-.87-1.12-1c-1.41-.53-3.06-.43-4.52-.8"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M827,798.12c-1.28.45-2.38.11-3.65.33-.48.08-.9.27-1.38.34a1.55,1.55,0,0,0-1.26.68c.9.35,2.18.08,2.92.75s.36,1.84.59,2.68a24.61,24.61,0,0,0,.81,3.2c.3.71,1.08,1.91,1.9,2.07a9.72,9.72,0,0,0,.36-2.93c.85,1.38,1.66,2.77,2.5,4.14.31.51,1,2,1.76,2,1.6,0,.63-5.2.51-6.1-.24-1.72,0-3.39,2.1-3.64a8.71,8.71,0,0,1,2.76.42,10.78,10.78,0,0,0,3.12,0,10.16,10.16,0,0,1,.26-1.28c-1.17-.34-2,.11-3.08-.7a13.32,13.32,0,0,0-3.39-1.88,33.11,33.11,0,0,0-3.38-1.33,6,6,0,0,0-4.47.86"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M840.45,779.64a12,12,0,0,1-3.78-1.21c-.9-.52-1.55-1.31-2.4-1.89a9.66,9.66,0,0,0-2.79-1.23c-1.26-.32-1.38.67-.8,1.59-1,0-1.92-1.17-3-.47-.17.11-.24.38-.38.5-.3.26-.41.22-.78.36a2.67,2.67,0,0,1-1.33.33,4.48,4.48,0,0,1,2.55.58c.75.42.93,1,1.48,1.58.25.27.64.42.86.69s.28.71.49,1,1.21,1.9,1.8,1.85a7.06,7.06,0,0,0,0-2.36c-.05-.4-.46-.92-.1-1.23.6.86,1.22,1.78,1.79,2.66.33.52.67,2.5,1.63,2.25a3.48,3.48,0,0,0-.42-2.56c-.39-.78-1.11-2.16.43-1.94.87.12,1.39,1.08,2.26.81s1.72-1.12,2.77-1.38l0-.27"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M829,767.88q-1.21-.69-2.43-1.35c-.27-.14-.72-.29-.87-.58a7.07,7.07,0,0,1-.1-2.51c.21-1.12,1.19-.42,2-.29s1.4-.28.49-.92a4.42,4.42,0,0,0-2.37-.47c-.91-.09-1.74-.58-2.65-.66-2.28-.21-.84,3-1.2,4.31-.49-.12-.94-.48-1.46-.64a4,4,0,0,0-1.48-.25c-.81,0-2.37-.34-3,.34a15,15,0,0,1,1.55.67c.21.08.46.05.68.14s.42.35.67.45c1.06.45,2.25.82,3.35,1.16a9.67,9.67,0,0,0,3.22.6H829"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M864.31,807.87a9.21,9.21,0,0,0-1.51-2.45c-.46-.49-1-.85-1.17-1.54-.21-.89.46-3.64,1.74-2.86.46.29.68,1.17,1,1.59a6.93,6.93,0,0,0,1.48,1.21,9.13,9.13,0,0,1,2.73,3.64c.19-.84.14-1.21,1-1.56.32-.13.72-.25,1-.36-.3.74-2,2.77-1.22,3.54.29.29,1.08.07,1.47.18s.9.55,1.48.43c1.14,1.23-2.43.65-2.7.61-.69-.12-1.27.24-2,.24a15.92,15.92,0,0,0-1.94.36,3.12,3.12,0,0,1-2-.66c-.58-.31-1.21-.6-1.8-.9a6.19,6.19,0,0,1-.67-.41,2.61,2.61,0,0,0-.79-.39c-.52-.2-1-.61-1.57-.15a3.26,3.26,0,0,1,2.25-.87c.57,0,3.14.76,3.19,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M872.38,841.47a4.54,4.54,0,0,0-.08-2.88,6.33,6.33,0,0,1-.42-1.78c.09-.58.83-1,1.18-1.49s.78-1.43,1.61-1.31c0-.42.21-.77.63-.58s.37.83.46,1.15a2.6,2.6,0,0,1,.09,1.53c-.1.48-.29.81.13,1.23.25-.75,2-2.12,2.1-.6,0,.9-1.43,1.28-1.68,2.07-.2.62-.14,2.1.42,2.52.35.26.9.1,1.29.26A2.06,2.06,0,0,1,879.2,843c.17,0,.26-.07.28-.25-.35.7-2.1.38-2.76.37a13.92,13.92,0,0,1-1.58,0c-.63-.08-.65-.56-1.2-.75s-.94,0-1.38-.14a11.36,11.36,0,0,1-1.37-.3c-.69-.29-1.21-1.09-2.07-.84.72-.38,1-.79,1.93-.69a8.26,8.26,0,0,0,2,.33"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M882.79,870.37a8,8,0,0,1-2.8-1.95c-.22-.22-.27-.53-.46-.75s-.5-.33-.87-.54c-.77.82.32,2.44.74,3.17-.77.29-1.22-.68-1.95-.58.59,2,3.59,2.66,5.44,2.35a2.82,2.82,0,0,1,1.51.1c.28.09,1.24.69,1.53.44,1-.84-2-1.64-2.43-1.63,0-.46,1.08-1.65.32-1.88-.45.28-.33.88-.67,1.25a.61.61,0,0,1-.69-.33"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M872,887.85c.59-1.06.64-1.71,1.66-2.11.83-.32.66-.07,1.34.08s1.21-.09,1.85.2c.32.15.5.43.86.52a5.77,5.77,0,0,0,.86,0c-.71.1-1.23-.37-1.86-.37a3.84,3.84,0,0,0-1.19.46,6.13,6.13,0,0,0-2,1.17c1.14.17,1.32.65,2,1.43-.44.63-.83.14-1.36,0a5.36,5.36,0,0,0-2.2.06,3.31,3.31,0,0,1-1.69.28c-.33-.08-.32-.28-.65-.35s-.6.08-.92-.06c.28-.35.79-.4,1.12-.68-.3-1-1.54-1.19-2.12-1.95.47-.66,2-.43,2.57,0,1,.68,1.57,2.26,2.69.71"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M883.47,895.24c.54-1.28.84-2.73,2.23-3,.37-.08.49.11.81-.2s.12-.81.35-1.08c.52-.62,1.2.06,1.53.71.44.89,0,2-.2,2.87.74.14,1.29-.13,1.84.54.31.38.34.9.73,1.23-.93-.32-1.59-1.2-2.58-1-.57.14-1.56.54-2.17.25-.43-.21-.81-1-1.37-1-.4,0-.65.32-1,.35s-.75-.26-1.18-.34"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M889.18,880.12c-1,0-2.62-.12-3.4.77a5.26,5.26,0,0,0-.81,2.25c-.24.84-.69,1.64-1,2.47a6.18,6.18,0,0,0-.13,2.2,1.55,1.55,0,0,0,1.34-1.1,17,17,0,0,1,1.22-2.58,3.81,3.81,0,0,1,2.45-1.65c.37,0,.72.1,1-.21-.2,1,1,1,1.6.66.38.42.4,1,.91,1.38a11.8,11.8,0,0,0,1.12.5c.36-.79-.31-1.05-.54-1.66a2.69,2.69,0,0,1-.06-1,9.81,9.81,0,0,1,0-2.3c.43,0,.73.46,1.12.15s.18-.81.22-1.13c.85,0,1.44-.85,2.21-1.16a10.79,10.79,0,0,1,2.41-.64c-.13-1.49-3.23-.12-4,.08a6,6,0,0,0-1.81.54c-.56.35-1,1-1.61,1.36-.57-.26-.36-1.42-.24-1.91.09-.33.47-.58.21-1-.07-.1-.49-.14-.62-.24-.21.25-.64.21-1,.23,0,.49,0,.87-.36,1.21s-.63.08-.88.4c-.64.81.11,1.93.42,2.65a4.14,4.14,0,0,1-1.24.05"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M906.32,899.94c-1.39,0-3.43-.48-3.91,1.3,3.16.41,2.17,3.52,1.68,5.7-.27,1.17.25,2.15.54,3.27s.07,2.69,1.11,3.39c.52-.51.27-2.05.23-2.75.45,0,.32-.63.35-1a9.21,9.21,0,0,1,.34-1.22c.23-1.14,0-2.05.5-3.18.79-1.64,3.17-1.85,3.23-3.82.8-.22,1.43,0,2.22-.39-.45-.25-1-.3-1.41-.52a3.24,3.24,0,0,0-1.47-.47,11.53,11.53,0,0,0-1.66-.38,10.94,10.94,0,0,0-1.38.35,12.83,12.83,0,0,1-2.05,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M905.64,861c-.78-.81-1.6-1.89-2.79-2-.31,0-.67.07-1,0s-.74-.29-1.12-.34c-.83-.11-1,.48-1.61.7-.25.09-.72,0-1,.14-.48.15-.82.18-1.11.66.42.63,1.35.19,1.77.67s.3,1.36.39,1.8,0,.91.09,1.38a9.12,9.12,0,0,0,.7,1.6,6.14,6.14,0,0,1,.4-1.92c.58.39.59,1.59,1.27,1.92,1-.53.09-2.87.19-3.75.22-2,2-.83,3.12-.55a.65.65,0,0,0-.63-1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M884.81,815.93a7.66,7.66,0,0,0-3-.33c-.75.06-1.55.59-2.33.32-.57-.2-.79-.85-1.35-1a9.06,9.06,0,0,0-.93-.1c-.39-.07-.56-.39-1,0,.62-.26.7-.5,1.41-.54a12.78,12.78,0,0,1,1.79.3,2.4,2.4,0,0,0,0-1.61c-.2-.46-.73-.81-.83-1.31-.25-1.37,1.8-1.48,2.71-1.42a11.16,11.16,0,0,0,3.43-.12c1.46-.33,2.91-.63,4.41-.82-.37.95-2.21,1.3-3.09,1.62s-1.79.22-2.29,1a3.33,3.33,0,0,0-.29,1c-.1.36-.34.74-.42,1.05s-.3.26,0,.7a1.15,1.15,0,0,0,1.1.32c0,.68.34,1.1,1.06,1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M913.37,840.8a10.4,10.4,0,0,1-3.89-1.41c-.8-.56-1.15-1.25-2.33-1s-2.34,1-3.56.45c-.56-.24-.93-.56-1.59-.44s-.83.52-1.29,1c2-.25,2.83,2,4.72,2.4,1.21.24,2.16-.36,3.23-.77a10,10,0,0,1,3-.3"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M911.69,862.31c-1-.57-1.79-1-2.83-.49-.78.39-.88.74-1.77.89,1.54.48,3,1.12,4.58,1.56,1.33.38,3.72.92,5,.27s-1-1.65-1.6-1.89a10.33,10.33,0,0,0-5.76,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M911,862.64c0-.35.31-2.56,0-3a6.43,6.43,0,0,0-.78-.17,2.46,2.46,0,0,1-.73-.47c-.4-.37-.39-.62-1-.76s-1.18.13-1.75,0a1.81,1.81,0,0,1,1.75-1c.69,0,1.22.48,1.78,0s.5-1.06,1.06-1.31a4.4,4.4,0,0,1,2.17,0c-1,.77-1.4,3,.45,2.09a1.57,1.57,0,0,0,1.37.93c.4,0,1.11-.34,1.38.13s-.38,1.15-.75,1.24c-.63.14-1.88-.66-1.77.44.06.59.85.91,1.19,1.3a14.91,14.91,0,0,1,.93,1.62c.66,1,2.32,1.78,2,3.26-.81.09-1.72-1.47-2.46-1.86a15.2,15.2,0,0,0-2.41-1.34,4.93,4.93,0,0,1-1.77-.7c-.39-.28-.57-.21-.74-.75,0-.31,0-.62.06-.93a10.73,10.73,0,0,0-.37-2.13"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M928.83,857.27c-.63.79-1.4,1.31-1.67,2.19a3.23,3.23,0,0,0-.15,1.76,15.17,15.17,0,0,1,.52,2.45c0,.67-.46,1.24-.33,1.93a4.74,4.74,0,0,0,1.2-2.48c.27-.87.72-1.61,1-2.45.21-.55.15-1.22.77-1.43s.89.29,1.32-.16c.15.82,1.09.34,1.43-.07a3.17,3.17,0,0,0,1.6.29c.63-.14.81-.6,1.22-1a11.14,11.14,0,0,1-2.57,0c-.79-.16-1-.55-1.36-1.13-.51-.76-.75-1.15-1.67-1.23s-.84.38-1.6.64c-.25-.86-.46-1.67-1.59-1.34-.44.13-.69.57-1.17.7a5,5,0,0,0-1.28.31,6.79,6.79,0,0,0,1.63.7c.26,0,.73-.13,1,0,.74.38-.08,1.2,1,1.68"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M940.59,880.12c-1.32-1.14-3.61-2.52-5.27-1.44.65.37,1.81.7,1.91,1.47s-.82.88-1,1.63.11,1.26-.29,2c-.29.56-.7,1.06-1,1.62,1,.36,3.13,0,3.59-1,.26-.59-.06-1.15.5-1.63s1-.23,1.52-.63a7.07,7.07,0,0,0,1.25,2.94c.29.39,1.67,2,2.27,1.26s-3.54-4.46-.19-4.28c-.55-.44-1-.59-1.35-1.28-.25-.53-.24-1.09-.63-1.55a1,1,0,0,0-1.1-.45c-.4.07-.74.54-1.12.63-.7.16-1-.45-1.41-1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M955,889.86c-1,.28-2.23,1.15-3.26,1a9.16,9.16,0,0,1,1.57,0,7.92,7.92,0,0,0,1.53.35c.5,0,1,0,1.51,0a4.58,4.58,0,0,0,1.42-.25c.38-.1.78-.33,1.12-.4s.55.05.82,0,.38-.29.43-.29c.48,0,.7.22,1.22.29a4.12,4.12,0,0,0,1.6-.08,4,4,0,0,1-2.64-1.06,17.73,17.73,0,0,1-1.2-2.44,22.24,22.24,0,0,1-1.57-2.51,29.85,29.85,0,0,1-.7-3.24c-.29-1-.67-.52-1.15.15a11.38,11.38,0,0,0-1.84,3.22,4.19,4.19,0,0,0,.48,2.78,5.83,5.83,0,0,1,.25,3.1l-.27.06"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M954,878.1c-1.6-.37-3.39-1-4.95-.34a5.66,5.66,0,0,0,2.19.69,1.37,1.37,0,0,1,1.3.67c-.45.17-1.23.36-1.2.94a4.36,4.36,0,0,0,1.49,0,2.79,2.79,0,0,1,1.2.3c1.14.35,1.11-.79,2.11-1,.44-.08.87.25,1.18-.16s-.09-1,.12-1.44c.35-.7,1.43-1.79,2.24-1.82-.33-1.27-3.76.64-3.75,1.73-1.19-.79-2.37.2-3.62,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M937.23,847.86a10.55,10.55,0,0,0-2-1.32,5.19,5.19,0,0,0-1.38,0c-.43,0-1,.06-1.37,0-.62-.07-.47-.17-.83-.54a21.32,21.32,0,0,0-2.17-1.73c-.84-.63-1.19-1.19-2.26-1.12a3.73,3.73,0,0,1,2.74.72c.73.54,1.48,1.53,2.36,1.66s1.86.14,2.86.3a3.13,3.13,0,0,1,2,1.69"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M944.63,857.27c-1.59-.36-2.72-.85-2.72,1.14a10.44,10.44,0,0,0,.4,3.13,1.8,1.8,0,0,0,1.3-1.25c.09-.33-.11-.86.28-1,.74-.25,1,1.5,1.07,1.87.18,1.35,1.29,4.16,3,4.13-.36-1.8-1-3.63-.67-5.53a1.56,1.56,0,0,1,1.22-1.15c.87-.19,1-.19,1.19-1.22-.54.68-2.12.06-2.75-.19a6.46,6.46,0,0,0-1.87-.65c-.89,0-1.55.85-2.47.71"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M965.12,854.24a4.09,4.09,0,0,1-1.54-.23.94.94,0,0,0-.18-.39c-.5-.12-.81.17-1.3.29a15.75,15.75,0,0,0-2.51.74c.49.34,1.52.49,1.79,1s-.17,1.28-.28,1.85.28,1,.35,1.65-.22,1,.32,1.38a.77.77,0,0,1,.33-.76c.46,1.88,2.93.37,2.72-1.17-.1-.71-.75-1.59-.26-2.29s1.11-.32,1.71-.22a3.08,3.08,0,0,0,2.23-.4c.66-.4.69-.4,0-.5a13.63,13.63,0,0,1-2.1-.23,9.09,9.09,0,0,0-3-.35"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M973.86,869a10.47,10.47,0,0,0-2-1.92c-.31-.17-.75,0-1-.44-.34-.61.52-1.64.67-2.32.62.15.66,1.31,1.25,1.66.33.2.89.16,1.2.34a5.12,5.12,0,0,1,1.18,1.27c.26.3.43.37.58.76s.15.94.5,1.32a3.54,3.54,0,0,0,1.83.69,14,14,0,0,1-2.1,0,8.65,8.65,0,0,0-2.19-.33,8.76,8.76,0,0,1-2.29.07,1.92,1.92,0,0,1-1.6-1.38,5,5,0,0,0,2.51.32c.69-.21,1-1,1.74-1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M978.23,881.46a7.74,7.74,0,0,0-3.13-1c-.87,0-.87.65-1.21,1.25a13.52,13.52,0,0,1-1.23,2c-.48.55-1,.62-1.13,1.47a4.18,4.18,0,0,0,3-.62c.68-.54.78-1.24,1.71-1.35a2.47,2.47,0,0,0,1-.18,12.61,12.61,0,0,1,1.09-.72c.59-.22,2.15-.16,2.52-.68-.88-1-2.79-.14-3.91-.49l0-.28"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M922.45,813.58a5.26,5.26,0,0,1-1.63-.6c-.16-.11-.12-.32-.32-.44-.49-.29-1.13-.33-1.65-.62-.87-.47-1.92-1.39-2.91-1.4a28.18,28.18,0,0,0-3.23.68,5.1,5.1,0,0,0-2.91,1c.7,0,1.78-.27,1.91.68.09.7-.73,1.51-.89,2.19a7.75,7.75,0,0,0-.36,2.81c.14.84.08,1.8.28,2.61,1.61-1.57,1.79-3.87,3.21-5.63.59-.74,1.32-1.09,1.36.13s-.38,2,.76,2.79a9.17,9.17,0,0,0,.73-2.77c0-.35-.24-.82-.1-1.12.29-.62.83-.27,1.2-.21.63.1,1.22.06,1.87.12.37,0,.38.28.91.28a2.68,2.68,0,0,0,2.09-1.14c-.46-.08-.86-.34-1.32-.41"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M925.47,771.57a23.66,23.66,0,0,0-2.52,0,6.42,6.42,0,0,0-1.61.6,3.17,3.17,0,0,1-1.74.43,7.26,7.26,0,0,0-1.83-.34A6.12,6.12,0,0,1,916,772c-.7-.18-1.32,0-2-.3-.47-.23-.8-.62-1.37-.72s-1.16.33-1.88.29a24.46,24.46,0,0,0,2.67-1.35c.43-.18,2.58-.06,2.74-.35s-1.29-1.52-1.52-1.81c-.59-.75-.91-1.65-1.51-2.4-.46-.58-1.88-1.54-1.54-2.42s2.38-1,3.12-.54.84,1.65,1.5,2.34a9.82,9.82,0,0,1,2.18,2.4c-.23-.6.3-.94.32-1.49s-.37-.85-.26-1.34c.23-1,1.52-1.14,2.24-1.61a3.81,3.81,0,0,1,1.49-.53,10.35,10.35,0,0,0,1.16-.62c.64-.26,2.37-.93,2.94-.53a5.14,5.14,0,0,1,2.36-.52,5.67,5.67,0,0,0,2.12-.29c-.84,1.48-3.33,1.72-4.79,2.34-.82.34-1.83.43-2.6.82s-.72,1.06-.78,1.85a5.08,5.08,0,0,1-.81,2.45c-.41.69-.76,1.33-.23,2.09,1,1.47,3.05.08,3.89,1.84"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M936.85,831.76a7.33,7.33,0,0,1-1.53.32A4.8,4.8,0,0,0,936.85,831.76Zm14.16.3a4.81,4.81,0,0,1-2.3-.4c-.23-.15-.38-.54-.63-.63a3.7,3.7,0,0,0-1.09,0l-1,0a1.79,1.79,0,0,1,.06-.29c.18-.56.65-1,.64-1.62,0-1.07-1.65-1.75-1.56-2.68.11-1.08,2.47-.28,3.18-.21-1-1.15-2.23-1.15-3.63-1.46-1-.21-3.59-1.24-4.42-.19-.68.85,1.2,2,1.16,3.06v0c-.59-.38-1.32-.58-1.46.12-.08.4.74.74,1.13,1.09a3.13,3.13,0,0,0-.24,1,2.79,2.79,0,0,0,.1.85,1.14,1.14,0,0,1-.66-.12c-.2-.12-.21-.4-.45-.53a1.24,1.24,0,0,0-1.09.13c-.62.3-.92,1-1.5,1.38a2.56,2.56,0,0,1-.37.16,1.79,1.79,0,0,1,1.74.28c.41.36.47.74,1,1a7.54,7.54,0,0,0,1.56.67,9.3,9.3,0,0,0,3.23,0c.21-.05.29-.25.48-.31s.55,0,.82-.09,1.22-.19,1.43-.54c1.25.78,3.06-.17,4.28-.73Z"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M949.33,820.3A14.54,14.54,0,0,0,945,820a7.93,7.93,0,0,0-1.54,0,6.08,6.08,0,0,1-1.7.31c-1,0-2.19.11-3-.65-.2-.19-.09-.53-.42-.7s-.55,0-.75,0c-.54,0-1.08,0-1.62,0a3.79,3.79,0,0,1,2.47-1.05c.64,0,.83,0,1.17.46s.36,1,1.11.63c1.24-.6.2-2.26-.08-3.05-.2-.58-.24-1.19.5-1,.57.16,1,1.21,1.53,1.58.09-.08.4-.16.49-.25.2.48.52.44.81.75s.37.55.63.81a4.93,4.93,0,0,0,1.3,1.1c.84.27,3.19-.68,3.4.66"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M961.76,821.65c-1.47.08-3.59.58-5,0-1.18-.49-2.07-1.76-3.16-2.45-.68-.43-2.2-1.41-2.55-.22-.26.89.22,1.37-.8,1.89s-1.77.55-2,1.74a31.22,31.22,0,0,0,.06,3.54c0,.56-.35,2.25.36,2.58s1.23-1.41,1.38-1.93c.81,1.65,1.79-1,1.91-1.74.21-1.21-.28-2,1-2.67a8.32,8.32,0,0,1,3.28-.58c.6,0,1.15.23,1.74.23a8.21,8.21,0,0,0,2-.46l.06-.27"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M972.52,807.53a8.52,8.52,0,0,0-2.37-1.32c-.45-.14-.92-.21-1.37-.37-.7-.24-1.49-.92-2.26-.62-.07.51-.31,1.59-.87,1.85-.93.44-1.06-.77-1.61-1.13s-1.17,0-1.65.18a15.45,15.45,0,0,1-2,.46,1,1,0,0,0,1.58.74,5.52,5.52,0,0,0,2.41,2,4.61,4.61,0,0,0,2.86-1.07c.92-.8.91-.69,1.95-.22.64.29,2.27.55,2.58-.35a9.28,9.28,0,0,1-1.66-1.1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M976.21,822.65c-.5-.24-1.73-.63-1.88-1.29-.1-.43.3-.64.36-1a4.86,4.86,0,0,0-.29-1.93c-.42-1-2.31-1.56-2.22-2.73a13.62,13.62,0,0,1,.94-2.31,4.44,4.44,0,0,1,1.06-1.66c.56-.65,1.08-1.33,1.67-1.95s1.26-1,1.37-1.79c-.09,1.65.12,3.29,0,4.93s-.26,2.37.81,3.6.78,3.11,1.82,4.22,2.85.2,4-.12c-.63.85-1.08.81-1.94,1.17-.56.24-1,.85-.57,1.47s1.31.22,1.88.38a4.48,4.48,0,0,1,1.63,1.07c-.55,0-1.9-.28-1.87.57.42.2,1.67,1.16.65,1.43-.61.16-1.6-.85-2.21-1a7.86,7.86,0,0,0-2.37,0c-.78.11-1.23.48-2.05.31s-1.61-.16-2.45-.27a4.81,4.81,0,0,1-1.56-.47c-.52-.32-.72-1-1.26-1.31a2.67,2.67,0,0,0-1.76-.23c.28-.1.34-.38.55-.5s.43-.1.69-.2a11.24,11.24,0,0,1,1.32-.58c1.18-.31,2,.54,3-.09"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M995.7,830a8.58,8.58,0,0,0-2-.7c-.38,0-.64.09-1-.08a4.19,4.19,0,0,1-1.31-1.47,7.2,7.2,0,0,1-.68-1.64c-.08-.38,0-.78-.09-1.16s-.53-.83-.7-1.3c-.73.28-1.41,1.33-2.24,1.37,0,.5.56.8.69,1.27a6,6,0,0,1,0,1c0,.59.16,1.46-.6,1.62-.06-.88-.22-1.63-1.21-1.73-.41,0-1.85.22-2,.77s.83,1.06,1,1.39c.36.58.42,1.94.93,2.35,1,.82,2.67-2.09,4-2,.72.05,1.3.62,2,.67a5,5,0,0,0,2.85-1.09"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M988,833.41c-.71-.73-2.66-2.54-3.68-2.19s-2.39,1.21-3.64,1.19a36.2,36.2,0,0,1,4.65.33c1.22.1,2.07,1.7,3,.33"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M997,852.9c.62-1.23,1.25-2.44.75-3.82-.32-.88-1.38-1.64.29-1.93a14.51,14.51,0,0,0,1.79-.15c.57-.17,1-.54,1.6-.42a4,4,0,0,1,2.52,1.89c-.88.4-1.77-.49-2.58,0-1,.55,0,1.42,0,2.15,0,1.24-.65,1.22.66,1.7.77.28,1.39,1.3,2.28.94a26,26,0,0,0-2.75-.18,19.82,19.82,0,0,1-3,.52c-.94,0-2,.2-2.77-.42-.28-.22-.4-.59-.79-.6a6.4,6.4,0,0,0-1.21.56,8.27,8.27,0,0,1,1.56-1.29,4.54,4.54,0,0,1,2.37-.26"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M992.34,805.52a10.87,10.87,0,0,0-2-2.07c-.28-.2-.33,0-.56-.35s-.08-.89-.08-1.25a3.89,3.89,0,0,1,.57-2.34c.47-.81.6-1.19-.16-1.92-.59.37-.61,1.26-1.3,1.37-.31,0-.61-.3-.93-.18s-.4.7-.61.95c-.41.49-1.08.75-1.28,1.41a13.71,13.71,0,0,0,0,2.26c-.78-.81-1.54-.58-2.46-.2s-1.1.69-2.1.62a6.68,6.68,0,0,0,3.09.69c.58,0,.7.24,1.17.35a9.57,9.57,0,0,0,1.53,0,9,9,0,0,1,2.41.76c.47.17.56.38,1.15.16s.72-.73,1.29-.57"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1000.41,814.25a19.44,19.44,0,0,0,1-3.36,5.93,5.93,0,0,0-.37-2.13c-.35-1.43-1.68-2.55-1.29-4.1s1.64-3,2.49-4.36a18.93,18.93,0,0,0,2.61-4.84c.32.06.57,1.58.6,2,0,.68-.6,1.32-.52,2,0,.43.36.39.47.69s0,.66-.19,1.08c-.21.6-1,1.69.18,1.67a16,16,0,0,0,.21,5c.38,1.83.94,3.41.6,5.28a3.33,3.33,0,0,0,2.37,3.65,6.44,6.44,0,0,1,2.18.87,7.36,7.36,0,0,0,1.64,1.31c-2.38-1.46-4.29-2-7-1.2a8.34,8.34,0,0,1-6-.27c-1-.39-2-.74-3-1.06-.37-.12-1.68-.36-1.75-.88-.19-1.43,6-1.88,6.82-2"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1016.88,824.33c-.64-.35-1.19-.77-1.85-1.05s-.7-.12-1.31.06a4.07,4.07,0,0,0-1.32.58,1.93,1.93,0,0,1-1.48.41,6.69,6.69,0,0,1,4.11.84,5.53,5.53,0,0,0,1.59.77c.32.09.6,0,.92.14s.47.4.76.5c1.14.42,2.5,0,3.62-.26a4.35,4.35,0,0,1,3.63,1.24c.07-1.06.79-.48,1.26-1s0-1.55-.45-1.91-1.2-.29-1.77-.45a21.18,21.18,0,0,1-2.3-1,6.62,6.62,0,0,1,0-1.45c0-.5-.22-.63-.32-1a13.69,13.69,0,0,1,0-3,13.39,13.39,0,0,1,0-2.85c.09-.36.25-.67.34-1s.46-.93.28-1.25c-1.41.49-1.55,2.19-2.59,3.06-.53.45-1.15.24-1.68.84a14.81,14.81,0,0,0-1.18,1.65c-.69,1.1.42,2.16.49,3.28a4.06,4.06,0,0,1-.1,1.8c-.37.57-1,.28-1.6.41"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1021.24,811.23c-1.65-.89-2.78-1.68-4.67-1.22-.55.13-1.24.44-1.76.5a6.37,6.37,0,0,1-1.81-.3,6.14,6.14,0,0,1-2.49-1.16,2,2,0,0,0-2.67,0,4.14,4.14,0,0,1,2.94-1.38c.73,0,2.29.35,2.75-.42s-.29-2.42-.31-3.26a2.44,2.44,0,0,1,1.36-2.48c1.12-.61,2.26-1.08,3.34-1.79.74-.49,2.47-2.15,3.46-1.95,1.82.38-3.16,4.87-3.73,5.32a4.55,4.55,0,0,1,.86.1c.22.92-.19,1.31-.57,2.07-.18.36-.87,1.67-.7,2,.1.23.64.28.83.38a3.67,3.67,0,0,1,1,.8,3.64,3.64,0,0,0,2.46,1.44c0,.55-.5.73-1,.62"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1018.89,836.43c-1.33-.11-1.79-1.06-3-1.52a4.33,4.33,0,0,0-2.63.16c-.56.11-.7-.11-1.16-.22a4.47,4.47,0,0,0-1.25-.09,19.73,19.73,0,0,0-3,0,2.71,2.71,0,0,0,1.67,1.1,2.47,2.47,0,0,1,1.41,1.2c.42.61.93,1.17,1.32,1.8s.75,2,1.55,2.09,1.25-.8,1.22-1.46-.61-1.14-.49-1.76.52-.63,1.1-.57a4.1,4.1,0,0,0,2.55-.5,2.92,2.92,0,0,0-.6-.62"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1021.24,830.38a3.88,3.88,0,0,0-2.13-.41c-.48.11-.89.38-1.4.45a4.76,4.76,0,0,1-2.47-.33c-.41-.16-.82-.49-1.26-.33s-1,1.32-1.31,1.73a14.77,14.77,0,0,0-1.59,2.89c-1.95-.3,1.15-4.33,1.63-5-.41,0-1.07.2-1.2-.23a15.39,15.39,0,0,1,2-.52c.63-.1.71.05,1.3-.2s.79-.76,1.22-1c.27-.17.56-.12.83-.35s.25-.6.6-.65a2.86,2.86,0,0,1-.12,1.82c-.27,1,.29,1,1.18.78s.75-.07,1.38.4c.36.27.68,0,.61.64,0,.39-.57.66-.91.67l-.06-.3"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1025.61,840.46a8.5,8.5,0,0,0-.41-1.35,4.25,4.25,0,0,1-.88,0c-.07-.5-.34-1.69-1-1.67s-.33,1.21-.46,1.69c-.31-.26-1.43-.59-1.58,0s.59.47.83.75.2,1,.17,1.47c0,.9-.07,1.24.67,1.91.6-.23.39-1.8.61-2.29a2.29,2.29,0,0,1,2.06-.82"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1021.58,870c-1.39-.43-3.17-1.53-4.68-.93.21.47.76.36,1,.89s-.31,1.43.2,1.74,1-.32,1.2-.64c.57-.74.62-.83,1.57-.4a3.68,3.68,0,0,0,.4-1.33"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1030,865.67c-.75-.57-1.33-1.17-2.06-1.72a2.3,2.3,0,0,0-1.83-.64c-1.13,0-2.28-.66-3.47-.27.51.27,1.5.72,1.56,1.4s-.7,1.06-1,1.57a3.94,3.94,0,0,0-.18,2.42c.08.72,0,2.06.59,2.61,1.33,1.29,1.58-2.26,2-2.94a13.29,13.29,0,0,1,1.52-1.91c.79-.77,1.54-.57,2.5-.19"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1036.37,880.12c0-1,.19-3-1.05-3.56-.61-.25-1.33.11-2,.11-1,0-1,.17-1.53,1.11a9.52,9.52,0,0,0-1.13,1.93c1.47.62,1.79-1.2,2.66-1.81a4.69,4.69,0,0,1,1,1.57c.23.53-.06.68.67.68a2.72,2.72,0,0,0,1.37-.7"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1046.78,870.71a16.61,16.61,0,0,0-1.95-4.89c-.66-1.09-2-3.07-3.12-1.12a1.29,1.29,0,0,0,.22,1.81c.53.31,1.23,0,1.76.32s.76,1.3,1.12,1.9a3.8,3.8,0,0,0,2,1.64"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1038,857.6a4.19,4.19,0,0,0-2.1-1.59,3.46,3.46,0,0,1-1.6-.94c-.18-.23-.22-.54-.42-.76s-.58-.44-.84-.72c-.43-.48-.6-1.16-1-1.61a2.24,2.24,0,0,0,.67,2.24c.3.35.79.57,1.06.91s.23.57.44.81c-.37-.07-1.36-.27-1.54.22-.28.72.89.82,1.3.8,1.26,0,2.41.31,3.73.3"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1040.4,846.85a7.25,7.25,0,0,0-1.51-1.87c-.29-.29-.76-.91-1.09-1-.27.83-2.63.31-1.85,1.3.22.28,3,.2,1.88,1a1.47,1.47,0,0,1,1.41.44c.32.26.69.86,1.09.84l.06-.3"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1048.8,844.83c-.26-.43-.57-.95-1.09-1.06-.26-.06-.52.15-.78.1-.58-.12-.83-.63-1.44-.74a7.48,7.48,0,0,0-1.4,0c-.73,0-.94.3-1.61.55-.33-1.33-2,.07-2.32.81a1.94,1.94,0,0,1,1.79.91c-.19.27-.48.4-.6.71-.19.52.29,1.08.37,1.54.13.88-.21,1.91.07,2.76.87-.72.91-1.71,1.37-2.63.28-.56.6-.36.94-.74s.1-.76.32-1.25c.68-1.54,4.18.35,4.37-1.33"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1020.91,765.19c-.93-1.6-1.68-4.14-3.64-4.66-1.34-.36-3.33-1.3-4.61-.54-.8.47-1.43,1.19-2.25,1.64a10.1,10.1,0,0,0-1.93,1c-1.37,1.19-1.86,3.45-3,4.89,1.58-.07,2.84-1.34,3.91-2.44.59-.61.91-1.32,1.71-1.7a20.67,20.67,0,0,0,2.27-.72,3.35,3.35,0,0,1,3.88-.14,13.47,13.47,0,0,1,4,3"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1034.35,773.93c-1.28-1.13-1.22-1.16-2.5-.66a6.09,6.09,0,0,0-1.86,1c.16,1.1,2.41.66,3.15.68.52,0,.65.23,1.06.32.57.13.87-.08,1.42.29s.72.91,1.16,1.31a11.73,11.73,0,0,0,1.62.87,5,5,0,0,0,1.68.86c.46.11,1.11.46,1.57.35-.34-1-2.26-1.64-1.88-2.89,1.35,1.2,3.59.33,5-.43a5,5,0,0,1,5,.55c0-.64-.86-.87-1.24-1.26s-.25-.48-.72-.74c-.27-.15-.59-.15-.86-.28-.5-.24-.73-.59-1.35-.66s-1.22.18-1.74-.05c.78-.42,1.75-.14.92-1.24a3.79,3.79,0,0,0-2-1.25,14.14,14.14,0,0,0-4.39-.41,6.81,6.81,0,0,0,.51,1.58c-2,.74-4.35-1.06-6.26.05"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1056.19,791.07a3.05,3.05,0,0,0-1.63-.29s-.25.27-.38.3c-.58.14-1.27-.11-1.87,0-1.24.25-2,.54-3.07-.38.76,0,1.87.13,2.51-.42,1-.82-.64-1.18-.54-2.06.14-1.14,1.67-.23,2.3-.32.16,0,.28-.22.45-.25s.49.09.73.07a2.63,2.63,0,0,0,1.09-.33.48.48,0,0,1,0,.57c-.46.08-.88.22-1.33.31-.15,0-.87,0-1,.08-.48.28-.48,1-.33,1.63a3.27,3.27,0,0,1,1.25.39c.3.15.15.54.73.34"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1045.1,801.48a8.54,8.54,0,0,1-1.8.34,7.14,7.14,0,0,0-1.19,0c-.58.13-1.14.44-1.75.57a4.83,4.83,0,0,1-2.14,0,11,11,0,0,0-2.18-.19c-.61,0-1.59.22-2.11-.1s-.54-1.12-.87-1.57a2.37,2.37,0,0,0-1.77-.67,13.93,13.93,0,0,0-2.22.36,4,4,0,0,1,1.76-.7c.52-.17.65-.45,1.12-.74a2.48,2.48,0,0,1,3.09.63c-1-2.35.21-3.89,2.11-5.16a9.72,9.72,0,0,0,2.58-3c.39-.59,1.25-2,1.38-.53.11,1.27-.52,2.64-.78,3.87a20.32,20.32,0,0,0-.28,3.79c0,.85-.22,2,.63,2.52a28,28,0,0,0,3.4,1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1044.77,798.12a4.34,4.34,0,0,1-3.53.14,9,9,0,0,0-2.69-1.17,22.07,22.07,0,0,0-2.85,0,5.15,5.15,0,0,1-1.4-.26c-.63-.14-.69,0-1.23.22-.31-.57.68-1.37,1.1-1.67s1.06-.15,1.21-.64-.59-.83-.33-1.51.75-.44,1.26-.29a1.65,1.65,0,0,0,1.05.12c.35-.13.42-.47.73-.62s.58,0,.89,0a5.4,5.4,0,0,0,1.38-.59c.27-.12.48-.11.71-.28s.42-.67.72-.91.65-.26.87-.55.05-.51.37-.72c.17,1,.65,2.24.26,3.22a6.55,6.55,0,0,1-1.49,2.24c.35.42.84.44,1,1.1a1.43,1.43,0,0,1-.3,1.09c.87.06,1.23.85,2,1.08"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1041.41,808.54c.5-.34,1.9-1.79,2.62-1a3.28,3.28,0,0,1,.62-2.18c.46-.6.89-1.21,1.41-1.77s1.2-1.48,2.18-1.07a2.47,2.47,0,0,1,1.19,1.61c-.52.29-.75-.41-1.3-.33s-.71.69-1.06,1-.9.56-1.15,1c-.12.24-.07.46-.15.68s-.28.33-.32.51c-.13.49-.68,2.2-1.65,1.44-.34-.27-.09-1-.94-.56-.65.33-.78,1.67-.79,2.29"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1043.42,811.23a2.75,2.75,0,0,1,.39-2c.33-.41.92-.85,1.38-.47a2.5,2.5,0,0,1,3.45-.66,7.67,7.67,0,0,1,1.25,1.15,1.68,1.68,0,0,0,1.49.3c-.92.72-1.73,0-2.61-.42s-1.23-.11-1.92.43c-.27.21-.3.53-.74.6s-.63-.24-1-.28c-.75-.08-1.52,1.15-2,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1054.51,806.19a3.35,3.35,0,0,1-1.42.73l-.44-.08c-.29.08-.51.1-.71.38,1-.08.9.3,1.4,1a13.18,13.18,0,0,0,1.17,1.34c.8.82,3.44,1,4.58.61-.21-.91-2.38-1.09-1.85-2.19a9.77,9.77,0,0,0,4.25,2.57c1,.2,3.63.75,3.74-.95a13.3,13.3,0,0,1-7.83-2.75c-2.5-1.91-3.89-4.59-5.89-6.94-.44-.52-1.69-2.09-2.54-1.49s.12,2.37.48,3c1.37,2.25,3.77,3.61,5.6,5.44l-.2.06"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1052.83,821a3,3,0,0,0-.06-1.34,3.44,3.44,0,0,1-.55-.06,5.93,5.93,0,0,1-1-3.2c0-.89.58-3,1.56-3.39s.88,1.8,1.24,2.48.88,1.06,1.26,1.65a4.93,4.93,0,0,1,.65,2.17c.93-.22-.32-2.47.1-3,.13-.17.66-.31.83-.46a5,5,0,0,0,.7-1c.33-.48.71-.76.66-1.42,0,1.22.52,2.38.16,3.58-.25.86-.64,1.69-.91,2.55-.72,2.31,1.58,2,3.08,2.78a7.74,7.74,0,0,1-3.73.07,4.44,4.44,0,0,0-1.58-.34,5.73,5.73,0,0,1-1.57.2,5,5,0,0,1-2.35-1.2c-.48-.4-2-.69-2.38,0a2.07,2.07,0,0,1,1.55-1.06,4.83,4.83,0,0,1,2.29-.27l.06.28"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1046.11,820.3a9.54,9.54,0,0,1-1.71,0c-.34-.07-.63-.36-1-.37-.74,0-1.16.59-2,.29.24-.72.68-.69.07-1.36-.36-.39-1-.46-1.27-.93.69.13,1.22-.34,1.87-.26a8.29,8.29,0,0,1,1.15.27c.79.32,1.27,1.68,2.18,1.68"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1028.3,813.92a3.45,3.45,0,0,1,.33,1.55c0,.29-.3.48-.34.79-.06.46.14,1.22.61,1.32.06-.41.24-.86.67-.94a3.32,3.32,0,0,0,.78,1.73c.25.25,1.11.88,1.49.84,1.08-.13-.13-2.25-.46-2.86.42-.62,2.13-.62,1.94-1.71-.45,0-.92-.29-1.32-.32s-.62.21-1,.3a4.31,4.31,0,0,1-2.29-.22l-.06.2"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1029,820c-.9-.12-3.31-.3-2,1.12.29.32.61.45.81.86a6,6,0,0,1,.27,1.51c.11.55,1,2.06,1.77,1.42.6-.48-.42-1.82-.72-2.28.48.32,1.23,1.22,1.78.48-.53-.29-.62-.92-.87-1.37.89-.38,2.94.7,3-1a4.93,4.93,0,0,0-1.45,0,2.07,2.07,0,0,1-1.4-.35,2.81,2.81,0,0,0-1.72-.33,9.92,9.92,0,0,1-2.11.34"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1056.7,838.11a5.18,5.18,0,0,1-3.36-1,2.3,2.3,0,0,0-1.12-.52,3.64,3.64,0,0,0-1,.24,1.72,1.72,0,0,1-1.09,0c-.28-.11-.56-.46-.84-.52-.57-.12-.21,1.14-.14,1.48a2.88,2.88,0,0,0,1.34,2.16c.75.35,1.06-.13,1.32-.76.38-.93.93-1,1.87-.8a3.68,3.68,0,0,0,2.9-.25"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1060.22,835.76a16.34,16.34,0,0,1-3.38-.12,2.53,2.53,0,0,1-.87-.2c-.15-.09-.33-.28-.47-.39a2.29,2.29,0,0,0-.58-.43c-.21-.07-.47.06-.58-.17a4,4,0,0,1,1.11,0c.16,0,.55.25.69.15.36-.25-.15-1.21-.24-1.47a1.77,1.77,0,0,1,.39-1.67,5.06,5.06,0,0,1,.8-.76c.19-.18.31-.58.59-.6a2.48,2.48,0,0,0,0,1.72c.23.61.53.53,1,.25a4.66,4.66,0,0,1,1.7-.6,2.77,2.77,0,0,0,1.07-.31c0,.6,0,.95-.49,1.34-.25.18-.65.24-.86.41s-.19.23-.18.54a4.38,4.38,0,0,1,0,.66c0,.25-.2.45-.24.69a.81.81,0,0,0,.86.83c-.42.14-1.09.36-1.47,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1066.27,830.21a9.56,9.56,0,0,0-1.84-.24c-.4,0-.79.14-1.19.15s-1.14-.09-1.27-.41.39-.3.64-.37c.48-.14.47-.3.16-.68s-.88-.73-1.09-1.28a6.84,6.84,0,0,1,0-2.84c.11.43.67.62.93,1s.25.57.47.81.39.37.58.56.68.49.85.82a3,3,0,0,1,.24.83c.15,0,.24-.12.38-.14s.38.08.6.12.67,0,1,.05c0,.17,0,.51-.2.62s-.34,0-.53.07c-.58.26.05.56.4.67.08.32-.48.33-.67.3"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1070,823.66c0-.2.17-1.07-.31-.83,0,1.05-.24,2.67.47,3.56.42.53,1,.41,1.6.68s1.67,2.7,2.14,1.48-1-3.49.2-4.45a4.28,4.28,0,0,1,2-.46,4.83,4.83,0,0,0,1.94-.83c-.52,0-1,.17-1.52.17a1.61,1.61,0,0,1-1.11-.37,7,7,0,0,0-2.79-.8,7.73,7.73,0,0,0-1.26,0,11.26,11.26,0,0,1-1.17-.18c-.39,0-.79,0-1.18,0a5.78,5.78,0,0,0-1.33,0c0,.29.3.37.46.54s.16.38.29.51c.37.36.95.21,1.4.3l0,.14"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1081.06,834.75a3.79,3.79,0,0,1-1.34-.4,6.77,6.77,0,0,0-.72-.56c-.25-.13-.45-.31-.69-.44a1.51,1.51,0,0,0-.9-.1c-1.06,0-2.13,0-3.2,0,.34.24.69.11,1.05.22s.45.34.64.62a1.52,1.52,0,0,0,.62.53c.36.22.45.61.78.84a2.08,2.08,0,0,0,.88.33c.49-.1,0-.7.18-.94s.74-.08,1-.08a10.78,10.78,0,0,0,1.71-.17"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1056.7,849.54a4.26,4.26,0,0,1-.9-.87,1.13,1.13,0,0,1-.14-.48c-.1-.22-.29-.38-.29-.64.78,0,1.48.53,2.23.55s.65-.9,1.27-1.11,2.15.78,2.61,1.31-.26.91-.21,1.57c.07.82,1.48.68,2,.85,1,.29-.3.66-.71.67-.82,0-1.72-.69-2.51-.51s-.9,1.28-1,1.9a4.57,4.57,0,0,0-2.49-1.89,8.88,8.88,0,0,1-1.32-.59c-.52-.26-.86-.67-1.38-.14-.18-.34.7-.74.94-.84.5-.21.73.11,1.22.06"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1065.77,858.28a2,2,0,0,1-2.89-2.06,4.51,4.51,0,0,0-1.16-.94c-.64-.27-.78.42-.62.91a13.27,13.27,0,0,1,.79,1.9c-.41-.15-.62.13-.92.37a6.43,6.43,0,0,1,1.09.18,6.83,6.83,0,0,1,0,1.92,1,1,0,0,0,.3.9,2.05,2.05,0,0,0,1,.55l.07.29a1,1,0,0,0,.29-.85,8.31,8.31,0,0,0,0-1.51c0-.27-.27-.36-.15-.66s.34-.23.56-.31a2.09,2.09,0,0,0,1.27-.86"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1054,879.28a6.9,6.9,0,0,1-.64,1.52,12.1,12.1,0,0,0-.14-2c-.09-.3-.17-.57-.24-.87s-.29-.55-.28-.86a4.86,4.86,0,0,1,.32-1,3.32,3.32,0,0,1,.59-1c.49-.65,1.19-1,1.55-1.74a1.18,1.18,0,0,1,.69,1,3.78,3.78,0,0,1-.18,1c-.09.52.06.73-.25,1.23s-.67.85-.34,1.38a3.73,3.73,0,0,1,.59,1.85,13,13,0,0,0,.36,2.08.57.57,0,0,1,.48,0,2.74,2.74,0,0,1,.57.27c.14.11.18.3.34.41a1.44,1.44,0,0,0,.58.2c0,.37-.71.44-1,.47A3,3,0,0,1,1056,883c-.32-.07-.63-.07-.93-.15a3.67,3.67,0,0,0-.93-.08,4.5,4.5,0,0,1-2.1-.16c-.63-.38-.81-1-1.67-1.05.23-.54,1-.62,1.49-.81a1.75,1.75,0,0,1,.86-.17c.29,0,.45.2.77.17h-.11"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1065.1,877.26c-.77,0-2.33-.18-2.93.45a2.49,2.49,0,0,0-.4.88c-.21.55-.44,1.1-.66,1.65s-.92,2.27.15,2,1.49-1.5,2.14-2.13,1.7-.22,2.58-.17a2,2,0,0,0,2.26-1.54c.27-.89.46-1.23,1.45-.93a6,6,0,0,0,1.21.4c.32,0,.34,0,.19-.29a4.8,4.8,0,0,0-1.28-1.16,6,6,0,0,0-1.48-.92,6.73,6.73,0,0,0-3.2.06,9.37,9.37,0,0,1-.36,1.4c-.05.24,0,.34-.15.54s-.75.2-.7.72"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1079.55,882.47c-.7.67-1.36,1.35-2.1,2s-.1.75.55.59,1.29-.79,1.92-.29.52,1.14,1.47,1.07c-.26-.33-.87-1.14-.6-1.59s.83-.09,1.13,0a1.83,1.83,0,0,0-1-1.29,4.84,4.84,0,0,0-2.21.18"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1081.39,889.19a6.07,6.07,0,0,1-1.48.54c-.22,0-.52-.06-.71.1-.5.41.22,1.33.21,1.83a3.63,3.63,0,0,0,.14,1.72c.21.4.76.62.69,1.15,1,0,.92-.94.81-1.67a2.25,2.25,0,0,1-.08-1.19c.12-.27.44-.42.67-.58.65-.45,1-.53,1.44.19s.64,1.16,1.47,1.4a3.74,3.74,0,0,0-.42-2,2.79,2.79,0,0,0-.86-1.34c-.36-.25-1-.71-1.4-.4-.2.15-.24.45-.46.58a1,1,0,0,1-.85,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1076.51,864.94V865l.18.15Zm-.49-.62.49.62C1076.49,864.6,1076.4,864.31,1076,864.32Zm1.55,5.46a1.94,1.94,0,0,1-.88-.87c-.22-.43-.8-1.43-.42-1.9s1.46.47,2-.35-1.28-.72-1.7-.83a3.27,3.27,0,0,1,0-.79,8.38,8.38,0,0,0-1.91-1.29c-.35-.15-1.06-.39-1.43-.16s0,.74.17,1,.29.5.42.76a9.4,9.4,0,0,1-1.86.12c.46.34.48.47,1.08.57a8.19,8.19,0,0,0,1.1.1c0,.1.06.19.09.29a3.75,3.75,0,0,0,.55,1.49,10,10,0,0,0,1.33,1.35,6,6,0,0,0,3,1.58A7.22,7.22,0,0,0,1077.56,869.78Z"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1081.56,855.25a11.71,11.71,0,0,1-2.72-1.39c-.32-.3-.48-.64-.94-.65s-.67,0-1,0c-.81,0-1.64-.07-2.45,0s-1.15,1.21-1.91,1c.24,0,.91-.14,1.08,0s-.18.57-.28.78c.3.26.78.22,1.15.29a4.78,4.78,0,0,0,1,.08,5.12,5.12,0,0,0,1.15,0c.3-.08.61-.43.88-.6s.4-.28.76-.17.62.19.91.3a2.9,2.9,0,0,0,1,.31,3.84,3.84,0,0,0,1.4-.35"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1095.51,865.5a3.29,3.29,0,0,0-1.45-1c-.31-.09-.88-.05-1.13-.25s-.28-1-.29-1.35a12.17,12.17,0,0,0-.14-1.33c-.14-1,.47-2,.75-2.9.09-.31.25-1-.22-1.06s-.77.7-.88,1c.13-.43.72-2.27-.2-2.2-.69.05-1.39,1.46-1.77,2a3.65,3.65,0,0,0-.77,2.63c0,.88,0,1.77.05,2.65-.11-.56-.2-1.28-.91-1-.3.11-1.28.54-1.3.94s1,.93,1.25,1.11a5.33,5.33,0,0,0,1.58.61,11.67,11.67,0,0,0,1.69.42c.54.06,1.09,0,1.63,0a5.55,5.55,0,0,0,2,.15c.93-.24-.41-.85-.72-1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1087.95,869.36c-.42,0-.84,0-1.26,0s-.3,0-.5.34a1.66,1.66,0,0,1-.61.64c-.23.09-.43,0-.62.2a4,4,0,0,1,1.79.11,2.77,2.77,0,0,0,1.81.13c1-.2,2.43,0,3.05-1-.62-.05-1.51-.23-.95-1,.37-.53,1.22-.4,1.79-.53a2.63,2.63,0,0,0-2.44-1.61c-.8,0-1.63-.22-2.42-.2s-1.45.35-1,1.09,1,1.07.93,2.05l-.14,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1092,846.68a1.8,1.8,0,0,0-1.17-.17,5.54,5.54,0,0,1-.74,0,8.35,8.35,0,0,0-1.29,0,5.19,5.19,0,0,0-1.15.4c-.29.13-.41.27-.73.19s-.35-.18-.55-.23a4.48,4.48,0,0,0-1.27-.35,3.64,3.64,0,0,1-1.16-.21c-.71-.24-1.65-1.2-2.37-.36.38-1.18,1.74-.91,2.68-.91.64,0,.86-.23,1-.84a2.42,2.42,0,0,0-.3-2,1.79,1.79,0,0,1,.82-3.06,7.81,7.81,0,0,1,2.07-.83c.84,0,.46.8.21,1.24.61.24,0,.74-.23,1s0,.45,0,.86a2.59,2.59,0,0,1,2.4.51c.31.2,1.68.65,1.46,1s-1,.3-1.28.28a2.89,2.89,0,0,1-.92-.37c-.9-.36-.87.51-1.46.9.43.34-.17,1,0,1.37s1.13.13,1.45.2c.53.12.95.39,1.46.54.22.06.56,0,.75.06a.28.28,0,0,1,0,.53"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1098.7,855.75c-.42-.25-.76-.41-1.17-.62-.19-.1-.2-.18-.42-.19s-.38.13-.56.14a17,17,0,0,1-2.65,0,2.22,2.22,0,0,1-1.61-.48c-.35-.35-.7-1-1.25-.61.26-.33.56-.76,1-.85s.87.2,1.31.17c1.11-.06.38-1.48.6-2.15s1-2.29,1.77-2,.47,1.76.51,2.37l.28.06a4.82,4.82,0,0,1,0,1.33c0,.44-.66,1.24.19,1.17.5,0,.86-.25,1.22.29.14.21.18.47.33.68s.23.16.29.36"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1107.44,860c-1.22.23-2.44.26-3.67.42-.48.06-2.73-.05-2.29-1,.16-.33.71-.29.87-.62s-.2-.8-.18-1.18.51-.57.94-.45a1.85,1.85,0,0,1,1.1,1c.16-1,1,.74,1.33,1s.55.2.89.35.58.65,1,.67"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1113.65,871.72l.12.07.14,0Zm4.49,4.85c-.06-.84-1.31-1.1-1.75-1.71-.2-.27-.28-.62-.45-.91a2.32,2.32,0,0,0-.65-.63c-.62-.49-.84-1.16-1.52-1.54l-.59-.07c-.3,0-.56-.15-.85-.18a3,3,0,0,0-.93.18,3.78,3.78,0,0,0-1.1.36.83.83,0,0,0,.32.66c.23.19.34.09.59.17a1.87,1.87,0,0,1,.71.52v0a.32.32,0,0,0-.09.13,2,2,0,0,0-1-.19,1.54,1.54,0,0,0-.9.43c-.32.25-.7.36-.93.73s-.22.57-.37.85a4,4,0,0,1,1.59-.35,10,10,0,0,1,1.48.35,1.48,1.48,0,0,0,.73-.09c-.09.46-.31.94,0,1.32s.79.26,1.17.36a6.48,6.48,0,0,0,1.73.43c0-.18-.13-.27-.13-.45.3-.12.54.08.82.13a1.92,1.92,0,0,0,.81,0C1117.09,876.36,1118.22,877.59,1118.14,876.57Zm-4.23-4.76.75.25A2.69,2.69,0,0,0,1113.91,871.8Z"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1109.45,864.83c-.7-.43-1.26-.92-2.09-.8a8,8,0,0,0-1.75.46c-.74.3-.77,1.14-.89,1.83-.07.39,0,2.06.56,1.08.32.54.58,1.68,1.27,1.84,1,.23.76-1.2.66-1.73-.17-.9-.14-1.36.72-1.81.66-.34,1.14-.38,1.18-1.21"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1102.73,865.84a3,3,0,0,1,.15-1.44c.13-.26.37-.44.46-.71-.63-.21-1-.17-1.28.4a1.6,1.6,0,0,1-.91.91,1.56,1.56,0,0,0-.95,1.12c-.1.57.12,1-.19,1.55-.34,0-.88-.4-1.15,0,0,.69,2.17.23,2.63.38.26.08.39.28.69.32a4.38,4.38,0,0,0,.58,0A3.4,3.4,0,0,0,1104,868c-.31-.25-.88-.29-1.11-.58a2.72,2.72,0,0,1-.12-.9,13.8,13.8,0,0,1-.06-1.88"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1103.41,871.55a4.67,4.67,0,0,0-1.32-1,13.11,13.11,0,0,0-1.12-.74,5.12,5.12,0,0,0-1.18-.27,2.45,2.45,0,0,0-1.25-.14c.19.4.5.48.74.79s0,.45-.23.71a1.94,1.94,0,0,0-.37,1.17c0,.44.24.64.49,1s.34.55.68.71a4.37,4.37,0,0,1,1.38.71,7.23,7.23,0,0,0,2,1.75,1.48,1.48,0,0,0,.48-1.12,5.22,5.22,0,0,0,0-.87c0-.23-.22-.4-.17-.66.1-.49.59-.31,1-.33a.79.79,0,0,0,.81-.81c-.52-.19-1.15.21-1.6-.2s-.81-.76-1.19-1.17"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1128.44,865a10,10,0,0,0-3.25-2.14c-.75-.26-1.73-.87-2.54-.7s-1.28,1.09-1.6,1.78a4.22,4.22,0,0,1-1.8,1.87c.2.06.39,0,.6.05a19.26,19.26,0,0,1,.53,2.16,2.47,2.47,0,0,1,0,.76c-.06.27-.25.43-.14.74a3.69,3.69,0,0,0,1.81-.8c.39-.31.52-1.21,1-1.36a2.24,2.24,0,0,0,.35.81,6.3,6.3,0,0,0,.71.37,1.94,1.94,0,0,0,1.45.3,2.92,2.92,0,0,1-.47-1.86c0-.55,0-1.48.4-1.9a1.57,1.57,0,0,1,1.32-.28c.24.06.44.19.7.13s.71-.49,1-.77"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1117.18,864.66a13.4,13.4,0,0,0-1.46-.95,2.61,2.61,0,0,1-.73-.48c-.17-.18-1-1.62-1.1-.67-.07.58.61,1.16,1,1.43a11.22,11.22,0,0,0,1.56.69c0,.14,0,.28,0,.41a.76.76,0,0,1,.66-.08c.24.14.14.46.47.47.17-.34-.22-.43.18-.66.24-.14.92-.2.84-.65-.23-.11-.73.13-1,.19-.48.11-.83-.13-1.35-.21"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1098,833.74a36.88,36.88,0,0,0-4.74.18,4.61,4.61,0,0,1-3.85-1.28c-1-1,1.76-.45,2-1.09s0-1.28.36-1.89a1.17,1.17,0,0,1,1.46-.44,3.52,3.52,0,0,0,.33.68c.1.24,0,.52.06.78.33.08.47,0,.61.35s0,.55.07.82c.47,0,.61.45.68.84.5.09.94.27,1.41.36s.8.49,1.25.68"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1106.09,829.21a1.08,1.08,0,0,0-.16-.85c-.51,0-1,.13-1.45.19s-.93.12-1.13-.28-.16-1-.38-1.42a1,1,0,0,0-1.21-.5c-.12-.17-1.12-2-1.41-1.35a1,1,0,0,0-1-.3c-.33.13-.31.37-.31.7s0,.68,0,1-.28.5-.37.86.05.52-.48.6-.83-.16-1.28-.14a2.42,2.42,0,0,0-1.19.38c-.29.19-.57.28-.86.46a3.53,3.53,0,0,0,1.37.78,6.1,6.1,0,0,0,1.24,0,4.18,4.18,0,0,1,1.37.24c.43.14.88.13,1.3.25a3.38,3.38,0,0,1,.95.61,5.74,5.74,0,0,0,1.33.71c.32.11.89.3.87-.16,0-.16-.29-.42-.37-.57a2,2,0,0,1-.2-.73,5.76,5.76,0,0,1,.92,0,9.21,9.21,0,0,0,.92-.17,4.27,4.27,0,0,0,.84-.34c.26-.09.53-.06.44-.48a2,2,0,0,0-.62,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1102.23,837.1a2.43,2.43,0,0,0-.35,1.37c0,.28.14.73,0,1-.28.56-1-.1-1.4-.31a1.07,1.07,0,0,0-1.36,0c-.15.13-.17.34-.31.49a4.23,4.23,0,0,1-.39.3,7.14,7.14,0,0,1,2.92,1.23,12,12,0,0,0,1,.76,5.27,5.27,0,0,0,2.5.85,9.62,9.62,0,0,0,2.7-.33,1.69,1.69,0,0,1,1.22,0,9.15,9.15,0,0,1,1.3.51c-.29-1-.83-1-1.69-1.23a1.56,1.56,0,0,1-.89-1.82c.19-.81,1.2-.2,1.77-.35.89-.25-.65-1-.82-1.28-.42-.68,1-.66,1.19-1.18s-1.62-.67-2-.67a4.54,4.54,0,0,0-3.66,2c-.13-.14-.13-.39-.22-.58s-.26-.29-.27-.56a3.38,3.38,0,0,0-.9,0c-.24,0-.54.24-.75.17"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1113.49,834.75a4.83,4.83,0,0,0-1.17-.16,9.89,9.89,0,0,0-1-.17,4,4,0,0,0-.94.08c-.21,0-.56.06-.71.26.63,0,1.38,0,1.69.69a5.12,5.12,0,0,1,.07,2.15,3.53,3.53,0,0,0,.73,2.21c.29.37,1,1.16,1.5.71,1-.87-.6-3.58.81-4a5.88,5.88,0,0,1,2.27.12c.75.09,1.49.19,2.22.33a3.09,3.09,0,0,0-1.63-1,13.42,13.42,0,0,1-1.3-.62c-.9-.46-2.06-1.08-3-.55"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1118.19,847c-.67-.3-1.32-.49-2-.81-.31-.16-.26-.19-.38-.46a3.68,3.68,0,0,1-.33-.83,7.07,7.07,0,0,0-.34-.92c-.1-.29-.14-.57-.22-.87a2,2,0,0,0-.37-.82c-.21-.24-.49-.32-.62-.65a4.53,4.53,0,0,0-1.18.87c-.2.16-.65.29-.77.49-.48.82.72,1.21.46,2-.34,1-1.18-.54-1.62-.51s-.67.44-.88.64-.61.47-.65.72a1.08,1.08,0,0,1,.64-.15c.24,0,.4.18.64.21,0,.72,1.37.9,1.88,1,.33.08.68.25,1,.3a8.34,8.34,0,0,0,1,0,2.3,2.3,0,0,0,.52,0c.12,0,.23-.18.34-.18s.56.28.83.32a2.3,2.3,0,0,0,1.12,0c.14-.06.21-.21.35-.26a2.85,2.85,0,0,1,.57,0c0-.37-.48-.26-.66-.47"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1121,840.3a2.51,2.51,0,0,0-1.65-1.24,4,4,0,0,0-1.5-.29c-.45.06-1.06-.14-1.47,0s-.71.81-1.07,1.13c.35.08.62.37,1,.41s.54-.22.71.17a1.61,1.61,0,0,1-.11.94,5.43,5.43,0,0,0-.05.94,3.63,3.63,0,0,0-.09,1.24,8.16,8.16,0,0,1,.06,1,4.2,4.2,0,0,0,.22.93c.13.29.45.41.46.77a3.86,3.86,0,0,0,.87-2.26c.33,0,.42-.09.48-.41,0-.14,0-.29,0-.42s.25-.16.31-.37c.17-.61.07-1.11.54-1.62.72.34,1.38.86,2.13,1.13.52.19,1.8.31,2-.48s-1.23-.83-1.69-.91a3.15,3.15,0,0,1-1.92-1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1121.22,857.94a7.14,7.14,0,0,0-1.69,0c-.44.11-.9,0-1.33.16a13.11,13.11,0,0,0,4.92.92c.27,0,1.8.22,2-.07s-.83-.76-1-.89a2.42,2.42,0,0,1-1.06-2.16,5.74,5.74,0,0,1,1.41-2.43c.29-.39.79-1.06.06-1.26a3.16,3.16,0,0,0-2.33.62c-1.66,1.16-1.21,3.65-1.32,5.41"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1128.44,857.43c-.86,0-1.72-.08-2.58-.1-.22,0-.66-.1-.51.14s.95.47,1.21.6a14.42,14.42,0,0,0,1.43.67,11.32,11.32,0,0,0,3.05.32,29.49,29.49,0,0,1,3.43.53,12.66,12.66,0,0,0-1.63-1.15,3.2,3.2,0,0,1-1.42-1.3,4.07,4.07,0,0,1-.14-1.76,3.68,3.68,0,0,0-.3-1.45,2.91,2.91,0,0,1-.22-1.55,9.37,9.37,0,0,0,.3-1.91c0-.35-.21-1.14-.72-1s-.61,1.31-.67,1.72c-.66-.63-.87,1-1,1.25s-.36.36-.49.59a2.26,2.26,0,0,0-.23,1.1,8.53,8.53,0,0,0,.13,1.71c.06.26.17.51.21.77s0,.49.15.73c0,.1,0,.17-.15.22s0-.07,0-.1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1126.76,856.59a12.18,12.18,0,0,1,.08-1.69c0-.3.16-.69,0-1a1.35,1.35,0,0,0-1.88.2c-.36.57,0,1.53-.69,1.9a4.15,4.15,0,0,1-1.07.17,2.25,2.25,0,0,0-1,.58c1.09.74,2.79.13,4,.38.31.06,1.42.43,1.57-.12.09-.33-.86-.57-1-1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1127.6,847.69a3.46,3.46,0,0,0-2.18-1.46,3.7,3.7,0,0,0-2,.32,2.64,2.64,0,0,0,1.05.38c.31.05.58,0,.79.3s.2.63.35.9.38.5.52.78.16.55.42.73a.89.89,0,0,0,1,0c.3-.28.16-.62.11-1a1.34,1.34,0,0,1,.17-1,6.41,6.41,0,0,0-1.13-.81"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1112,821.14a8,8,0,0,0-1.17-.67,3.51,3.51,0,0,0-.85,0,12,12,0,0,1-1.95,0c-.63-.1-1.21-.53-1.84-.66-.34-.07-.74-.12-1.08-.17s-.62.12-1,.15c0-1.13,1-1.28,2-1.19.37,0,1,.26,1.28-.05s.13-.59.59-.75a1.67,1.67,0,0,1,1.51.26c.35.35.37.89.8,1.18s.83.22,1.13.7a1.83,1.83,0,0,1,.29,1.2l0-.15"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1091.64,794.43a5.49,5.49,0,0,0-3.87-.27c-.22.08-.46.07-.68.16s-.41.27-.66.35c-.68.22-1.42,0-2.11.16a1.87,1.87,0,0,1-1.32-.06,7.58,7.58,0,0,1-.83-.35c-.24-.13-.32-.36-.52-.52a4.21,4.21,0,0,0-3.11-.18,2.78,2.78,0,0,1,2.17-1.14c.69,0,1.49.16,1.93-.52.92-1.43-.25-3.1-.28-4.57,0-.81.5-1.16,1.23-1.31s1.54-.25,2.3-.36c1.45-.2,2.73.64,4.18.37.65,1-1.88,1.32-2.37,1.32a2.55,2.55,0,0,0-1.47.28c-.31.28,0,1,0,1.38.73.13.95.56,1,1.3a4.41,4.41,0,0,1,.06,1.08c-.07.4-.36.88-.21,1.28a3.24,3.24,0,0,1,2.55.25c.6.33,2-.52,1.46.82"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1083.41,790.06a2.66,2.66,0,0,0-1.21-1.79c-.3-.13-1-.24-1.15.14-.34.67,1.31,1.19,1.69,1.37A2.77,2.77,0,0,1,1084,791a3.35,3.35,0,0,0,0-1.13"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1087.61,801.15a8.47,8.47,0,0,0-1.46-.85,4.77,4.77,0,0,1-.88-.37,13.92,13.92,0,0,0-1.77-1.23c-.45-.2-1.26-1.09-1.71-.54s.35,1.51.71,2c-.42.43-.94,0-1.42.24-.21.11-.37.36-.58.48a1.87,1.87,0,0,1-.75.18c.71.7,1.4.32,2.24.51a3.58,3.58,0,0,0,.84.14,5.23,5.23,0,0,1,.88,0,5,5,0,0,0,2.08.23,2.16,2.16,0,0,0,.9-.31c.23-.2.27-.46.58-.59"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1101.22,806.19a3,3,0,0,1-1.4.35,4.2,4.2,0,0,1-1-.46c-.25-.15-.4-.37-.68-.47-.46-.17-1-.12-1.43-.34a8.16,8.16,0,0,0-1-.52c-.29-.1-.59-.16-.88-.27a6.06,6.06,0,0,0-2.29-.29,4.89,4.89,0,0,0-2,.32,1.91,1.91,0,0,1-1.44,0,8.15,8.15,0,0,0-.87-.28c-.37,0-.55.16-.84.34-.52.31-1.07.14-1.6.55.93.4,1.89.54,2.84.85s1.31.9.85,1.79a24.65,24.65,0,0,0-1.91,3.39,16.28,16.28,0,0,0-.63,3.65c.48.22,1-.28,1.19-.64.31-.52.64-1,1-1.45.35.29.6-.15.81-.36s.67-.34.86-.78a8.49,8.49,0,0,0,.31-.87c.06-.2,0-.36.18-.51.29.41.38,1,.79,1.3a3.38,3.38,0,0,0,1.9.32c.31,0,1,.13,1.12-.31s-.63-.88-.77-1.19c.06,0,.21.08.27.13a1,1,0,0,0,.39-.79,2,2,0,0,1,.31-.75,1.14,1.14,0,0,0,.12-1c-.06-.2-.35-.51-.22-.71s1.18-.15,1.39-.18a3.65,3.65,0,0,1,1.5-.14,2.36,2.36,0,0,0,1.09.27,1.25,1.25,0,0,0,1-.69"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1099.88,804.34a3.45,3.45,0,0,1-1.41-.27,6.91,6.91,0,0,0-.85-.15,3.43,3.43,0,0,1-1.48-1c-.4-.41-.67-.93-1-1.37s-.87-.75-1.25-1.22a2.72,2.72,0,0,0,.54,1.91c.08.17.6,1.4.47,1.53s-.78-.17-1-.13c-.53.07-.84.66-1,1.16.39,0,.76,0,1.15.06s.66.19,1,.26a5.08,5.08,0,0,0,1.75-.29,6.68,6.68,0,0,1,1.57-.35,14.06,14.06,0,0,1,1.89.35"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1126.09,817.95c-1.18.1-2.37.19-3.56.2-1,0-1.6-.32-1.55-1.4a2.74,2.74,0,0,0-.07-1.38c-.21-.43-.68-.65-1-.93-.94-.74-.69-1.44.11-2.16-.6-.31-1.2-.61-1.77-1-.35-.22-.9-.91-1.32-1s-.75.58-.88.91a4.05,4.05,0,0,0-.06,2.45,6.05,6.05,0,0,0,1,1.68c.17.25.69,1.08.2,1.25-.18.06-.51-.16-.67-.21a1.58,1.58,0,0,0-.83-.1,5.57,5.57,0,0,0-1.71,1,12.33,12.33,0,0,0,1.77.46c.31.05.76,0,1,.32.54.71-.59,1.76-.92,2.3-1.08,1.78-.61,3.93,0,5.75a10.25,10.25,0,0,1,1.93-4.13,13.24,13.24,0,0,0,1.37-1.59,3.2,3.2,0,0,1,.87-1.12c.81-.47,2.13-.12,3-.06a2.86,2.86,0,0,0,2.54-.86l0,.1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1135.5,817.95c-1.41-.46-2.9-.52-4.27-1.06-1.21-.48-2-1.52-3.24-1.89a2.39,2.39,0,0,0-1.46-.23c-.49.18-.86,1.11-1.38,1.09s-.63-.78-1.25-.59-.82.67-1.47.69a5.71,5.71,0,0,0,1.38.65.78.78,0,0,1,.52.32c.21.38-.15.67-.32,1a3.53,3.53,0,0,0-.25,1.3c1-.63,2-1.21,3.05-1.77a2.7,2.7,0,0,1,2.6-.19,3.14,3.14,0,0,1,1.27.68c.31.39.11.82-.07,1.23-.43,1-1.25,2.15-.36,3.1a6.53,6.53,0,0,1,3.55-3.41,2.64,2.64,0,0,1,.79-.28c.27,0,.6.14.85,0a.39.39,0,0,0,.2-.63c-.21-.32-.64-.31-1-.26"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1117.86,800c.37,1.33-.78.35-1.3,0-.77-.6-1.65-.93-2.47-1.44s-3.59-3.08-4.11-1.29c-.21.71.79,1.68,0,2a2.1,2.1,0,0,1-1.11.22c-.37-.15-.23-.7-.2-1s.29-1.08,0-1.37-.74,0-1,.15a20.19,20.19,0,0,0-2.4,1.82c.43.21.83-.05,1.22.08a1.73,1.73,0,0,1,.62.7,3.21,3.21,0,0,1,.1,1.48c0,.34,0,.67,0,1,0,.85,0,1.7,0,2.56a3.82,3.82,0,0,1-.12,1.19c-.1.32-.33.63-.2,1,.47-.27,1.13-1.1,1.7-1,.26.07.39.35.69.29s.59-.89.67-1.2a6.25,6.25,0,0,0,.23-1.59,1.66,1.66,0,0,1,.08-.75c.28-.57,1-.65,1.39-1.07s.47-1,1.11-1.17a8.55,8.55,0,0,1,1.7-.29c1.1,0,2.71,1,3.65,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1122.39,810.39a5,5,0,0,1-2.27-.88,14.69,14.69,0,0,0-1.77-1,8.48,8.48,0,0,0-3.85-1.18c-.4,0-.79,0-1.19,0a9.05,9.05,0,0,0-1.36-.12,4.67,4.67,0,0,0-3.62,2.08,6.79,6.79,0,0,1,3.76-1.31,6.41,6.41,0,0,1,1.52.15c.62.12.81.42.92,1-.36.2-.74-.13-1.1-.17a4.64,4.64,0,0,0-1.15,0,3.47,3.47,0,0,0-1.68.84c.1.52,1.2.58,1.61.67a2.41,2.41,0,0,1,1.78.84c.29.43.29.4.56-.1s.57-1.33,1.09-1.46,1.09.5,1.46.7c.59.31,1.24.51,1.85.78s1,.08,1.73-.07a7.12,7.12,0,0,0,1.14-.2h-.12"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1128.61,793.75a4.18,4.18,0,0,1-2.72,1.32c-.68,0-1.37-.15-2-.21a13.14,13.14,0,0,1-1.83-.39c-.59-.14-1.19-.14-1.79-.23a3.64,3.64,0,0,0-1.56-.13,2.42,2.42,0,0,0-1.16.95,3.65,3.65,0,0,0-.44,2,8.89,8.89,0,0,1-.38,2.39,8.32,8.32,0,0,1-.78-2.35,4.32,4.32,0,0,0-.36-1.57,2.52,2.52,0,0,1-.46-.86,4.82,4.82,0,0,1,.18-1.88c.45-1.53,1.66.51,2.61.17.62-.22,1.06-.67,1.74-.74a2.09,2.09,0,0,1,1.39.36,7.94,7.94,0,0,0,.27-3.88c-.21-1.23,0-2.09,1.23-2.57a7.63,7.63,0,0,1,4.66-.15,7.32,7.32,0,0,1,3.08,2.09,8.83,8.83,0,0,0-3.15-.26c-.54.06-1.71,0-2,.57a1.79,1.79,0,0,0-.1.94c0,.52,0,1,0,1.56,0,.84-.05,1.68-.06,2.53.57,0,1.21-.08,1.77,0a1.61,1.61,0,0,1,.81.49c.21.18.62.41.61.73,0,.7-1.48.35-1.86.3"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1143.23,803.33a6.54,6.54,0,0,0-1.61-.35,10.71,10.71,0,0,1-1.37,0,5.61,5.61,0,0,0-2.42.47,7.18,7.18,0,0,1-1.35.42,2.79,2.79,0,0,0-1.17.37,13.64,13.64,0,0,1-1.47.53,5.6,5.6,0,0,1-1.64.42,3.33,3.33,0,0,1-2.11-.3,10.55,10.55,0,0,0-2.28-1c-1.35-.45-2.5-.76-3.85-.08,1.69-.83,3.09-2.2,5.09-2.05.63,0,1.77.47,2.33,0s.33-1.45.21-2a2.66,2.66,0,0,0-1-1.59,2,2,0,0,1-.93-1.79c.14-1.24,1.15-2.32,1.89-3.26a22.26,22.26,0,0,0,2.21-3.38,3,3,0,0,1,.55,1.88,2.49,2.49,0,0,1-.08,1c-.12.28-.47.48-.41.82.35,0,.58-.25,1-.14a1.26,1.26,0,0,1-.22,1.08c-.17.29-.67.82-.54,1.16.3,0,.63,0,.65.38s-.38.6-.31.86c.12.43,1,.24,1.3.76a3.9,3.9,0,0,1,.17,1.72c0,.46-.17,1.16.21,1.53s.9.2,1.26.6.43.79,1.08.91,1.41.37,2.14.49a3.08,3.08,0,0,1,1.8.89,9.62,9.62,0,0,0-2.22-.14,8.26,8.26,0,0,0-1.76,0,3.46,3.46,0,0,0-.82.45,1.08,1.08,0,0,1-.77.22,5.1,5.1,0,0,0-2.45.5"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1152.64,807.2a8.14,8.14,0,0,0-1.87-1.45c-.48-.3-1-.33-1.49-.64a1.87,1.87,0,0,0-1.44-.35,7.4,7.4,0,0,0-1.19.61c-.79.36-1.7-.26-2.54-.21a3,3,0,0,0-1.77.46,2.12,2.12,0,0,1-.85.42c-.18,0-.27,0-.36.16,1.13-.13,2.45.25,2.28,1.64a9.08,9.08,0,0,0-.18,1.29,2.66,2.66,0,0,0,.6,1.62c.29.44.51,1,.81,1.37.13.19.32.3.44.5a4.18,4.18,0,0,1,.28.91c.13.39.4.73.5,1.13s0,.84.07,1.26c.56.08,1.17-1.54,1.15-2,0-.63-.17-1.24-.17-1.88a2.89,2.89,0,0,1,0-.9c.09-.19.33-.27.43-.51.26-.64-.24-1.12-.27-1.73,0-.37.16-.42.32-.71.28-.49-.06-1,0-1.51.28.08.48.32.75.41a6,6,0,0,0,1.3.24,2.71,2.71,0,0,0,1.07-.12c.37-.13.66-.51,1.06-.53"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1149.44,817.28a2.93,2.93,0,0,0-1.15.12,2.19,2.19,0,0,1-.66.21c-.24,0-.48,0-.72,0a2.1,2.1,0,0,1-.75.1c-.17,0-.37-.21-.57-.25a4.41,4.41,0,0,0-.66,0,.85.85,0,0,1-.77-.35,1.77,1.77,0,0,0,1.64-.49c.32-.51,0-1.15.25-1.68a2.79,2.79,0,0,1,.79-.8c.4-.27.82-.18,1.24-.35.6-.24,1-.87,1.5-1.21a3.15,3.15,0,0,1-.57,1.52c-.24.38-.64.61-.88,1,.89-.06.31.9.18,1.34a.66.66,0,0,0,.13.67c.21.16.58,0,.83.2"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1161.37,815.43a13.11,13.11,0,0,0-2.5-.2,15.35,15.35,0,0,0-2.3.4c-.43.09-4.25.7-3.82-.42.17-.45,1.1-.62,1.49-.71a3.61,3.61,0,0,0,1.49-.54,19.37,19.37,0,0,0,1.33-1.44,12.77,12.77,0,0,1,2.42-1.73,5.65,5.65,0,0,0-.62,1.78c.31,0,.62-.59,1-.33s-.57,1.4-.5,1.87c.23-.14.45-.62.77-.53s.29.44.35.64a1.72,1.72,0,0,0,.49.83c.18.17.58.22.65.42a2.61,2.61,0,0,1-1.1.47"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1164.4,812.91a4.32,4.32,0,0,0-1.5-.51,4.6,4.6,0,0,1-1-.13c-.78-.22-1.43.16-2.22.14s-3.37.2-3-1.08a1.68,1.68,0,0,0,1.86-.58c.34-.67,0-1.39.16-2.09a7.55,7.55,0,0,1,1.66-2.89,2.9,2.9,0,0,0,.84-1.35,3.6,3.6,0,0,1,.37-1.22c.32.2.44.24.49.66a5.42,5.42,0,0,1,0,1.51,12.36,12.36,0,0,0-.63,2c.47,0,1-.44,1,.36a2.91,2.91,0,0,1-.43,1.34c.32.24-.11,1.29-.22,1.62.9-.46.72.49,1.25.85.14.09.29.08.41.2s.13.36.24.52.72.64,1.08.48l0-.14"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1154,811.73c-.23-.37-.44-.88-.86-1.07-.16-.07-.42-.1-.6-.17a4,4,0,0,0-.6-.1c-.23-.05-.41-.25-.63-.29s-.36.09-.59.13-.34-.06-.49,0-.08,1.25-.12,1.57a3.14,3.14,0,0,1-.78-1.47c-.13-.32-.77-.92-.6-1.25s.83-.32,1.08-.34a4.83,4.83,0,0,1,1.95.27,3.86,3.86,0,0,1,2.24,2.16"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1150.12,792.91a16.17,16.17,0,0,0-1.67-.2c-.38,0-.45.22-.5.62a4.35,4.35,0,0,0,1.85,3.75,1.88,1.88,0,0,0,.06-1.5c-.12-.33-.54-.77-.41-1.14a6.74,6.74,0,0,1,.74,0c.28,0,.6-.2.88-.1.09.68.31,1.82,1,2.18,1,.52.42-1.23.29-1.58.33-.26,1.35-.31,1.12-.89-.14-.36-.42-.22-.74-.24a6.1,6.1,0,0,1-2.61-1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1171.79,819.8a6.41,6.41,0,0,1-1,.22,3.94,3.94,0,0,1-.88-.2,5.83,5.83,0,0,1-1.73-.89,8.63,8.63,0,0,0-1.13-.79,4.33,4.33,0,0,0-1.25-.2c-.83,0-1.6.27-2.45.21-.48,0-.94-.48-1.44-.37s-.75.66-1,1a2.45,2.45,0,0,1,2.08.8c.46.54.83.66.64,1.41a7.25,7.25,0,0,0,0,4.53c.61-.19.9-1.43,1-2,.21.32.69.11.87-.12s.1-.31.21-.44.29-.14.4-.26c.54-.58.19-1.72.69-2.31.27-.33.45-.19.81-.06a1.93,1.93,0,0,0,1.12.13c1-.17,2.13-.26,2.75-1.15"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1145.75,829.54a14.31,14.31,0,0,0-2-.15c0,.21,0,.67-.23.81s-.4-.07-.55,0c-.34.18-.41,1-.46,1.33a.46.46,0,0,1-.28-.09,6,6,0,0,0-.15,1.12c-.05.3,0,.6,0,.9a3.91,3.91,0,0,1-.52,1.38,3.14,3.14,0,0,0-.28-1.27,5.82,5.82,0,0,1,0-1.19c-.05-.6-.37-1.13-.11-1.75s.79-.87,1-1.46c.12-.33.24-.28-.1-.56a1.09,1.09,0,0,0-1.22-.24c.84.13,1.72-.44,2.51-.62a1,1,0,0,1,.67,0,1,1,0,0,0,.24.34c.19.14.38.29.57.45s.42.64.77.68"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1152.8,837.94c-.59,0-.58-.23-1-.47s-.68-.07-1-.6-.26-1.15-.66-1.63a4.8,4.8,0,0,0-1.17-1.16c-.58-.36-1.17-.24-1.62-.8.1.43,1.22,3.16.41,3.11-.22,0-.47-.32-.66-.42a1.08,1.08,0,0,0-.87-.08,11.7,11.7,0,0,1-1.79.76c.35.22.78,0,1.11.15s.43.54.71.75a3.35,3.35,0,0,0,2.44.86c.39-.07.77-.2,1.16-.27a1.48,1.48,0,0,1,.94.2,2.76,2.76,0,0,0,1.45.1c.38-.1.45-.27.57-.64-.59-.08-.75-.29-1.17-.7"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1159,832.73a7.44,7.44,0,0,0,.32.83c-.33.06-.59-.57-.92-.66-.14,0-.27,0-.41,0s-.28-.23-.42-.32a3.7,3.7,0,0,0-1.95-.66,8.47,8.47,0,0,1-2.36-.17,5.25,5.25,0,0,1-.81-.2c-.27-.14-.29-.48-.69-.47-.29.42-.49,1.1-1,1.33-.29.13-.61,0-.76.37.57,0,2.29-.33,2.45.5a4.35,4.35,0,0,1-.17,1.1,4,4,0,0,0,.14,1.26c.1.46.14,1,.26,1.41s.16.5.46.21a2.82,2.82,0,0,0,.71-2.75c.58.62.62-.7.8-.85.35-.28,1,.13,1.39.14a9.15,9.15,0,0,0,1.71,0,.24.24,0,0,0,.28,0l.75-.41c.25-.11.7-.06.88-.29"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1153.64,832.57a6.08,6.08,0,0,0,.26-3.71c-.27-1-.57-1.62.46-2.3a6.62,6.62,0,0,1,2.61-1c.44-.07,1.36-.19,1.7.23.5.61-1.1,1.25-1.47,1.47.2.36.69.16,1,.12a4.91,4.91,0,0,1,1.68.13,2,2,0,0,1,1,.32,4.88,4.88,0,0,1,.41.55c-.76.48-1.77.24-2.59.48s-.54,1.5-.62,2.24c0,.36-.1,1.65-.54,1.67-.09,0-.3-.19-.38-.23a2.78,2.78,0,0,0-.79-.27,9.55,9.55,0,0,0-2.93.09"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1148.44,855.92a13.93,13.93,0,0,0-2-.43c-.42,0-.78.13-1.22.1s-.84-.29-1.28-.25a5.54,5.54,0,0,1-1.5-.08,2.91,2.91,0,0,1-1.14-.7,12.18,12.18,0,0,1-1.06-.84c-.18-.17-.28-.38-.5-.5s-.3-.1-.43-.17a2.93,2.93,0,0,0-1.38-.48,3.57,3.57,0,0,1,2.09,0c.27.09.86.52,1.11.22s0-.84.07-1.14c.15-.48.68-.65,1.07-.89a4.52,4.52,0,0,0,1.37-1.25c.18-.25.48-.88.86-.87.87,0,.06,1.87-.1,2.21l.29,0c.14.46,0,1,.06,1.46.05.23.19.37.14.67s-.13.33-.17.51c.84,0,1.18.66,1.51,1.29a1.3,1.3,0,0,0,.76.74c.46.21,1.14.44,1.42-.16"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1142.22,848.19a1.54,1.54,0,0,0-.47-.84c-.16-.11-.38-.1-.56-.21-.44-.26-.47-.55-1-.4s-1,.44-1.52.32a2.84,2.84,0,0,0-1.59-.07,6.86,6.86,0,0,1-.94.37,5,5,0,0,0-.56,0,3.17,3.17,0,0,0-.68.38c-.3.15-.53,0-.85.12a2.71,2.71,0,0,0,1.6.6c.34,0,.87-.09,1,.26.25.62-.49,1.11-.61,1.66s.41,1.26.2,1.87c-.15.43-.32.64-.19,1.13a2.9,2.9,0,0,0,.64,1.14c.38-.38.25-1.11.66-1.5a2.09,2.09,0,0,1,1.17-.32c.85,0,1.23-.61,1.36-1.42a5.54,5.54,0,0,1,.14-1.16c.21-.55.56-.37,1-.63a2.16,2.16,0,0,0,.64-.81c.18-.41-.13-.94-.28-1.34"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1142.22,844.83c-.24-.06-.8-.31-1-.11s0,.36-.16.49-.92.19-1.21.24-.8.19-.94,0,.6-.5.73-.66c1.22-1.56-1.89-2.42-2.88-2,.18-.62.93-1,1.45-1.31s1-.31,1.41.25c.15.2.3,1.08.52,1.1.56.05.69-1.32.78-1.63.14-.5.71-.56,1.19-.4s.81.78,1.26,1.13a23.07,23.07,0,0,0,3.26,1.93c-.54,1-1.49.22-2.12-.23a2.46,2.46,0,0,0-1-.44c-.19,0-.71-.29-.89-.23s-.32,1.3-.37,1.64,0,.57-.27.78a6,6,0,0,1-1.86.41l0-.11"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1142.22,842.31a3,3,0,0,0,.35-1.54,1.47,1.47,0,0,0,0-.38c-.15-.31-.07,0-.31-.12a.21.21,0,0,1-.21,0,.51.51,0,0,1-.12-.16c-.13-.07-.23-.17-.37-.23a3.51,3.51,0,0,0-1.17-.44,1.22,1.22,0,0,1,.73-1c.34-.06.81.49,1.11.64,1.11.56.9,1.8.68,2.87"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1144.57,844.83c-.46,0-2.42-.15-2.57.55s1.29.71,1.64.73a6.83,6.83,0,0,1,2.17.32,4,4,0,0,0,2.19.5c.72-.17.09-.55-.27-.71s-1.11-.38-.79-1,1.23-1.18,1.21-1.79-1-.22-1.38-.13c-.2-1.14-.14-2.24-1-3.12-.48-.48-.86-1-1.37-1.48a12.44,12.44,0,0,0,.34,1.84c0,.57.27,1.19.27,1.74s-.5.9-.7,1.4.21,1.07-.5,1.3a.19.19,0,0,1-.07-.29"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1153.81,850.88c-.69,0-1.36-.32-2,0a1.62,1.62,0,0,1-1.35.11c-.5-.16-1-.24-1.51-.39-.3-.09-1.07-.34-1.17-.64.33,0,.65-.09,1-.13a3.43,3.43,0,0,0,1.1-.18c.51-.32.06-.74-.14-1.14a2.77,2.77,0,0,1-.35-1.7,4.48,4.48,0,0,1,1.15-1.75c.43.38,0,1.74.2,2.29s.64.63,1.05.88a1.39,1.39,0,0,1,.55-.62c.13,0,.25,0,.38,0,.29-.05.53-.27.82-.33a4.9,4.9,0,0,1,.9,0c.27,0,.5-.15.75-.16a2,2,0,0,1-.87,1.33c-.39.25-.88.15-1.25.51a1.29,1.29,0,0,0-.37,1c.14.41.7,0,1,.52"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1155.66,856.93c-.68-.29-1.11-.51-1.75-.15-.46.25-.64,0-1.06-.26.11-.5.6-.39,1-.51a1.12,1.12,0,0,1,.81-.1c0-.4-.3-.44-.5-.7a.84.84,0,0,1-.17-.7,3.3,3.3,0,0,1,.23-1.47,4.13,4.13,0,0,1,.66-1.25c.32-.34.81-.52,1.14-.86,0,.29-1,2.82-.22,2.8.08-.31.86-2,1.35-1.51.32.31-.41,1.52-.47,1.85s-.34,1.5-.08,1.7.91-.08,1.21,0c-.18.19-.38.6-.6.73s-.51,0-.82.08c-.67.12-1.72.88-2.23,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1158.52,862.31a4.94,4.94,0,0,1-2.13.83c-.42.06-.95.29-1.35.15a1.17,1.17,0,0,1-.52-.46,3,3,0,0,0-.32-.48,1.18,1.18,0,0,0-1.22.25c.2-.19.3-.43.52-.61s.54-.3.78-.52,0-.6-.07-1a3.46,3.46,0,0,1,.25-2.15c.16-.34.93-2.14,1.34-2a3.69,3.69,0,0,1,.11,2.05c-.07.61-.31,1.72.21,2.24.11-.35.32-1.46.79-1,.22.22.2,1,.3,1.26a1,1,0,0,0,.37.57c.34.25.77.14.93.59s-.55,1.43-.83.62"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1158.68,862c-.78-.27-.53-1.16-.66-1.84a7.05,7.05,0,0,1-.22-1.17,5.06,5.06,0,0,1,.57-2.09c.58,1.18.19,2.75.92,3.83.32.48.74.44,1.26.42.32,0,.45-.07.61.22.06.11.15.61,0,.72s-1.11,0-1.37,0a11.72,11.72,0,0,0-1.81.05"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1163.56,866.17a7.3,7.3,0,0,1-1.48-.79c-.23-.18-.38-.42-.6-.62a2.47,2.47,0,0,0-1.52-.4c-.24,0-1.17,0-1-.37s1.36-.69,1.72-.79.6-.21.73-.67c.09-.3.1-.61.2-.9a5.33,5.33,0,0,1,.42-.75c.31-.57-.08-.8-.51-1.19s-.82-.82-1.25-1.21c.26-.57,2.24.28,2.58.53a1.51,1.51,0,0,1,.54.69c.14.43-.06.86.23,1.26.33-.12.63-.45,1-.5a1.87,1.87,0,0,1,.68.14c.25,0,.55,0,.79,0s.29.46.5.75.4.13.86.15c.16,0,.29.09.46.11a2.61,2.61,0,0,0,1-.35c-.28.1-.47.37-.75.49a7.82,7.82,0,0,1-1.11.24,5.56,5.56,0,0,1-1.32.46c-.3,0-.52,0-.59.36.49,0,.52.32.42.72a1.09,1.09,0,0,0-.06.62c.06.21.24.3.23.55s-.29.56-.45.79a3.2,3.2,0,0,1-.37.59c-.49.38-1.28-.32-1.67-.59"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1145.58,866.68a.69.69,0,0,1-.07-.47,3.48,3.48,0,0,0-1.56,1.79,7.25,7.25,0,0,0,.16,2.87c.15,1,.21,2,.31,3,0,.4.07.95.52.55a5.81,5.81,0,0,0,1.13-2.09c.32-.78.55-1.62.94-2.37.72-1.38,2.21-1.48,3.6-1.61-.41-.43-1.31-.82-1.32-1.49s.78-1.38,1.06-2c.43-1-.31-1.37-1.18-.88s-1,1.38-1.74,1.78c-.13-.58-.46-.74-1-1-.79-.36-1,.81-.86,1.41"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1146.08,866c-.55-.73-1.3-1.59-2.31-1.24a3.23,3.23,0,0,1-.93.32,5.57,5.57,0,0,0-1.07,0,4,4,0,0,0-1.71,1.21c-.55.76-.35,1.9-.48,2.78.43-.31.89-.53,1.29-.87s.8-1,1.56-1.16a3.93,3.93,0,0,1,1.71.08c.26.1.47.31.79.27s.65-.25.47-.53"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1142.05,865.16a4.79,4.79,0,0,1-1.19-.85,1.5,1.5,0,0,0-.66-.46,3.6,3.6,0,0,0-1.29,0c-.62.11-1.09.5-1.76.51a9.55,9.55,0,0,0-1.67,0c-.11.77,1.43.82,2,.83a5.14,5.14,0,0,0,1.21,0,2.63,2.63,0,0,1,.55-.15c.54,0,.91.39,1.37.61s.59.33,1-.08"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1133.31,871.72c.17-.34.52-.92.21-1.27s-.54-.19-.79-.22-.75-.06-.95-.37,0-.63-.32-.82a.92.92,0,0,0-1.12.35c-.15.36.18.73.07,1.1-.15.53-1.16.71-1.6.9.82.26,1.58.79,2.37,1.1a5,5,0,0,0,2.15.44c.27,0,.5-.29.74-.37s.45.15.51-.15c-.37-.21-1.4-.15-1.28-.84"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1161.88,867.35c-.88.48-1.33.89-1.32,1.92,0,.77-.28.77-.73,1.32a2.22,2.22,0,0,0-.45,1.23c0,.23-.08,1,.18,1.16.54.34.76-1.08,1.05-1.3s.13-.23.27,0,0,.82,0,1.08a3.38,3.38,0,0,0,.84,2.28c.63.55.45-.6.47-1s0-1.49.82-1.29a13.63,13.63,0,0,1,.4-1.82c.38,0,.34-.55.66-.69s.75.33,1.17.34c.25,0,.44-.17.7-.15s.51.33.79.55a1.3,1.3,0,0,0,1.13.23,15.42,15.42,0,0,0-2-1.89c-.42-.35-.83-.8-1.42-.8s-1,.52-1.43.32-.41-.64-.71-.83-.56-.17-.82-.31"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1156.84,864.49a11.1,11.1,0,0,1-2.08-.28c-.52-.16-.6-.47-.86-.89-.38-.61-1.39-.83-1.78-.11a4.39,4.39,0,0,0-.39,1.34c-.06.38.06,1.16-.4,1.24s-.62-.29-.9.26a2.47,2.47,0,0,0-.16,1.11c-.13,0-.27,0-.39.06a1.44,1.44,0,0,1,.82.66,1.47,1.47,0,0,0,1.39.82c.52,0,1.06,0,1.58,0a5.93,5.93,0,0,0,1.49,0c-.22-.51-.82-.7-1.17-1.1s.07-.74.18-1.27-.2-1,.48-1.43a5.64,5.64,0,0,1,2.14-.72h-.11"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1181.54,854.75a13.11,13.11,0,0,1-1.89-.89,3.51,3.51,0,0,0-1.07-.55c-.71-.1-1.39.92-1.82,1.35s-1.19,1.29-2.07,1.29a1.7,1.7,0,0,1-.92-.24c-.27-.17-.39-.35-.75-.31a6.15,6.15,0,0,0,1.39-1.41.79.79,0,0,0-.1-1.21,3.51,3.51,0,0,0-2.8-.88,7.65,7.65,0,0,0,1.49-.45c.34-.15.71-.41,1.1-.32s.7.79,1.19.9.57-.49.95-.65,1,.17,1.44.31a13.81,13.81,0,0,1,1.95.82,2.8,2.8,0,0,1,1.11.89,5.5,5.5,0,0,0,1,1.19"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1181.87,859.28c-.86-.34-2.48-.66-2.54-1.79,0-.55.23-2.4-.65-2.52s-.74,2.4-.87,2.88c-.27,1-1.14-.07-1.81,0-.87.14-1.24,1.89-.84,2.56s.91-.56,1.33-.69c.7-.21.63,1.14.72,1.54.21.88,1,.73,1.76.66.12-.46-.05-1.09.26-1.5s.71-.22,1.05-.16c.54.09.88.28,1.43-.08.4-.27.37-.66.31-1.1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1183,863.82a9.82,9.82,0,0,0-2.81-1.19c-.37-.09-.73-.52-1.05-.24s-.21.5-.41.68-.38.13-.53.26c-.4.36-.27,1.19-.21,1.66.91-.59,1,.32,1.13,1.05.05.24.16,1,.52,1s.49-.85.5-1.13a7.46,7.46,0,0,1,.1-1.32c.05-.26,0-.69.08-.91.27-.52,1,0,1.36.16.62.29.94.29,1.32-.32"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1193,862.47c-.1-.29-.18-.92-.5-1a2.05,2.05,0,0,0-.85.1c-.58.13-1.27-.06-1.81.2a1.25,1.25,0,0,0-.44-.87c-.25-.15-.52,0-.77-.15,0,.49.16.94.19,1.43a1.35,1.35,0,0,1-.59,1.24c-.16.15-.46.33-.42.58s.41.38.5.5a2,2,0,0,1,.31.88c0,.29-.07.7.06,1,.47-.32.75-.4.81-1,0-.37-.1-.73.15-1s.36-.17.59-.27.66-.52,1.06-.54.41.2.68.23.75-.12.84-.31-.38-1-.48-1.2"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1168.09,857.77a1.92,1.92,0,0,0-1.27-.33,2.09,2.09,0,0,1-.75,0c-.28-.1-.38-.25-.68-.3s-.66.06-1,0a5.28,5.28,0,0,1-1.39-.44,5.92,5.92,0,0,1-1.77-1.6c.44.16,1.59.76,2,.26s0-1.32-.1-1.72c-.15-1.18.27-1.61,1-2.46.3-.34,1.3-2.3,1.92-1.75s-.8,2.52-1,3.17a.25.25,0,0,0,.18-.08,1.08,1.08,0,0,1,.29.62,2.88,2.88,0,0,1,.16,1.1,3,3,0,0,0-.15,1.14c.08.46.26,1,.36,1.42.32,0,2.27,1.11,2.32.52"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1167.59,852.39a9.21,9.21,0,0,0-2.18-.33,7,7,0,0,1-1.14-.13,4.11,4.11,0,0,1-.82-.06c.62-.29,1.24-.7,1.06-1.43a5.62,5.62,0,0,1-.46-1.75,5.33,5.33,0,0,1,.67-2.3,3,3,0,0,1,.45-.66c.12-.14.36-.56.53-.6a17.4,17.4,0,0,1,.22,2.63,1.74,1.74,0,0,0,.63,1.33,12.29,12.29,0,0,1,.92.88c.23.31.09.43-.21.64s-.83.47-.6,1c.11.24.36.36.55.51.91.73-.13,1.2-.8.46"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1171.29,851.38a9.55,9.55,0,0,0-1.92-.35,9.21,9.21,0,0,1-1.47,0c-.82-.19-.42-1.71-.44-2.33,0-.92-.46-2.17,0-3a2.08,2.08,0,0,1,1.34-1c.14.74.85,1.09,1,1.8s-.11,1.36,0,2c.36,0,1-.62,1.19-.17.08.26-.43.69-.56.87s-.34.46-.32.57c0,.33.45.29.69.5s.47.7.84.58"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1174.81,836.94a4.65,4.65,0,0,0-1.51.12,3.28,3.28,0,0,1-.91.22,4.82,4.82,0,0,1-1.87-.17c-.71-.24-1.42-.37-2.11-.67-.33-.14-.6-.44-1-.5a1.6,1.6,0,0,0-1.14.36c.29-.16.35-.43.56-.65s.38-.21.69-.27a1.82,1.82,0,0,1,.72-.09c.24,0,.46.21.7.27.47.11.63.06.77-.38a4.93,4.93,0,0,0,.22-1.6,4.09,4.09,0,0,1,1-2.38c.81-1,2-1.47,2.76-2.45a2.23,2.23,0,0,1-.05,1.79c-.15.33-.35.6-.5.94s-.18.67-.45.78c.58.22.06,1.15,0,1.54a1.92,1.92,0,0,0,.11.59,2.17,2.17,0,0,1-.15.91c-.19.73.25.51.65.91s.44.73,1.12.76c-.15.28-.71.7-1,.31"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1170.78,833.74c.07-.55.29-1,0-1.54s-.74-.61-1.15-.92a7.54,7.54,0,0,0-1.17-.76c-.46-.22-1-.24-1.41-.61a5,5,0,0,0,.71,1.28c.4.42,1,.67,1.49,1.06a5.19,5.19,0,0,1,1.06,1.06,5.28,5.28,0,0,1,.42,1.4,3.9,3.9,0,0,1,.85-1.81"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1182,839a6.16,6.16,0,0,0-2.7-.84,20.31,20.31,0,0,1-2.15,0,5.9,5.9,0,0,1-.79-.4,8.28,8.28,0,0,1-1-.45,2.86,2.86,0,0,0-1.76-.31c.35-.13.49-.51.91-.5.23,0,.44.15.67.18s.38-.06.57-.06.49.2.65-.18,0-1.13,0-1.6.41-1.86,1-1.9c.34,0,.58.2.95.11s.48-.18.7-.25a2.33,2.33,0,0,0,1,.89c.36.13,1.06,0,1,.52,0,.29-.48.71-.64.92a3.78,3.78,0,0,0-.59.88,3.73,3.73,0,0,0-.11,1.46c.22.06.44,0,.66,0s.29.24.42.42c.27.4.67,1.19,1.26,1.09"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1178.51,828.54a16.75,16.75,0,0,1-3.3.16c-.28,0-.56.18-.83.19s-.31-.08-.55-.13-.8,0-1.18-.05c-.58-.14-1.07-.74-1.57-1a2,2,0,0,0-1.2-.17c-.43.05-.79.46-1.24.32.65-.46,1.11-1.13,1.88-1.42.29-.11.59-.26.87-.13s.47.39.79.51a1.64,1.64,0,0,0,.2-1.55,1.43,1.43,0,0,1,.34-1.47,13.11,13.11,0,0,1,1.44-1.47c.3-.26.65-.43.94-.7a4.42,4.42,0,0,1,.61-.57c.88.39-.09,2.22-.09,2.84a8.44,8.44,0,0,1-.05,3c.76.17.83.86,1.54,1.12a6.26,6.26,0,0,0,1.17.24c.47.08.64.18.59.68"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1187.08,823.49a9.42,9.42,0,0,1-3.33.51c-.58,0-1.17,0-1.75,0a7.61,7.61,0,0,1-1.1,0,5.92,5.92,0,0,1-1.75-.58c-.34-.19-.45-.45-.88-.45a1.47,1.47,0,0,0-.9.36,2.77,2.77,0,0,1,1.86-1.24c.42,0,1.15.27,1.46-.11s.1-1.54.06-2.06a2.67,2.67,0,0,1,.16-1.61,5.63,5.63,0,0,1,1.58-1.17,8,8,0,0,1,2.92-1.48c.91-.13.59.68.28,1.18a13.44,13.44,0,0,1-2,2.26c.35.11.35.38.33.69a3.72,3.72,0,0,0,.12.92,3,3,0,0,1-.09,1.21c0,.21-.15.58,0,.78s.43.1.67.06a1.21,1.21,0,0,1,1,.17c.46.33,1.33,1.74,1.84.74"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1193.3,822.65a5.16,5.16,0,0,1-2.28-1.06c-.33-.37-.44-.87-.77-1.24a4.9,4.9,0,0,0-1.4-.92c-.32-.17-1.2-.84-1.58-.73s0,.65.1.94c.18.59-.25.56-.73.65s-1.15.31-.85.94,1.18.73,1.78.78c.18.83-.15,1.76,0,2.59.18,1,1.24.78,1.75,1.4.12-.2.25-.83.45-.92s.22.15.51.09c.55-.11.48-1.45.5-1.82,0-.18-.05-.59.16-.68a3.57,3.57,0,0,1,1,.17c.37.11,1.75,1,1.73,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1190.78,831.56a8.3,8.3,0,0,0-1.53-1.53,11.79,11.79,0,0,0-1.32-1.06c-.21-.11-.8-.38-1-.14s.25.93-.05,1.26-.8.14-1.1.17a7.07,7.07,0,0,0-1.74.21c-.48.18-.79.56-1.33.67a5,5,0,0,1-1.77-.06,5.7,5.7,0,0,0,1.5.68c.44.11.84.42,1.28.48a3.61,3.61,0,0,0,2.38-.28,3,3,0,0,0,.6,2c.09.19.08.42.16.62s.21.29.29.46c.18.4,0,.85.13,1.24.48-.14.74-1.37.8-1.83s-.07-.78,0-1.15.16-.56.21-.85c.26,0,.56-.29.8-.4s1.09-.47,1-1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1189.1,841.64a4.73,4.73,0,0,1-.47-.82,2.58,2.58,0,0,0-.16-.54,3.7,3.7,0,0,0-.81-.74c-.38-.34-.4-.68-.67-1.1a5.75,5.75,0,0,0-.5-.5c-.17-.19-.28-.4-.56-.45a2.42,2.42,0,0,0,.57,2.46,5,5,0,0,1,.71.94c.17.35.19.73.32,1.08.31.79,1.15.16,1.78.06.9-.14,1.79.08,2.41-.72-.13-.05-.19,0-.32,0a6,6,0,0,1-.82,0,3,3,0,0,0-1.66.08"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1180.36,847.35c-.35-.17-.63-.35-1-.1-.14.11-.23.29-.38.4s-.48.15-.81.24c.23.4.9.24,1.25.47s.22.38.31.68a.84.84,0,0,0,.81.68c.45,0,.89-.27,1.31-.19.2,0,.24.17.4.25a1.89,1.89,0,0,0,.42.1,2.39,2.39,0,0,1,.84.48c.3.27.6.61.88.9a7.84,7.84,0,0,1,.61.75c.13-.64-.56-1.37-.31-2,.18-.44.87-.19,1-.62a14.46,14.46,0,0,1-1.71-.72c-.42-.29-.62-.81-1.06-1.08a5.59,5.59,0,0,0-1.67-.58c-.22,0-.39-.16-.62-.21-.37-.07-.75.17-1.13.14,0-.1,0-.19,0-.29"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1187.08,851c-.77.21-1,.24-1.1,1,0,.3-.13.56.15.81a4.62,4.62,0,0,1,2.64-.46c.25.05,1.37.72,1.58.54s-.29-.88-.5-1a3.37,3.37,0,0,0-1.18-.21l-2.1-.19"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1198.51,850.71c-1-.31-2-.58-3-1a1.35,1.35,0,0,0-1.08,0c-.32.1-.73.05-1,.35a2.51,2.51,0,0,0-.52-1.43,2.3,2.3,0,0,0-.59-.64,1.2,1.2,0,0,1-.42-.23.36.36,0,0,1-.12-.25,2.93,2.93,0,0,1-1-.83,1.93,1.93,0,0,1-.12-1.34,12.13,12.13,0,0,0,.8,1.37,6.55,6.55,0,0,0,.69.49,4.53,4.53,0,0,0,.58.34c.19.1.55.15.7.25s.19.26.31.42.41.56.63.89a5.05,5.05,0,0,1,1-.16,2.15,2.15,0,0,1,1.45.6c.39.34.45.88.94,1.12s1.21-.13,1.67.15"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1200,851.89a5.4,5.4,0,0,0-2.31-.62c-.54.07-1.12.2-1.65.29a.84.84,0,0,0-.7.53,13.11,13.11,0,0,1-.5,1.6c1,.25,1.58-1.3,2.49-1.45a1.68,1.68,0,0,1,1.89.74c.26.61-.3,1.05.21,1.64.34.39.79.69,1.15,1.06.21.22.39.44.72.37-.44-.83-1-1.54-.76-2.52.13-.54.57-1.08.62-1.62a1,1,0,0,0-.53,0,1.93,1.93,0,0,1-1.21,0c-.37-.15-.71-.46-1.09-.62a13,13,0,0,0-1.7-.4"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1203,858.78c-.62.18-.8.23-1,.75a2,2,0,0,0-.13.9,6.92,6.92,0,0,0,0,2,1.39,1.39,0,0,1,.47-.18c.33,0,.62.2.93.18s.22-.11.23-.38a2,2,0,0,1,.47-1.14c-.33-.4-.69.39-1.07.08-.16-.12,0-.89,0-1,0-.4-.08-.79-.06-1.19"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1072.15,744a26,26,0,0,1-3,.79c-.53.09-1.07.12-1.6.26a3.79,3.79,0,0,0-1.72.77c-.41.43-.15.84.11,1.29s2,3,.28,2.37a23.76,23.76,0,0,1-3.79-1.94,5.73,5.73,0,0,0-3.44-.68,5.9,5.9,0,0,1-4.13-.87,2.32,2.32,0,0,0-2-.71,8.91,8.91,0,0,0-2.09.86,5.79,5.79,0,0,1,1.13-1.49c.31-.26.67-.45,1-.71a3.55,3.55,0,0,1,2.8-1.15c.69.05,2.44,0,2.23-1.16s-2.14-1.28-3-1.3c.27,0,1.44-.29,1.53-.6l-.39-.29c.61-.7,1.75-.49,2.53-.3.53.13,1.06.23,1.57.4s.7.37,1.06.5.11.09.43-.09c.59-.33,1-.21,1.57.15a11.48,11.48,0,0,1,2.48,1.88,6.35,6.35,0,0,0,1.38,1,5.31,5.31,0,0,0,1.63.32c.43.06.84,0,1.26,0a8.79,8.79,0,0,1,.92.15c.51.07,1,0,1.55,0-.24.87-1.47,1.46-2.24,1.81a3.15,3.15,0,0,1-1.34.18c-.82.05-1.29.28-2.08-.15"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1096.85,718l.15,0a7.79,7.79,0,0,0-2.6-1.67c-1.16-.58-1.31-1.73-1.69-2.85a3.66,3.66,0,0,0-2.48-2.58,11,11,0,0,0-3.19-.38c-.66,0-1.54.13-.72.77s1.91,1.26,1.57,2.4-1.45,1.34-2.4,1.48a10.34,10.34,0,0,0-3.86,1.67,2.23,2.23,0,0,0,1.08-.2,3.76,3.76,0,0,1,.85-.11,5,5,0,0,1,1.25-.06c.24,0,.47,0,.7,0a2.38,2.38,0,0,1,1.26.55c.27.24.38.58.7.78a11.3,11.3,0,0,0,1.34.46,3.57,3.57,0,0,0,1.52.26.67.67,0,0,0-.2.4c0,.5.74.16,1.1.32.7.31,1,1.13,1.41,1.7a11.08,11.08,0,0,1-.56-1.44c-.14-.38-.28-.6-.19-.94a.58.58,0,0,1,.07-.15,7.66,7.66,0,0,1,1,.09c.5.08.74.42,1.31.31s.86-.44,1.27-.44a1.12,1.12,0,0,1,.43.1,3.05,3.05,0,0,0,1.06-.13Z"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1155,727.39a8.81,8.81,0,0,1-3.16-.05,3.1,3.1,0,0,0-1.59,0c-.59.19-1.17.36-1.77.52s-1.34.48-2,.64a12.76,12.76,0,0,1-1.81.21,21.86,21.86,0,0,1-3.4.25,6,6,0,0,1-2.22-.63,14.23,14.23,0,0,0-2.37-1.17,6.48,6.48,0,0,0-1.06-.11,9.47,9.47,0,0,0-1.44-.16,5.83,5.83,0,0,0-2.61.71c1.68-1.27,3.15-2.48,5.29-2.86a7.69,7.69,0,0,0,2.8-.65,1.82,1.82,0,0,0,.63-1.79,6.32,6.32,0,0,0-.79-2.6A10.46,10.46,0,0,1,1138,717a4.1,4.1,0,0,1,.92-2.87,18.73,18.73,0,0,0,1.6-3.37c.6-.12.78,1.84.84,2.29.35.15.46-.52.91-.2s.12,1.11.3,1.59c.46,0,.61-.16,1,.25s.33.92.5,1.42c1.18.43.91,1.25.95,2.3s.88,1.62,1,2.58-.18,1.65.3,2.39a5.4,5.4,0,0,0,1.29,1.42,4.74,4.74,0,0,0,2.05,1.08c.84.06,1.6-.49,2.48-.4a6.09,6.09,0,0,1,2.41.73c.85.54-.47.52-.84.5"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1158.52,715.62c-.71.08-1.18-.29-1.86-.35a8.73,8.73,0,0,1-2-.07,4.58,4.58,0,0,0-.75-.28,4.63,4.63,0,0,0-.68,0,8,8,0,0,0-2,.13c-.65.17-1.29.24-1.95.36s-1.48.38-2.22.49a11.16,11.16,0,0,1-1.79,0h-1.86a6.46,6.46,0,0,1-1.46-.24,3.4,3.4,0,0,1-1.26-.33,7.17,7.17,0,0,0-1.68-1.12,8.21,8.21,0,0,0-3.6-.41c-.64.09-1.26.05-1.9.16a4.94,4.94,0,0,0-1.35.18c1.79-.71,3.58-1.23,5.41-1.77,1.1-.33,1.28-1,1.79-2a13.22,13.22,0,0,1,2.92-3.05c1.06-1,.89-2.07.28-3.3a9.44,9.44,0,0,1-1.33-4,26.08,26.08,0,0,1,.33-4.55,18.31,18.31,0,0,1,1.93-5.3c.45-.91.91-1.82,1.4-2.71.21-.37.62-1.34,1.12-1.42.8-.12,1.38,2.42,1.55,3a7.38,7.38,0,0,1,.21,3.69c.86-.11.82,1.17.84,1.72a12,12,0,0,1-.68,2.63c1.35.49-.31,2,0,2.72.07.16.36.21.48.33a1.27,1.27,0,0,1,.3.68c.06.28.19,1.35-.06,1.5.28,1.08.69,2.13.92,3.22a16,16,0,0,1,.12,3.18,4.48,4.48,0,0,0,.8,2.18,4.68,4.68,0,0,0,1.85,2,6.67,6.67,0,0,0,2.49.23c1.1.1,2.09.73,3.22.6.78-.09,2-.87,2.48.12.65,1.32-.89,1.91-1.88,2.08a.43.43,0,0,1,.13.29c-.79.14-1.25-.94-2-.83"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1197.5,702.35a11,11,0,0,0-2.71.59,21.66,21.66,0,0,1-2.27,1,7.53,7.53,0,0,0-1.14.41,7.51,7.51,0,0,1-1.68.37,12.2,12.2,0,0,1-1.77.35c-.49,0-1-.12-1.5-.15a12.8,12.8,0,0,1-2.77-.61,6,6,0,0,0-2.09-.6c-.84,0-1.69,0-2.53,0a6.8,6.8,0,0,0-2,.13,22.67,22.67,0,0,1,2.54-1.47,11.47,11.47,0,0,1,2.73-.74c.6-.11,1.71-.19,2-.82s-.18-1.24-.47-1.67c-.86-1.28-2.1-1.28-3.41-1.84-.61-.26-1.4-.68-1.55-1.39s.42-1.27.83-1.73a.41.41,0,0,0,.26.08c.08-.55.33-.72.88-.8a1.76,1.76,0,0,1,1.4.21c.41.35.72.91,1.13,1.28s.32.54.77.51c.18,0,.37-.15.54-.16.32,0,.47.17.71.39a4.45,4.45,0,0,0,1.12.88c.33.13.73,0,1.05.23s.3.46.5.52.6-.22.89-.17.5.34.67.6a1.64,1.64,0,0,1,.3.86c0,.18-.12.5,0,.67s.87.46,1.15.52a2.46,2.46,0,0,1,.59.09c.26.13.36.46.59.62a3,3,0,0,0,1.22.34,2.29,2.29,0,0,1,.76.22,1.71,1.71,0,0,0,.69.26l1.87.21a8.08,8.08,0,0,1,1.85.18c.29.11.54.06.48.42s-.39.47-.58.54a2,2,0,0,0-.21-.32"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1137.51,769.22c-1.57-.86-2.93-2-4.79-2a5.55,5.55,0,0,1-3.54-.81,4.82,4.82,0,0,0-2-.79c-.77-.18-1.25.41-2,.43a12.27,12.27,0,0,0-1.94.38c-.72.13-1.35.51-2.07.67a24.29,24.29,0,0,1,2.39.58c.5.17.72.37.63.91-.12.75-1.17,2.37.17,2.51.44,0,.93-.09,1.33.18s.49.81.71,1.21c.56,1,1.57.94,2.56.66a24.35,24.35,0,0,1,3.27-.74,10.31,10.31,0,0,1,4.05.4c.16,0,.86.28,1,0s-.45-.56-.57-.66a15.32,15.32,0,0,1-1.95-1.9c.15-.4.21-1,.72-1.15.28-.06.36.1.59.19s.52.07.76.17.58.69.83.17-.47-1-.84-1.07"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1161.71,770.9c-.76-.29-1.58-.46-2.32-.79a5,5,0,0,1-1.2-.89,6.67,6.67,0,0,0-2.36-1.33,10.63,10.63,0,0,0-3.46-.37c-.86,0-1.71.41-2.58.38.22.44.79.57,1.18.81a3.87,3.87,0,0,0,1.17.44c.37.11.76.3,1.12.45s.46.31.62.69a3.23,3.23,0,0,0,3,2.47c.33-.16,0-.64,0-1,.3.3.67.89,1.17.6s.07-1.14.4-1.6,1.14,0,1.57.05c.88.21,1.25-.2,1.88-.79"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1167.76,771.07c-.82-.2-1.64-.22-2.45-.43a2.69,2.69,0,0,0-.82-.07c-.43,0-.38.08-.69.33a4.39,4.39,0,0,1-.94.48c-.14.06-.28,0-.42.13s-.21.31-.37.38-.43-.05-.67.16a1.46,1.46,0,0,0-.33.68c.34.14.69-.29,1-.33a2.81,2.81,0,0,1,.82,0c.14,0,.19.21.32.28s.34,0,.55.05a5.08,5.08,0,0,1,.64.18,1.88,1.88,0,0,1,.79.45c.26.24.36.24.73.22a2.53,2.53,0,0,1,.57,0c.23.05.27.2.45.29a2.58,2.58,0,0,0,1,.06,2.42,2.42,0,0,0,.5,0c.24,0,.31-.25.51-.32a5,5,0,0,1,1.19,0c.18,0,.2.07.39-.06s.14-.33.27-.43.73-.13,1-.2a3.21,3.21,0,0,1,.85-.13,1.41,1.41,0,0,1,.85.22,2.09,2.09,0,0,0,1,.1c.33,0,.56-.21.89-.2a2.85,2.85,0,0,0-1.06-1.31c-.35-.22-1.07-.33-1.32-.66s0-1.19,0-1.6a22.63,22.63,0,0,0-.31-2.74c-.07-.52,0-.57.49-.49a5.75,5.75,0,0,0,1.87,0c1.07-.17,2.45-.2,3.08-1.21a4.46,4.46,0,0,0-1.24,0h-1.78c-1,0-2-.17-3-.17h-1.53a11.93,11.93,0,0,0-1.79,0,12.56,12.56,0,0,0-1.54.68c-.43.13-1.63.06-1.7-.49"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1179.35,790.56a3.63,3.63,0,0,1-1.27-.16,2.14,2.14,0,0,0-1-.2,2.75,2.75,0,0,0-1.26.57,2.45,2.45,0,0,1-1.37.78,7.36,7.36,0,0,1-1.73-.15,2.52,2.52,0,0,1-1.3-.48,2.33,2.33,0,0,0-1.1-.38,3.9,3.9,0,0,1-.66,0c-.2,0-.37-.15-.58-.18-.49-.07-.93.23-1.42.17a5.06,5.06,0,0,1,2.52-.93c.62,0,1.38.08,1.78-.51.7-1-.19-2.68-.47-3.71a5,5,0,0,0,1.5-.53,3.46,3.46,0,0,1,1.67-.17c.62,0,1.25,0,1.88,0a1.59,1.59,0,0,0,.77-.15,2.73,2.73,0,0,1,.66-.34,2.19,2.19,0,0,1-.71.93c-.26.21-.64.21-.87.44.55.66,0,1.39-.14,2.15a4.77,4.77,0,0,0-.06,2c.26,0,.38-.25.61-.29a1.23,1.23,0,0,1,.81.23,2.48,2.48,0,0,0,.91.42,3.57,3.57,0,0,0,1-.18"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1183,802.15c-.55-.22-.89-.47-1.48-.23-.32.13-.65.23-1,.38a3.79,3.79,0,0,0-1.61,1.47,3,3,0,0,1,1.32-.56c.43-.1.52,0,.88.2.2.09.41.06.6.16s.37.43.61.57a2.73,2.73,0,0,0,1.29.36,11.24,11.24,0,0,0,1.88-.08,12,12,0,0,1,1.51-.26,5.32,5.32,0,0,1,.71,0c.37,0,.48.18.8.29s.4,0,.6.07.23.2.38.25c.76-1-1.26-1.28-1.88-1.48-1.14-.37-.88-1.31-1.13-2.28-.13-.51-.6-1.16-.44-1.71s.89-.57,1.37-.54c.72,0,1.51.3,1.89-.48-.31-.28-.25-.57-.41-.91-.26-.55-1-.53-1.52-.58-1.34-.13-3.1-1-4.28,0s.64,2.09.59,3.24a2.82,2.82,0,0,1-1.53,2.5l0-.11"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1202.87,825c-.42-.09-.79-.27-1.2-.36s-.62-.47-1-.63a5,5,0,0,0-1-.37,8.08,8.08,0,0,0-1,0,3.59,3.59,0,0,1-1.77-.17c-.52-.3-.56-.07-1,.15s-.6.09-.72.63c.46,0,1.19.08,1.32.64s-.34,1.14-.44,1.64c-.13.71.26,4.92,1.37,4.33a4.56,4.56,0,0,0,.88-.83c.36-.35.74-.69,1.07-1.07a2.66,2.66,0,0,0,.73-2.28c-.06-.55-.26-1.09.34-1.37s1.29-.4,1.9-.72a.31.31,0,0,0-.29-.11"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1203.88,827.36a5.34,5.34,0,0,1-1.87-.21c-.59-.2-1.18-.25-1.76-.43-.41-.13-.79-.33-1.18-.47a4.81,4.81,0,0,0-1-.07c-.74-.1-1.47-.33-2.21-.38a4,4,0,0,0-1,.21c-.33.06-.66-.05-1,0,0,.52.78.5,1.09.81s.23.83.13,1.31c-.27,1.3.41,2.34.81,3.54.48-.84,1.37-1,2.12-1.48a2.51,2.51,0,0,0,.9-1.14,6,6,0,0,1,.4-1.1c.36-.5,1-.34,1.5-.3a1.81,1.81,0,0,0,1.28-.58c-.2.26-.9-.09-.86-.41"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1205.9,844.66a1.19,1.19,0,0,0-1-.49,3.69,3.69,0,0,0-.71-.13,6.43,6.43,0,0,0-1.09.3,9.4,9.4,0,0,1-1.23.16,2.53,2.53,0,0,1-1.75-.13,6.27,6.27,0,0,0-1.24-.75c.06-.25.77-.63,1-.73s.84.08,1.12-.13c.43-.32.22-1.79,0-2.17.54-.45.74-.86,1.44-.31a2.67,2.67,0,0,1,1,1.32,2.37,2.37,0,0,1,0,1.08c-.05.25-.27.55,0,.76a2.26,2.26,0,0,0,.4.08,1.32,1.32,0,0,1,.6.34,4,4,0,0,1,.92,1.28l-.16-.13"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1215.64,855.75a15.62,15.62,0,0,0-2,0,15.35,15.35,0,0,0-1.66.34c-.31,0-.59.17-.9.18s-.73,0-1.1,0a6.67,6.67,0,0,1-2.1-.43c-.4-.12-.69-.44-1.1-.54s-.38,0-.64-.26a2.83,2.83,0,0,0-1.51-.61,7.61,7.61,0,0,0-1.8-.18c-.56,0-1,.39-1.6.32a3.56,3.56,0,0,1,1.94-1.66,6.55,6.55,0,0,1,2.44.1,1.65,1.65,0,0,0,1.75-1.11,3.29,3.29,0,0,0-.17-2.49,4,4,0,0,1-.28-2.25,5.11,5.11,0,0,1,1.33-2.28,10.35,10.35,0,0,1,3.11-1.75,27.14,27.14,0,0,0,2.82-1.55,4.59,4.59,0,0,1-1,2.6c-.43.54-.85.93-.67,1.66a3.2,3.2,0,0,1,0,2.06,10.84,10.84,0,0,0-.73,2.29,12.72,12.72,0,0,0-.26,2.23c0,.59.57.44,1,.53.78.15,1.1.65,1.52,1.25s1,.66,1.72.57"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1218.5,862a3,3,0,0,0-2.61-1.78,6.41,6.41,0,0,0-2.58-.32c-1.07.29-1.14,1.64-1.41,2.53s-.9,2.1-.11,3c.35-.39.31-1,.58-1.45.11-.17.37-.58.55-.63a2.54,2.54,0,0,1,0,.69c0,.38.11.75.16,1.12a2.39,2.39,0,0,0,.39,1.18,6,6,0,0,0,.88-2.1c.1-.41.28-.79.38-1.18a5.67,5.67,0,0,1,.1-.64c.14-.36.69-.83,1.08-.77a6.28,6.28,0,0,0,.84.19,6.17,6.17,0,0,0,.83,0h-.11"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1216.15,859.28a2.38,2.38,0,0,0-1.5-.33,3.18,3.18,0,0,1-.86.19c-.54,0-1.07,0-1.61-.05-1-.11-2.8-.39-2.4-1.78.23.26.76.61,1,.35s.16-.52.21-.73a1,1,0,0,1,.47-.69,5.83,5.83,0,0,1,1.93-.7c.5-.15,1.36-.46,1.6-1,.65.18.09,1.42-.23,1.65a1,1,0,0,0-.54.85c-.05.27-.28.7-.06.94.36-.16,1.89.2,1.79.74"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1203.88,840.46a3.79,3.79,0,0,0-2.25-.26,1.34,1.34,0,0,0-1.17.69c-.83,1.21-2.19,1.92-2.5,3.45a3.31,3.31,0,0,1-.31-1.56c.05-.58.36-1,.34-1.55a1.55,1.55,0,0,1,.42-1.19,1.59,1.59,0,0,1,.51-.43c.21-.07.35.1.54-.11-.63,0-1,.43-1.62.53-.83.14-.29-.74.09-1a4.59,4.59,0,0,1,2.45-.43,3.3,3.3,0,0,1,2,1.16"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1201.19,834.75a2.18,2.18,0,0,0-.93-.56l-.52.07a4.5,4.5,0,0,1-.71-.24c-.61-.17-1.08.2-1.69.22a3.47,3.47,0,0,1-1.55-.32,2.1,2.1,0,0,0-1-.35,2,2,0,0,0-.82,0c-.09.32-.49,1.2-.28,1.46s1.08.48,1.31.22c.35.17,1,.29.65.67-.1.11-.24.06-.33.21a1.09,1.09,0,0,0,0,.52c0,.47.34.79.39,1.24.55-.41,1-.88,1.62-1.26a5.38,5.38,0,0,0,1.21-.72,2.46,2.46,0,0,0,.23-.51c.24-.47.32-.52.82-.52s1.14.34,1.61,0a1.09,1.09,0,0,1-.18-.43l-.13,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1197,836.1a7.32,7.32,0,0,0-1.33.66,4.24,4.24,0,0,0-.82,1.44c-.41.83-1.31,1.53-.71,2.52.29-.07.7-1,.85-1.18a2,2,0,0,1,.8-.75c.08.45.05,2.12.75,2,.42-.07.82-1.08,1-1.4s.5-.79.74-1.2c.82-1.43,2.93.2,4-1.06a9.43,9.43,0,0,1-1.25-.22c-.52-.2-1-.59-1.5-.85a1.85,1.85,0,0,0-1.43-.22,3.36,3.36,0,0,0-1.06.24c-.27.16-.41.4-.72.52"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1194.3,834.42a1.05,1.05,0,0,1-.66.15,2.82,2.82,0,0,0-.59-.75c-.24-.27-.42-.22-.74-.39-.49-.25-1.74-.77-2.09,0,.22.32.59.37.93.51a2.36,2.36,0,0,1,.92.62,2.34,2.34,0,0,1,.75,1.39c.24.07.56-.52.73-.67a2.35,2.35,0,0,1,1.42-.67"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1195,836.1a2.26,2.26,0,0,0-1.34.58c-.37.26-.89.91-1.33.94-.18-1-.61-2.22-1.6-2.61s-1.58.26-2.43.23c.91.35,2.59.73,3.07,1.7.3.61,0,1.77.8,2.12.33.15.47-.07.67-.33a4.5,4.5,0,0,1,1.28-1.19c.31-.19,1.49-.53,1.61-.89s-.6-.59-.9-.71"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1205.9,809.38a2.81,2.81,0,0,0-.88-.52,1.64,1.64,0,0,0-.32.2,4,4,0,0,1-1.15.17c-.3,0-.57-.05-.87,0a4,4,0,0,1-.72.1,4.34,4.34,0,0,1-.56-.14.41.41,0,0,0-.19-.14l-.4,0a3.44,3.44,0,0,1-1.42-.47,2.48,2.48,0,0,1,1.48-.73,3.54,3.54,0,0,0,.61,0,3.75,3.75,0,0,1,.52-.15c.42-.69-.23-2.32.71-2.66a5.62,5.62,0,0,1,1.37,0,3.8,3.8,0,0,0,1.46-.49c.3.78-.95,1.09-1.32,1.47s-.17.61-.17.94-.28.94-.15,1.26a1.36,1.36,0,0,1,.69.17c.48.28.5.58,1.13.52"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1215.14,810.22a7.68,7.68,0,0,0-1.69-.79,5.56,5.56,0,0,0-1,0,7.24,7.24,0,0,1-1.11-.16,4.27,4.27,0,0,0-.54-.17c-.29,0-.51.13-.8.08-.64-.1-1.57.09-2-.51a2.19,2.19,0,0,0-1.72-1.1c.55,0,1.06-.28,1.63-.15.29.07,1,.08,1.19.26,0-.47.08-1,.54-1.27a4.77,4.77,0,0,1,.93-.24c.67-.19,1.58-1.56,1.82-.27.15.83-.64,2.36.26,2.9.45.27,1,.27,1.5.54a2.79,2.79,0,0,1,.82.92"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1217,818.45a2.41,2.41,0,0,1-1.7-.55,9.32,9.32,0,0,0-.89-.79,2,2,0,0,0-2.23.35c2.23-.08,1.05,2.27,1.8,3.55.16.28.53.8.91.78s.43-.57.45-.88c.09-1.24.13-2,1.49-2.28"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1218.33,832.06a12,12,0,0,1-1.29.86,1.26,1.26,0,0,1-1,0,6.63,6.63,0,0,0-1.28,0,2.71,2.71,0,0,1-1-.46,12.67,12.67,0,0,1-1.92-.84,1,1,0,0,0-.8-.23,1.2,1.2,0,0,0-.82.15c-.25-.48.86-1.13,1.23-1.17a4.35,4.35,0,0,1,.94,0c.28,0,.32.25.63,0-.18-.43-.6-.82-.64-1.31s.17-.56.34-.86.22-1,.62-1.11c.08.43.14.87.23,1.29a2.42,2.42,0,0,1,1.93-.66c.89,0,1.29.09,1.26,1.09a6.91,6.91,0,0,0,1.12-.52c.15.35.36.47.38.88a3.58,3.58,0,0,1-.37,1.58c-.07.18-.3.76-.14.95s1,0,1.32.08a.67.67,0,0,1-.38.6,4.39,4.39,0,0,1-1.44.37v-.15"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1219.68,836.43a13.41,13.41,0,0,1-1.13-4.23,6.34,6.34,0,0,1,1.45-4.07c.26-.32,1-1.26,1.51-.94s.23,1.73.15,2.23c-.21,1.29-1.14,3.58.08,4.62.47-.9,1.28-1.1,2.18-1.42s1.5-.68,2.3-.93c.31-.1,2.73-.86,2.17,0s-2,1.16-2.7,2.09a9.8,9.8,0,0,0-.91,2c-.19.46-.55,1.28.11,1.46.32.09.67,0,1,.15s.5.39.69.4.37-.24.52-.38a5.4,5.4,0,0,1,1.33-.77,2.05,2.05,0,0,0-.36.8,2.65,2.65,0,0,1-.17.34c0,.4.41.16.69.24a1.84,1.84,0,0,1,1,.9,3.1,3.1,0,0,1,.34,1.43c-.74-.29-1.09-1.06-1.93-1.2a7.63,7.63,0,0,0-2.44.37,4.65,4.65,0,0,1-1.31-.19,9.52,9.52,0,0,0-2.38-.08,6.26,6.26,0,0,1-2.38-.32A4.27,4.27,0,0,1,1218,838a1.22,1.22,0,0,0-.66-.23,4.83,4.83,0,0,1-.66-.3,2.41,2.41,0,0,0-1.51.07c.08-.56,1.06-.87,1.51-1.07a5.16,5.16,0,0,0,.9-.35c.54-.39.58-.61,1.39-.49.37.05,1.57.72,1.4-.2"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1230.93,865c-.27.13-.54.25-.82.36a1,1,0,0,0,.29,0,3.41,3.41,0,0,0,0-1.44c-.06-.29-.25-.57-.14-.87a6.06,6.06,0,0,1,2.53-.45,3.41,3.41,0,0,0,2.1-.54,1.51,1.51,0,0,1-1,1.63c-.5.15-1,.18-1.09.78-.07.33-.14,1.25.21,1.4a2.51,2.51,0,0,0,1.45-.11c0,.6-.59,1-1.07,1.12a1.81,1.81,0,0,1-.78.19c-.24,0-.44-.29-.67-.37a5,5,0,0,0-1.83-.14,3.21,3.21,0,0,0-1.5.15c-.1-.38.28-.75.57-.93l.71-.21a4,4,0,0,0,1.22-.71"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1229.93,852.06a1.68,1.68,0,0,0-1.44-.71c-.28,0-1.23.28-1.35.58s.39.74.5,1,0,.9.24,1,1.1-.28,1.38-.3a3.06,3.06,0,0,1,1.33.29,4.3,4.3,0,0,1,2.36,2.26c.54-.69-1.1-1.66-.3-2.46.37-.38.57,0,1,0a.91.91,0,0,0,.78-.8c-.39-.17-.69.27-1.06.17s-.79-.86-1.28-1.09a6.66,6.66,0,0,0-1.18-.25,8.09,8.09,0,0,0-2,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1226.73,848.7a8,8,0,0,0-2-1.72,2.47,2.47,0,0,1-1.31-1.34,12.66,12.66,0,0,1-.34-1.23c-.05-.21-.12-.78-.33-.9-.48-.29-.65,1.07-.71,1.35s0,1.75-.41,2-1.1-.31-1.41-.37-1.41-.19-1.53.3,1,.72,1.31.81c-.19.35-1.1.59-1.48.68a5.85,5.85,0,0,1-1.92.11c.24.39,1,.45,1.39.54a13,13,0,0,0,1.38.19,11.45,11.45,0,0,0,1.46.16c.48,0,1-.16,1.43-.19a3.59,3.59,0,0,1,1.6,0,1.71,1.71,0,0,1,1.22,1.52c.35-.09.48-.35.71-.6s.78-.57,1.08-1,.25-.37.1-.56a5.7,5.7,0,0,0-.72-.35,17.7,17.7,0,0,1-1.53-1.22"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1214,842.48a4.94,4.94,0,0,1-1.91-.06,1.45,1.45,0,0,0-1.33.07c-.5.37-.28.82.22,1a17.41,17.41,0,0,0,1.93.26c.45.13.67.51,1,.8a3.64,3.64,0,0,0,1.62.7c1.22.26,2.41.41,3.28-.64-.67,0-1.33.05-1.63-.64a7.71,7.71,0,0,1-.43-2.57,3.33,3.33,0,0,1,.94-2.27c.35-.45.87-1.09-.06-1a6,6,0,0,0-2.3.65c-.69.47-.58,1.51-.61,2.24,0,.93-.25,1.75-1.27,2a3.75,3.75,0,0,1-2.49-.44"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1220,838.78a6.71,6.71,0,0,0-.8,2.45c0,.28.21.75.16,1s-.27.25-.53.1a4.09,4.09,0,0,0-.83-.33c.19.61.84.82,1.39,1,.4.11.29.16.48.5s.63.36,1.08.29a3,3,0,0,1,1.17,0c.22,0,.81.35,1,.23.41-.22-.13-1.14.19-1.58-.08.23-.9.15-1.09,0a3,3,0,0,1-.9-1.28c-.13-.52.06-1-.36-1.42s-1-1-1.49-.43"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1225.22,841.14a15.86,15.86,0,0,0-1.62-.66c-.39-.17-.49-.35-1-.36s-1.21,0-.94.66c.6.08.78-.1,1,.48a4.34,4.34,0,0,0,.46.81,1.42,1.42,0,0,0,.9.63,5,5,0,0,0,.82.16,9,9,0,0,0,.93.29c-.18-.45-.52-.73,0-1.09.25-.19.72-.28.61-.72a12.22,12.22,0,0,1-1.65-.1c-.62-.2-.91-.72-1.39-1.1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1232.28,844.16c0-.57,0-.85-.5-1-.17-.05-.23,0-.4-.12s-.17-.24-.27-.33-.41-.29-.6-.48c-.36-.36-.73-.6-1.1-.92a1.44,1.44,0,0,1-.53-1.14c-.58.43-.19,1.57.06,2.09.14.28.38.45.53.73s0,.53.13.81a6.77,6.77,0,0,0,1.89-.15c.38,0,1.36.25,1.39-.3l-.1,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1245.22,856.09a4,4,0,0,0,.49-2.25c-.26-.66-1-.58-1.45-1,.73-.34,1.83-.64,2.46,0s.31,1.34.38,2a2.15,2.15,0,0,1,1-.24c.3,0,.83.17.48.52s-1.29.17-1.77.35c-.8.3-1.23.69-1.92-.11"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1250.93,847.19a16.39,16.39,0,0,0-2-.33c-.43,0-.78-.16-1.2-.18a8.64,8.64,0,0,1-1.2-.14c-.35-.07-.68-.28-1-.35-.14,0-.3,0-.43,0s-.21-.18-.33-.24a5,5,0,0,0-.5-.12c-.13-.05-.2-.15-.33-.21a8,8,0,0,0-1-.39c-.41-.13-.61.13-1,.1a3.12,3.12,0,0,1,1.9-1.09c.43,0,1.15.28,1.53,0s.27-1,.26-1.43a2.34,2.34,0,0,1,.31-1.45c.37-.57.84-1.09,1.25-1.63.27-.36.83-1.32,1.4-1.16s.61,1.25.57,1.72a3.24,3.24,0,0,1-.86,1.88c.17.46.6.64.52,1.22s-.5.94-.56,1.48.48.57.77.93.78,1,1.43.86"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1251.94,856.09a5.35,5.35,0,0,0,.1-2.06c-.09-.55-.27-1.22.12-1.7a5.8,5.8,0,0,1,.78-.59c.31-.26.62-.52.94-.77.54-.41.92-.94,1.44-1.38.05.18.24.22.3.42s-.23.79-.36,1c-.37.68-1.07,1.19-1.24,2a.27.27,0,0,0,.24.09,12.93,12.93,0,0,0,.09,1.33,2.44,2.44,0,0,1,0,.86c0,.29-.14.26.13.42.1.06.29.09.4.14s.33.2.52.27c.38.15.91.46,1.32.35-.48.61-.81.63-1.55.64s-1.36-.2-2.06-.16a5.24,5.24,0,0,1-2.25-.2c-.41-.21-.47-.43,0-.63a7.88,7.88,0,0,0,1.6-.69"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1241.69,854.58a5.44,5.44,0,0,1,2.5,1.61c.32.43.45.87,1,.93a6.56,6.56,0,0,1,1.6.09,4.64,4.64,0,0,0,3.38.52c.38,0,.53,0,.62.36a7,7,0,0,1,0,1.95,5,5,0,0,1-.54,1.87c.16-1.11-1.08-1.92-1.88-2.49-.55-.4-2-1.05-2.27,0-.05.22-.22,1.45-.76,1-.14-.11-.21-.59-.28-.76s-.21-.42-.32-.62a2.09,2.09,0,0,0-2.62-1c.29-.37,1-.74,1-1.28,0-.32-.41-.63-.54-.93a2.8,2.8,0,0,1-.16-1.43"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1266.05,859.12a6.11,6.11,0,0,0-2-.65,3.82,3.82,0,0,1-.92-.22c-.44-.16-1.6-.67-2-.45a13.18,13.18,0,0,1,1.42.86c.25.15.49.17.26.48s-1.81.72-1.39,1.48c.61-.34.86-.41,1.39,0s1,.29,1.61.31a3.24,3.24,0,0,1,1.89.61c.26.17.43.24.53.55a6.16,6.16,0,0,0,.22.94c.13.27.27.56.41.82s.4.39.7.58.48.5.77.71.49.23.72.39a11.16,11.16,0,0,0,1.61,1.14,3.91,3.91,0,0,0,1.88.11c-.24-.43-.7-.72-1-1.14a6,6,0,0,1-.89-1.49,10,10,0,0,0-1.18-2.75c-.56-.81-1.44-1-2.22-1.52a8.51,8.51,0,0,0-3.47-1.63"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1272.1,859.28c-1-.21-2.46-.48-3.15.47.91.25,1.66.48,2,1.42.16.38.51,2.35,1.21,2.1s0-2,.87-2.18c.72-.12,1.25,1,1.86.07-.22-.07-.43,0-.65,0a2.54,2.54,0,0,1-.8-.39c-.35-.26-.46-.61-.89-.76a10.3,10.3,0,0,1-1.84-.53"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1259.67,847.19a13.6,13.6,0,0,0-1.49,2c-.15.25-.45.75-.75.84-.86.27-.2-1.59-.24-2s-.27-.79-.33-1.2,0-.89-.39-1.17a5.11,5.11,0,0,0-1.54-.46,1.24,1.24,0,0,0-1.07.09c.35-.23.68-.52,1-.72a2.86,2.86,0,0,1,1.38-.25c.82,0,1.17.37,1.41,1.15.55.09,1.12-.06,1.67,0a2.18,2.18,0,0,1,.81.44,4.61,4.61,0,0,0,.75.28,6.15,6.15,0,0,1,1.86,1c.35.34.87.63,1.09,1.07-.23.13-.52.56-.79.54a2.33,2.33,0,0,0-1.1-1c-.43-.16-.87-.3-1.29-.46s-.63-.36-1-.26-.34.77-.41,1.17l-.14,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1270.42,855.42a16,16,0,0,0-1.66.28,5.8,5.8,0,0,0-.55-1.72c-.18-.33-.68-1-1.12-1s-.07.87,0,1.15.27.86.41,1.29c-.44.11-.74,0-.94.49a2,2,0,0,1,1.44,1c.19.4.42,1.62,1.06,1.58s0-1.11.24-1.48c.37-.59,1.22-.19,1-1.17l-.14,0"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1273.11,851.22a3,3,0,0,0-1.67-1c-.21,0-.45.09-.64,0s-.2-.66-.33-.92a5.33,5.33,0,0,0-1.22-1.64,2.05,2.05,0,0,0-1.46-.6c.7.26,1.24,1.8,1.3,2.47a9.85,9.85,0,0,1,0,1.07c0,.21-.21.63-.15.77a2.65,2.65,0,0,1,.81-.11,4.21,4.21,0,0,1,1.12-.17,4.5,4.5,0,0,1,1,.17c.27.1.35.53.68.5.26-.67-.62-.77-.65-1.33"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1257.48,826.18c-.2-.74-.61-.77-1.17-1.15a6,6,0,0,0-1.65-.53,1.74,1.74,0,0,0-.71-.17c-.23,0-.43.17-.67.19a1.39,1.39,0,0,1-.88-.17l-.88-.54a2.75,2.75,0,0,0-1.15-.31c-.21,0-.39-.11-.6-.14s-.63.17-1,.25c-.53.13-.91,0-1.36.4,1,0,2.22.13,2.82,1,.74,1.08-.79,2.29-1.35,3.08-1,1.41-.81,2.86-.71,4.49a25,25,0,0,1,0,2.63c0,.61-.21,1.66.22,2.18,1,1.26,2.81-2.21,3-2.83a6.09,6.09,0,0,0,.38-2.38,5.35,5.35,0,0,1,0-1.73c.18-.66,1.44-3.09,2.38-2.6.51.27.46,1.43.48,1.89s0,.91,0,1.36c0,.24,0,.85.13,1,.35.4.82-.88.93-1.12a10.75,10.75,0,0,1,1.43-2.63c1-1.12,2.37.2,3.29.81.52-.36.42-.83.07-1.27a1,1,0,0,0-.53-.41l-.73-.3a4.72,4.72,0,0,1-1.2-.81c-.26-.22-.93-.51-.84-.88"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1177.17,776.28a9.79,9.79,0,0,1-1.23-.17,11.84,11.84,0,0,0-1.22.17,6.35,6.35,0,0,0-1.55.49,15.65,15.65,0,0,0-2,.75,5.94,5.94,0,0,0-1.7,1.22,5.78,5.78,0,0,1,1.55-.11,4.35,4.35,0,0,1,1.22,0c.27.1.39.37.65.52l.76.41c.65.34,1.33.58,2,.92a4.85,4.85,0,0,0,2.68.08,11.84,11.84,0,0,0,2.27-.53,7.7,7.7,0,0,1,1.19-.25c.39-.09.63-.32,1-.33a7.06,7.06,0,0,1,2.45.31,4.18,4.18,0,0,1,1.53.66c.26-.66.53-1.4,0-2a8.31,8.31,0,0,0-2.65-1.35,3.68,3.68,0,0,1-2.06-1.91,7.65,7.65,0,0,1-.18-2.94c.05-1.29.09-2.58.05-3.87,0-.65.12-2.34-.53-2.79s-1.66,1-2,1.41a16,16,0,0,0-2.18,3.69c-.72,1.79-.23,3.72-.4,5.6"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1180.36,755.95a14.43,14.43,0,0,1-2.61.21c-.68,0-1.32-.24-2-.19-1.34.1-1.67,1.38-2.62,2,.79.13,1.56-.11,2.37,0a1.55,1.55,0,0,1,1.16.46c.25.35.1.8.21,1.19a7,7,0,0,0,1.27,2.15,7.78,7.78,0,0,0,1.25,1.25,11.71,11.71,0,0,1,.94,1.09,16.89,16.89,0,0,0-.1-2.55c-.05-.59-.28-1.74.29-2.17a2.19,2.19,0,0,1,1.36-.14,2.25,2.25,0,0,1,1.3.66c0-.64.41-.89,1-.79a1.78,1.78,0,0,0,.82.06,3.84,3.84,0,0,0,1.21-.4c-.65-.13-1.28-.32-1.93-.42-.35-.05-1.36.05-1.35-.44s.8-.88.7-1.49c-.07-.45-.78-.9-1.09-1.18a10.12,10.12,0,0,0-1.74-1.09c-.33-.19-.81-.75-1.21-.53-.56.31-.38,1.29-.33,1.78s-.16.61-.75.68"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1180.86,770.4c.9-.51,1.87-1,2.73-1.56a1.16,1.16,0,0,0,.59-1.13,5.18,5.18,0,0,1,0-1.76c.21-.55.69-.38,1.15-.21a10.49,10.49,0,0,1,3.24,1.84c.64.56,1.2,1,.54,1.83a5.41,5.41,0,0,0,1.47.34c.26.06.21-.11.33.15s0,.47,0,.66c.32.08.63.05,1,.14a3.8,3.8,0,0,0,.81.22c.51,0,.91.13,1.41.19-.12.54-1.05.48-1.46.47a11.43,11.43,0,0,1-2.41-.19,5,5,0,0,0-1.69-.17c-.33,0-.48.23-.76.44a1.82,1.82,0,0,1-.7.37,10.75,10.75,0,0,1-1.36.38,5.09,5.09,0,0,1-1.88-.22,12.67,12.67,0,0,0-1.67-.27,3.64,3.64,0,0,1-1.16-.28,2,2,0,0,0-.65-.25,2.77,2.77,0,0,1,.14-1.83"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1202.54,796.27a5.77,5.77,0,0,1-1.65-1.34,1.82,1.82,0,0,0-.36-.52,5.5,5.5,0,0,1-.61-.56,3.14,3.14,0,0,0-1.17-.76c-.39-.21-.51-.32-1-.15a5.86,5.86,0,0,1-.71.36c.33.47,1.57,2,.51,2.3-.5.14-1.06.07-1.58.2s-.79.22-.33.49a6.2,6.2,0,0,0,2.18.28c.56.06,1.11.17,1.66.21.8.06,2.07-.11,2.33-1"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1212.52,783.65a2,2,0,0,1-.67-.59,3.16,3.16,0,0,0-1.42-.83c-.5-.14-1-.21-1.52-.34-.29-.07-.59,0-.87-.12a4.4,4.4,0,0,0-.79-.29l-.84-.17a4.59,4.59,0,0,1-.87,0c-.16,0-.18-.11-.39-.1a4,4,0,0,0-.71.27c-.23.07-.48.07-.72.14a4.34,4.34,0,0,1-1.44.24c-.22,0-.53-.09-.64.16a6.83,6.83,0,0,0,1.4.6c.42.13.37.32.23.69a10.53,10.53,0,0,1-.7,1.55,16.47,16.47,0,0,0-1,1.49,11,11,0,0,0-.66,2.1,7.32,7.32,0,0,0,0,1.29,3.23,3.23,0,0,0,0,.6c0,.19.17.38.17.56s-.1.3-.17.49a2.66,2.66,0,0,0-.14,1c1.48-1,2.3-2.67,3.35-4.07.43-.57.86-1.14,1.26-1.74a3.22,3.22,0,0,1,1.23-1.28h0a20.18,20.18,0,0,1,1,2.63c.08.24.22.9.57.92s.55-.65.64-.89a9,9,0,0,0,.56-3.95l0-.08a5.39,5.39,0,0,1,1.47.14,5.23,5.23,0,0,0,2.29-.26C1212.92,783.63,1212.72,783.73,1212.52,783.65Z"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1214.47,784.35c-.81-1.82-1.54-4.06-.75-6a9.69,9.69,0,0,1,2.18-3.07,5.91,5.91,0,0,1,2.55-1.3c.22.46,0,1.21-.16,1.7-.15.71-.44,1.41-.62,2.11a8.63,8.63,0,0,0-.18,2.27,15.66,15.66,0,0,0,0,2.44c.5-.31.68-1,1.21-1.3a1.82,1.82,0,0,1,.94-.33c.18.58-.66,1.07-.88,1.52a9.44,9.44,0,0,0-.28,1.2,3.14,3.14,0,0,0-.32.93c.5.26.68.72,1.07,1.09a3.17,3.17,0,0,1,.54.5c-1.09-.05-1.9-.7-2.94-.89s-2.23.46-3.34,0c-.43-.18-.8-.47-1.23-.66-.27-.12-.92-.26-.94-.63s1-.82,1.34-.92a4,4,0,0,1,1.89-.12h-.11"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1207.91,800.14a8.4,8.4,0,0,0-1.84.29,8.61,8.61,0,0,0-1.28.33,7.37,7.37,0,0,0,3.68,1.13c.82,0,1.65-.1,2.47-.17.39,0,1.67.05,2-.26s-.11-.42-.4-.61a10.31,10.31,0,0,1-1.71-1.21,2,2,0,0,1-.45-2.69,2.74,2.74,0,0,1,1.1-.79c.3-.13.94-.2,1.18-.43s.07-.27-.23-.39a8.8,8.8,0,0,0-2-.46c-1.26-.18-2.38-.12-2.46,1.37a15.45,15.45,0,0,1-.36,3.56"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1217,801.82a3.45,3.45,0,0,0-2.68-.14,2.85,2.85,0,0,1-1.24.48c-.46,0-.82-.09-1.24.17,1-.11,1.88.62,2.72,1.05a16.46,16.46,0,0,0,1.53.86,9,9,0,0,0,2.09.57,6.74,6.74,0,0,0,2.46,0c.69-.19,1.11-.69,1.86-.7h2.28a4.14,4.14,0,0,1,1.45.33,2.66,2.66,0,0,0-1.4-1.21c-.62-.26-1.42-.23-2-.53-.36-.18-.24-.34-.12-.69a10.5,10.5,0,0,0,.32-1.43,7.22,7.22,0,0,1,.63-2.4c.43-.64,1.44-.38,2.09-.32-.84-.6-1.62-1.27-2.47-1.85a4.28,4.28,0,0,0-2.93-1.08,4.48,4.48,0,0,0-2.05,1,6,6,0,0,1-1.6,1.21c-.51.16-1-.08-1.51,0s-.79.49-1,1c.95-.32,2.42-.08,2.66,1.09.11.53-.19,1-.06,1.56,0,.18.2.43.07.6s-.64.13-.84.13"
                transform="translate(-783.42 -686.11)"
              />
              <path
                className="footflockanim  footer-flock-of-birds-cls-1"
                d="M1228.25,795.94c-.38-.59-.58-.87-1.32-.85a5.84,5.84,0,0,0-2.2.74,2.92,2.92,0,0,1-2,.08,2.72,2.72,0,0,1-1.23-.69,6.61,6.61,0,0,0,1.5-.7,3.29,3.29,0,0,0,.84-1.75c.13-.49.41-1.3-.18-1.6a4.07,4.07,0,0,0-2,0,5.76,5.76,0,0,0,1.08-.65,1.12,1.12,0,0,1,1-.32c.22,0,.51-.07.71,0a1.1,1.1,0,0,1,.44.78c.35-.23.67-.68,1.12-.7a4.62,4.62,0,0,1,1,.27,8.53,8.53,0,0,1,1.09.18,20.28,20.28,0,0,1,1.8,1c.54.25,1.25.21,1.67.66a2.24,2.24,0,0,0,.32-.21,2.71,2.71,0,0,1-1.81.43c-.63-.09-1.24-.62-1.88-.61-.91,0-.39,1.06-.32,1.62a2.27,2.27,0,0,1-.33,1.41c.38.09.55,0,.82.37"
                transform="translate(-783.42 -686.11)"
              />
            </g>
          </svg>
        </div>
      </Fragment>
    );
  }
}

export default FlockBirds;
