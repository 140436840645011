import React, { Fragment, Component } from "react";
import { JoomlaContent } from "./CMS/JoomlaContent";
import { CakePHPContent } from "./Framework/CakePHPContent";
import { CodeIgniterContent } from "./Framework/CodeIgniterContent";
import { LaravelContent } from "./Framework/LaravelContent";
import { YiiContent } from "./Framework/YiiContent";
import "./PHPFrameworks.css";

interface ITabMenuData {
  name: string;
  componentName: string;
  link: string;
  shown: Boolean;
}

type Props = {
  name: string;
  show: boolean;
};
type State = {
  phpData: ITabMenuData[];
};

class PHPFrameworks extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phpData: [
        {
          name: "Laravel",
          componentName: "<LaravelsContent />",
          link: "laravel",
          shown: false,
        },
        {
          name: "CakePHP",
          componentName: "<CakePHPContent />",
          link: "cakephp",
          shown: false,
        },
        {
          name: "Yii",
          componentName: "<YiiContent />",
          link: "yii",
          shown: false,
        },
        {
          name: "Codeigniter",
          componentName: "<CodeigniterContent />",
          link: "codeigniter",
          shown: false,
        },
      ],
    };
  }

  render() {
    const { phpData } = this.state;
    const { show } = this.props;
    return (
      <Fragment>
        <ul
          className={`nav nav-pills nav-justified flex flex-col md:flex-row flex-wrap list-none pl-0 mb-4 ${
            show ? "" : "hidden"
          }`}
          id="pills-tabJustify"
          role="tablist"
        >
          {phpData.map((anObjectMapped, index) => {
            return (
              <li
                className="nav-item flex-grow text-center my-2 md:mr-2"
                key={anObjectMapped.link}
                role="presentation"
              >
                <a
                  href={`#pills-${anObjectMapped.link}-tabJustify`}
                  className="
      nav-link
      w-full
      block
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      px-6
      py-3
      focus:outline-none focus:ring-0
    "
                  id={`#pills-${anObjectMapped.link}-tabJustify`}
                  data-bs-toggle="pill"
                  data-bs-target={`#pills-${anObjectMapped.link}Justify`}
                  role="tab"
                  aria-controls={`pills-${anObjectMapped.link}Justify`}
                  aria-selected="true"
                >
                  {anObjectMapped.name}
                </a>
              </li>
            );
          })}
        </ul>
        <div
          className={`tab-content ${show ? "" : "hidden"}`}
          id="pills-tabContentJustify"
        >
          <div
            className="tab-pane fade show active"
            id="pills-laravelJustify"
            role="tabpanel"
            aria-labelledby="pills-laravel-tabJustify"
          >
            <LaravelContent />
          </div>
          <div
            className="tab-pane fade"
            id="pills-cakephpJustify"
            role="tabpanel"
            aria-labelledby="pills-cakephp-tabJustify"
          >
            <CakePHPContent />
          </div>
          <div
            className="tab-pane fade"
            id="pills-yiiJustify"
            role="tabpanel"
            aria-labelledby="pills-yii-tabJustify"
          >
            <YiiContent />
          </div>
          <div
            className="tab-pane fade"
            id="pills-codeigniterJustify"
            role="tabpanel"
            aria-labelledby="pills-codeigniter-tabJustify"
          >
            <CodeIgniterContent />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PHPFrameworks;
