import React, { Fragment, Component } from "react";
import "./MainBackgroundSquares.css";
import anime from "animejs";

class MainBackgroundSquares extends Component {
  componentDidMount() {
    var footerSquareAnimation = (function () {
      var footerSquareTimeline = anime.timeline({
        autoplay: false,
        direction: "alternate",
        loop: false,
      });

      footerSquareTimeline
        //Square type 1
        .add(
          {
            targets: ".footer-main-bg-1",
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: "easeInOutElastic(1, .6)",
            duration: 3000,
          },
          0
        )
        .add(
          {
            targets: ".footer-main-bg-1",
            fill: "#485e87",
            easing: "easeInOutSine",
            delay: 3200,
          },
          0
        )
        //Square type 2
        .add(
          {
            targets: ".footer-main-bg-2",
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: "cubicBezier(.5, .05, .1, .3)",
            duration: 3000,
            delay: 700,
          },
          0
        )
        .add(
          {
            targets: ".footer-main-bg-2",
            fill: "#0094d9",
            easing: "easeInOutSine",
            delay: 3500,
          },
          0
        )
        //Square type 3
        .add(
          {
            targets: ".footer-main-bg-3",
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: "spring(1, 80, 10, 0)",
            duration: 3000,
            delay: 1500,
          },
          0
        )
        .add(
          {
            targets: ".footer-main-bg-3",
            fill: "#bbbdbf",
            easing: "easeInOutSine",
            delay: 5500,
          },
          0
        );

      function init() {
        footerSquareTimeline.play();
      }

      return {
        init: init,
      };
    })();
    setTimeout(() => {
      footerSquareAnimation.init();
    }, 2000);
  }

  render() {
    return (
      <Fragment>
        <div id="mainfooterbluelines">
          <svg
            id="blue_lines"
            data-name="blue lines"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 254.51 224.42"
          >
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1275.16,822.29c-22.07,8.95-42,17.12-60.05,24.55-7.31-8.57-15.26-17.92-24-28,19.17-9.87,40.95-21.36,65.62-34.27"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-2"
              d="M1254.19,779.47c-25,13.58-46.79,25.11-66.23,35.54-9.79-11.55-20.64-24.18-32.44-38.7,20.92-15.31,45.07-32.6,72.94-52.24,9.75,20.53,18.25,38.9,25.73,55.4"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1248.19,890.37c-14,3.65-26.9,7-39,10.1-5.41-4.17-11.12-8.57-17.16-13.32,12.58-4,26.13-8.23,40.8-12.77,5.51,5.64,10.6,11,15.34,16"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1209.35,849.1c-15.74,6.49-30,12.25-43.1,17.61-8.14-6.5-16.89-13.41-26.24-21.07,13.48-7.14,28.39-14.92,44.85-23.42,8.9,9.64,17,18.59,24.49,26.88Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-2"
              d="M1181.73,818.43c-16.63,8.92-31.64,17.1-45.16,24.53-10.35-8.59-21.58-17.9-33.89-28,13.57-9.81,28.92-21.23,46.28-34,12,13.87,22.79,26.45,32.77,37.45Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1144.53,775.95c-17.44,13.42-32.79,24.88-46.37,35.27-13.84-11.48-29.11-24-46.14-38.33,13-15.07,28-32,45.64-51.24,17.58,20,33.11,38.08,46.86,54.31Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1205.22,901.52q-16.44,4.28-30.92,8.09-8.53-5.32-18-11.14c9.8-3.08,20.35-6.46,31.69-10,6.1,4.62,11.8,9,17.28,13.07Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-2"
              d="M1185.72,886.78c-11.36,3.72-21.91,7.1-31.75,10.29-6.82-4.24-14-8.68-21.65-13.47q14.93-6.08,32.31-12.93c7.52,5.68,14.51,11.05,21.08,16.11Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-2"
              d="M1162,868.49q-17.5,7.15-32.4,13.4c-8.25-5.26-17-10.83-26.39-16.75,9.9-5.19,20.68-11,32.49-17.23q14.1,11.12,26.31,20.58Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-2"
              d="M1132.26,845.26c-11.82,6.52-22.62,12.31-32.48,17.71-10.25-6.55-21.22-13.47-33.11-21.15,9.53-7.17,20-15,31.64-23.44,12.29,9.62,23.56,18.57,33.95,26.87Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1203.16,929.58l-24.05,4.34-13.63-7c7.76-1.65,15.93-3.4,24.55-5.21q6.92,4.07,13.13,7.83Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-2"
              d="M1188.41,920.76q-13,2.78-24.63,5.34c-5-2.58-10.23-5.28-15.73-8.08,7.85-2,16.17-4.18,25-6.45,5.37,3.22,10.47,6.31,15.35,9.19Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-2"
              d="M1171.12,910.42c-8.85,2.34-17.17,4.51-25,6.59q-8.75-4.49-18.36-9.47,11.84-3.78,25.25-8,9.56,5.69,18.14,10.87Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-2"
              d="M1150.71,898.14q-13.42,4.32-25.27,8.23-10.2-5.39-21.54-11.27c7.81-3.14,16.17-6.58,25.16-10.21,7.63,4.68,14.84,9.12,21.66,13.25Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1126.3,883.21c-9,3.76-17.34,7.2-25.14,10.43-8.09-4.26-16.67-8.76-25.76-13.62,7.57-4.08,15.7-8.44,24.51-13.08q14.07,8.59,26.39,16.27Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-2"
              d="M1161.2,926.64c-7.23,1.59-14.07,3.07-20.6,4.48-5.08-2.26-10.37-4.59-15.88-7.08q9.81-2.55,20.71-5.33l15.78,7.92Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1143.47,917.7q-10.91,2.85-20.72,5.48l-18.2-8.23c6.49-2,13.31-4.27,20.56-6.6,6.42,3.26,12.52,6.41,18.36,9.34Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1122.85,907.22l-20.57,6.73c-6.71-3.06-13.73-6.23-21.1-9.6,6.32-2.59,13-5.32,20.13-8.18,7.56,3.86,14.73,7.52,21.55,11Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1098.58,894.74q-10.65,4.42-20,8.42c-7.86-3.64-16.09-7.45-24.79-11.44,6-3.19,12.33-6.67,19.14-10.38q13.61,7.12,25.69,13.4Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-3"
              d="M1038.44,863.25c11.08,5.77,21.44,11.25,31.22,16.42h0c-6.78,3.81-13.11,7.29-19,10.6-9.32-4.32-19.12-8.87-29.59-13.77"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1154,938.47c-6.08,1.12-11.88,2.18-17.47,3.19q-6.74-2.66-14-5.55l17.54-3.75q7.24,3.16,13.9,6.11Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-2"
              d="M1138.4,931.59c-6.11,1.33-12,2.63-17.54,3.86q-7.59-3.06-15.76-6.32l17.42-4.54c5.52,2.44,10.79,4.77,15.87,7Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1120.59,923.75c-6.09,1.64-11.9,3.14-17.45,4.6-5.72-2.28-11.67-4.65-17.89-7.18q8.13-2.63,17.13-5.5c6.34,2.79,12.41,5.5,18.21,8.07Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1100.12,914.65q-9,3-17.07,5.65c-6.54-2.68-13.35-5.45-20.48-8.35,5.22-2.11,10.7-4.37,16.49-6.76,7.37,3.32,14.37,6.5,21.07,9.47Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-3"
              d="M1036.33,901.16c4.86-2.64,10-5.42,15.46-8.32q13,5.82,24.66,11.17h0c-5.77,2.44-11.23,4.72-16.43,6.9-7.53-3.1-15.4-6.31-23.69-9.75"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-1"
              d="M1134.66,942q-7.9,1.42-15.21,2.8c-4.47-1.64-9.09-3.32-13.9-5l15.15-3.25,14,5.48Z"
              transform="translate(-1020.84 -720.89)"
            />
            <path
              className="footer-squaresanim footer-main-bg-3"
              d="M1081.24,920.88l-14.48,4.75-19.78-7.3c4.41-1.8,9-3.69,13.85-5.65,7.11,2.83,13.88,5.56,20.41,8.19Z"
              transform="translate(-1020.84 -720.89)"
            />
          </svg>
        </div>
      </Fragment>
    );
  }
}

export default MainBackgroundSquares;
