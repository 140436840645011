import React, { Component, Fragment } from "react";
import "./Loader.css";

class Loader extends Component {
  render() {
    return (
      <Fragment>
        <div className="ajovloader">
          <small className="glowskill ajoload text-base font-semibold text-blue-700">
            Page is loading please wait...
          </small>
          <span className="glowajov">AJ OV </span> does&nbsp;
          <div className="dropping-texts font-bold">
            <div> Design</div>
            <div> BackEnd</div>
            <div> FrontEnd</div>
            <div> DevOps</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Loader;
