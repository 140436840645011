import React, {
  Fragment,
  FunctionComponent,
  useRef,
  useEffect,
  useState,
} from "react";
import { Timeline } from "@knight-lab/timelinejs";
import "@knight-lab/timelinejs/dist/css/timeline.css";
import "./PortfolioAjov.css";

type Props = {
  hashedpage: string;
};
export enum VisibleStatus { //Need to do this for string issue assignment with css
  Visible = "visible",
  Hidden = "hidden",
}

export const PortfolioAjov: FunctionComponent<Props> = ({ hashedpage }) => {
  const ref = useRef(null);
  const vidframe = useRef(null);

  const [kisible, setKisible] = useState(VisibleStatus.Hidden);
  const [showPort, setShowPort] = useState("");
  /*useEffect(() => {
    if (ref.current != null) {
      new Timeline(ref.current, "./timeline.json");
    }
  }, []); */
  const clickPortfolio = () => {
    window.location.hash = "#";
    setKisible(VisibleStatus.Hidden);
    setShowPort("");
  };

  useEffect(() => {
    if (ref.current != null) {
      new Timeline(ref.current, "./timeline.json");
    }
    if (hashedpage === "#portfolio") {
      setKisible(VisibleStatus.Visible);
      setShowPort("show");
    }
  }, [hashedpage]);

  return (
    <Fragment>
      <div
        className={`offcanvas offcanvas-top fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 left-0 right-0 border-none h-5/6 max-h-full ${showPort}`}
        style={{ visibility: kisible }}
        id="offcanvasPortfolioTop"
        aria-labelledby="offcanvasTopLabel"
      >
        <div className="offcanvas-header flex items-center justify-between p-4 bg-white z-10">
          <h5
            className="offcanvas-title mb-0 leading-normal font-semibold"
            id="offcanvasTopLabel"
          >
            AJOV's Portfolio
          </h5>
          <button
            type="button"
            className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="offcanvas"
            onClick={clickPortfolio}
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body flex-grow p-4 overflow-y-auto">
          <div className="wrapperPort">
            <div className="video-container">
              <iframe
                width="560"
                height="315"
                className="mainPortVideo"
                src="https://www.youtube-nocookie.com/embed/kCcxTqxe-QY?controls=0&autoplay=1&mute=1&list=PLvYNamf9X2gIR0HViMFS8RysUjJsOXVXu&loop=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                ref={vidframe}
              ></iframe>
            </div>
            <div style={{ width: "100%", height: "600px" }} ref={ref} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
