import React, { Fragment, Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./ContactAjov.css";

class ContactAjov extends Component {
  render() {
    return (
      <Fragment>
        <div
          className="offcanvas offcanvas-end fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-4/12 xs:w-full"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header flex items-center justify-between p-4">
            <h5
              className="offcanvas-title mb-0 leading-normal font-semibold"
              id="offcanvasRightLabel"
            >
              Contact Me
            </h5>
            <button
              type="button"
              className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body flex-grow p-4 overflow-y-auto">
            <span className="absolute w-11/12 -right-0 z-[-1]">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 187.88 177.62"
              >
                <g>
                  <path
                    className="ajov-contact-speak-1"
                    d="M323.78,268.77v67.91A23.45,23.45,0,0,0,347.2,360.1h53.27l24.71,21.48,19.28,16.76.11-25.55.06-14a23.46,23.46,0,0,0,15.68-22.1V268.77a23.45,23.45,0,0,0-23.42-23.42H347.2A23.45,23.45,0,0,0,323.78,268.77Zm6.09,0a17.35,17.35,0,0,1,17.33-17.33H436.9a17.35,17.35,0,0,1,17.33,17.33v67.91a17.35,17.35,0,0,1-15.66,17.25l-.08,18.84L438.43,385l-9.25-8-26.43-23H347.2a17.35,17.35,0,0,1-17.33-17.33Z"
                    transform="translate(-272.43 -245.35)"
                  />
                  <path
                    className="ajov-contact-speak-2"
                    d="M347.2,257.06H436.9a11.71,11.71,0,0,1,11.71,11.71v67.91a11.71,11.71,0,0,1-11.71,11.71H433l-.11,24.36-28-24.36H347.2a11.71,11.71,0,0,1-11.71-11.71V268.77A11.71,11.71,0,0,1,347.2,257.06Z"
                    transform="translate(-272.43 -245.35)"
                  />
                </g>
                <g>
                  <path
                    className="ajov-contact-speak-3"
                    d="M385.44,295.25H289.89a12.47,12.47,0,0,0-12.47,12.47v72.34a12.47,12.47,0,0,0,12.47,12.47h4.18l.11,25.94L324,392.53h61.4a12.47,12.47,0,0,0,12.47-12.47V307.72A12.47,12.47,0,0,0,385.44,295.25Z"
                    transform="translate(-272.43 -245.35)"
                  />
                  <path
                    className="ajov-contact-speak-4"
                    d="M380.45,299.74H284.9a12.47,12.47,0,0,0-12.47,12.47v72.34A12.47,12.47,0,0,0,284.9,397h4.18L289.2,423,319,397h61.41a12.47,12.47,0,0,0,12.47-12.47V312.21A12.47,12.47,0,0,0,380.45,299.74Z"
                    transform="translate(-272.43 -245.35)"
                  />
                </g>
              </svg>
            </span>
            <span className="absolute w-2/12 bottom-10 right-0 z-[-1]">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22.29 28.84"
              >
                <g>
                  <ellipse
                    className="ajov-contact-ant-1"
                    cx="217.73"
                    cy="348.69"
                    rx="6.55"
                    ry="2.06"
                    transform="translate(-379.66 -104.26) rotate(-41.42)"
                  />
                  <path
                    className="ajov-contact-ant-2"
                    d="M220.75,351.61a6.59,6.59,0,0,0,1.2-1.68,8,8,0,0,0,.68-1.9,8.76,8.76,0,0,0,.21-1.85,5.87,5.87,0,0,0-.15-1.4,1.8,1.8,0,0,0-.39-.84.87.87,0,0,0-.64-.21,5.28,5.28,0,0,0-2.48.95,16.66,16.66,0,0,0-3.09,2.48l-1-1.08a.94.94,0,0,1,0-.13,1,1,0,0,0,0-.12.77.77,0,0,0-.24-.58.81.81,0,0,0-.58-.23.78.78,0,0,0-.58.24.79.79,0,0,0-.23.58.77.77,0,0,0,.23.56.78.78,0,0,0,.58.24h.23l1,1.09a16.33,16.33,0,0,0-1.47,1.83,9.88,9.88,0,0,0-.95,1.68,3.5,3.5,0,0,0-.33,1.32.91.91,0,0,0,.23.67,1.8,1.8,0,0,0,.84.39,6.88,6.88,0,0,0,1.39.15,7.37,7.37,0,0,0,3.37-.72l.84,6.49,3.2-2.13-2.74-5.1A6.72,6.72,0,0,0,220.75,351.61Zm-5.34,1A3.4,3.4,0,0,1,214,353a.92.92,0,0,1-.63-.2.87.87,0,0,1-.22-.64,4.23,4.23,0,0,1,.67-1.93,13.1,13.1,0,0,1,1.81-2.42l2.39,2.7s0,0,.09,0a.51.51,0,0,0,.31-.14.4.4,0,0,0,.17-.3.3.3,0,0,0-.09-.22l-2.33-2.61a17.72,17.72,0,0,1,1.89-1.65,10.8,10.8,0,0,1,1.81-1.13,3.42,3.42,0,0,1,1.39-.4.85.85,0,0,1,.63.21.83.83,0,0,1,.21.62,4.65,4.65,0,0,1-.84,2.21,15.11,15.11,0,0,1-2.25,2.78,17,17,0,0,1-1.86,1.62A10.2,10.2,0,0,1,215.41,352.66Z"
                    transform="translate(-203.44 -335.5)"
                  />
                  <path
                    className="ajov-contact-ant-3"
                    d="M220.44,351.61a6.58,6.58,0,0,0,1.2-1.68,8,8,0,0,0,.68-1.9,8.73,8.73,0,0,0,.21-1.85,5.89,5.89,0,0,0-.15-1.4,1.8,1.8,0,0,0-.39-.84.87.87,0,0,0-.64-.21,5.28,5.28,0,0,0-2.48.95,16.66,16.66,0,0,0-3.09,2.48l-1-1.08a.92.92,0,0,1,0-.13.88.88,0,0,0,0-.12.77.77,0,0,0-.24-.58A.81.81,0,0,0,214,345a.78.78,0,0,0-.58.24.79.79,0,0,0-.23.58.77.77,0,0,0,.24.56.78.78,0,0,0,.58.24h.23l1,1.09a16.39,16.39,0,0,0-1.47,1.83,9.88,9.88,0,0,0-.95,1.68,3.51,3.51,0,0,0-.33,1.32.92.92,0,0,0,.23.67,1.8,1.8,0,0,0,.84.39,6.88,6.88,0,0,0,1.39.15,7.37,7.37,0,0,0,3.37-.72l.84,6.49,3.2-2.13-2.74-5.1A6.73,6.73,0,0,0,220.44,351.61Zm-5.34,1a3.39,3.39,0,0,1-1.37.38.91.91,0,0,1-.63-.2.87.87,0,0,1-.22-.64,4.23,4.23,0,0,1,.67-1.93,13.13,13.13,0,0,1,1.81-2.42l2.39,2.7s0,0,.09,0a.51.51,0,0,0,.31-.14.4.4,0,0,0,.17-.3.31.31,0,0,0-.09-.22l-2.33-2.61a17.72,17.72,0,0,1,1.89-1.65,10.8,10.8,0,0,1,1.81-1.13,3.42,3.42,0,0,1,1.39-.4.85.85,0,0,1,.63.21.84.84,0,0,1,.21.62,4.64,4.64,0,0,1-.84,2.21,15.07,15.07,0,0,1-2.25,2.78,17,17,0,0,1-1.86,1.62A10.2,10.2,0,0,1,215.09,352.66Z"
                    transform="translate(-203.44 -335.5)"
                  />
                  <path
                    className="ajov-contact-ant-4"
                    d="M224.3,362.08c0,.46-.9.83-2,.83H210.66c-1.11,0-2-.37-2-.83v-4.43c0-.46.9-.83,2-.83h11.63c1.11,0,2,.37,2,.83Z"
                    transform="translate(-203.44 -335.5)"
                  />
                  <path
                    className="ajov-contact-ant-5"
                    d="M218.39,342h.28a6.62,6.62,0,0,0-3.43-.95,6.55,6.55,0,0,0-6.58,6.51,6.42,6.42,0,0,0,.47,2.42"
                    transform="translate(-203.44 -335.5)"
                  />
                  <path
                    className="ajov-contact-ant-6"
                    d="M219,337.7h.42a10,10,0,0,0-15.18,8.43,9.73,9.73,0,0,0,.71,3.66"
                    transform="translate(-203.44 -335.5)"
                  />
                </g>
              </svg>
            </span>

            <section className="py-20 lg:py-[120px] relative z-10">
              <div className="container">
                <div className="flex justify-center items-center h-screen">
                  <div className="w-11/12 px-4">
                    <div className="bg-white relative rounded-lg p-8 sm:p-12 shadow-lg">
                      <form>
                        <div className="mb-6">
                          <input
                            type="text"
                            placeholder="Your Name"
                            className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                          />
                        </div>
                        <div className="mb-6">
                          <input
                            type="email"
                            placeholder="Your Email"
                            className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                          />
                        </div>
                        <div className="mb-6">
                          <input
                            type="text"
                            placeholder="Your Phone"
                            className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                          />
                        </div>
                        <div className="mb-6">
                          <textarea
                            rows={6}
                            placeholder="Your Message"
                            className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        resize-none
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                          ></textarea>
                        </div>

                        <div>
                          <ReCAPTCHA sitekey="6Lc4AxchAAAAAKHHlxXUyfmSveMHUyBoZz_JLAzX" />
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="
                        w-full
                        text-white
                        bg-blue-700
                        rounded
                        border border-primary
                        p-3
                        transition
                        hover:bg-opacity-90
                        "
                          >
                            Send Message
                          </button>
                        </div>
                      </form>
                      <div>
                        <span className="absolute -right-10 top-[90px] z-[-1]">
                          <svg
                            width="34"
                            height="134"
                            viewBox="0 0 34 134"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="31.9993"
                              cy="132"
                              r="1.66667"
                              transform="rotate(180 31.9993 132)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="117.333"
                              r="1.66667"
                              transform="rotate(180 31.9993 117.333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="102.667"
                              r="1.66667"
                              transform="rotate(180 31.9993 102.667)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="88"
                              r="1.66667"
                              transform="rotate(180 31.9993 88)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="73.3333"
                              r="1.66667"
                              transform="rotate(180 31.9993 73.3333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="45"
                              r="1.66667"
                              transform="rotate(180 31.9993 45)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="16"
                              r="1.66667"
                              transform="rotate(180 31.9993 16)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="59"
                              r="1.66667"
                              transform="rotate(180 31.9993 59)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="30.6666"
                              r="1.66667"
                              transform="rotate(180 31.9993 30.6666)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="1.66665"
                              r="1.66667"
                              transform="rotate(180 31.9993 1.66665)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="132"
                              r="1.66667"
                              transform="rotate(180 17.3333 132)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="117.333"
                              r="1.66667"
                              transform="rotate(180 17.3333 117.333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="102.667"
                              r="1.66667"
                              transform="rotate(180 17.3333 102.667)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="88"
                              r="1.66667"
                              transform="rotate(180 17.3333 88)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="73.3333"
                              r="1.66667"
                              transform="rotate(180 17.3333 73.3333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="45"
                              r="1.66667"
                              transform="rotate(180 17.3333 45)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="16"
                              r="1.66667"
                              transform="rotate(180 17.3333 16)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="59"
                              r="1.66667"
                              transform="rotate(180 17.3333 59)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="30.6666"
                              r="1.66667"
                              transform="rotate(180 17.3333 30.6666)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="1.66665"
                              r="1.66667"
                              transform="rotate(180 17.3333 1.66665)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="132"
                              r="1.66667"
                              transform="rotate(180 2.66536 132)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="117.333"
                              r="1.66667"
                              transform="rotate(180 2.66536 117.333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="102.667"
                              r="1.66667"
                              transform="rotate(180 2.66536 102.667)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="88"
                              r="1.66667"
                              transform="rotate(180 2.66536 88)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="73.3333"
                              r="1.66667"
                              transform="rotate(180 2.66536 73.3333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="45"
                              r="1.66667"
                              transform="rotate(180 2.66536 45)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="16"
                              r="1.66667"
                              transform="rotate(180 2.66536 16)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="59"
                              r="1.66667"
                              transform="rotate(180 2.66536 59)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="30.6666"
                              r="1.66667"
                              transform="rotate(180 2.66536 30.6666)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="1.66665"
                              r="1.66667"
                              transform="rotate(180 2.66536 1.66665)"
                              fill="#13C296"
                            />
                          </svg>
                        </span>
                        <span className="absolute -left-7 -bottom-7 z-[-1]">
                          <svg
                            width="107"
                            height="134"
                            viewBox="0 0 107 134"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="104.999"
                              cy="132"
                              r="1.66667"
                              transform="rotate(180 104.999 132)"
                              fill="#13C296"
                            />
                            <circle
                              cx="104.999"
                              cy="117.333"
                              r="1.66667"
                              transform="rotate(180 104.999 117.333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="104.999"
                              cy="102.667"
                              r="1.66667"
                              transform="rotate(180 104.999 102.667)"
                              fill="#13C296"
                            />
                            <circle
                              cx="104.999"
                              cy="88"
                              r="1.66667"
                              transform="rotate(180 104.999 88)"
                              fill="#13C296"
                            />
                            <circle
                              cx="104.999"
                              cy="73.3333"
                              r="1.66667"
                              transform="rotate(180 104.999 73.3333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="104.999"
                              cy="45"
                              r="1.66667"
                              transform="rotate(180 104.999 45)"
                              fill="#13C296"
                            />
                            <circle
                              cx="104.999"
                              cy="16"
                              r="1.66667"
                              transform="rotate(180 104.999 16)"
                              fill="#13C296"
                            />
                            <circle
                              cx="104.999"
                              cy="59"
                              r="1.66667"
                              transform="rotate(180 104.999 59)"
                              fill="#13C296"
                            />
                            <circle
                              cx="104.999"
                              cy="30.6666"
                              r="1.66667"
                              transform="rotate(180 104.999 30.6666)"
                              fill="#13C296"
                            />
                            <circle
                              cx="104.999"
                              cy="1.66665"
                              r="1.66667"
                              transform="rotate(180 104.999 1.66665)"
                              fill="#13C296"
                            />
                            <circle
                              cx="90.3333"
                              cy="132"
                              r="1.66667"
                              transform="rotate(180 90.3333 132)"
                              fill="#13C296"
                            />
                            <circle
                              cx="90.3333"
                              cy="117.333"
                              r="1.66667"
                              transform="rotate(180 90.3333 117.333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="90.3333"
                              cy="102.667"
                              r="1.66667"
                              transform="rotate(180 90.3333 102.667)"
                              fill="#13C296"
                            />
                            <circle
                              cx="90.3333"
                              cy="88"
                              r="1.66667"
                              transform="rotate(180 90.3333 88)"
                              fill="#13C296"
                            />
                            <circle
                              cx="90.3333"
                              cy="73.3333"
                              r="1.66667"
                              transform="rotate(180 90.3333 73.3333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="90.3333"
                              cy="45"
                              r="1.66667"
                              transform="rotate(180 90.3333 45)"
                              fill="#13C296"
                            />
                            <circle
                              cx="90.3333"
                              cy="16"
                              r="1.66667"
                              transform="rotate(180 90.3333 16)"
                              fill="#13C296"
                            />
                            <circle
                              cx="90.3333"
                              cy="59"
                              r="1.66667"
                              transform="rotate(180 90.3333 59)"
                              fill="#13C296"
                            />
                            <circle
                              cx="90.3333"
                              cy="30.6666"
                              r="1.66667"
                              transform="rotate(180 90.3333 30.6666)"
                              fill="#13C296"
                            />
                            <circle
                              cx="90.3333"
                              cy="1.66665"
                              r="1.66667"
                              transform="rotate(180 90.3333 1.66665)"
                              fill="#13C296"
                            />
                            <circle
                              cx="75.6654"
                              cy="132"
                              r="1.66667"
                              transform="rotate(180 75.6654 132)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="132"
                              r="1.66667"
                              transform="rotate(180 31.9993 132)"
                              fill="#13C296"
                            />
                            <circle
                              cx="75.6654"
                              cy="117.333"
                              r="1.66667"
                              transform="rotate(180 75.6654 117.333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="117.333"
                              r="1.66667"
                              transform="rotate(180 31.9993 117.333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="75.6654"
                              cy="102.667"
                              r="1.66667"
                              transform="rotate(180 75.6654 102.667)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="102.667"
                              r="1.66667"
                              transform="rotate(180 31.9993 102.667)"
                              fill="#13C296"
                            />
                            <circle
                              cx="75.6654"
                              cy="88"
                              r="1.66667"
                              transform="rotate(180 75.6654 88)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="88"
                              r="1.66667"
                              transform="rotate(180 31.9993 88)"
                              fill="#13C296"
                            />
                            <circle
                              cx="75.6654"
                              cy="73.3333"
                              r="1.66667"
                              transform="rotate(180 75.6654 73.3333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="73.3333"
                              r="1.66667"
                              transform="rotate(180 31.9993 73.3333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="75.6654"
                              cy="45"
                              r="1.66667"
                              transform="rotate(180 75.6654 45)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="45"
                              r="1.66667"
                              transform="rotate(180 31.9993 45)"
                              fill="#13C296"
                            />
                            <circle
                              cx="75.6654"
                              cy="16"
                              r="1.66667"
                              transform="rotate(180 75.6654 16)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="16"
                              r="1.66667"
                              transform="rotate(180 31.9993 16)"
                              fill="#13C296"
                            />
                            <circle
                              cx="75.6654"
                              cy="59"
                              r="1.66667"
                              transform="rotate(180 75.6654 59)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="59"
                              r="1.66667"
                              transform="rotate(180 31.9993 59)"
                              fill="#13C296"
                            />
                            <circle
                              cx="75.6654"
                              cy="30.6666"
                              r="1.66667"
                              transform="rotate(180 75.6654 30.6666)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="30.6666"
                              r="1.66667"
                              transform="rotate(180 31.9993 30.6666)"
                              fill="#13C296"
                            />
                            <circle
                              cx="75.6654"
                              cy="1.66665"
                              r="1.66667"
                              transform="rotate(180 75.6654 1.66665)"
                              fill="#13C296"
                            />
                            <circle
                              cx="31.9993"
                              cy="1.66665"
                              r="1.66667"
                              transform="rotate(180 31.9993 1.66665)"
                              fill="#13C296"
                            />
                            <circle
                              cx="60.9993"
                              cy="132"
                              r="1.66667"
                              transform="rotate(180 60.9993 132)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="132"
                              r="1.66667"
                              transform="rotate(180 17.3333 132)"
                              fill="#13C296"
                            />
                            <circle
                              cx="60.9993"
                              cy="117.333"
                              r="1.66667"
                              transform="rotate(180 60.9993 117.333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="117.333"
                              r="1.66667"
                              transform="rotate(180 17.3333 117.333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="60.9993"
                              cy="102.667"
                              r="1.66667"
                              transform="rotate(180 60.9993 102.667)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="102.667"
                              r="1.66667"
                              transform="rotate(180 17.3333 102.667)"
                              fill="#13C296"
                            />
                            <circle
                              cx="60.9993"
                              cy="88"
                              r="1.66667"
                              transform="rotate(180 60.9993 88)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="88"
                              r="1.66667"
                              transform="rotate(180 17.3333 88)"
                              fill="#13C296"
                            />
                            <circle
                              cx="60.9993"
                              cy="73.3333"
                              r="1.66667"
                              transform="rotate(180 60.9993 73.3333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="73.3333"
                              r="1.66667"
                              transform="rotate(180 17.3333 73.3333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="60.9993"
                              cy="45"
                              r="1.66667"
                              transform="rotate(180 60.9993 45)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="45"
                              r="1.66667"
                              transform="rotate(180 17.3333 45)"
                              fill="#13C296"
                            />
                            <circle
                              cx="60.9993"
                              cy="16"
                              r="1.66667"
                              transform="rotate(180 60.9993 16)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="16"
                              r="1.66667"
                              transform="rotate(180 17.3333 16)"
                              fill="#13C296"
                            />
                            <circle
                              cx="60.9993"
                              cy="59"
                              r="1.66667"
                              transform="rotate(180 60.9993 59)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="59"
                              r="1.66667"
                              transform="rotate(180 17.3333 59)"
                              fill="#13C296"
                            />
                            <circle
                              cx="60.9993"
                              cy="30.6666"
                              r="1.66667"
                              transform="rotate(180 60.9993 30.6666)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="30.6666"
                              r="1.66667"
                              transform="rotate(180 17.3333 30.6666)"
                              fill="#13C296"
                            />
                            <circle
                              cx="60.9993"
                              cy="1.66665"
                              r="1.66667"
                              transform="rotate(180 60.9993 1.66665)"
                              fill="#13C296"
                            />
                            <circle
                              cx="17.3333"
                              cy="1.66665"
                              r="1.66667"
                              transform="rotate(180 17.3333 1.66665)"
                              fill="#13C296"
                            />
                            <circle
                              cx="46.3333"
                              cy="132"
                              r="1.66667"
                              transform="rotate(180 46.3333 132)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="132"
                              r="1.66667"
                              transform="rotate(180 2.66536 132)"
                              fill="#13C296"
                            />
                            <circle
                              cx="46.3333"
                              cy="117.333"
                              r="1.66667"
                              transform="rotate(180 46.3333 117.333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="117.333"
                              r="1.66667"
                              transform="rotate(180 2.66536 117.333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="46.3333"
                              cy="102.667"
                              r="1.66667"
                              transform="rotate(180 46.3333 102.667)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="102.667"
                              r="1.66667"
                              transform="rotate(180 2.66536 102.667)"
                              fill="#13C296"
                            />
                            <circle
                              cx="46.3333"
                              cy="88"
                              r="1.66667"
                              transform="rotate(180 46.3333 88)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="88"
                              r="1.66667"
                              transform="rotate(180 2.66536 88)"
                              fill="#13C296"
                            />
                            <circle
                              cx="46.3333"
                              cy="73.3333"
                              r="1.66667"
                              transform="rotate(180 46.3333 73.3333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="73.3333"
                              r="1.66667"
                              transform="rotate(180 2.66536 73.3333)"
                              fill="#13C296"
                            />
                            <circle
                              cx="46.3333"
                              cy="45"
                              r="1.66667"
                              transform="rotate(180 46.3333 45)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="45"
                              r="1.66667"
                              transform="rotate(180 2.66536 45)"
                              fill="#13C296"
                            />
                            <circle
                              cx="46.3333"
                              cy="16"
                              r="1.66667"
                              transform="rotate(180 46.3333 16)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="16"
                              r="1.66667"
                              transform="rotate(180 2.66536 16)"
                              fill="#13C296"
                            />
                            <circle
                              cx="46.3333"
                              cy="59"
                              r="1.66667"
                              transform="rotate(180 46.3333 59)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="59"
                              r="1.66667"
                              transform="rotate(180 2.66536 59)"
                              fill="#13C296"
                            />
                            <circle
                              cx="46.3333"
                              cy="30.6666"
                              r="1.66667"
                              transform="rotate(180 46.3333 30.6666)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="30.6666"
                              r="1.66667"
                              transform="rotate(180 2.66536 30.6666)"
                              fill="#13C296"
                            />
                            <circle
                              cx="46.3333"
                              cy="1.66665"
                              r="1.66667"
                              transform="rotate(180 46.3333 1.66665)"
                              fill="#13C296"
                            />
                            <circle
                              cx="2.66536"
                              cy="1.66665"
                              r="1.66667"
                              transform="rotate(180 2.66536 1.66665)"
                              fill="#13C296"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ContactAjov;
