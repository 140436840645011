import React, { Fragment, FunctionComponent } from "react";
import "./HerokuMainContent.css";

export const HerokuMainContent: FunctionComponent = () => {
  return (
    <Fragment>
      <div className="flex flex-col  justify-between lg:flex-row-reverse rounded overflow-hidden h-auto border shadow shadow-lg">
        <img
          className="block h-auto w-full lg:w-48 flex-none bg-cover"
          src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/heroku/heroku.png"
          alt="heroku"
        />
        <div className="bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col leading-normal">
          <div className="text-black font-bold text-xl mb-2 leading-tight">
            <h2 className="focus:outline-none text-lg font-bold leading-tight text-gray-800">
              with ROR, I tried using Heroku and instantly love it, still using
              with NodeJS worker for my discord bots.
            </h2>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-2">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Experience using
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-teal-800 text-white rounded">
                Heroku cli
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-indigo-700 text-white rounded">
                NodeJS
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-indigo-700 text-white rounded">
                PHP
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-indigo-700 text-white rounded">
                Ruby
              </span>
            </div>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-1">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                First Project
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                2011
              </span>
            </div>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-1">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Last Project
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                2022
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
