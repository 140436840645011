import React, { Fragment, Component } from "react";
import "./RubyOthers.css";

interface ITabMenuData {
  name: string;
  componentName: string;
  link: string;
  shown: Boolean;
}

type Props = {
  name: string;
  show: boolean;
};
type State = {
  rubyData: ITabMenuData[];
};

class RubyOthers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      rubyData: [
        {
          name: "Rails",
          componentName: "<DjangoContent />",
          link: "rails",
          shown: false,
        },
      ],
    };
  }

  render() {
    const { rubyData } = this.state;
    const { show } = this.props;
    return (
      <Fragment>
        <ul
          className={`nav nav-pills nav-justified flex flex-col md:flex-row flex-wrap list-none pl-0 mb-4 ${
            show ? "" : "hidden"
          }`}
          id="pills-tabJustify"
          role="tablist"
        >
          {rubyData.map((anObjectMapped, index) => {
            return (
              <li
                className="nav-item flex-grow text-center my-2 md:mr-2"
                key={anObjectMapped.link}
                role="presentation"
              >
                <a
                  href={`#pills-${anObjectMapped.link}-tabJustify`}
                  className="
      nav-link
      w-full
      block
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      px-6
      py-3
      focus:outline-none focus:ring-0
    "
                  id={`#pills-${anObjectMapped.link}-tabJustify`}
                  data-bs-toggle="pill"
                  data-bs-target={`#pills-${anObjectMapped.link}Justify`}
                  role="tab"
                  aria-controls={`pills-${anObjectMapped.link}Justify`}
                  aria-selected="true"
                >
                  {anObjectMapped.name}
                </a>
              </li>
            );
          })}
        </ul>
        <div
          className={`tab-content ${show ? "" : "hidden"}`}
          id="pills-tabContentJustify"
        >
          <div
            className="tab-pane fade show active"
            id="pills-homeJustify"
            role="tabpanel"
            aria-labelledby="pills-home-tabJustify"
          >
            Tab 1 content justify
          </div>
          <div
            className="tab-pane fade"
            id="pills-profileJustify"
            role="tabpanel"
            aria-labelledby="pills-profile-tabJustify"
          >
            Tab 2 content justify
          </div>
          <div
            className="tab-pane fade"
            id="pills-joomlaJustify"
            role="tabpanel"
            aria-labelledby="pills-joomla-tabJustify"
          ></div>
        </div>
      </Fragment>
    );
  }
}

export default RubyOthers;
