import React, { Fragment, FunctionComponent } from "react";
import "./RailsContent.css";

export const RailsContent: FunctionComponent = () => {
  return (
    <Fragment>
      <div className="flex flex-col  justify-between lg:flex-row-reverse rounded overflow-hidden h-auto border shadow shadow-lg">
        <img
          className="block h-auto w-full lg:w-48 flex-none bg-cover"
          src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/rails/rails.png"
          alt="Rails"
        />
        <div className="bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col leading-normal">
          <div className="text-black font-bold text-xl mb-2 leading-tight">
            <h2 className="focus:outline-none text-lg font-bold leading-tight text-gray-800">
              Rails for me was the reason I learn CakePHP, Laravel, and other
              PHP framework and of course Ruby, its the framework that was ahead
              of its time. <br />
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-mostblue text-white rounded">
                The only framework I know :)
              </span>
            </h2>

            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-2">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Experience with development
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                Docker setup environment
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                Currently mostly for API, using with Postgres SQL
              </span>
            </div>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-1">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                First Project
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                2010
              </span>
            </div>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-1">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Last Project
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                2022
              </span>
            </div>
            <div className="flex flex-wrap space-x-2 space-y-0.5 mt-1">
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                Last version used
              </span>
              <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded">
                6.0.4
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
