import React, { Fragment, Component } from "react";
import "./Navigation.css";
import NavigationItem from "./Navigation/NavigationItem";

class Navigation extends Component {
  render() {
    return (
      <Fragment>
        <div id="brandNavigation">
          <svg
            id="Layer_1"
            data-name="brandnavigation"
            viewBox="0 0 285.15 262.08"
          >
            <g>
              <circle
                className="brandbox-1"
                cx="726.56"
                cy="496.2"
                r="49.43"
                transform="translate(-620.71 -108.04) rotate(-19.62)"
              />
              <path
                d="M771.56,480.15a47.78,47.78,0,1,1-61-29A47.83,47.83,0,0,1,771.56,480.15Zm-62.16-32.07a51.07,51.07,0,1,0,65.26,31A51.13,51.13,0,0,0,709.41,448.09Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <path
                d="M773.73,479.38a50.09,50.09,0,0,1-76.9,57.14"
                transform="translate(-496.29 -380.81)"
              />
              <path
                d="M769.24,481a45.31,45.31,0,0,1-69.57,51.69l-5.66,7.7a54.85,54.85,0,0,0,84.23-62.59Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <circle
                className="brandbox-1"
                cx="726.11"
                cy="496.17"
                r="39.89"
                transform="translate(-563.72 494.43) rotate(-59.88)"
              />
              <path
                d="M706.76,529.52A38.56,38.56,0,1,1,736,533.43,38.61,38.61,0,0,1,706.76,529.52Zm55-12.67A41.22,41.22,0,1,0,736.69,536,41.27,41.27,0,0,0,761.76,516.85Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <path
                className="brandbox-2"
                d="M691.14,475.88a40.39,40.39,0,0,1,32.24-20"
                transform="translate(-496.29 -380.81)"
              />
              <path
                className="brandbox-2"
                d="M696.31,478.88a34.45,34.45,0,0,1,27.48-17.09L723,449.88a46.37,46.37,0,0,0-37,23Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <circle
                className="brandbox-1"
                cx="726.5"
                cy="496.31"
                r="35.68"
                transform="translate(-620.76 -108.05) rotate(-19.62)"
              />
              <path
                d="M738.08,528.8A34.49,34.49,0,1,1,759,484.73,34.53,34.53,0,0,1,738.08,528.8Zm23.15-44.86A36.87,36.87,0,1,0,738.88,531,36.91,36.91,0,0,0,761.23,483.94Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <path
                className="brandbox-1"
                d="M738.64,530.37a36.77,36.77,0,0,1-41.94-56.44"
                transform="translate(-496.29 -380.81)"
              />
              <path
                d="M737.69,527.7A33.93,33.93,0,0,1,699,475.61l-4.57-3.36A39.59,39.59,0,0,0,739.59,533Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <circle
                className="brandbox-1"
                cx="726.52"
                cy="495.99"
                r="28.79"
                transform="translate(-563.37 494.7) rotate(-59.88)"
              />
              <path
                d="M702.45,482a27.83,27.83,0,1,1-2.82,21.12A27.87,27.87,0,0,1,702.45,482Zm9.14,39.7a29.75,29.75,0,1,0-13.82-18.09A29.55,29.55,0,0,0,711.59,521.73Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <path
                className="brandbox-1"
                d="M741.17,470.75A29.16,29.16,0,0,1,755.64,494"
                transform="translate(-496.29 -380.81)"
              />
              <path
                d="M738.19,475.88a23.25,23.25,0,0,1,11.53,18.54l11.83-.81a35.1,35.1,0,0,0-17.41-28Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <circle
                className="brandbox-1"
                cx="725.7"
                cy="495.51"
                r="19.31"
                transform="translate(-620.53 -108.37) rotate(-19.62)"
              />
              <path
                d="M732,513.1a18.67,18.67,0,1,1,11.32-23.85A18.69,18.69,0,0,1,732,513.1Zm12.53-24.29a20,20,0,1,0-12.1,25.5A20,20,0,0,0,744.5,488.81Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <path
                className="brandbox-1"
                d="M732.27,513.95a19.57,19.57,0,0,1-22.33-30"
                transform="translate(-496.29 -380.81)"
              />
              <path
                d="M731.47,511.7a17.18,17.18,0,0,1-19.6-26.38L708,482.49a22,22,0,0,0,25.05,33.71Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <circle
                className="brandbox-1"
                cx="725.72"
                cy="495.34"
                r="15.59"
                transform="translate(-563.19 493.71) rotate(-59.88)"
              />
              <path
                d="M713.94,488.51a13.61,13.61,0,1,1-1.38,10.32A13.62,13.62,0,0,1,713.94,488.51Zm3,22a17.56,17.56,0,1,0-8.16-10.68A17.59,17.59,0,0,0,716.9,510.53Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <circle
                cx="725.78"
                cy="495.5"
                r="11.25"
                transform="translate(-563.3 493.84) rotate(-59.88)"
              />
              <path
                className="brandbox-1"
                d="M731.24,486.09a10.88,10.88,0,1,1-14.87,4A10.89,10.89,0,0,1,731.24,486.09Zm-15.51,3.57a11.63,11.63,0,1,0,15.89-4.22A11.64,11.64,0,0,0,715.73,489.67Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <path
              className="brandbox-2"
              d="M748.91,487.26a24.55,24.55,0,1,1-31.37-14.88A24.58,24.58,0,0,1,748.91,487.26Zm-32-16.74a26.53,26.53,0,1,0,33.89,16.08A26.55,26.55,0,0,0,716.88,470.51Z"
              transform="translate(-496.29 -380.81)"
            />
            <g>
              <circle
                className="brandbox-1"
                cx="654.73"
                cy="435.93"
                r="39.54"
                transform="translate(-623.54 139.97) rotate(-39.85)"
              />
              <path
                d="M632.45,409.24a34.77,34.77,0,1,1-12.35,23.57A34.54,34.54,0,0,1,632.45,409.24ZM620.7,464.33A44.32,44.32,0,1,0,610.59,432,44,44,0,0,0,620.7,464.33Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <path
                className="brandbox-1"
                d="M629.05,405.17a40.07,40.07,0,0,1,64.17,41.92"
                transform="translate(-496.29 -380.81)"
              />
              <path
                d="M630.58,407a37.68,37.68,0,0,1,60.34,39.42l4.59,1.33a42.45,42.45,0,0,0-68-44.41Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <circle
                className="brandbox-1"
                cx="654.83"
                cy="436.27"
                r="31.91"
                transform="translate(-383.73 625.62) rotate(-80.11)"
              />
              <path
                d="M685.22,441.57a30.85,30.85,0,1,1-5.15-23A30.89,30.89,0,0,1,685.22,441.57Zm-24.73-37.78a33,33,0,1,0,21.31,13.52A32.76,32.76,0,0,0,660.49,403.79Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <path
                className="brandbox-1"
                d="M640.27,477.13a32.31,32.31,0,0,1-24-18.65"
                transform="translate(-496.29 -380.81)"
              />
              <path
                d="M641.09,472.43a27.56,27.56,0,0,1-20.43-15.9l-8.72,3.91a37.1,37.1,0,0,0,27.51,21.4Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <circle
                className="brandbox-1"
                cx="654.83"
                cy="435.94"
                r="28.54"
                transform="translate(-623.53 140.05) rotate(-39.85)"
              />
              <path
                d="M676,418.26a27.59,27.59,0,1,1-38.86-3.5A27.41,27.41,0,0,1,676,418.26Zm-40.08-5A29.49,29.49,0,1,0,677.47,417,29.3,29.3,0,0,0,635.93,413.29Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <path
                className="brandbox-1"
                d="M677,417.4a29.41,29.41,0,0,1-30.77,47.1"
                transform="translate(-496.29 -380.81)"
              />
              <path
                d="M675.3,418.86a27.15,27.15,0,0,1-28.4,43.47l-1.26,4.36A31.67,31.67,0,0,0,678.78,416Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <circle
                className="brandbox-1"
                cx="654.58"
                cy="436.01"
                r="23.04"
                transform="translate(-383.68 625.15) rotate(-80.11)"
              />
              <path
                d="M650.76,457.95a22.27,22.27,0,1,1,16.63-3.72A22.12,22.12,0,0,1,650.76,457.95ZM678,440.1a23.81,23.81,0,1,0-9.76,15.38A23.65,23.65,0,0,0,678,440.1Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <circle
                className="brandbox-1"
                cx="654.45"
                cy="436.76"
                r="15.45"
                transform="translate(-624.14 140.02) rotate(-39.85)"
              />
              <path
                d="M665.92,427.19a14.94,14.94,0,1,1-21-1.9A15,15,0,0,1,665.92,427.19Zm-21.7-2.69a16,16,0,1,0,22.49,2A15.86,15.86,0,0,0,644.22,424.5Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <path
                className="brandbox-1"
                d="M666.47,426.73a15.66,15.66,0,0,1-16.38,25.07"
                transform="translate(-496.29 -380.81)"
              />
              <path
                d="M665,428a13.75,13.75,0,0,1-14.38,22l-1.06,3.67a17.57,17.57,0,0,0,18.38-28.13Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <circle
                className="brandbox-1"
                cx="654.32"
                cy="436.8"
                r="12.47"
                transform="translate(-384.66 625.57) rotate(-80.11)"
              />
              <path
                d="M652.45,447.53a10.89,10.89,0,1,1,8.13-1.82A10.9,10.9,0,0,1,652.45,447.53Zm15.71-8.31a14,14,0,1,0-5.76,9.08A14.07,14.07,0,0,0,668.16,439.21Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <g>
              <circle
                cx="654.42"
                cy="436.7"
                r="9"
                transform="translate(-384.54 625.52) rotate(-80.1)"
              />
              <path
                className="brandbox-1"
                d="M645.85,435.21a8.7,8.7,0,1,1,7.08,10.07A8.64,8.64,0,0,1,645.85,435.21Zm7,10.66a9.3,9.3,0,1,0-7.57-10.76A9.31,9.31,0,0,0,652.82,445.87Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
            <circle
              className="brandbox-2"
              cx="654.42"
              cy="436.7"
              r="20.43"
              transform="translate(-624.11 139.94) rotate(-39.85)"
            />
            <path
              className="brandbox-3"
              d="M761.39,424.67l-9.17,18.6L702.39,383l-85.81,18.63-16.18,52.16-15.59,8.75-6.17-3.66a3.76,3.76,0,0,1-2.22,1c-3,.61-4.64,1.84-4.13,3,.6,1.44-1.16,4.62-2.32,4.18-3.8-1.44-5.69-6.79-3.07-8.7,1.68-1.23,1.76-1.21,3.71.52,4.32,3.83,3.1-1.73,4.66-1.91h.18L558.53,447l-15.14,25.49h.17c1,.13,1.14,1,.28.87a1.56,1.56,0,0,1-.73-.41l-3.37,5.67a6,6,0,0,1,2.85-1.09c.74.1.06,3-.72,3.09-.31,0-.49.31-.45.7.08.72-1.06,1.82-1.4,1.37-.57-.73.66-4-.74-2.42a1.41,1.41,0,0,1-.77.44l-7,11.77-7.85,4.4,2.54,4.53-13,21.85a.36.36,0,0,1-.12.2L507.42,533c.06.38.17.73.49.85.67.25.26.92-.51.81a1,1,0,0,1-.65-.53l-.64,1.08L535,552.42c.24,0,.44.09.43.25l6.78,4,0,2.81c1.74.1,3.82,0,4.4-.46,1.75-1.35,2.26.84-1.85,3.08a2.52,2.52,0,0,1-2.58-.12L542,576.3c.29-.32,1.43-.31,1.08.2l25.42.31,5.37,9.58,9.44-5.3c4.19,2.49,3.67,1.18,2.27,5.06h0c-2.52,9,.15,18.39,7.77,24-7.25-1-13.91,4.18-16.12,10.82.39-10.89-13.94-13.91-19.75-5.6-7.85,13.94,14.32,23.52,19.1,9.37-.31,9.16,6.65,15.82,15.58,16.12,13.48-.83,20.92-17.51,10-26.87a21.72,21.72,0,0,0,21.72-8.82L653,622.46l26.3-44.28,33.54.41L713.7,508l9.18-5.15L714,486.9l.13-11-6.33-.08-35.11-62.59-56.19,31.52,9.85-28,60-6.42,35.34,55.89-16,4.1,55.83,28.37-.06-74"
              transform="translate(-496.29 -380.81)"
            />
            <path
              className="brandbox-4"
              d="M669.7,463.26a13.29,13.29,0,1,0-18.09-5.09,13.29,13.29,0,0,0,18.09,5.09ZM656.55,484a8.54,8.54,0,1,0-11.63-3.27A8.54,8.54,0,0,0,656.55,484Zm-21-6.43a5,5,0,1,0-6.78-1.91,5,5,0,0,0,6.78,1.91Z"
              transform="translate(-496.29 -380.81)"
            />
            <g>
              <path
                className="brandbox-5"
                d="M511,461.76c13.31,13.49,11.29,16.87,27.08,6.19-4.05-9.92-2.28-16.05-12-9.28-.35,5.71-1.79,6.32,4.06,6.58-8.86.91-7.58,1.26-7-7.54,15.53-9.81,12.64-3.44,19.92,12.38-20.45,14.72-18.91,8.91-38.3-7,9.87-19.74,9.78-23.84,27.61-28.65-5.33-2.36-11.34-6.12-18.61-9-9.08.34-8.91,2-14.45,9.33,5.79,7.17,4.65,8.71,12.8,4.3-1.48-5-.33-7.88-5.35-5.07-.44,2.75-1.17,3,1.65,3.38-4.33,0-3.73.25-3-4,8-4,6.28-1.05,9,7-10.6,6.13-9.58,3.4-18.19-5.22,6.25-9.89,5.87-11.36,17.36-12.91,15.92,6.77,29.91,22.85,19.89-1.54-3.16-3-3.69-2.42-8.09-1.89-.55,4.42-1.47,4.54,2.8,5.89,1.24-2.19,2.63-2.76,0-3.56-1.1.77-1.43.59-.62,1.71-1.46-1.5-1.34-1.21.38-2.38,4.07,1.43,2.47,1.83.62,5.47-5.69-1.63-4.4-2.19-4.29-8.08,5.53-1.15,5.92-1.78,10.32,1.7,2.26,5.89,2.53,11.86,6.19,14.44l4.61,1.79c30.83,11.4,54.28,29.15,75.43.25-19.55,38.78-43.4,14.79-82.2,3.67C520.13,442.25,520.82,445.67,511,461.76Z"
                transform="translate(-496.29 -380.81)"
              />
              <path
                className="brandbox-5"
                d="M637.35,435.31c-1.7,5.41-.7,6-6.36,5.36-.28-3.2-1.59-4.58,1.92-4.11.87,1.47,1.34,1.44-.18,2.31,2.48-1,2.19-.7.82-3-5.48-.48-3.84.82-3.61,6,7.46,1.11,6.25-.22,9.24-7.1-5.33-3.9-5.87-5-11.27-3.84,1.09-1.36,2.18-3.18,3.72-4.94,2.46-1.15,2.65-.68,5.12.5-.56,2.7,0,3-2.82,2.9-.29-1.53-1-2.14.73-2.08.49.67.72.63,0,1.12,1.15-.59,1-.44.25-1.47-2.67,0-1.82.58-1.45,3.09,3.66.18,3-.41,4.12-3.88-3-1.78-3.12-2.22-6.39-1.06-3.31,4-4.83,10.18-5.5,2.32.42-1.24.65-1.15,1.89-1.61.75,1.1,1,1,.06,2-.63-.41-1.08-.37-.48-1,.4.05.46,0,.4.37.18-.6.19-.51-.43-.58-.89.94-.41.83.58,1.54,1.29-1.21.87-1.19,0-2.74-1.63.45-1.82.34-2.51,1.86.21,1.88,1,3.5.33,4.69l-1,1.11c-7.1,5.48-7.68,27.94-39.68,25.06,32.09,9.58,33.22-16.67,42-24.94C632.24,431.37,632.53,432.38,637.35,435.31Z"
                transform="translate(-496.29 -380.81)"
              />
            </g>
          </svg>
        </div>
        <NavigationItem />
      </Fragment>
    );
  }
}

export default Navigation;
