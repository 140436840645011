import React, { Fragment, Component } from "react";
import anime from "animejs";
import "./AjovLogo.css";

class AjovLogo extends Component {
  componentDidMount() {
    var logoAnimation = (function () {
      var logoTimeline = anime.timeline({
        autoplay: false,
        direction: "alternate",
        loop: false,
      });

      logoTimeline
        //A BG
        .add(
          {
            targets: ".logo-a",
            scale: [{ value: [0, 1], duration: 200, easing: "easeOutBack" }],
          },
          0
        )
        //J BG
        .add(
          {
            targets: ".logo-j",
            translateY: { value: [-200, 0], duration: 500, elasticity: 400 },
            scale: [{ value: [0, 1], duration: 100, easing: "easeOutQuart" }],
            delay: 500,
          },
          0
        )
        //O BG
        .add(
          {
            targets: ".logo-o",
            translateX: { value: [200, 0], duration: 500, elasticity: 400 },
            scale: [{ value: [0, 1], duration: 100, easing: "easeOutQuart" }],
            delay: 1000,
          },
          0
        )
        //V BG
        .add(
          {
            targets: ".logo-v",
            translateY: { value: [200, 0], duration: 500, elasticity: 400 },
            scale: [{ value: [0, 1], duration: 100, easing: "easeOutQuart" }],
            delay: 1900,
          },
          0
        )

        //A
        .add(
          {
            targets: ".cls-stroke-a",
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: "easeInOutElastic(1, .6)",
            duration: 3000,
            delay: 1500,
          },
          0
        )
        .add(
          {
            targets: ".cls-bg-a",
            fill: "#FFF",
            easing: "easeInOutSine",
            delay: 3200,
          },
          0
        )
        //J
        .add(
          {
            targets: ".cls-stroke-j",
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: "cubicBezier(.5, .05, .1, .3)",
            duration: 3000,
            delay: 2200,
          },
          0
        )
        .add(
          {
            targets: ".cls-bg-j",
            fill: "#FFF",
            easing: "easeInOutSine",
            delay: 3500,
          },
          0
        )
        //O
        .add(
          {
            targets: ".cls-stroke-o",
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: "spring(1, 80, 10, 0)",
            duration: 3000,
            delay: 4700,
          },
          0
        )
        .add(
          {
            targets: ".cls-bg-o",
            fill: "#FFF",
            easing: "easeInOutSine",
            delay: 5500,
          },
          0
        )
        //V
        .add(
          {
            targets: ".cls-stroke-v",
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: "easeInSine",
            duration: 3000,
            delay: 5200,
          },
          0
        )
        .add(
          {
            targets: ".cls-bg-v",
            fill: "#FFF",
            easing: "easeInOutSine",
            delay: 8000,
          },
          0
        );

      function init() {
        logoTimeline.play();
      }

      return {
        init: init,
      };
    })();
    logoAnimation.init();
  }
  render() {
    return (
      <Fragment>
        <div id="ajovmain">
          <svg x="8.5" y="8.5" viewBox="0 0 123 123">
            <g id="a-tri" className="logo-a">
              <path
                id="a-tri-2"
                data-name="a-tri"
                className="cls-a"
                d="M199.84,337.85a10.24,10.24,0,0,0-14.45,0l-23.48,23.48L192.62,392a1.72,1.72,0,0,0,.43-.42l0,0,30.26-30.26Z"
                transform="translate(-130.97 -334.86)"
              />
              <path
                className="cls-stroke-a"
                d="M203.29,341.23a16.06,16.06,0,0,1,6.94,6.4,19.64,19.64,0,0,1,2.46,10.1v21H200.94v-8.62h-16.2v8.62H173.17v-21a19.63,19.63,0,0,1,2.46-10.1,16,16,0,0,1,6.94-6.4,25.5,25.5,0,0,1,20.71,0Zm-2.34,20.39v-4.84q0-4.28-2.2-6.45a9.12,9.12,0,0,0-11.81,0q-2.2,2.17-2.2,6.45v4.84Z"
                transform="translate(-130.97 -334.86)"
              />
              <path
                className="cls-bg-a"
                d="M203.29,341.23a16.06,16.06,0,0,1,6.94,6.4,19.64,19.64,0,0,1,2.46,10.1v21H200.94v-8.62h-16.2v8.62H173.17v-21a19.63,19.63,0,0,1,2.46-10.1,16,16,0,0,1,6.94-6.4,25.5,25.5,0,0,1,20.71,0Zm-2.34,20.39v-4.84q0-4.28-2.2-6.45a9.12,9.12,0,0,0-11.81,0q-2.2,2.17-2.2,6.45v4.84Z"
                transform="translate(-130.97 -334.86)"
              />
            </g>
            <g id="j-tri" className="logo-j">
              <path
                className="cls-j"
                d="M157.45,365.79,134,389.28a10.24,10.24,0,0,0,0,14.45l23.49,23.48,30.7-30.71Z"
                transform="translate(-130.97 -334.86)"
              />
              <path
                className="cls-stroke-j"
                d="M149.3,418.38a15.45,15.45,0,0,1-6.18-4.29l6.45-7.17q3.37,4.09,7.23,4.09a5.21,5.21,0,0,0,4-1.46,6,6,0,0,0,1.36-4.26V382.83H147.58v-8.57H174v30.37q0,7.62-4.13,11.43t-12.14,3.81A22.76,22.76,0,0,1,149.3,418.38Z"
                transform="translate(-130.97 -334.86)"
              />

              <clipPath id="svgVideoJ">
                <path
                  className="cls-video-j"
                  d="M149.3,418.38a15.45,15.45,0,0,1-6.18-4.29l6.45-7.17q3.37,4.09,7.23,4.09a5.21,5.21,0,0,0,4-1.46,6,6,0,0,0,1.36-4.26V382.83H147.58v-8.57H174v30.37q0,7.62-4.13,11.43t-12.14,3.81A22.76,22.76,0,0,1,149.3,418.38Z"
                  transform="translate(-130.97 -334.86)"
                />
              </clipPath>
              <path
                className="cls-bg-j"
                d="M149.3,418.38a15.45,15.45,0,0,1-6.18-4.29l6.45-7.17q3.37,4.09,7.23,4.09a5.21,5.21,0,0,0,4-1.46,6,6,0,0,0,1.36-4.26V382.83H147.58v-8.57H174v30.37q0,7.62-4.13,11.43t-12.14,3.81A22.76,22.76,0,0,1,149.3,418.38Z"
                transform="translate(-130.97 -334.86)"
              />
            </g>
            <g id="o-tri" className="logo-o">
              <path
                className="cls-o"
                d="M251.27,389.28l-23.43-23.43-.06-.06-30.7,30.71,30.7,30.71.06-.06,23.43-23.42A10.24,10.24,0,0,0,251.27,389.28Z"
                transform="translate(-130.97 -334.86)"
              />
              <path
                className="cls-stroke-o"
                d="M215.8,414.38a20.72,20.72,0,0,1-8.38-7.28,19.56,19.56,0,0,1,0-21,20.74,20.74,0,0,1,8.38-7.28,28.76,28.76,0,0,1,24,0,20.75,20.75,0,0,1,8.38,7.28,19.56,19.56,0,0,1,0,21,20.73,20.73,0,0,1-8.38,7.28,28.76,28.76,0,0,1-24,0Zm17.81-8a10.45,10.45,0,0,0,4.1-4,12.13,12.13,0,0,0,0-11.71,10.47,10.47,0,0,0-4.1-4,12.65,12.65,0,0,0-11.57,0,10.45,10.45,0,0,0-4.1,4,12.14,12.14,0,0,0,0,11.71,10.44,10.44,0,0,0,4.1,4,12.64,12.64,0,0,0,11.57,0Z"
                transform="translate(-130.97 -334.86)"
              />
              <path
                className="cls-bg-o"
                d="M215.8,414.38a20.72,20.72,0,0,1-8.38-7.28,19.56,19.56,0,0,1,0-21,20.74,20.74,0,0,1,8.38-7.28,28.76,28.76,0,0,1,24,0,20.75,20.75,0,0,1,8.38,7.28,19.56,19.56,0,0,1,0,21,20.73,20.73,0,0,1-8.38,7.28,28.76,28.76,0,0,1-24,0Zm17.81-8a10.45,10.45,0,0,0,4.1-4,12.13,12.13,0,0,0,0-11.71,10.47,10.47,0,0,0-4.1-4,12.65,12.65,0,0,0-11.57,0,10.45,10.45,0,0,0-4.1,4,12.14,12.14,0,0,0,0,11.71,10.44,10.44,0,0,0,4.1,4,12.64,12.64,0,0,0,11.57,0Z"
                transform="translate(-130.97 -334.86)"
              />
            </g>
            <g id="v-tri" className="logo-v">
              <path
                className="cls-v"
                d="M192.88,401.22l-.26-.26-30.71,30.71,23.48,23.48a10.24,10.24,0,0,0,14.45,0l23.49-23.48Z"
                transform="translate(-130.97 -334.86)"
              />
              <path
                className="cls-stroke-v"
                d="M215.75,416.48l-17.52,40.88H186.84l-17.46-40.87h12.5L193,443.1l11.33-26.63Z"
                transform="translate(-130.97 -334.86)"
              />
              <path
                className="cls-bg-v"
                d="M215.75,416.48l-17.52,40.88H186.84l-17.46-40.87h12.5L193,443.1l11.33-26.63Z"
                transform="translate(-130.97 -334.86)"
              />
            </g>
          </svg>
          {/* <iframe
            className="j-svg-clipped"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/or12kjVolzw?controls=0&autoplay=1&mute=1"
            title="J of AJOV"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
            allowFullScreen
    ></iframe> */}
        </div>
      </Fragment>
    );
  }
}

export default AjovLogo;
