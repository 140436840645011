import React, { Fragment, Component } from "react";
import "./SquareHeader.css";
import anime from "animejs";

class SquareHeader extends Component {
  componentDidMount() {
    var squareAnimation = (function () {
      var squareTimeline = anime({
        targets: "#header-squares .header-sqanimate",
        translateX: 270,
        delay: anime.stagger(100, { direction: "reverse" }),
        direction: "reverse",
      });

      function init() {
        squareTimeline.play();
      }

      return {
        init: init,
      };
    })();
    squareAnimation.init();
    setTimeout(() => {
      this.rotateHeaderSqaure();
    }, 20000);
  }
  rotateHeaderSqaure = () => {
    anime({
      targets: "#header-squares .header-sqanimate",
      //translateX: 0,
      opacity: function () {
        return anime.random(0, 1);
      },
      /*translateX: function () {
        return anime.random(0, 270);
      },*/
      /*rotate: function () {
        return anime.random(0, 360);
      },*/
      easing: "easeInOutQuad",
      delay: 100,
      duration: 3000,
      complete: this.rotateHeaderSqaure,
    });
  };
  render() {
    return (
      <Fragment>
        <div id="headersquareajov">
          <svg
            id="header-squares"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 263.4 141.36"
          >
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="0 107.83 7.33 107.83 7.33 100.58 0 100.58 0 107.83 0 107.83"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="0 49.16 7.33 49.16 7.33 41.91 0 41.91 0 49.16 0 49.16"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="0 7.26 7.33 7.26 7.33 0 0 0 0 7.26 0 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="8.47 124.6 15.8 124.6 15.8 117.34 8.47 117.34 8.47 124.6 8.47 124.6"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="8.47 99.45 15.8 99.45 15.8 92.2 8.47 92.2 8.47 99.45 8.47 99.45"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="8.47 82.69 15.8 82.69 15.8 75.43 8.47 75.43 8.47 82.69 8.47 82.69"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="8.47 57.55 15.8 57.55 15.8 50.29 8.47 50.29 8.47 57.55 8.47 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="8.47 24.02 15.8 24.02 15.8 16.76 8.47 16.76 8.47 24.02 8.47 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="8.47 15.64 15.8 15.64 15.8 8.38 8.47 8.38 8.47 15.64 8.47 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="8.47 7.26 15.8 7.26 15.8 0 8.47 0 8.47 7.26 8.47 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="16.93 141.36 24.27 141.36 24.27 134.1 16.93 134.1 16.93 141.36 16.93 141.36"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="16.93 107.83 24.27 107.83 24.27 100.58 16.93 100.58 16.93 107.83 16.93 107.83"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="16.93 99.45 24.27 99.45 24.27 92.2 16.93 92.2 16.93 99.45 16.93 99.45"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="16.93 65.93 24.27 65.93 24.27 58.67 16.93 58.67 16.93 65.93 16.93 65.93"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="16.93 49.16 24.27 49.16 24.27 41.91 16.93 41.91 16.93 49.16 16.93 49.16"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="16.93 40.78 24.27 40.78 24.27 33.52 16.93 33.52 16.93 40.78 16.93 40.78"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="16.93 32.4 24.27 32.4 24.27 25.14 16.93 25.14 16.93 32.4 16.93 32.4"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="16.93 15.64 24.27 15.64 24.27 8.38 16.93 8.38 16.93 15.64 16.93 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="16.93 7.26 24.27 7.26 24.27 0 16.93 0 16.93 7.26 16.93 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="25.4 132.98 32.73 132.98 32.73 125.72 25.4 125.72 25.4 132.98 25.4 132.98"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="25.4 116.22 32.73 116.22 32.73 108.96 25.4 108.96 25.4 116.22 25.4 116.22"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="25.4 91.07 32.73 91.07 32.73 83.81 25.4 83.81 25.4 91.07 25.4 91.07"
            />
            <polyline
              className="header-sqanimate header-squares-cls-3"
              points="25.4 67.05 25.4 74.31 25.4 74.31 32.73 74.31 32.73 67.05"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="25.4 65.93 32.73 65.93 32.73 58.67 25.4 58.67 25.4 65.93 25.4 65.93"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="25.4 49.16 32.73 49.16 32.73 41.91 25.4 41.91 25.4 49.16 25.4 49.16"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="25.4 40.78 32.73 40.78 32.73 33.52 25.4 33.52 25.4 40.78 25.4 40.78"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="25.4 24.02 32.73 24.02 32.73 16.76 25.4 16.76 25.4 24.02 25.4 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="25.4 15.64 32.73 15.64 32.73 8.38 25.4 8.38 25.4 15.64 25.4 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="25.4 7.26 32.73 7.26 32.73 0 25.4 0 25.4 7.26 25.4 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="33.87 99.45 41.2 99.45 41.2 92.2 33.87 92.2 33.87 99.45 33.87 99.45"
            />
            <polyline
              className="header-sqanimate header-squares-cls-3"
              points="33.87 67.05 33.87 74.31 33.87 74.31 41.2 74.31 41.2 67.05"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="33.87 49.16 41.2 49.16 41.2 41.91 33.87 41.91 33.87 49.16 33.87 49.16"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="33.87 32.4 41.2 32.4 41.2 25.14 33.87 25.14 33.87 32.4 33.87 32.4"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="33.87 24.02 41.2 24.02 41.2 16.76 33.87 16.76 33.87 24.02 33.87 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="33.87 7.26 41.2 7.26 41.2 0 33.87 0 33.87 7.26 33.87 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="42.34 91.07 49.67 91.07 49.67 83.81 42.34 83.81 42.34 91.07 42.34 91.07"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="42.34 82.69 49.67 82.69 49.67 75.43 42.34 75.43 42.34 82.69 42.34 82.69"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="42.34 65.93 49.67 65.93 49.67 58.67 42.34 58.67 42.34 65.93 42.34 65.93"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="42.34 57.55 49.67 57.55 49.67 50.29 42.34 50.29 42.34 57.55 42.34 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="42.34 49.16 49.67 49.16 49.67 41.91 42.34 41.91 42.34 49.16 42.34 49.16"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="42.34 40.78 49.67 40.78 49.67 33.52 42.34 33.52 42.34 40.78 42.34 40.78"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="42.34 32.4 49.67 32.4 49.67 25.14 42.34 25.14 42.34 32.4 42.34 32.4"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="42.34 24.02 49.67 24.02 49.67 16.76 42.34 16.76 42.34 24.02 42.34 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="42.34 15.64 49.67 15.64 49.67 8.38 42.34 8.38 42.34 15.64 42.34 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="42.34 7.26 49.67 7.26 49.67 0 42.34 0 42.34 7.26 42.34 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="50.8 132.98 58.13 132.98 58.13 125.72 50.8 125.72 50.8 132.98 50.8 132.98"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="50.8 116.22 58.13 116.22 58.13 108.96 50.8 108.96 50.8 116.22 50.8 116.22"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="50.8 91.07 58.13 91.07 58.13 83.81 50.8 83.81 50.8 91.07 50.8 91.07"
            />
            <polyline
              className="header-sqanimate header-squares-cls-3"
              points="50.8 67.05 50.8 74.31 50.8 74.31 58.13 74.31 58.13 67.05"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="50.8 65.93 58.13 65.93 58.13 58.67 50.8 58.67 50.8 65.93 50.8 65.93"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="50.8 49.16 58.13 49.16 58.13 41.91 50.8 41.91 50.8 49.16 50.8 49.16"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="50.8 32.4 58.13 32.4 58.13 25.14 50.8 25.14 50.8 32.4 50.8 32.4"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="50.8 24.02 58.13 24.02 58.13 16.76 50.8 16.76 50.8 24.02 50.8 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="50.8 15.64 58.13 15.64 58.13 8.38 50.8 8.38 50.8 15.64 50.8 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="59.27 99.45 66.6 99.45 66.6 92.2 59.27 92.2 59.27 99.45 59.27 99.45"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="59.27 82.69 66.6 82.69 66.6 75.43 59.27 75.43 59.27 82.69 59.27 82.69"
            />
            <polyline
              className="header-sqanimate header-squares-cls-3"
              points="59.27 67.05 59.27 74.31 59.27 74.31 66.6 74.31 66.6 67.05"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="59.27 49.16 66.6 49.16 66.6 41.91 59.27 41.91 59.27 49.16 59.27 49.16"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="59.27 40.78 66.6 40.78 66.6 33.52 59.27 33.52 59.27 40.78 59.27 40.78"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="59.27 24.02 66.6 24.02 66.6 16.76 59.27 16.76 59.27 24.02 59.27 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="59.27 15.64 66.6 15.64 66.6 8.38 59.27 8.38 59.27 15.64 59.27 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="59.27 7.26 66.6 7.26 66.6 0 59.27 0 59.27 7.26 59.27 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="67.74 124.6 75.07 124.6 75.07 117.34 67.74 117.34 67.74 124.6 67.74 124.6"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="67.74 107.83 75.07 107.83 75.07 100.58 67.74 100.58 67.74 107.83 67.74 107.83"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="67.74 82.69 75.07 82.69 75.07 75.43 67.74 75.43 67.74 82.69 67.74 82.69"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="67.74 65.93 75.07 65.93 75.07 58.67 67.74 58.67 67.74 65.93 67.74 65.93"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="67.74 57.55 75.07 57.55 75.07 50.29 67.74 50.29 67.74 57.55 67.74 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="67.74 40.78 75.07 40.78 75.07 33.52 67.74 33.52 67.74 40.78 67.74 40.78"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="67.74 32.4 75.07 32.4 75.07 25.14 67.74 25.14 67.74 32.4 67.74 32.4"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="67.74 24.02 75.07 24.02 75.07 16.76 67.74 16.76 67.74 24.02 67.74 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="67.74 7.26 75.07 7.26 75.07 0 67.74 0 67.74 7.26 67.74 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="76.2 91.07 83.53 91.07 83.53 83.81 76.2 83.81 76.2 91.07 76.2 91.07"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="76.2 65.93 83.53 65.93 83.53 58.67 76.2 58.67 76.2 65.93 76.2 65.93"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="76.2 49.16 83.53 49.16 83.53 41.91 76.2 41.91 76.2 49.16 76.2 49.16"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="76.2 40.78 83.53 40.78 83.53 33.52 76.2 33.52 76.2 40.78 76.2 40.78"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="76.2 24.02 83.53 24.02 83.53 16.76 76.2 16.76 76.2 24.02 76.2 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="76.2 15.64 83.53 15.64 83.53 8.38 76.2 8.38 76.2 15.64 76.2 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="76.2 7.26 83.53 7.26 83.53 0 76.2 0 76.2 7.26 76.2 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="84.67 99.45 92 99.45 92 92.2 84.67 92.2 84.67 99.45 84.67 99.45"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="84.67 49.16 92 49.16 92 41.91 84.67 41.91 84.67 49.16 84.67 49.16"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="84.67 32.4 92 32.4 92 25.14 84.67 25.14 84.67 32.4 84.67 32.4"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="84.67 15.64 92 15.64 92 8.38 84.67 8.38 84.67 15.64 84.67 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="84.67 7.26 92 7.26 92 0 84.67 0 84.67 7.26 84.67 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="93.14 82.69 100.47 82.69 100.47 75.43 93.14 75.43 93.14 82.69 93.14 82.69"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="93.14 65.93 100.47 65.93 100.47 58.67 93.14 58.67 93.14 65.93 93.14 65.93"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="93.14 7.26 100.47 7.26 100.47 0 93.14 0 93.14 7.26 93.14 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="101.61 40.78 108.94 40.78 108.94 33.52 101.61 33.52 101.61 40.78 101.61 40.78"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="101.61 24.02 108.94 24.02 108.94 16.76 101.61 16.76 101.61 24.02 101.61 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="101.61 15.64 108.94 15.64 108.94 8.38 101.61 8.38 101.61 15.64 101.61 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="101.61 7.26 108.94 7.26 108.94 0 101.61 0 101.61 7.26 101.61 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="110.07 57.55 117.4 57.55 117.4 50.29 110.07 50.29 110.07 57.55 110.07 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="110.07 15.64 117.4 15.64 117.4 8.38 110.07 8.38 110.07 15.64 110.07 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="110.07 7.26 117.4 7.26 117.4 0 110.07 0 110.07 7.26 110.07 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="118.54 24.02 125.87 24.02 125.87 16.76 118.54 16.76 118.54 24.02 118.54 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="118.54 15.64 125.87 15.64 125.87 8.38 118.54 8.38 118.54 15.64 118.54 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="118.54 7.26 125.87 7.26 125.87 0 118.54 0 118.54 7.26 118.54 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="127.01 15.64 134.34 15.64 134.34 8.38 127.01 8.38 127.01 15.64 127.01 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="127.01 7.26 134.34 7.26 134.34 0 127.01 0 127.01 7.26 127.01 7.26"
            />
            <polyline
              className="header-sqanimate header-squares-cls-4"
              points="142.81 8.38 135.47 8.38 135.47 15.64 135.47 15.64 142.81 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="136 7.26 143.33 7.26 143.33 0 136 0 136 7.26 136 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="144.47 7.26 151.8 7.26 151.8 0 144.47 0 144.47 7.26 144.47 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="152.94 7.26 160.26 7.26 160.26 0 152.94 0 152.94 7.26 152.94 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="161.4 7.26 168.73 7.26 168.73 0 161.4 0 161.4 7.26 161.4 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="169.87 7.26 177.2 7.26 177.2 0 169.87 0 169.87 7.26 169.87 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="178.34 7.26 185.67 7.26 185.67 0 178.34 0 178.34 7.26 178.34 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="187.27 7.26 194.6 7.26 194.6 0 187.27 0 187.27 7.26 187.27 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="195.74 7.26 203.07 7.26 203.07 0 195.74 0 195.74 7.26 195.74 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="204.21 7.26 211.53 7.26 211.53 0 204.21 0 204.21 7.26 204.21 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="212.67 7.26 220 7.26 220 0 212.67 0 212.67 7.26 212.67 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="221.14 7.26 228.47 7.26 228.47 0 221.14 0 221.14 7.26 221.14 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="229.61 7.26 236.94 7.26 236.94 0 229.61 0 229.61 7.26 229.61 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="238.07 7.26 245.4 7.26 245.4 0 238.07 0 238.07 7.26 238.07 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="246.54 7.26 253.87 7.26 253.87 0 246.54 0 246.54 7.26 246.54 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-2"
              points="255.01 7.26 262.34 7.26 262.34 0 255.01 0 255.01 7.26 255.01 7.26"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="144.61 15.64 151.94 15.64 151.94 8.38 144.61 8.38 144.61 15.64 144.61 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="153.07 15.64 160.4 15.64 160.4 8.38 153.07 8.38 153.07 15.64 153.07 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="161.54 15.64 168.87 15.64 168.87 8.38 161.54 8.38 161.54 15.64 161.54 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="170.01 15.64 177.34 15.64 177.34 8.38 170.01 8.38 170.01 15.64 170.01 15.64"
            />
            <polyline
              className="header-sqanimate header-squares-cls-4"
              points="185.81 8.38 178.47 8.38 178.47 15.64 178.47 15.64 185.81 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="195.61 15.64 202.94 15.64 202.94 8.38 195.61 8.38 195.61 15.64 195.61 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="204.07 15.64 211.4 15.64 211.4 8.38 204.07 8.38 204.07 15.64 204.07 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="212.54 15.64 219.87 15.64 219.87 8.38 212.54 8.38 212.54 15.64 212.54 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-4"
              points="221.01 15.64 228.34 15.64 228.34 8.38 221.01 8.38 221.01 15.64 221.01 15.64"
            />
            <polyline
              className="header-sqanimate header-squares-cls-4"
              points="236.81 8.38 229.47 8.38 229.47 15.64 229.47 15.64 236.81 15.64"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="134.47 24.02 141.8 24.02 141.8 16.76 134.47 16.76 134.47 24.02 134.47 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="151.4 24.02 158.73 24.02 158.73 16.76 151.4 16.76 151.4 24.02 151.4 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="159.87 24.02 167.2 24.02 167.2 16.76 159.87 16.76 159.87 24.02 159.87 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="168.34 24.02 175.67 24.02 175.67 16.76 168.34 16.76 168.34 24.02 168.34 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="176.8 24.02 184.13 24.02 184.13 16.76 176.8 16.76 176.8 24.02 176.8 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="185.27 24.02 192.6 24.02 192.6 16.76 185.27 16.76 185.27 24.02 185.27 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="193.74 24.02 201.07 24.02 201.07 16.76 193.74 16.76 193.74 24.02 193.74 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="202.21 24.02 209.53 24.02 209.53 16.76 202.21 16.76 202.21 24.02 202.21 24.02"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="118.07 57.55 125.4 57.55 125.4 50.29 118.07 50.29 118.07 57.55 118.07 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="126.07 57.55 133.4 57.55 133.4 50.29 126.07 50.29 126.07 57.55 126.07 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="134.07 57.55 141.4 57.55 141.4 50.29 134.07 50.29 134.07 57.55 134.07 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="142.07 57.55 149.4 57.55 149.4 50.29 142.07 50.29 142.07 57.55 142.07 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="150.07 57.55 157.4 57.55 157.4 50.29 150.07 50.29 150.07 57.55 150.07 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="158.07 57.55 165.4 57.55 165.4 50.29 158.07 50.29 158.07 57.55 158.07 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="166.07 57.55 173.4 57.55 173.4 50.29 166.07 50.29 166.07 57.55 166.07 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="181.94 57.57 189.26 57.57 189.26 50.31 181.94 50.31 181.94 57.57 181.94 57.57"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="190.4 57.57 197.73 57.57 197.73 50.31 190.4 50.31 190.4 57.57 190.4 57.57"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="198.87 57.57 206.2 57.57 206.2 50.31 198.87 50.31 198.87 57.57 198.87 57.57"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="207.34 57.57 214.67 57.57 214.67 50.31 207.34 50.31 207.34 57.57 207.34 57.57"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="215.8 57.57 223.13 57.57 223.13 50.31 215.8 50.31 215.8 57.57 215.8 57.57"
            />
            <polygon
              className="header-sqanimate header-squares-cls-3"
              points="224.27 57.57 231.6 57.57 231.6 50.31 224.27 50.31 224.27 57.57 224.27 57.57"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="240.07 57.55 247.4 57.55 247.4 50.29 240.07 50.29 240.07 57.55 240.07 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="248.07 57.55 255.4 57.55 255.4 50.29 248.07 50.29 248.07 57.55 248.07 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="256.07 57.55 263.4 57.55 263.4 50.29 256.07 50.29 256.07 57.55 256.07 57.55"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="126.07 66.07 133.4 66.07 133.4 58.81 126.07 58.81 126.07 66.07 126.07 66.07"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="134.07 66.07 141.4 66.07 141.4 58.81 134.07 58.81 134.07 66.07 134.07 66.07"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="142.07 66.07 149.4 66.07 149.4 58.81 142.07 58.81 142.07 66.07 142.07 66.07"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="190.07 66.07 197.4 66.07 197.4 58.81 190.07 58.81 190.07 66.07 190.07 66.07"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="199.07 66.07 206.4 66.07 206.4 58.81 199.07 58.81 199.07 66.07 199.07 66.07"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="216.07 66.07 223.4 66.07 223.4 58.81 216.07 58.81 216.07 66.07 216.07 66.07"
            />
            <polygon
              className="header-sqanimate header-squares-cls-1"
              points="224.07 66.07 231.4 66.07 231.4 58.81 224.07 58.81 224.07 66.07 224.07 66.07"
            />
          </svg>
        </div>
      </Fragment>
    );
  }
}

export default SquareHeader;
